import React from 'react';
import {
	Container,
	Row,
	Col,
	Form,
	FloatingLabel,
	Button,
} from 'react-bootstrap';
import ThreadComponent from '../ThreadComponent';

import { Link } from 'react-router-dom';
import URI from '../../../defaults/RoutesDefault';
import { ApiService } from '../../../lib/api/HttpService';
import { generatePath } from 'react-router-dom';
import {
	clearUserData,
	setCookie,
	isLoggedIn,
	parseJwt,
} from '../../../utilities/Auth';

import logo from '../../../assets/images/logo.svg';
import logoDark from '../../../assets/images/logo-dark.svg';

import briaHammel from '../../../assets/images/bria-hammel.png';
import thomFilicia from '../../../assets/images/thom-filicia.png';
import libbyLangdon from '../../../assets/images/libby-langdon.png';
import { AlertError } from '../../../app/components/bootstrap/BAlerts';
import { setLocalStorage } from '../../../utilities/LocalStorage';
import { PRO_CLOUD_LOGIN_PAGE } from 'legacy/app/context/security';
import { isUserValid } from 'legacy/lib/api/utils/utils';

import { FeatureFlagChecker } from 'legacy/app/components/FeatureFlagChecker/FeatureFlagChecker';
import { FeatureFlags } from 'legacy/app/enums/featureFlags/featureFlags';
import { getInstances } from 'api/resources/user';
import { getCookie } from 'utils/cookies/cookies';
import { displayAlert } from 'legacy/utilities/Response';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';
class Login extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			showLoggedOutModal: false,
			errorMessage: '',
			showErrorMessage: false,
			showLoginPage: false,
			isLoggingIn: false,
			instanceError: false,
			isTrialDemoBannerEnabled: false,
		};

		this.api = new ApiService();
		this.loginData = {};
		this.redirectLocation = '/';
		this.cookieExpiry = 0;
	}

	async componentDidMount() {
		const searchParams = new URLSearchParams(window.location.search);

		if (searchParams.get('loggedOut')) {
			this.setState({
				showLoggedOutModal: true,
			});
		}

		const location = window.localStorage.getItem('location');
		this.redirectLocation = location ? location : '/';

		if (isLoggedIn()) {
			window.localStorage.removeItem('location');
			window.onbeforeunload = null;
			setTimeout(() => {
				window.location.href = generatePath(this.redirectLocation);
			}, 1500);
		} else {
			clearUserData();
			this.setState({
				showLoginPage: true,
			});
		}
	}

	setDefaultInstance = async () => {
		if (this.state.isTrialDemoBannerEnabled) {
			const userName = getCookie('dmUsername');
			try {
				const instances = await getInstances(userName);
				const defaultInstance = instances.find((i) => i.defaultInstance);
				if (defaultInstance) {
					setCookie(
						'dmInstanceId',
						defaultInstance.instanceId,
						this.cookieExpiry
					);
				}
			} catch (error) {
				const errorMessage =
					error.response?.data?.userError || 'Unable to set default instance';
				displayAlert('danger', errorMessage);
			}
		}
	};

	handleLoginClick = async (e) => {
		this.setState({
			isLoggingIn: true,
			showErrorMessage: false,
			instanceError: false,
		});
		let token = '';
		this.cookieExpiry = this.loginData.remember ? 7 : 0;

		await this.api
			.getAuthToken(this.loginData)
			.then((resp) => {
				token = resp;
			})
			.catch((err) => {
				this.setState({
					showErrorMessage: true,
					errorMessage: 'Incorrect username or password.',
					isLoggingIn: false,
				});
			});

		if (token !== '') {
			setCookie('dmAuthToken', token.access_token, this.cookieExpiry);
			const parsedToken = parseJwt(token.access_token);
			const instances = JSON.parse(parsedToken.DmInstances);
			setLocalStorage('userInstances', instances, true);
			const isImpersonating = parsedToken?.ImpersonationMode ?? false;
			const userName = isImpersonating
				? parsedToken.name
				: this.loginData.username;

			setCookie('dmInstanceId', instances[0].Id, this.cookieExpiry);

			await this.api
				.getUserData(`?$filter=password eq '${encodeURIComponent(userName)}'`)
				.then((resp) => {
					const [data] = resp;
					setCookie('dmUsercode', data.usercode, this.cookieExpiry);
					setCookie('dmUsername', data.username, this.cookieExpiry);
					setCookie(
						'dmLoginUsername',
						this.loginData.username,
						this.cookieExpiry
					);
					setCookie('dmUserEmail', data.useremail, this.cookieExpiry);

					window.localStorage.removeItem('location');
					window.onbeforeunload = null;

					setTimeout(() => {
						window.location.href = generatePath(this.redirectLocation);
					}, 1500);
				})
				.catch((err) => {
					if (!isUserValid(err)) {
						return this.setState({
							showErrorMessage: true,
							isLoggingIn: false,
							instanceError: true,
							errorMessage:
								'Web DM is not available for your company at this time.  Please continue to Pro Cloud via',
						});
					}
					this.setState({
						isLoggingIn: false,
					});
				});

			await this.setDefaultInstance();
		}
	};

	handleChange = (e) => {
		let key, value;
		if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.name;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}
		this.loginData[key] = value;
	};

	renderSpinner() {
		return (
			<div className="flex-grow-1 loginRight-form">
				<div>
					<div
						className="d-flex justify-content-center align-items-center"
						style={{ height: '10vh' }}
					>
						<div
							className="spinner-border"
							style={{ width: '3rem', height: '3rem' }}
							role="status"
						></div>
					</div>
					<p className="text-secondary-ash fsx-16 mb-4 text-center">Loading</p>
				</div>
			</div>
		);
	}

	setFeatureValue = (enabled) => {
		this.setState({ isTrialDemoBannerEnabled: enabled });
	};

	render() {
		return (
			<>
				<ConfirmationModal
					title=""
					confirmAction={() =>
						this.setState({
							showLoggedOutModal: false,
						})
					}
					hideCancel
					show={this.state.showLoggedOutModal}
				>
					<div className="tw-p-4 tw-m-0">
						<p className="tw-text-base">
							Another person has logged into Design Manager using the same
							username as you.
						</p>
						<p className="tw-text-base tw-m-0">
							Logins are for single users only. To avoid this, we suggest you
							purchase additional users. Click{' '}
							<span>
								<a
									target="_blank"
									href={`${window.location.protocol}//${window.location.host}${URI.settings.userManagement.add}`}
									rel="noreferrer"
								>
									here
								</a>
							</span>{' '}
							to add a new user.
						</p>
					</div>
				</ConfirmationModal>
				<FeatureFlagChecker
					callback={this.setFeatureValue}
					feature={FeatureFlags.TrialDemoBanner}
				/>
				<form onSubmit={(e) => e.preventDefault()}>
					<Container fluid className="loginContainer d-lg-flex">
						<div className="p-4 mb-5 d-lg-none">
							<img
								src={logoDark}
								width="100%"
								height="auto"
								className="logo"
								alt=""
							/>
						</div>
						<Row className="w-100 justify-content-center">
							<Col lg={4} className="d-none d-lg-flex loginLeft flex-column">
								<div className="p-4 flex-grow-1">
									<img
										src={logo}
										width="100%"
										height="auto"
										className="logo"
										alt=""
									/>
								</div>
								<div className="loginLeft-footer">
									<div className="mb-3">
										TRUSTED BY THOUSANDS OF INTERIOR DESIGNERS
									</div>

									<div className="logos px-4 pb-4">
										<img src={briaHammel} alt="" />

										<img src={thomFilicia} alt="" />

										<img src={libbyLangdon} alt="" />
									</div>
								</div>
							</Col>

							<Col lg={8} className="d-flex loginRight flex-column">
								<div className="flex-grow-1 loginRight-form">
									{this.state.showLoginPage ? (
										<div>
											<h1 className="fsx-40 mb-4">Sign into Design Manager</h1>

											<div className="d-grid gap-3">
												<FloatingLabel controlId="username" label="Username">
													<Form.Control
														type="text"
														placeholder="Username"
														size="lg"
														name="username"
														onChange={this.handleChange}
													/>
												</FloatingLabel>
												<FloatingLabel controlId="password" label="Password">
													<Form.Control
														type="password"
														placeholder="Password"
														size="lg"
														name="password"
														onChange={this.handleChange}
													/>
												</FloatingLabel>
												<Form.Check
													inline
													label="Remember me"
													name="remember"
													type="checkbox"
													id={`checkbox-remember`}
													onChange={this.handleChange}
												/>
												{this.state.showErrorMessage && (
													<AlertError
														isClose={false}
														className="compact mb-1"
														fw="fw-400"
													>
														{`${this.state.errorMessage}  `}
														{this.state.instanceError && (
															<a
																className="!tw-font-bold !tw-text-[#212529]"
																target="_blank"
																rel="noreferrer"
																href={PRO_CLOUD_LOGIN_PAGE}
															>
																{`  ${PRO_CLOUD_LOGIN_PAGE}`}
															</a>
														)}
													</AlertError>
												)}
												<Button
													variant="primary"
													size="lg"
													onClick={this.handleLoginClick}
													disabled={this.state.isLoggingIn}
													type="submit"
												>
													{this.state.isLoggingIn ? 'Signing in...' : 'Sign in'}
												</Button>
												<div className="text-center">
													<Link to={URI.login.password}>
														Forgot your password?
													</Link>
												</div>
												<p className="tw-m-4 tw-text-base tw-text-center">
													Logins are for single users only. By logging in with a
													shared username you could take over another person’s
													session causing them to lose their work.
												</p>
											</div>
										</div>
									) : (
										this.renderSpinner()
									)}
								</div>
								<div className="loginRight-footer py-4">
									By signing in to Design Manager, you agree to our{' '}
									<a href="https://www.designmanager.com/terms">
										Terms of Service
									</a>{' '}
									and{' '}
									<a href="https://www.designmanager.com/privacy-policy">
										Privacy Policy
									</a>
									.
								</div>
							</Col>
						</Row>
					</Container>
				</form>
			</>
		);
	}
}

export default Login;
