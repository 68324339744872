import { useMutation } from '@tanstack/react-query';
import {
	patchDefaultInstance,
	type IDefaultInstanceParams,
} from 'api/resources/user';

export const usePatchUserDefaultInstance = () => {
	return useMutation((params: IDefaultInstanceParams) =>
		patchDefaultInstance(params)
	);
};
