import React from 'react';
import { Button, Col, Modal, Row, Table, Form } from 'react-bootstrap';
import { WithRouter } from '../../../../helpers/Router';
import { displayAlert } from '../../../../utilities/Response';
import ThreadComponent from '../../ThreadComponent';
import { debounce, isEmpty, toLower } from 'lodash';
import {
	getItemInvoiceToDate,
	getItemPrice,
	getItemQuantity,
} from '../../../../utilities/modules/ClientInvoiceItem';
import { getCheckAllClass } from '../../../../utilities/ModuleHelper';
import { showEmpty, showLoading } from '../../../../helpers/Loading';
import { Event } from '../../../../utilities/DOM';
import { currencyFormat } from '../../../../helpers/Number';
import { Link } from 'react-router-dom';
import {
	sortLocalData,
	tableSortingEnableSort,
} from '../../../../utilities/modules/TableSorting';
import { tableSearch } from '../../../../utilities/modules/TableSearch';
import { SearchExpressionButton } from '../../../components/SearchExpressionButton';
import Select from 'react-select';
import { delay } from 'lodash/function';
import FilterSort from '../../../../utilities/modules/FilterSort';
import { getStatusColor } from '../../../../helpers/Util';
class ClientInvoiceAddInvoiceAddItemModal extends ThreadComponent {
	constructor(props) {
		super(props);

		this.fs = new FilterSort('ar_client_invoice_add_modal');
		this.fs.setDefaultSort('item asc');
		this.state = {
			data: [],
			show: false,
			checks: [],
			totalChecked: 0,
			isImagesLoaded: true,
			sort: {},
			page: 1,
			pageSize: { value: 10, label: '10' },
			processedData: [],
			showTableSearch: this.fs.isSearchActive(),
			searchProperties: this.fs.getSearches(),
		};
		this.availablePageSize = [
			{ value: '10', label: '10' },
			{ value: '20', label: '20' },
			{ value: '50', label: '50' },
			{ value: '75', label: '75' },
			{ value: '100', label: '100' },
		];
		this.setIsChildSpinner(true);
		this.searchHandler = debounce(this.handleDoSearch.bind(this), 200);

		this.changePageHandler = debounce(this.handleChangePage.bind(this, 1), 200);

		this.sortPattern = (key) => [
			(d) => {
				if (key === '_itemprice') {
					return getItemPrice(d);
				}

				if (key === '_invqty') {
					return getItemQuantity(d);
				}

				if (key === '_invtodate') {
					return getItemInvoiceToDate(d);
				}

				if (typeof d[key] === 'string') {
					return toLower(d[key]);
				}

				return d[key] ?? '';
			},
		];
	}

	async componentDidMount() {
		const { data, show, isImagesLoaded } = this.props;
		await this.doSearchData(data, true);
		this.setState({
			show,
			totalChecked: 0,
			checks: [],
			isImagesLoaded,
		});
	}

	componentDidUpdate(previousProps, previousState) {
		if (
			previousProps.data !== this.props.data ||
			previousProps.show !== this.props.show ||
			previousProps.isImagesLoaded !== this.props.isImagesLoaded
		) {
			const { data, show, isImagesLoaded } = this.props;

			this.setState({
				show,
				totalChecked: 0,
				checks: [],
				isImagesLoaded,
			});

			this.doSearchData(data, true);

			delay(() => {
				const sort = document.querySelectorAll(
					'.a-table-heading.in-modal .sort'
				);
				if (sort?.length && !window.enableSortTableModal) {
					window.enableSortTableModal = true;
					this.enableSortTable();
				}
			}, 250);
		}
	}

	paginateLocalData = async (data) => {
		const newData = (data ?? []).slice(
			(this.state.page - 1) * this.state.pageSize.value,
			this.state.page * this.state.pageSize.value
		);

		this.setState({
			data: newData,
			processedData: data ?? [],
		});
	};

	handleFilter = (name) => (e) => {
		this.setState(
			{
				[name]: e,
				page: 1,
			},
			this.changePageHandler
		);
	};

	handleChangePage = async (page) => {
		this.setState(
			{
				page,
			},
			() => {
				this.doSearchData(this.props.data, true);
			}
		);
	};

	handleSelectAllChecks = ({ target }) =>
		this.setState({
			checks: target.checked
				? this.state.data.map((item) => {
						return item.id;
				  })
				: [],
			totalChecked: target.checked ? this.state.data.length : 0,
		});

	handleChecks = (id) => {
		const checks = this.state.checks;
		const index = checks.findIndex((_id) => _id === id);

		index > -1 ? checks.splice(index, 1) : checks.push(id);
		this.setState({
			checks: checks,
			totalChecked: checks.length,
		});
	};

	addItemClick = (e) => {
		let ids = this.state.checks;

		if (isEmpty(ids)) {
			displayAlert('danger', 'Please select at least one item', 2000);
		} else {
			if (this.props.addItemCall) {
				this.props.addItemCall(ids);
			}
		}
	};

	enableSortTable = () =>
		tableSortingEnableSort({
			data: this.props.data,
			classRef: this,
			targetTable: '.a-table-heading.in-modal',
			pattern: this.sortPattern,
			callback: (data, sort) => {
				this.doSearchData(data);

				this.setState({
					sortProperty: sort,
				});

				// Save sortProperty to localStorage
				this.fs.setSort(sort);
			},
		});

	handleShowTableSearch = (e) => {
		if (!this.props.data.length) {
			return;
		}

		this.fs.setIsActiveSearch(!this.state.showTableSearch);
		this.setState(
			{
				showTableSearch: !this.state.showTableSearch,
			},
			() => {
				if (!this.state.showTableSearch) {
					this.fs.setSearches({});
					Event('.a-table-search-fields.unselected .form-control').value('');
					this.setState(
						{
							searchProperties: {},
						},
						() => {
							this.doSearchData(this.props.data, true);
						}
					);
				}
			}
		);
	};

	handleSearch = (e) => {
		const key = e.target.attributes['data-field'].value;
		const custom = Boolean(e.target.attributes['data-custom']?.value);
		const value = e.target.value;
		const type = e.target.attributes['data-type']
			? e.target.attributes['data-type'].value
			: 'string';
		const exp = e.target.attributes['placeholder']?.value ?? '>=';

		this.setState(
			{
				searchProperties: {
					...this.state.searchProperties,
					[key]: { value, type, exp, custom },
				},
			},
			() => {
				this.fs.setSearches(this.state.searchProperties);
				this.searchHandler();
			}
		);
	};

	doSearchData = (data, isSort = false) =>
		tableSearch({
			data,
			searchProps: this.state.searchProperties,
			callback: async (data) => {
				if (isSort) {
					await this.paginateLocalData(
						sortLocalData(this, data, this.sortPattern)
					);
				} else {
					this.paginateLocalData(data);
				}
			},
			customFilter: (item, key) => {
				switch (key) {
					case '_itemprice':
						return getItemPrice(item);
					case '_invtodate':
						return getItemInvoiceToDate(item);
					case '_invqty':
						return getItemQuantity(item);
					default:
						return item[key];
				}
			},
		});

	handleDoSearch = () => {
		// reset the current page to 1 when searching
		this.setState(
			{
				page: 1,
			},
			() => {
				this.doSearchData(this.props.data, true);
			}
		);
	};

	sortClass(name) {
		return `sort ${this.fs.isActiveSort(name)}`;
	}

	isNextDisabled() {
		const { processedData, page, pageSize } = this.state;
		const total = page * pageSize.value;

		return Number(processedData?.length) <= total;
	}

	renderPagination() {
		return (
			<div className={'d-flex flex-wrap justify-content-center'}>
				<div className={'d-flex flex-row align-items-center pagination'}>
					<Button
						variant="ivory"
						size="sm"
						className={'btn-icon pagination-btn'}
						disabled={this.state.page === 1}
						onClick={() => this.handleChangePage(this.state.page - 1)}
					>
						<i className="ri-arrow-left-s-line"></i> Previous
					</Button>
					<span className={'pagination-span'}>{this.state.page}</span>
					<Button
						variant="ivory"
						size="sm"
						className={'btn-icon pagination-btn'}
						disabled={this.isNextDisabled()}
						onClick={() => this.handleChangePage(this.state.page + 1)}
					>
						Next <i className="ri-arrow-right-s-line ms-1"></i>
					</Button>
				</div>
				<Select
					onChange={this.handleFilter('pageSize')}
					key={`${Math.floor(Math.random() * 1000)}-min`}
					options={this.availablePageSize}
					defaultValue={this.state.pageSize}
					className="react-select pagination-select mx-3"
					placeholder="Please select"
				/>
			</div>
		);
	}

	render() {
		const { checks, totalChecked, data, isImagesLoaded } = this.state;
		const { statusCodes } = this.props;
		this.setIsLoaded(this.props.dataIsLoaded);
		return (
			<Modal
				size="xxl"
				show={this.state.show}
				onHide={() => this.props.hideModal.bind(null)}
				aria-labelledby="example-modal-sizes-title-lg"
				className="right a-modal modal-quarter fixed-footer"
				backdrop="static"
			>
				<Modal.Header className="bg-ivory py-4 px-5">
					<Row className="w-100">
						<Col className="text-start">
							<Button
								as={Link}
								to="#"
								variant="ivory"
								size="sm"
								className={`d-inline-block ms-3 mt-2 btn-icon btn-action fw-bold ${
									this.state.showTableSearch ? 'bg-primary-ash text-white' : ''
								}`}
								onClick={this.handleShowTableSearch}
							>
								<i className="ri-search-line"></i> Search
							</Button>
						</Col>
						<Col className="text-end">
							<Button
								variant="trans-light border-secondary-ash"
								onClick={this.props.hideModal.bind(null)}
								className="d-inline-block w-150px"
							>
								Cancel
							</Button>
							<Button
								variant="primary"
								onClick={this.addItemClick.bind(null)}
								className="d-inline-block w-150px ms-3"
							>
								Save
							</Button>
						</Col>
					</Row>
				</Modal.Header>
				<Modal.Body className="bg-ivory">
					<Row className="w-100 px-5">
						<Col sm={12}>
							<div className="table-gradient mb-5 sticky-container">
								{this.renderView(
									<Table striped responsive className="a-table">
										<thead>
											<tr key="0" className="a-table-heading in-modal">
												<th className="ps-4">
													<Form.Check
														inline
														label=""
														name={`group-all`}
														type="checkbox"
														data-id={`th-0`}
														id={`inline-radio-th-0-modal`}
														checked={totalChecked > 0}
														className={getCheckAllClass(totalChecked, data)}
														onChange={this.handleSelectAllChecks}
													/>
												</th>
												<th>Image</th>
												<th className="mw-100px">
													<span
														className={this.sortClass('loc')}
														data-field="loc"
													>
														Location
													</span>
												</th>
												<th className="mw-90px">
													<span
														className={this.sortClass('item')}
														data-field="item"
													>
														Ref. No.
													</span>
												</th>
												<th className="mw-180px">
													<span
														className={this.sortClass('latestprpno')}
														data-field="latestprpno"
													>
														Last Prop#
													</span>
												</th>
												<th>
													<span
														className={this.sortClass('shortdesc')}
														data-field="shortdesc"
													>
														Description
													</span>
												</th>
												<th className="mw-140px">
													<span
														className={this.sortClass('statusName')}
														data-field="statusName"
													>
														Status
													</span>
												</th>
												<th className="mw-100px">
													<span
														className={this.sortClass('_invqty')}
														data-field="_invqty"
													>
														Quantity
													</span>
												</th>
												<th className="mw-100px">
													<span
														className={this.sortClass('_itemprice')}
														data-field="_itemprice"
													>
														Price
													</span>
												</th>
												<th className="mw-100px">
													<span
														className={this.sortClass('tdinvqty')}
														data-field="tdinvqty"
													>
														Qty. to Date
													</span>
												</th>
												<th className="mw-100px">
													<span
														className={this.sortClass('_invtodate')}
														data-field="_invtodate"
													>
														Invoiced to Date
													</span>
												</th>
												<th className="mw-100px">
													<span
														className={this.sortClass('invqty')}
														data-field="invqty"
													>
														Inv. Qty.
													</span>
												</th>
												<th className="mw-100px">
													<span
														className={this.sortClass('invprice')}
														data-field="invprice"
													>
														Invoice Price
													</span>
												</th>
												<th className="mw-100px">
													<span
														className={this.sortClass('esttcost')}
														data-field="esttcost"
													>
														Est (PO) Cost
													</span>
												</th>
												<th className="mw-100px">
													<span
														className={this.sortClass('acttcost')}
														data-field="acttcost"
													>
														Actual (Vendor Inv) Cost
													</span>
												</th>
												<th className="mw-100px">
													<span
														className={this.sortClass('esttprice')}
														data-field="esttprice"
													>
														Est (Proposal) Price
													</span>
												</th>
											</tr>
											<tr
												className={`a-table-search-fields unselected ${
													this.state.showTableSearch ? '' : 'd-none'
												}`}
											>
												<th></th>
												<th></th>
												<th>
													<Form.Control
														type="text"
														data-field="loc"
														onChange={this.handleSearch}
														defaultValue={this.fs.getValue('loc')}
													/>
												</th>
												<th className="mw-100px">
													<Form.Control
														type="text"
														data-field="item"
														onChange={this.handleSearch}
														defaultValue={this.fs.getValue('item')}
													/>
												</th>
												<th>
													<Form.Control
														type="text"
														data-field="latestprpno"
														onChange={this.handleSearch}
														defaultValue={this.fs.getValue('latestprpno')}
													/>
												</th>
												<th className="mw-120px">
													<Form.Control
														type="text"
														data-field="shortdesc"
														onChange={this.handleSearch}
														defaultValue={this.fs.getValue('shortdesc')}
													/>
												</th>
												<th>
													<Form.Control
														type="text"
														data-field="statusName"
														onChange={this.handleSearch}
														defaultValue={this.fs.getValue('statusName')}
													/>
												</th>
												<th className="position-relative">
													<Form.Control
														type="number"
														data-field="_invqty"
														data-type="number"
														data-custom={1}
														onChange={this.handleSearch}
														placeholder={this.fs.getSearchExpression('_invqty')}
														defaultValue={this.fs.getValue('_invqty')}
													/>
													<SearchExpressionButton
														operator={this.fs.getSearchExpression('_invqty')}
														callback={(exp) =>
															this.fs.setSearchExperation('_invqty', exp, this)
														}
													/>
												</th>
												<th className="position-relative">
													<Form.Control
														type="number"
														data-field="_itemprice"
														data-type="number"
														data-custom={1}
														onChange={this.handleSearch}
														placeholder={this.fs.getSearchExpression(
															'_itemprice'
														)}
														defaultValue={this.fs.getValue('_itemprice')}
													/>
													<SearchExpressionButton
														operator={this.fs.getSearchExpression('_itemprice')}
														callback={(exp) =>
															this.fs.setSearchExperation(
																'_itemprice',
																exp,
																this
															)
														}
													/>
												</th>
												<th className="position-relative">
													<Form.Control
														type="number"
														data-field="tdinvqty"
														data-type="number"
														onChange={this.handleSearch}
														placeholder={this.fs.getSearchExpression(
															'tdinvqty'
														)}
														defaultValue={this.fs.getValue('tdinvqty')}
													/>
													<SearchExpressionButton
														operator={this.fs.getSearchExpression('tdinvqty')}
														callback={(exp) =>
															this.fs.setSearchExperation('tdinvqty', exp, this)
														}
													/>
												</th>
												<th className="position-relative">
													<Form.Control
														type="number"
														data-field="_invtodate"
														data-type="number"
														data-custom={1}
														onChange={this.handleSearch}
														placeholder={this.fs.getSearchExpression(
															'_invtodate'
														)}
														defaultValue={this.fs.getValue('_invtodate')}
													/>
													<SearchExpressionButton
														operator={this.fs.getSearchExpression('_invtodate')}
														callback={(exp) =>
															this.fs.setSearchExperation(
																'_invtodate',
																exp,
																this
															)
														}
													/>
												</th>
												<th className="position-relative">
													<Form.Control
														type="number"
														data-field="invqty"
														data-type="number"
														onChange={this.handleSearch}
														placeholder={this.fs.getSearchExpression('invqty')}
														defaultValue={this.fs.getValue('invqty')}
													/>
													<SearchExpressionButton
														operator={this.fs.getSearchExpression('invqty')}
														callback={(exp) =>
															this.fs.setSearchExperation('invqty', exp, this)
														}
													/>
												</th>
												<th className="position-relative">
													<Form.Control
														type="number"
														data-field="invprice"
														data-type="number"
														onChange={this.handleSearch}
														placeholder={this.fs.getSearchExpression(
															'invprice'
														)}
														defaultValue={this.fs.getValue('invprice')}
													/>
													<SearchExpressionButton
														operator={this.fs.getSearchExpression('invprice')}
														callback={(exp) =>
															this.fs.setSearchExperation('invprice', exp, this)
														}
													/>
												</th>
												<th className="position-relative">
													<Form.Control
														type="number"
														data-field="esttcost"
														data-type="number"
														onChange={this.handleSearch}
														placeholder={this.fs.getSearchExpression(
															'esttcost'
														)}
														defaultValue={this.fs.getValue('esttcost')}
													/>
													<SearchExpressionButton
														operator={this.fs.getSearchExpression('esttcost')}
														callback={(exp) =>
															this.fs.setSearchExperation('esttcost', exp, this)
														}
													/>
												</th>
												<th className="position-relative">
													<Form.Control
														type="number"
														data-field="acttcost"
														data-type="number"
														onChange={this.handleSearch}
														placeholder={this.fs.getSearchExpression(
															'acttcost'
														)}
														defaultValue={this.fs.getValue('acttcost')}
													/>
													<SearchExpressionButton
														operator={this.fs.getSearchExpression('acttcost')}
														callback={(exp) =>
															this.fs.setSearchExperation('acttcost', exp, this)
														}
													/>
												</th>
												<th className="position-relative">
													<Form.Control
														type="text"
														data-field="esttprice"
														data-type="number"
														onChange={this.handleSearch}
														placeholder={this.fs.getSearchExpression(
															'esttprice'
														)}
														defaultValue={this.fs.getValue('esttprice')}
													/>
													<SearchExpressionButton
														operator={this.fs.getSearchExpression('esttprice')}
														callback={(exp) =>
															this.fs.setSearchExperation(
																'esttprice',
																exp,
																this
															)
														}
													/>
												</th>
											</tr>
										</thead>
										<tbody>
											{this.state.data.length === 0 && !this.isLoaded
												? showLoading()
												: (data || []).map((item, i) => (
														<tr
															key={i}
															className={`tr-loading ${
																checks.includes(item.id) ? `active` : ''
															}`}
														>
															<td className="ps-4">
																<Form.Check
																	inline
																	label=""
																	name={`group-` + i}
																	type="checkbox"
																	data-id={i}
																	id={`chk-projectview-proposal-modal-` + i}
																	onChange={this.handleChecks.bind(
																		this,
																		item.id
																	)}
																	className="chk-projectview-proposal-modal-item"
																	checked={checks.includes(item.id)}
																/>
															</td>
															<td
																className={`${!isImagesLoaded ? 'td-1' : ''}`}
															>
																{this.props.renderImage(isImagesLoaded, item)}
															</td>
															<td>{item.loc}</td>
															<td>{item.item}</td>
															<td>{item.latestprpno}</td>
															<td>{item.shortdesc}</td>
															<td>
																<strong
																	className={`dot`}
																	style={{
																		'--color': getStatusColor(
																			item.statusName,
																			statusCodes
																		),
																		fontStyle:
																			item.statusName === 'Undefined'
																				? 'italic'
																				: 'normal',
																	}}
																>
																	{item.statusName}
																</strong>
															</td>
															<td>{getItemQuantity(item)}</td>
															<td>{currencyFormat(getItemPrice(item))}</td>
															<td>{item.tdinvqty}</td>
															<td>
																{currencyFormat(getItemInvoiceToDate(item))}
															</td>
															<td>{item.invqty}</td>
															<td>{currencyFormat(item.invBal)}</td>
															<td>{currencyFormat(item.esttcost)}</td>
															<td>{currencyFormat(item.acttcost)}</td>
															<td>{currencyFormat(item.esttprice)}</td>
														</tr>
												  ))}
											{this.state.data.length === 0 && this.isLoaded ? (
												showEmpty(16)
											) : (
												<></>
											)}
										</tbody>
									</Table>
								)}
								{this.props.data?.length > 1 && this.renderPagination()}
							</div>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		);
	}
}

export default WithRouter(ClientInvoiceAddInvoiceAddItemModal);
