import React from 'react';
import { WithRouter } from 'legacy/helpers/Router';
import { Outlet } from 'react-router-dom';

export const SignUpLayout = () => (
	<>
		<Outlet />
		<div id="response-alert-div"></div>
	</>
);
SignUpLayout.displayName = 'SignUpLayout';

export default WithRouter(SignUpLayout);
