import { apiClient, PUBLIC_BASE_URL } from 'api/apiClient';
import type { TFormValues } from 'legacy/templates/modules/sign-up/types/TFormValues';
import type { ESignUpStatus } from 'legacy/app/enums/signUp/SignUpStatus';

const RESOURCE = '/signup';

export interface IUserNameAvailability {
	usernameTaken: boolean;
	suggestions: string[];
}

export interface IPostUserResponse {
	clientId: number;
	status: number;
	error: string | null;
}

export interface IGetCompanyRolesResponse {
	key: number;
	value: string;
}

export interface ISignUpStatusResponse {
	error: string | null;
	instanceStatus: ESignUpStatus;
	recurlyStatus: string;
	hubspotStatus: string;
}

export const getUserNameIsAvailable = async (
	username: string,
	returnSuggestions = false
): Promise<IUserNameAvailability> => {
	const { data } = await apiClient.get(
		`${RESOURCE}/usernames/${username}?returnSuggestions=${returnSuggestions}`,
		{
			baseURL: PUBLIC_BASE_URL,
		}
	);

	return data;
};

export const postUser = async (
	params: TFormValues
): Promise<IPostUserResponse> => {
	const { data } = await apiClient.postPublic(`${RESOURCE}`, params);
	return data;
};

export const getCompanyRoles = async (): Promise<
	IGetCompanyRolesResponse[]
> => {
	const { data } = await apiClient.get(`${RESOURCE}/companyroles`, {
		baseURL: PUBLIC_BASE_URL,
	});

	return data;
};

export const getSignUpStatus = async (
	clientId: number | null
): Promise<ISignUpStatusResponse> => {
	const { data } = await apiClient.get(
		`${RESOURCE}/status?clientId=${clientId}`
	);

	return data;
};
