import React from 'react';
import {
	Col,
	Row,
	Form,
	Button,
	Table,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import Select from 'react-select';
import { WithRouter } from '../../../../helpers/Router';
import MyDropzone from '../../../components/MyDropzone';
import WysiwygEditor from '../../../components/WysiwygEditor';
import ThreadComponent from '../../ThreadComponent';
import { ApiService } from '../../../../lib/api/HttpService';
import { Is } from '../../../../helpers/Util';
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import { mapRtfToDraft } from '../../../../utilities/Rtf';
import { debounce, delay } from 'lodash';
import FormCurrencyInput from '../../../../app/components/form/FormCurrencyInput';
import { adjustData } from '../../../../utilities/modules/ItemComponent';
import VendorQuickAddModal from '../../../../app/components/modal/VendorQuickAddModal';
import { removeHTMLTags } from '../../../../utilities/String';
import VendorsDropdown from '../../../../app/components/dropdowns/VendorsDropdown';
import AsyncVendorsDropdown from '../../../../app/components/dropdowns/AsyncVendorsDropdown';
import AsyncAddressesDropdown from '../../../../app/components/dropdowns/AsyncAddressesDropdown';

class ItemAddComponentAddInfo extends ThreadComponent {
	constructor(props) {
		super(props);

		this.api = new ApiService();
		this.htmlToRtfConverter = new HtmlToRtfBrowser();

		this.percentageType = {
			Markup: 0,
			Discount: -1,
			Fee: 1,
		};

		this.selectData = [
			{ value: 0, label: 'Markup', calcValue: 'dmMarkup' },
			{ value: -1, label: 'Discount', calcValue: 'dmDiscount' },
			{ value: 1, label: 'Fee', calcValue: 'dmFee' },
		];

		this.compTypePTypeMap = {
			0: {
				markup: 'markupmerc',
				disc: 'usediscountmerc',
				tax: 'taxm',
			},
			1: {
				markup: 'markupdesign',
				disc: 'usediscountdesign',
				tax: 'taxd',
			},
			2: {
				markup: 'markupfreight',
				disc: 'usediscountfreight',
				tax: 'taxf',
			},
			3: {
				markup: 'markupinstall',
				disc: 'usediscountinstall',
				tax: 'taxi',
			},
			4: {
				markup: 'markuplabor',
				disc: 'usediscountlabor',
				tax: 'taxl',
			},
			5: { markup: 'markupother', disc: 'usediscountother', tax: 'taxo' },
		};

		this.state = {
			data: {},
			uoms: {},
			shipto: {},
			comps: ['m', 'd', 'f', 'i', 'l', 'o'],
			acomps: [],
			descHtml: '',
			isBuyingTermsChecked: false,
			pType: null,
			updatedData: props.updatedData,
			isParentComponent: false,
			associatedComponents: [],
			showVendorModal: false,
			selectedVendor: null,
			selectedShipto: null,
			vendorNewName: '',
			newSupplier: false,
		};

		this.recalculateHandler = debounce((id) => {
			this.recalculate(id);
		}, 500);
		this.props.onRef(this);
	}

	async componentDidMount() {
		this.setState({
			uoms: await this.getUnitsOfMeasures(),
		});

		if (Object.keys(this.props.data).length > 0) {
			let data = this.props.data || {};

			const descToUse =
				this.state.updatedData.descOnlyRtf ??
				data?.descOnlyRtf ??
				data?.descrtf ??
				data?.desc ??
				'';

			this.setState(
				{
					data: data,
					isBuyingTermsChecked:
						data.bterm1 || data.bterm2 || data.bterm3 || data.useterms,
					descHtml: await mapRtfToDraft(descToUse),
					isParentComponent: data.comp === data.assocwithcomp,
				},
				async () => {
					await this.setDefaultVendor();
					await this.setDefaultShipto();
				}
			);
		}

		await this.loadAdditionalData();
		this.props.associatedComponents?.map((item, index) => {
			if (
				item.usedisc === this.percentageType.Discount ||
				item.usedisc === this.percentageType.Fee
			) {
				return item;
			}
			this.handleAssociatedComponentChange(
				index,
				'comptype',
				item.comptype,
				true,
				true
			);
		});
	}

	async componentDidUpdate(previousProps, previousState) {
		if (previousProps.data !== this.props.data) {
			await this.loadAdditionalData();
			this.setState(
				{
					associatedComponents: this.props.associatedComponents,
					isBuyingTermsChecked:
						this.props.data?.isVendorBuyingTermsChecked ||
						this.state.isBuyingTermsChecked,
				},
				async () => {
					await this.setDefaultVendor();
					await this.setDefaultShipto();
				}
			);
		}
	}

	async loadAdditionalData() {
		let data = this.props.data || {};

		data.ptype = { value: '', label: 'Please select' };

		if (data.markup) {
			data.ptype = this.selectData[0];
		} else if (data.discount) {
			data.ptype = this.selectData[1];
		} else if (data.fee) {
			data.ptype = this.selectData[2];
		}

		data = await this.getComType(data).then(async () => {
			const descToUse =
				this.state.updatedData.descOnlyRtf ??
				data?.descOnlyRtf ??
				data?.descrtf ??
				data?.desc ??
				'';

			this.setState({
				data: data,
				dataIsLoaded: true,
				descHtml: await mapRtfToDraft(descToUse),
				pType:
					this.selectData.filter((item) => {
						return (
							item.value ===
							(this.props.updatedData.usedisc ?? this.props.data.usedisc)
						);
					})[0] ?? this.selectData[1],
				isParentComponent: data.comp === data.assocwithcomp,
			});
		});
	}

	async getComType(data) {
		const res = await this.api.get('Company');
		let list = [];
		for (let i = 0; i < 5; i++) {
			{
				/* show v1 i < 5 */
			}
			list.push({
				value: i,
				label: res['compview' + this.state.comps[i]],
			});
		}
		data.compTypes = list;
		return data;
	}

	async getUnitsOfMeasures() {
		let response = await this.api.get('UnitsOfMeasures');
		let options = {};

		let list = [];
		let keyedList = [];
		for (let item of response) {
			if (!keyedList[item.value]) {
				const listItem = {
					value: item.value,
					label: item.value,
				};
				keyedList[item.value] = listItem;
				list.push(listItem);
			}
		}

		options.list = list;
		options.keyedList = keyedList;

		return options;
	}

	updateRows = () => {
		this.props.onAddAssociatedComponent();
	};

	removeRow = (index) => {
		this.props.onRemoveAssociatedComponent(index);
	};

	handleChange = (e, meta = {}, callback = () => {}) => {
		let key, value;
		if (e === null) {
			key = meta.name;
			value = null;
			// Since this is a select
			window.clearedSelect = window.clearedSelect ?? [];
			if (!this.isClearedSelect(key)) {
				window.clearedSelect.push(key);
			}
		} else if (e?.target?.localName === 'select') {
			key = e.target.name;
			value = e.target.value;
		} else if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;

			if (meta.name === 'supplier') {
				this.setState((prevState) => ({
					data: {
						...prevState.data,
						supdep: e.deposit ?? 0,
					},
					updatedData: {
						...prevState.updatedData,
						supdep: e.deposit ?? 0,
					},
				}));
			}
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.id;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}

		if (key == 'supdep' && value == '') {
			value = 0;
		}

		this.props.onDataChange({ key: key, value: value });

		if (key === 'descOnly') {
			const valueHtml = this.htmlToRtfConverter.convertHtmlToRtf(e.target.html);
			this.props.onDataChange({ key: `${key}Rtf`, value: valueHtml });
			this.setState(
				{
					updatedData: {
						...this.state.updatedData,
						[key]: value,
						[`${key}Rtf`]: valueHtml,
					},
				},
				() => callback()
			);
		} else {
			this.setState(
				{
					updatedData: {
						...this.state.updatedData,
						[key]: value,
					},
				},
				() => {
					if (key === 'supplier') {
						this.setState({
							updatedData: {
								...this.state.updatedData,
								selectedVendor: e,
							},
						});
						this.props.onDataChange({
							key: 'selectedVendor',
							value: e,
						});
					}
					if (key === 'shipto') {
						this.setState({
							updatedData: {
								...this.state.updatedData,
								selectedShipto: e,
							},
						});
						this.props.onDataChange({
							key: 'selectedShipto',
							value: e,
						});
					}
					callback();
				}
			);
		}
	};

	calculateNewMarkUpOnSellPriceChange = async () => {
		const isDiscount = this.percentageType.Discount === this.state.pType?.value;
		const recalculatedData = await this.recalculate('cwcEstimatedPrice');
		const { markup } = recalculatedData;
		this.setState({
			updatedData: {
				...this.state.updatedData,
				estmarkup: markup,
				markup,
			},
			data: {
				...this.state.data,
				estmarkup: markup,
				markup,
			},
		});

		if (isDiscount) {
			const { estDisc, markup } = recalculatedData;
			this.setState({
				updatedData: {
					...this.state.updatedData,
					estdisc: estDisc,
					markup,
				},
				data: {
					...this.state.data,
					estdisc: estDisc,
					markup,
				},
			});
		}
	};

	calculateNewMarkUpForChildComponentsOnPriceChange = async (
		component,
		index
	) => {
		const getDiscountedMarkup = ({ price, cost }) => {
			const noPrice = !price || price === '0.00';
			const noCost = !cost || cost === '0.00';

			if (noPrice || noCost) {
				return -100;
			}
			const discountedPrice = cost - price;
			const difference = discountedPrice / cost;
			const markup = difference * 100;
			return price < cost ? -markup : markup;
		};
		const getProperPayloadForRecalculate = (component) => ({
			whatChanged: 'cwcEstimatedPrice',
			comptype: component.comptype,
			useDisc:
				(component.usedisc === this.percentageType.Fee &&
					this.percentageType.Fee) ||
				this.percentageType.Markup,
			markup: component.markup,
			estDisc: component.estdisc,
			taxable: component.taxable,
			estPrice: component.estprice,
			...(component.usedisc === this.percentageType.Fee && {
				feeCost: component.estcost,
			}),
			estCost: component.estcost,
		});

		const isDiscount = component.usedisc === this.percentageType.Discount;
		const componentsCopy = [...this.props.associatedComponents];
		if (isDiscount) {
			const markup = getDiscountedMarkup({
				price: component.estprice,
				cost: component.estcost,
			});
			const updatedComponent = {
				...component,
				markup,
			};

			componentsCopy[index] = updatedComponent;
			return this.props.updateChildComponents(componentsCopy);
		}

		const payload = getProperPayloadForRecalculate(component);

		const updatedComponent = await this.api.componentRecalculate(
			component.id,
			payload
		);

		componentsCopy[index] = {
			...component,
			markup: updatedComponent.markup,
		};
		this.props.updateChildComponents(componentsCopy);
	};

	handleBuyingTerms = (e, meta = {}) => {
		this.handleChange(e, meta, () => {
			this.recalculateHandler('cwcUseBuyingTerms');
		});

		this.setState({
			isBuyingTermsChecked: !this.state.isBuyingTermsChecked,
		});
	};

	handlePTypeChange = (e, meta = {}, whatChanged) => {
		this.setState({
			pType: e,
			isBuyingTermsChecked: false,
		});

		this.handleChange(e, meta, () => {
			this.recalculateHandler('cwcPercentType');
		});
	};

	handleCompTypeChange = (e, meta = {}) => {
		const self = this;
		const { project, useVendorDefaults = false } = this.props;

		this.setState(
			{
				updatedData: {
					...this.state.updatedData,
					markup: useVendorDefaults
						? this.props.data?.vendorDefaults?.find(
								(component) => component.comptype === e.value
						  )?.markup
						: this.state.data[this.compTypePTypeMap[e.value].markup],
					taxable: project[this.compTypePTypeMap[e.value].tax],
				},
				pType: this.selectData.find((item) => {
					return (
						item.value === this.state.data[this.compTypePTypeMap[e.value].disc]
					);
				}),
				data: Object.assign({}, this.state.data, {
					markup: this.state.data[this.compTypePTypeMap[e.value].markup],
				}),
			},
			() => {
				this.handleChange(e, meta, () => {
					self.recalculateHandler('cwcComponentType');
				});
			}
		);
	};

	handleChangeAndRecalculate = (e, meta = {}, doNotOverwriteMarkup = true) => {
		const id =
			e['recalculate-id'] ??
			e?.target?.getAttribute?.('recalculate-id') ??
			e?.target['recalculate-id'] ??
			this.props.onBlur(id);
		this.handleChange(e, meta, () => {
			this.recalculateHandler(id);
		});
		this.props.associatedComponents?.map((item, index) => {
			this.handleAssociatedComponentChange(
				index,
				'comptype',
				item.comptype,
				doNotOverwriteMarkup
			);
		});
	};

	async recalculate(
		whatChanged,
		shouldUpdateState = true,
		callback = () => {}
	) {
		if (!whatChanged || !this.props.compId) {
			return;
		}

		const data = await this.api.componentRecalculate(this.props.compId, {
			whatChanged: whatChanged,
			quantity: Number(this.getDataValueForKey('quantity') ?? 0),
			markup: Number(this.getDataValueForKey('markup') ?? 0),
			comptype: this.state.updatedData?.comptype ?? 0,
			useDisc: this.state.pType.calcValue ?? 'dmDiscount',
			estCost:
				this.state.pType.calcValue === 'dmFee'
					? 0
					: Number(this.getDataValueForKey('estcost') ?? 0),
			estUnitCost: Number(this.getDataValueForKey('estunitcost') ?? 0),
			feeCost:
				this.state.pType.calcValue === 'dmFee'
					? Number(this.getDataValueForKey('estcost') ?? 0)
					: 0,
			feeUnitCost: Number(this.getDataValueForKey('feeunitcost') ?? 0),
			estPrice: Number(this.getDataValueForKey('estprice') ?? 0),
			estUnitPrice: Number(this.getDataValueForKey('estunitprice') ?? 0),
			estMarkup: Number(this.getDataValueForKey('estmarkup') ?? 0),
			list: Number(this.getDataValueForKey('list') ?? 0),
			unitList: Number(this.getDataValueForKey('unitlist') ?? 0),
			estDisc: Number(this.getDataValueForKey('estdisc') ?? 0),
			useTerms: this.state.isBuyingTermsChecked,
			bTerm1: Number(this.getDataValueForKey('bterm1') ?? 0),
			bTerm2: Number(this.getDataValueForKey('bterm2') ?? 0),
			bTerm3: Number(this.getDataValueForKey('bterm3') ?? 0),
			supDep: Number(this.getDataValueForKey('supdep') ?? 0),
			taxable: this.getDataValueForKey('taxable'),
			supplier: this.getDataValueForKey('supplier'),
		});

		const recalculatedData = {};
		Object.keys(data).forEach((key) => {
			recalculatedData[key.toLowerCase()] = data[key];
		});

		const updatedDate = {
			...this.state.updatedData,
			...adjustData(recalculatedData),
		};

		if (shouldUpdateState) {
			this.setState(
				{
					updatedData: updatedDate,
				},
				() => {
					this.props.onRecalculate(this.state.updatedData);
					callback();
				}
			);
		} else {
			this.props.onRecalculate(this.state.updatedData);
			callback();
		}
		return data;
	}

	getDataValueForKey(key) {
		return this.state.updatedData[key] ?? this.state.data[key];
	}

	onAcceptedFiles = (file) => {
		this.props.onAcceptedFiles(file);
	};

	onRemoveFiles = () => {
		this.props.onRemoveFiles();
	};

	defaultCompType() {
		const { data } = this.state;

		if (!(data.compTypes && data.compTypes.length > 0)) {
			return { value: '', label: 'Please select' };
		}

		let existingCompType;
		const selectedCompType = data.compTypes.filter((compType) => {
			if (compType.value === this.state.updatedData.comptype) {
				return true;
			}

			if (data.comptype === compType.value) {
				existingCompType = compType;
			}
		})[0];

		return (
			selectedCompType ?? existingCompType ?? { value: 0, label: 'Merchandise' }
		);
	}

	async setDefaultVendor(selectedVendor = null) {
		const vendorn =
			this.state.updatedData?.supplier ??
			(this.state.data?.supplier && !this.isClearedSelect('supplier')
				? this.state.data?.supplier
				: null) ??
			null;

		if (vendorn && !this.state.updatedData?.selectedVendor) {
			selectedVendor = vendorn
				? await this.api.getVendorByCode(vendorn, true)
				: null;

			this.setState({
				updatedData: {
					...this.state.updatedData,
					selectedVendor,
				},
			});
		}
	}

	async setDefaultShipto(selectedShipto = null) {
		const shipToCode =
			this.state.updatedData?.shipto ??
			(this.state.data?.shipto && !this.isClearedSelect('shipto')
				? this.state.data?.shipto
				: null) ??
			null;

		if (shipToCode && !this.state.updatedData?.selectedShipto) {
			selectedShipto = shipToCode
				? await this.api.getAddressByCode(shipToCode, true, 'addresstype eq 1')
				: null;
			this.setState({
				updatedData: {
					...this.state.updatedData,
					selectedShipto,
				},
			});
		}
	}

	handleAssociatedComponentChange = async (
		index,
		key,
		value,
		doNotOverwriteMarkup,
		doNotOverwriteDiscounts
	) => {
		try {
			await this.props.onAssociatedComponentChange(
				index,
				key,
				value,
				doNotOverwriteMarkup,
				doNotOverwriteDiscounts
			);

			if (key === 'taxable') {
				this.props.onAssociatedComponentBlur();
			}
		} catch (e) {
			console.log('Error fetching component recalculate');
		}
	};

	isClearedSelect(name) {
		return window.clearedSelect?.includes(name);
	}

	handleSelectChange = async (e, meta = {}) => {
		this.setState({
			isBuyingTermsChecked: false,
		});

		this.handleChangeAndRecalculate(
			{
				id: meta?.name,
				value: e?.value,
				'recalculate-id': meta?.name === 'supplier' && 'cwcVendor',
				label: e?.label,
			},
			meta,
			false
		);
		const isVendorBuyingTermsChecked =
			await this.props.updateComponentsDefaults(e?.id);

		if (!isVendorBuyingTermsChecked) {
			this.setState({
				updatedData: Object.assign({}, this.state.updatedData, {
					bterm1: 0,
					bterm2: 0,
					bterm3: 0,
				}),
				isBuyingTermsChecked: isVendorBuyingTermsChecked,
			});
		}
	};

	render() {
		const { data } = this.state;

		return (
			<>
				<Row xs={1} className="mb-4">
					<Col lg={7} className="mb-3 mb-lg-0">
						<Form.Group>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Name</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Please enter"
										id="itemName"
										maxLength={45}
										value={this.state.updatedData.itemName ?? data.itemName}
										onChange={this.handleChange}
									/>
								</Col>
							</Row>

							<Row className="mb-4">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0 pt-2">Description</Form.Label>
								</Col>
								<Col>
									<WysiwygEditor id="descOnly" onChange={this.handleChange}>
										{this.state.descHtml}
									</WysiwygEditor>
								</Col>
							</Row>

							<Row className="align-items-center mb-4">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Type</Form.Label>
								</Col>
								<Col lg={5}>
									<Select
										key={`${Math.floor(Math.random() * 1000)}-min`}
										options={data.compTypes}
										name="comptype"
										defaultValue={this.defaultCompType()}
										className="react-select"
										placeholder="Please select"
										onChange={this.handleCompTypeChange}
									/>
								</Col>
								{/* show v1
                                <Col>
                                    <div className="d-grid">
                                        <Button variant="primary" size='md'>Get from Inventory..</Button>
                                    </div>
                                </Col>
                                */}
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Vendor</Form.Label>
								</Col>
								<Col className="mb-3 mb-lg-0">
									<AsyncVendorsDropdown
										defaultValue={this.state.updatedData?.selectedVendor}
										name="supplier"
										className="react-select"
										placeholder="Please select"
										onChange={this.handleSelectChange}
										isClearable={true}
										creatable={true}
										recalculate-id="cwcVendor"
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Vendor Deposit %</Form.Label>
								</Col>
								<Col>
									<Row>
										<Col>
											<Form.Control
												type="number"
												placeholder="Please enter"
												id="supdep"
												recalculate-id="cwcVendorDepositPercent"
												value={this.state.updatedData.supdep ?? data.supdep}
												onChange={this.handleChange}
												onBlur={this.handleChangeAndRecalculate}
											/>
										</Col>
										<Col></Col>
									</Row>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Ship To</Form.Label>
								</Col>
								<Col>
									<AsyncAddressesDropdown
										name="shipto"
										defaultValue={this.state.updatedData?.selectedShipto}
										className="react-select"
										placeholder="Please select"
										onChange={this.handleChange}
										isClearable={true}
										urlQuery="?$filter=addresstype eq 1"
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0"></Col>
								<Col lg={5}>
									<Form.Check
										inline
										label="Buying Terms"
										type="checkbox"
										id="useterms"
										checked={this.state.isBuyingTermsChecked}
										onChange={this.handleBuyingTerms}
										disabled={this.state.pType?.value === 1}
									/>
								</Col>
							</Row>

							{this.state.isBuyingTermsChecked && (
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Terms</Form.Label>
									</Col>
									<Col>
										<Row>
											<Col>
												<Form.Control
													type="text"
													placeholder="0"
													id="bterm1"
													defaultValue={
														this.state.updatedData.bterm1 ?? data.bterm1
													}
													onChange={this.handleChange}
												/>
											</Col>
											<Col>
												<Form.Control
													type="text"
													placeholder="0"
													id="bterm2"
													defaultValue={
														this.state.updatedData.bterm2 ?? data.bterm2
													}
													onChange={this.handleChange}
												/>
											</Col>
											<Col>
												<Form.Control
													type="text"
													placeholder="0"
													id="bterm3"
													defaultValue={
														this.state.updatedData.bterm3 ?? data.bterm3
													}
													onChange={this.handleChange}
												/>
											</Col>
										</Row>
									</Col>
								</Row>
							)}

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">% Type</Form.Label>
								</Col>
								<Col>
									<Select
										key={`${Math.floor(Math.random() * 1000)}-min`}
										name="usedisc"
										options={this.selectData}
										className="react-select"
										placeholder="Please select"
										recalculate-id="cwcPercentType"
										value={this.state.pType}
										onChange={this.handlePTypeChange}
									/>
								</Col>
								<Col
									lg={2}
									className="text-lg-end mb-2 mb-lg-0 mt-sm-2 mt-lg-0"
								>
									<Form.Label className="mb-0">% Value</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="number"
										placeholder="Please enter"
										id="markup"
										recalculate-id="cwcPercentType"
										value={
											this.props.useVendorDefaults
												? this.state.updatedData.markup
												: this.state.updatedData.markup ??
												  this.state.data.markup
										}
										onChange={this.handleChange}
										onBlur={this.handleChangeAndRecalculate}
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Mfg. Cat.</Form.Label>
								</Col>
								<Col>
									<Row>
										<Col>
											<Form.Control
												type="text"
												placeholder="Please enter"
												id="catalog"
												defaultValue={
													this.state.updatedData.catalog ?? data.catalog
												}
												onChange={this.handleChange}
											/>
										</Col>
										<Col></Col>
									</Row>
								</Col>
							</Row>
						</Form.Group>
					</Col>
					<Col lg={5}>
						<MyDropzone
							image={this.props.uploadedImage ?? this.props.data.primaryImage}
							onAcceptedFiles={this.onAcceptedFiles}
							onRemoveFiles={this.onRemoveFiles}
						/>
					</Col>
				</Row>
				<div className="bg-secondary-grey ps-4 roundedx-4 mb-4">
					<Row xs={1} lg={2} className="py-4">
						<Col>
							<h6 className="fw-bold mb-0">Cost / Pricing</h6>
						</Col>
					</Row>
				</div>
				<div className="table-gradient">
					<Table responsive borderless>
						<thead>
							<tr>
								<th className="mw-100px"></th>
								<th className="mw-100px"></th>
								<th className="mw-100px"></th>
								<th className="mw-100px"></th>
								<th className="mw-100px"></th>
								<th className="mw-100px"></th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="align-bottom p-0">
									{[0, -1].includes(this.state.pType?.value) && (
										<div>
											<Form.Label>Unit Cost</Form.Label>
											<div className="form-group-extra">
												<FormCurrencyInput
													id="estunitcost"
													name="estunitcost"
													value={
														this.state.updatedData?.estunitcost ??
														data.estunitcost
													}
													onValueChange={(value) => {
														const e = {
															target: {
																id: 'estunitcost',
																value: value,
															},
														};
														this.handleChange(e);
													}}
													onBlur={(val) => {
														const e = {
															target: {
																id: 'estunitcost',
																value:
																	this.state.updatedData?.estunitcost ??
																	data.estunitcost,
																'recalculate-id': 'cwcEstimatedUnitCost',
															},
														};
														this.handleChangeAndRecalculate(e);
													}}
													readOnly={this.state.isBuyingTermsChecked}
													disabled={this.state.isBuyingTermsChecked}
												/>
											</div>
											{(this.state.isBuyingTermsChecked ||
												this.state.pType?.value === -1) && (
												<div>
													<Form.Label className="mt-3">Unit List</Form.Label>
													<div className="form-group-extra">
														<FormCurrencyInput
															value={
																this.state.updatedData.unitlist ?? data.unitlist
															}
															onValueChange={(value) => {
																const e = {
																	target: {
																		id: 'unitlist',
																		value: value,
																	},
																};
																this.handleChange(e);
															}}
															onBlur={(val) => {
																const e = {
																	target: {
																		id: 'unitlist',
																		value:
																			this.state.updatedData.unitlist ??
																			data.unitlist,
																		'recalculate-id': 'cwcUnitListPrice',
																	},
																};
																this.handleChangeAndRecalculate(e);
															}}
														/>
													</div>
												</div>
											)}
										</div>
									)}

									{this.state.pType?.value === 1 && (
										<div>
											<Form.Label>Unit Base Cost</Form.Label>
											<div className="form-group-extra">
												<FormCurrencyInput
													value={
														this.state.updatedData.feeunitcost ??
														data.feeunitcost
													}
													onValueChange={(value) => {
														const e = {
															target: {
																id: 'feeunitcost',
																value: value,
															},
														};
														this.handleChange(e);
													}}
													onBlur={(val) => {
														const e = {
															target: {
																id: 'feeunitcost',
																value:
																	this.state.updatedData.feeunitcost ??
																	data.feeunitcost,
																'recalculate-id': 'cwcEstimatedUnitCost',
															},
														};
														this.handleChangeAndRecalculate(e);
													}}
												/>
											</div>
										</div>
									)}
								</td>
								<td className="align-bottom p-0">
									<div className="d-flex flex-row">
										<div className="d-flex align-items-end">
											<div className="d-flex form-control-sm align-items-center align-content-center p-0">
												<i className="ri-close-line ri-lg text-secondary-ash px-2"></i>
											</div>
										</div>
										<div className="d-block form-group-extra">
											<Form.Label>Qty.</Form.Label>
											<Form.Control
												type="number"
												placeholder="0.00"
												size="sm"
												id="quantity"
												value={this.state.updatedData.quantity ?? data.quantity}
												recalculate-id="cwcQuantity"
												onChange={this.handleChange}
												onBlur={this.handleChangeAndRecalculate}
											/>
										</div>
									</div>
								</td>

								<td className="align-bottom p-0 ps-2">
									<div className="d-flex flex-row">
										<div>
											<Form.Label>Units</Form.Label>
											<div className="d-block form-group-extra mw-100px">
												<Form.Select
													size="sm"
													name="unitmeasure"
													placeholder="Units"
													key={`${Math.floor(Math.random() * 1000)}-min`}
													defaultValue={(
														this.state.updatedData.unitmeasure ||
														data.unitmeasure ||
														'each'
													).trim()}
													onChange={this.handleChange}
												>
													{(this.state.uoms.list || []).map((item, i) => (
														<option key={i} value={item.label}>
															{item.label}
														</option>
													))}
												</Form.Select>
											</div>
										</div>
										<div className="d-flex align-items-end">
											<span className="form-control-sm fsx-24 text-secondary-ash position-relative px-2">
												=
											</span>
										</div>
									</div>
								</td>
								<td className="align-bottom p-0">
									{[0, -1].includes(this.state.pType?.value) && (
										<div>
											<Form.Label>Extended Cost</Form.Label>
											<div className="form-group-extra">
												<FormCurrencyInput
													value={this.state.updatedData.estcost ?? data.estcost}
													onValueChange={(value) => {
														const e = {
															target: {
																id: 'estcost',
																value: value,
															},
														};
														this.handleChange(e);
													}}
													onBlur={(val) => {
														const e = {
															target: {
																id: 'estcost',
																value:
																	this.state.updatedData.estcost ??
																	data.estcost,
																'recalculate-id': 'cwcEstimatedCost',
															},
														};
														this.handleChangeAndRecalculate(e);
													}}
												/>
											</div>
											{(this.state.isBuyingTermsChecked ||
												this.state.pType?.value === -1) && (
												<div>
													<Form.Label className="mt-3">
														Ext. List Price
													</Form.Label>
													<div className="form-group-extra">
														<FormCurrencyInput
															value={this.state.updatedData.list ?? data.list}
															onValueChange={(value) => {
																const e = {
																	target: {
																		id: 'list',
																		value: value,
																	},
																};
																this.handleChange(e);
															}}
															onBlur={(val) => {
																const e = {
																	target: {
																		id: 'list',
																		value:
																			this.state.updatedData.list ?? data.list,
																		'recalculate-id': 'cwcListPrice',
																	},
																};
																this.handleChangeAndRecalculate(e);
															}}
														/>
													</div>
												</div>
											)}
										</div>
									)}

									{this.state.pType?.value === 1 && (
										<div>
											<Form.Label>Ext. Base Cost</Form.Label>
											<div className="form-group-extra">
												<FormCurrencyInput
													value={this.state.updatedData.feecost ?? data.feecost}
													onValueChange={(value) => {
														const e = {
															target: {
																id: 'feecost',
																value: value,
															},
														};
														this.handleChange(e);
													}}
													onBlur={(val) => {
														const e = {
															target: {
																id: 'feecost',
																value:
																	this.state.updatedData.feecost ?? data.list,
															},
														};
														this.handleChangeAndRecalculate(e);
													}}
												/>
											</div>
										</div>
									)}
								</td>
								<td className="align-bottom p-0">
									{this.state.pType?.value === 0 && (
										<div className="d-flex flex-row">
											<div className="d-flex align-items-end">
												<div className="d-flex form-control-sm align-items-center align-content-center p-0">
													<i className="ri-add-line ri-lg text-secondary-ash px-2"></i>
												</div>
											</div>
											<div className="d-block form-group-extra">
												<Form.Label>Markup</Form.Label>
												<FormCurrencyInput
													value={
														this.state.updatedData.estmarkup ?? data.estmarkup
													}
													onValueChange={(value) => {
														const e = {
															target: {
																id: 'estmarkup',
																value: value,
															},
														};
														this.handleChange(e);
													}}
													onBlur={(val) => {
														const e = {
															target: {
																id: 'estmarkup',
																value:
																	this.state.updatedData.estmarkup ??
																	data.estmarkup,
																'recalculate-id': 'cwcEstimatedMarkup',
															},
														};
														this.handleChangeAndRecalculate(e);
													}}
												/>
											</div>
										</div>
									)}

									{this.state.pType?.value === -1 && (
										<div className="d-flex flex-row">
											<div className="d-flex align-items-end">
												<div className="d-flex form-control-sm align-items-center align-content-center p-0">
													<i className="ri-subtract-line ri-lg text-secondary-ash px-2"></i>
												</div>
											</div>
											<div className="d-block form-group-extra">
												<Form.Label>Discount</Form.Label>
												<FormCurrencyInput
													value={this.state.updatedData.estdisc ?? data.estdisc}
													onValueChange={(value) => {
														const e = {
															target: {
																id: 'estdisc',
																value: value,
															},
														};
														this.handleChange(e);
													}}
													onBlur={(val) => {
														const e = {
															target: {
																id: 'estdisc',
																value:
																	this.state.updatedData.estdisc ??
																	data.estdisc,
																'recalculate-id': 'cwcDiscount',
															},
														};
														this.handleChangeAndRecalculate(e);
													}}
												/>
											</div>
										</div>
									)}
								</td>
								<td
									className={`${
										this.state.pType?.value === 0 ? 'align-top' : 'align-bottom'
									} p-0`}
								>
									<div className="d-flex flex-row">
										<div className="d-flex align-items-end">
											{[0, -1].includes(this.state.pType?.value) && (
												<span className="form-control-sm fsx-24 text-secondary-ash position-relative px-2">
													=
												</span>
											)}
										</div>
										<div>
											<Form.Label>Sell Price</Form.Label>
											<div className="form-group-extra">
												<FormCurrencyInput
													value={
														this.state.updatedData.estprice ?? data.estprice
													}
													onValueChange={(value) => {
														const e = {
															target: {
																id: 'estprice',
																value: value,
															},
														};
														this.handleChange(e);
													}}
													onBlur={this.calculateNewMarkUpOnSellPriceChange}
												/>
											</div>
										</div>
										<div className="d-flex align-items-end">
											<div className="d-flex form-control-sm align-items-center align-content-center p-0">
												<i
													className="ri-lock-fill px-2"
													onClick={() => {
														const e =
															this.state.pType?.value !== -1
																? this.selectData[1]
																: this.selectData[0];

														const meta = {
															action: 'select-option',
															name: 'usedisc',
															option: undefined,
														};

														this.handlePTypeChange(e, meta);
													}}
												/>
											</div>
										</div>
										<div className="d-flex align-items-end">
											<div className="d-flex form-control-sm align-items-center align-content-center p-0">
												<Form.Check
													inline
													label="Taxable"
													type="checkbox"
													id="taxable"
													recalculate-id="cwcTaxable"
													checked={
														this.state?.updatedData?.taxable ?? data?.taxable
													}
													onChange={this.handleChangeAndRecalculate}
												/>
											</div>
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</Table>
				</div>
				{this.state.isParentComponent && (
					<div>
						<div className="bg-secondary-grey ps-4 roundedx-4 mb-4">
							<Row
								xs={'auto'}
								className="py-4 justify-content-between align-items-center"
							>
								<Col>
									<h6 className="fw-bold mb-0">
										Freight / Design Fee / Additional Charges
									</h6>
								</Col>

								{this.props.associatedComponents.length < 4 ? (
									<Col>
										<Button
											variant="primary"
											size="sm"
											className={`btn-icon btn-action me-5`}
											onClick={this.updateRows}
										>
											<i className="ri-add-line"></i> Add
										</Button>
									</Col>
								) : (
									<OverlayTrigger
										id="otr"
										overlay={
											<Tooltip id="selprop">
												Maximum number of subcomponents has been reached.
											</Tooltip>
										}
									>
										<Col>
											<Button
												variant="primary"
												size="sm"
												className={`btn-icon btn-action me-5 disabled`}
											>
												<i className="ri-add-line"></i> Add
											</Button>
										</Col>
									</OverlayTrigger>
								)}
							</Row>
						</div>
						<Row className="mb-4">
							<Col lg={12}>
								<div className="table-gradient">
									<Table responsive borderless>
										<thead>
											<tr key={0}>
												<th className="mw-100px"></th>
												<th className="mw-100px">Cost</th>
												<th className="mw-100px">Markup %</th>
												<th className="mw-100px">Price</th>
												<th className="mw-150px"></th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{this.props.associatedComponents.map(
												(item, index, array) => {
													return (
														<tr key={index}>
															<td>
																<Select
																	menuPortalTarget={document.body}
																	styles={{
																		menuPortal: (base) => ({
																			...base,
																			zIndex: 99999,
																		}),
																	}}
																	key={`${Math.floor(
																		Math.random() * 1000
																	)}-min`}
																	options={data.compTypes}
																	defaultValue={
																		data.compTypes
																			? data.compTypes.filter((type) => {
																					return type.value === item.comptype;
																			  })[0]
																			: []
																	}
																	className="react-select"
																	placeholder="Please select"
																	onChange={(e) => {
																		this.handleAssociatedComponentChange(
																			index,
																			'comptype',
																			e.value
																		);
																	}}
																/>
															</td>
															<td>
																<div className="form-group-extra">
																	<FormCurrencyInput
																		sizeClass="lg"
																		value={item.estcost}
																		onValueChange={(value) => {
																			this.handleAssociatedComponentChange(
																				index,
																				'estcost',
																				value
																			);
																		}}
																		onBlur={
																			this.props.onAssociatedComponentBlur
																		}
																	/>
																</div>
															</td>
															<td>
																<Form.Control
																	type="number"
																	placeholder="Please enter"
																	value={item.markup}
																	onChange={(e) => {
																		this.handleAssociatedComponentChange(
																			index,
																			'markup',
																			e.target.value
																		);
																	}}
																	onBlur={this.props.onAssociatedComponentBlur}
																/>
															</td>
															<td>
																<div className="form-group-extra">
																	<FormCurrencyInput
																		sizeClass="lg"
																		value={item.estprice}
																		onValueChange={(value) => {
																			this.handleAssociatedComponentChange(
																				index,
																				'estprice',
																				value
																			);
																		}}
																		onBlur={() =>
																			this.calculateNewMarkUpForChildComponentsOnPriceChange(
																				item,
																				index
																			)
																		}
																	/>
																</div>
															</td>
															<td
																style={{
																	verticalAlign: 'middle',
																}}
															>
																<Form.Check
																	inline
																	label="Taxable"
																	type="checkbox"
																	checked={item.taxable}
																	onChange={(e) => {
																		this.handleAssociatedComponentChange(
																			index,
																			'taxable',
																			e.target.checked
																		);
																	}}
																/>
															</td>
															<td>
																<Button
																	variant="light"
																	className="btn-icon"
																	size="md"
																	onClick={() => this.removeRow(index)}
																>
																	<i className="ri-subtract-fill"></i> Remove
																</Button>
															</td>
														</tr>
													);
												}
											)}
										</tbody>
									</Table>
								</div>
							</Col>
						</Row>
					</div>
				)}
			</>
		);
	}
}

export default WithRouter(ItemAddComponentAddInfo);
