import { useQuery } from '@tanstack/react-query';
import { ECacheKeys } from 'cache/CacheKeys';
import { getCurrentUser } from 'api/resources/user';

export const useGetCurrentUser = () => {
	const query = useQuery({
		queryKey: [ECacheKeys.UserData],
		queryFn: getCurrentUser,
		...{
			refetchInterval: false,
			refetchOnWindowFocus: false,
		},
	});

	return query;
};
