import { IJournalEntry } from 'api/resources/financeCharges';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';
import AccountsDropdown, {
	EAccountType,
	TAccountsDropdownOption,
} from 'legacy/pages/accounts-payable/credit-card/reconcile/AccountsDropdown';
import {
	TFinanceChargeAccount,
	TOnUpdateArgs,
} from 'legacy/pages/accounts-payable/credit-card/reconcile/FinanceCharge/FinanceChargeTypes';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import {
	FINANCE_CHARGE_MAX_VALUE,
	FINANCE_CHARGE_MAX_VALUE_ERROR,
} from '../constants/constants';

type TCharge = {
	account: TFinanceChargeAccount | null;
	newAccount?: TFinanceChargeAccount | null;
	damt: number;
};

type TFinanceChargeUpdateProps = {
	showModal: boolean;
	onToggleModal: () => void;
	account: TFinanceChargeAccount;
	charge: TCharge | null;
	journalEntries: IJournalEntry[];
	onUpdate: (values: TOnUpdateArgs, hasUserChangedAccount: boolean) => void;
};

export const FinanceChargeUpdate = ({
	showModal,
	onToggleModal,
	account,
	charge,
	journalEntries,
	onUpdate,
}: TFinanceChargeUpdateProps) => {
	const defaultValues: TCharge = {
		account: null,
		newAccount: charge?.account || null,
		damt: charge?.damt || 0,
	};

	const {
		register,
		control,
		handleSubmit,
		reset,
		getValues,
		setValue,
		formState: { errors },
	} = useForm<TCharge>({ defaultValues });

	const [hasUserChangedAccount, setHasUserChangedAccount] = useState(false);
	const financeChargeTotal =
		journalEntries.reduce(
			(accumulator, currentValue) => accumulator + (currentValue?.damt ?? 0),
			0
		) ?? 0;

	useEffect(() => {
		// populate when modal opens and data is available
		if (!getValues('newAccount') && !getValues('damt')) {
			setValue('newAccount', charge?.account);
			setValue('damt', charge?.damt as number);
		}
	}, [charge, register, getValues, setValue]);

	const omitAccounts = [
		...(charge?.account ? [] : journalEntries.map((entry) => entry.account)),
		account.value,
	];

	const onSubmit = (data: TCharge) => {
		onUpdate(
			{
				...data,
				...{ account: charge?.account as TFinanceChargeAccount },
			} as TOnUpdateArgs,
			hasUserChangedAccount
		);

		reset();

		onToggleModal();
	};

	return (
		<ConfirmationModal
			title="Service/Finance Charge Distribution"
			show={showModal}
			toggleModal={() => {
				reset();
				onToggleModal();
			}}
			footer={false}
			labelCancel="Cancel"
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="tw-grid tw-grid-flow-row tw-gap-2 tw-px-4 tw-pb-4">
					<div className="tw-grid tw-grid-flow-col tw-grid-cols-[1fr_6fr] tw-items-center">
						<Form.Label>Account:</Form.Label>
						<Controller
							name="newAccount"
							control={control}
							render={({ field }) => (
								<AccountsDropdown
									type={EAccountType.ALL}
									omitAccounts={omitAccounts}
									isDisabled={typeof charge?.account === 'string' ?? false}
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...field}
									onChange={(selected: TAccountsDropdownOption) => {
										setHasUserChangedAccount(true);
										field.onChange(selected);
									}}
								/>
							)}
						/>
					</div>
					<div className="tw-grid tw-grid-flow-col tw-grid-cols-[1fr_6fr] tw-items-baseline">
						<Form.Label>Amount:</Form.Label>
						<div>
							<Form.Control
								type="number"
								step={0.01}
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...register('damt', {
									required: true,
									valueAsNumber: true,
									validate: (value) => {
										return (
											value + financeChargeTotal <= FINANCE_CHARGE_MAX_VALUE ||
											FINANCE_CHARGE_MAX_VALUE_ERROR
										);
									},
								})}
							/>
							{errors.damt && (
								<span className="tw-text-red-500 tw-text-xs tw-mt-1">
									{errors.damt.message}
								</span>
							)}
						</div>
					</div>
				</div>
				<Modal.Footer className="bg-ivory tw-grid tw-grid-flow-col tw-gap-2">
					<Button variant="primary" type="submit">
						OK
					</Button>
					<Button
						variant="trans-light border-secondary-ash"
						onClick={() => {
							reset();
							onToggleModal();
						}}
					>
						Cancel
					</Button>
				</Modal.Footer>
			</form>
		</ConfirmationModal>
	);
};

FinanceChargeUpdate.displayName = 'FinanceChargeUpdate';
