import React from 'react';
import briaHammel from '../../../assets/images/bria-hammel.png';
import thomFilicia from '../../../assets/images/thom-filicia.png';
import libbyLangdon from '../../../assets/images/libby-langdon.png';
import logo from '../../../assets/images/logo.svg';
import InteriorDesignersImage from '../../../assets/images/auth-interior-designers.jpg';

interface ISignUpBackgroundProps {
	backgroundClass: string;
	children?: React.ReactNode;
}

const SignUpBackground = ({
	backgroundClass,
	children,
}: ISignUpBackgroundProps) => {
	const logos = [
		{ src: briaHammel, alt: 'Bria Hammel' },
		{ src: thomFilicia, alt: 'Thom Filicia' },
		{ src: libbyLangdon, alt: 'Libby Langdon' },
	];
	return (
		<div className={backgroundClass}>
			<div className="tw-p-8 tw-flex-grow tw-relative tw-z-2">
				<img
					src={logo}
					alt="logo-interior-designers"
					className="tw-max-w-[140px] tw-w-full tw-h-auto tw-relative tw-z-[2]"
				/>
			</div>
			<div
				className="tw-absolute tw-inset-0 tw-bg-cover tw-bg-center"
				style={{
					backgroundImage: `url('${InteriorDesignersImage}')`,
				}}
			>
				{children && (
					<div className="tw-relative tw-z-20 tw-h-full">{children}</div>
				)}
			</div>

			<div className="tw-absolute tw-inset-0 tw-bg-black tw-bg-opacity-60"></div>

			<div className="tw-text-center tw-text-white tw-relative tw-z-2 tw-text-xs tw-pb-4">
				<div className="tw-mb-3">
					TRUSTED BY THOUSANDS OF INTERIOR DESIGNERS
				</div>
				<div className="tw-px-6 tw-pb-6  tw-mx-4">
					{logos.map((logo, index) => (
						<img
							key={`footer-logo-${index}`}
							src={logo.src}
							alt={`logo-${logo.alt}`}
							className={`tw-max-w-[140px] tw-max-h-[80px] tw-m-2 `}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

SignUpBackground.displayName = 'SignUp';

export { SignUpBackground };
