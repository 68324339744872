import React from 'react';
import { getRouteWithParam } from 'legacy/helpers/Router';
import { Breadcrumb, Button, NavLink } from 'react-bootstrap';
import { Link, generatePath, useNavigate, useParams } from 'react-router-dom';
import URI from 'legacy/defaults/RoutesDefault';
import { HeaderLight } from 'legacy/templates/components/Header';
import SecureBootstrapButton from 'legacy/app/components/security/SecureBootstrapButton';
import { SECURITY_ATTRIBUTE_TYPES } from 'legacy/app/context/security';
import useGetProject from 'legacy/lib/api/hooks/useGetProject';
import useDeleteObject from 'legacy/lib/api/hooks/useDeleteObject';
import { displayAlertError } from 'legacy/utilities/Response';
import BudgetBannerProvider from 'legacy/app/context/BudgetBannerContext';
import BudgetBanner, {
	BudgetBannerContext,
} from 'legacy/app/components/BudgetBanner/BudgetBanner';
import { WhatChangedResponse } from 'legacy/lib/api/types/WhatChangedItems';
import { DeleteType } from 'legacy/lib/api/types/DeleteType';
import { DeleteObjectTypes } from 'legacy/lib/api/types/DeleteObjectTypes';
import { usePageNumber } from 'legacy/hooks/usePageNumber';

type HeaderProps = {
	itemId: number;
	disableButtons: boolean;
	disableSaveButton: boolean;
	isEdit: boolean;
	itemName: string;
	budgetCheck?: WhatChangedResponse['budgetCheck'];
};

const Header = ({
	itemId,
	disableButtons,
	isEdit,
	disableSaveButton,
	itemName,
	budgetCheck,
}: HeaderProps) => {
	const { id } = useParams();
	const pageNumber = usePageNumber();
	const { data: project } = useGetProject(id as string);
	const navigate = useNavigate();
	const { mutate: deleteItem, isLoading } = useDeleteObject({
		onSuccess: () =>
			navigate(
				generatePath(URI.project.projectItems, {
					id,
				})
			),
		onError: () =>
			displayAlertError(
				'There was an error deleting the Item, please try again'
			),
	});

	const projectName = `${project?.projn || ''}${
		project?.proj ? ` [${project.proj}]` : ''
	} `.trim();

	const onCancel = () => {
		const confirmation = confirm(
			'Leaving this screen will discard your changes.'
		);

		if (confirmation) {
			if (isEdit) {
				let pathToRedirect = generatePath(URI.project.projectItems, {
					id,
				});
				if (pageNumber) {
					pathToRedirect += `?pageNumber=${pageNumber}`;
				}
				navigate(pathToRedirect);
			} else {
				const deletePayload = {
					deleteType: DeleteType.dmriTestAndFullDeleteIfPossible,
					objectType: DeleteObjectTypes.objItem,
					objectCodeOrId: itemId,
				};
				deleteItem(deletePayload);
			}
		}
	};

	return (
		<HeaderLight>
			<HeaderLight.Breadcumbs>
				<NavLink
					onClick={onCancel}
					className="tw-!flex tw-mr-6 tw-items-center tw-!text-[#008B6D] tw-!font-bold"
				>
					<i className="ri-arrow-left-s-line"></i> Back
				</NavLink>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: URI.project.base }} linkAs={Link}>
						Projects
					</Breadcrumb.Item>
					<Breadcrumb.Item
						linkProps={{
							to: getRouteWithParam(URI.project.projectItems, {
								id,
							}),
						}}
						linkAs={Link}
					>
						{projectName}
					</Breadcrumb.Item>
					<Breadcrumb.Item
						linkProps={{
							to: getRouteWithParam(URI.project.projectItems, {
								id,
							}),
						}}
						linkAs={Link}
					>
						Items
					</Breadcrumb.Item>
					<Breadcrumb.Item>{isEdit ? 'Edit' : 'Add'}</Breadcrumb.Item>
				</Breadcrumb>
			</HeaderLight.Breadcumbs>
			<HeaderLight.Content>
				<HeaderLight.Title>
					{isEdit
						? `Edit Item${itemName?.length ? `: ${itemName}` : ''}`
						: 'Add Item'}
				</HeaderLight.Title>
				<div>
					<Button
						type="button"
						disabled={isLoading || disableButtons}
						onClick={onCancel}
						className="tw-mr-2"
						variant="light"
						data-testid="item-add-cancel-button"
					>
						Cancel
					</Button>
					<SecureBootstrapButton
						type="submit"
						disabled={
							isLoading ||
							disableButtons ||
							budgetCheck?.passed === false ||
							disableSaveButton
						}
						attributeNo={14}
						attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
						variant="primary"
					>
						Save
					</SecureBootstrapButton>
				</div>
			</HeaderLight.Content>
			<BudgetBannerProvider overrideBudgetCheck budgetCheck={budgetCheck}>
				<BudgetBanner
					context={BudgetBannerContext.Header}
					dataTestId="budget-banner-item"
					className="tw-mb-4 tw-mt-4"
				/>
			</BudgetBannerProvider>
		</HeaderLight>
	);
};

Header.displayName = 'Header';

export default Header;
