import { createContext } from 'react';

import { SingleValue } from 'react-select';

export type TChangeFunction = (
	e: SingleValue<{ label: string; value: number }>
) => void;

export type TSubmitFunction = () => void;

export type TUserRoleContext = {
	isLoading: boolean;
	shouldShowRoleModal: boolean;
	userRole: number | null;
	handleSubmit: TSubmitFunction;
	handleChange: TChangeFunction;
};
export const UserRoleContext = createContext<TUserRoleContext>(
	{} as TUserRoleContext
);
