import { Button } from 'react-bootstrap';
import cn from 'classnames';
import Stack from 'react-bootstrap/Stack';
import SearchButton from './SearchButton';
import { formatMoney } from './utils';
import { FeatureFlags } from 'legacy/app/enums/featureFlags/featureFlags';
import { Feature } from 'use-feature';
import { useReconcile } from './hooks/useReconcile';

function HeaderBox({ children, className }) {
	const classes = cn(
		'a-box-basis px-4 py-1 d-inline-block mt-2 border-start',
		className
	);

	return <div className={classes}>{children}</div>;
}

function HeaderBoxLabel({ children }) {
	return <div className="fs-sm">{children}</div>;
}

function HeaderBoxValue({ children }) {
	return <h3 className="ff-type-bold w-100 h-100 pb-3">{children}</h3>;
}

function AsyncBoxValue({ query, selector, selectedItems, isClearedTab }) {
	const { isLoading, data } = query;

	if (isLoading) {
		return (
			<HeaderBoxValue>
				<div className="text-center">
					<div
						className="spinner-grow spinner-grow-sm text-primary"
						role="status"
					>
						<span className="visually-hidden">Loading...</span>
					</div>
				</div>
			</HeaderBoxValue>
		);
	}

	return (
		<HeaderBoxValue>
			{selector(data, selectedItems, isClearedTab)}
		</HeaderBoxValue>
	);
}

const ReconcileHeader = ({
	onMarkCleared,
	onMarkUncleared,
	onSearch,
	searching,
	gridQuery,
}) => {
	const { tab, selected, openFinanceChargesModal } = useReconcile();
	const isClearedTab = tab === 'cleared';
	const onClearClick = () => {
		if (isClearedTab) {
			onMarkUncleared();
		} else {
			onMarkCleared();
		}
	};

	const totalClearedCount = gridQuery.isLoading
		? '?'
		: gridQuery.data.filter((x) => x.cleared).length;

	const totalCrearedSelector = (data) => {
		const totalCreared = data.reduce((acc, cur) => {
			if (cur.cleared) {
				acc += cur.amount;
			}
			return acc;
		}, 0);

		return formatMoney(totalCreared);
	};

	const totalOutstandingCount = gridQuery.isLoading
		? '?'
		: gridQuery.data.filter((x) => !x.cleared).length;

	const totalOutstandingSelector = (data) => {
		const totalOutstanding = data.reduce((acc, cur) => {
			if (!cur.cleared) {
				acc += cur.amount;
			}
			return acc;
		}, 0);

		return formatMoney(totalOutstanding);
	};

	const selectedItems = Object.keys(selected).filter((key) => selected[key]);

	const totalClearedSelectedCount = gridQuery.isLoading
		? '?'
		: selectedItems.reduce((acc, cur) => {
				const { data } = gridQuery;
				const exists = data.some(
					({ recnum, cleared }) => cleared && recnum === Number(cur)
				);
				if (exists) {
					return acc + 1;
				}
				return acc;
		  }, 0);

	const totalOutstandingSelectedCount = gridQuery.isLoading
		? '?'
		: selectedItems.reduce((acc, cur) => {
				const { data } = gridQuery;
				const exists = data.some(
					({ recnum, cleared }) => !cleared && recnum === Number(cur)
				);
				if (exists) {
					return acc + 1;
				}
				return acc;
		  }, 0);

	const totalSelectedCount = isClearedTab
		? totalClearedSelectedCount
		: totalOutstandingSelectedCount;

	const totalSelectedAmountSelector = (data, selectedItems, isClearedTab) => {
		if (isClearedTab) {
			const totalClearedSelectedAmount = selectedItems.reduce((acc, cur) => {
				const currentItem = data.find(
					({ recnum, cleared }) => cleared && recnum === Number(cur)
				);
				return acc + (currentItem?.amount ?? 0);
			}, 0);

			return formatMoney(totalClearedSelectedAmount);
		} else {
			const totalOutstandingSelectedAmount = selectedItems.reduce(
				(acc, cur) => {
					const currentItem = data.find(
						({ recnum, cleared }) => !cleared && recnum === Number(cur)
					);
					return acc + (currentItem?.amount ?? 0);
				},
				0
			);
			return formatMoney(totalOutstandingSelectedAmount);
		}
	};

	return (
		<div className="a-filter bg-ivory border-1 border-sand roundedx-4 row">
			<div className="d-flex flex-row justify-content-between align-items-center">
				<div className="d-flex flex-row">
					<HeaderBox className="ps-0 border-start-0">
						<HeaderBoxLabel>
							Total Outstanding ({totalOutstandingCount})
						</HeaderBoxLabel>
						<AsyncBoxValue
							query={gridQuery}
							selector={totalOutstandingSelector}
						/>
					</HeaderBox>
					<HeaderBox>
						<HeaderBoxLabel>Total Cleared ({totalClearedCount})</HeaderBoxLabel>
						<AsyncBoxValue query={gridQuery} selector={totalCrearedSelector} />
					</HeaderBox>
					<HeaderBox>
						<HeaderBoxLabel>
							Total Selected ({totalSelectedCount})
						</HeaderBoxLabel>
						<AsyncBoxValue
							query={gridQuery}
							selector={totalSelectedAmountSelector}
							selectedItems={selectedItems}
							isClearedTab={isClearedTab}
						/>
					</HeaderBox>
				</div>
				<Stack direction="horizontal" gap={2}>
					<SearchButton onClick={() => onSearch()} searching={searching} />
					<Feature name={FeatureFlags.FinanceCharges}>
						<Button onClick={openFinanceChargesModal} variant="ivory">
							Finance Charges
						</Button>
					</Feature>

					<Button variant="primary" onClick={onClearClick}>
						{isClearedTab ? <>Mark Uncleared</> : <>Mark Cleared</>}
					</Button>
				</Stack>
			</div>
		</div>
	);
};

ReconcileHeader.displayName = 'ReconcileHeader';

export { ReconcileHeader };
