import React from 'react';
import { generatePath, Link, NavLink } from 'react-router-dom';
import { Container, Row, Breadcrumb, Form, Col, Button } from 'react-bootstrap';
import ThreadComponent from '../ThreadComponent';
import { getActiveMenuUri, WithRouter } from '../../../helpers/Router';
import { compareStr, StringCompare } from '../../../helpers/String';
import ProjectViewInfo from './ProjectViewInfo';
import ProjectViewInfoClosed from './ProjectViewInfoClosed';
import ProjectViewSummary from './ProjectViewSummary';
import ProjectViewItem from './ProjectViewItem';
import ProjectViewProposal from './ProjectViewProposal';
import ProjectViewInvoice from './ProjectViewInvoice';
import ProjectViewDeposit from './ProjectViewDeposit';
import ProjectViewSettings from './ProjectViewSettings';
import ProjectViewVendorOrder from './ProjectViewVendorOrder';
import { HeaderLight } from '../../components/Header';
import URI from '../../../defaults/RoutesDefault';
import ProjectViewTicket from './ProjectViewTicket';
import { getMatchedValue, Is } from '../../../helpers/Util';
import { ApiService } from '../../../lib/api/HttpService';
import { ErrorObjects } from '../../../defaults/Error';
import AsyncProjectsDropdown from '../../../app/components/dropdowns/AsyncProjectsDropdown';
import { isEmpty, pick } from 'lodash';
import {
	displayAlert,
	displayAlertError,
	displayAlertLoader,
	getErrorMessage,
	hideAlertLoader,
} from '../../../utilities/Response';
import ConfirmModal from '../../../app/components/modal/ConfirmModal';
import MSG from '../../../defaults/Message';
import { isDraft, promptDraft } from '../../../utilities/ModuleHelper';
import SecureBootstrapButton from '../../../app/components/security/SecureBootstrapButton';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../app/context/security';
import SecureContent from '../../../app/components/security/SecureContent';
import {
	renderSecureContent,
	renderSecureHideContent,
} from '../../../utilities/modules/SecureContent';
import BudgetBannerProvider from 'legacy/app/context/BudgetBannerContext';
import BudgetBanner, {
	BudgetBannerContext,
} from 'legacy/app/components/BudgetBanner/BudgetBanner';
import Documents from '../documents/Documents';
import Budget from '../budget/Budget';
import ProjectViewSchedule from './ProjectViewSchedule';
import { FeatureFlags } from '../../../app/enums/featureFlags/featureFlags';
import { Feature } from 'use-feature';

class ProjectView extends ThreadComponent {
	constructor(props) {
		super(props);

		this.projId = this.props.params.id;
		this.state = {
			activeMenu: getActiveMenuUri(
				this.props.paths.pathname,
				[
					'summary',
					'info',
					'items',
					'proposals',
					'deposits',
					'invoices',
					'vendor-orders',
					'tickets',
					'budget',
					'settings',
					'documents',
					'schedule',
				],
				'items'
			),
			options: [],
			dataLoaded: false,
			project: {
				id: this.projId,
			},
			notes: '',
			notesrtf: '',
			clientPortalEnabled: false,
			statuses: {},
			closed: '',
			projectName: '',
			projectId: this.projId,
			isFound: true,
			showSalesTaxWarningModal: false,
			continueSaving: false,
		};

		this.string = new StringCompare(this.state.activeMenu);

		this.api = new ApiService();
		this.updatedData = {};
		this.updatedBillingAddressData = {};
		this.updatedSiteAddressData = {};
		this.salesTaxProps = ['td', 'tf', 'ti', 'tl', 'tm', 'to'];

		this.handleMenuClick = this.handleMenuClick.bind(this);
	}

	componentInit() {
		this.setIsLoaded(true);
	}

	async fetchInitialData() {
		window.localStorage.setItem('isDraft', 'false');

		if (!this.props.params.page) {
			this.props.navigate(
				generatePath(URI.project.viewPage, {
					id: this.projId,
					page: 'items',
				})
			);
		}

		if (this.projId !== undefined) {
			await this.getProjectData();
		}

		if (
			[
				'summary',
				'info',
				'items',
				'proposals',
				'deposits',
				'invoices',
				'vendor-orders',
				'tickets',
				'budget',
				'settings',
				'documents',
				'schedule',
			].indexOf(this.props.params.page) <= -1
		) {
			try {
				throw ErrorObjects.Notfound;
			} catch (error) {
				this.setIsFound(false);
				this.setError(error, true);
			}
		}
	}

	async componentDidMount() {
		await this.fetchInitialData();
	}

	async getProjectData(isLoader) {
		if (isLoader !== false) this.dMloader(true);

		let statuses = new Map();
		let st = await this.api.getStatusCodes('?$orderby=priority');
		st.forEach((el) => {
			statuses.set(el.statusNumber, {
				name: el.statusName,
				color: el.hexColor,
			});
		});

		let [project] = await this.api.getProjects(`?$filter=id eq ${this.projId}`);

		// Check if project exists. Else display 404.
		if (isEmpty(project)) {
			this.setIsLoaded(true);
			this.setIsFound(false);
			this.dMloader(false);
			this.setState({
				errorLoad: true,
			});
			return;
		}

		this.updatedData = project;

		let closed = Boolean(project.closeddt);

		const projectName = `${project.projn ? `${project.projn} ` : ''}[${
			project.proj
		}]`;

		this.setState({
			project: project,
			statuses: statuses,
			projectName: projectName,
			closed: closed,
		});

		this.dMloader(false);

		document.title = 'Project - ' + project.proj;
	}

	handleMenuClick = (e) => {
		if (isDraft() && !promptDraft()) {
			e.preventDefault();
			return false;
		}

		// Set the active menu and string.
		this.setState(
			(prevState) =>
				(prevState.activeMenu = this.string.theString =
					e.target.getAttribute('data-menu'))
		);
	};

	switchProject = async (e) => {
		try {
			this.dMloader(true, true, false);
			const [project] = await this.api.getProjects(
				`?$filter=proj eq '${encodeURIComponent(e.value)}' and closeddt eq null`
			);
			if (project && project?.id !== this.state.project.id) {
				window.location.href = generatePath(URI.project.viewPage, {
					id: project?.id,
					page: this.state.activeMenu,
				});
			}
		} catch (error) {
			console.error(error);
		}
		this.dMloader(false, true);
	};

	toggleConfirmModal = (e) => {
		this.setState({
			showSalesTaxWarningModal: !this.state.showSalesTaxWarningModal,
		});
	};

	handleWarningConfirm = (e) => {
		this.setState(
			{
				showSalesTaxWarningModal: false,
				continueSaving: true,
			},
			this.handleSave
		);
	};

	handleSave = async () => {
		let isError = false;
		if (
			this.updatedData.clientdep === null ||
			this.updatedData.clientdep === undefined ||
			this.updatedData.clientdep === ''
		) {
			this.updatedData.clientdep = 0;
		}
		try {
			displayAlertLoader(MSG.loading.update.msg);
			if (this.updatedData.taxc) {
				const salesTaxData = pick(this.updatedData, this.salesTaxProps);
				/* TODO: Find out why this call to edit sales tax code is present */
				try {
					await this.api.editSalesTaxCodes({
						taxc: this.updatedData.taxc,
						...salesTaxData,
					});
				} catch (err) {
					if (err?.response?.status !== 403) {
						displayAlertError(getErrorMessage(err));
					}
				}
			}

			if (this.updatedData.projn === '' || this.updatedData.dateopened === '') {
				displayAlert('danger', 'All fields marked with * must be filled out.');
				return;
			} else {
				let taxCode = null;
				if (this.updatedData?.taxc) {
					taxCode = await this.api.getSalesSummaryTaxCodeByCode(
						this.updatedData.taxc
					);
				}

				if (!this.state.continueSaving && taxCode && taxCode.inactive) {
					hideAlertLoader();
					this.setState({
						showSalesTaxWarningModal: true,
					});
					return;
				} else {
					try {
						if (Object.keys(this.updatedBillingAddressData).length > 0) {
							await this.api.editAddress(
								this.updatedBillingAddressData.id,
								this.updatedBillingAddressData
							);
						}

						if (Object.keys(this.updatedSiteAddressData).length > 0) {
							await this.api.editAddress(
								this.updatedSiteAddressData.id,
								this.updatedSiteAddressData
							);
						}

						this.updatedData = {
							...this.updatedData,
							notes: this.state.notes,
							notesrtf: this.state.notesrtf,
							clientPortalEnabled: this.state.project.clientPortalEnabled,
							clientPortalShowProjectBudget:
								this.state.project.clientPortalShowProjectBudget,
							clientPortalItemsApproval:
								this.state.project.clientPortalItemsApproval,
							clientPortalProposalsApproval:
								this.state.project.clientPortalProposalsApproval,
							clientPortalProposalsRequireSignature:
								this.state.project.clientPortalProposalsRequireSignature,
						};

						if (Object.keys(this.updatedData).length > 0) {
							await this.api.editProject(this.projId, this.updatedData);
						}
						await this.fetchInitialData();
						displayAlert('success', 'Project has been updated successfully');
						this.setState({
							continueSaving: false,
						});
					} catch (e) {
						isError = true;
						this.setState({
							continueSaving: false,
						});

						displayAlertError(
							getErrorMessage(e, 'Project could not be updated')
						);
					}
				}
			}

			if (!isError) {
				await this.fetchInitialData();
			} else {
				await this.getProjectData(false);
			}
		} catch (e) {
			displayAlert('danger', 'Project could not be updated');
		}
	};

	handleUpdateProject = (data) => {
		this.setState({
			project: data,
		});
	};

	handleNotes = (notes, notesrtf) =>
		this.setState({
			notes,
			notesrtf,
		});

	handleClientPortalChange = (e) => {
		const { id, checked } = e.target;

		if (id === 'clientPortalEnabled' && !checked) {
			this.setState({
				project: {
					...this.state.project,
					clientPortalEnabled: false,
					clientPortalShowProjectBudget: false,
					clientPortalItemsApproval: false,
					clientPortalProposalsApproval: false,
					clientPortalProposalsRequireSignature: false,
				},
			});
		} else {
			this.setState({
				project: {
					...this.state.project,
					[id]: checked,
				},
			});
		}
	};

	renderHeader() {
		const { projectId } = this.state;
		const { page } = this.props.params;

		this.string.set('trueValue', 'active').set('falseValue', '');

		return (
			<HeaderLight style={{ position: 'relative' }} className="!tw-pt-5">
				<HeaderLight.Breadcumbs>
					<NavLink
						to="/projects"
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
						data-testid="breadcrumbs-back-button"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb className="ms-4">
						<Breadcrumb.Item linkProps={{ to: '/projects' }} linkAs={Link}>
							Projects
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: this.isLoaded
									? generatePath(URI.project.viewPage, {
											id: projectId,
											page: 'items',
									  })
									: generatePath(URI.project.add),
								reloadDocument: true,
							}}
							linkAs={Link}
						>
							{this.isLoaded ? this.state.projectName : ''}
						</Breadcrumb.Item>
						<Breadcrumb.Item>{this.getModTitle()}</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content actions={true}>
					<h1 className="text-charcoal project">
						{this.isLoaded ? this.state.projectName : ''}
						{this.state.projectName && <span className="tw-px-1">-</span>}
						{this.state.project.clientName}
					</h1>
					<Form.Group
						as={Row}
						controlId="formGridText2"
						style={{ width: '300px' }}
					>
						<Form.Label as={Col} xs={4} className="mt-2 text-end px-0">
							<strong>Project</strong>
						</Form.Label>
						<Col xs={8}>
							<AsyncProjectsDropdown
								defaultValue={{
									value: this.state.project.id,
									label: this.state.projectName,
								}}
								styles={{
									menuPortal: (provided) => ({
										...provided,
										zIndex: 9999,
									}),
									menu: (provided) => ({
										...provided,
										zIndex: 9999,
									}),
								}}
								placeholder="Reference"
								className="react-select-sm"
								onChange={this.switchProject}
							/>
						</Col>
					</Form.Group>
				</HeaderLight.Content>
				<BudgetBannerProvider>
					<BudgetBanner
						context={BudgetBannerContext.Header}
						dataTestId="budget-banner-project"
						className="mb-4"
					/>
				</BudgetBannerProvider>
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'space-between',
					}}
				>
					<HeaderLight.Actions className="text-charcoal">
						<li>
							<Button
								as={Link}
								to={generatePath(URI.project.viewPage, {
									id: projectId,
									page: 'items',
								})}
								data-menu="items"
								className={`${compareStr(page, 'items', 'active')}`}
								onClick={this.handleMenuClick}
							>
								Items
							</Button>
						</li>
						{renderSecureHideContent(
							<li>
								<Button
									as={Link}
									to={generatePath(URI.project.viewPage, {
										id: projectId,
										page: 'proposals',
									})}
									data-menu="proposals"
									className={`${compareStr(page, 'proposals', 'active')}`}
									onClick={this.handleMenuClick}
								>
									Proposals
								</Button>
							</li>
						)(17, SECURITY_ATTRIBUTE_TYPES.DenyAccess)}
						<li>
							<Button
								as={Link}
								to={generatePath(URI.project.viewPage, {
									id: projectId,
									page: 'deposits',
								})}
								data-menu="deposits"
								className={`${compareStr(page, 'deposits', 'active')}`}
								onClick={this.handleMenuClick}
							>
								Deposits
							</Button>
						</li>
						<li>
							<Button
								as={Link}
								to={generatePath(URI.project.viewPage, {
									id: projectId,
									page: 'invoices',
								})}
								data-menu="invoices"
								className={`${compareStr(page, 'invoices', 'active')}`}
								onClick={this.handleMenuClick}
							>
								Invoices
							</Button>
						</li>
						<li>
							<Button
								as={Link}
								to={generatePath(URI.project.viewPage, {
									id: projectId,
									page: 'vendor-orders',
								})}
								data-menu="vendor-orders"
								className={`${compareStr(page, 'vendor-orders', 'active')}`}
								onClick={this.handleMenuClick}
							>
								Purchase Orders
							</Button>
						</li>
						<li>
							<Button
								as={Link}
								to={generatePath(URI.project.viewPage, {
									id: projectId,
									page: 'summary',
								})}
								data-menu="summary"
								className={`${compareStr(page, 'summary', 'active')}`}
								onClick={this.handleMenuClick}
							>
								Status
							</Button>
						</li>
						<SecureContent
							attributeNo={200}
							attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
						>
							<SecureContent.HasAccess>
								<li>
									<Button
										as={Link}
										to={generatePath(URI.project.viewPage, {
											id: projectId,
											page: 'budget',
										})}
										data-menu="budget"
										className={`${compareStr(page, 'budget', 'active')}`}
										onClick={this.handleMenuClick}
									>
										Budget
									</Button>
								</li>
							</SecureContent.HasAccess>
						</SecureContent>
						<Feature name={FeatureFlags.ProjectSchedule}>
							<li>
								<Button
									as={Link}
									to={generatePath(URI.project.viewPage, {
										id: projectId,
										page: 'schedule',
									})}
									data-menu="schedule"
									className={`${compareStr(page, 'schedule', 'active')}`}
									onClick={this.handleMenuClick}
								>
									Schedule
								</Button>
							</li>
						</Feature>
						<li>
							<Button
								as={Link}
								to={generatePath(URI.project.viewPage, {
									id: projectId,
									page: 'info',
								})}
								data-menu="info"
								className={`${compareStr(page, 'info', 'active')}`}
								onClick={this.handleMenuClick}
							>
								Info
							</Button>
						</li>
						<li>
							<Button
								as={Link}
								to={generatePath(URI.project.viewPage, {
									id: projectId,
									page: 'settings',
								})}
								data-menu="settings"
								className={`${compareStr(page, 'settings', 'active')}`}
								onClick={this.handleMenuClick}
							>
								Settings
							</Button>
						</li>
						<li>
							<Button
								as={Link}
								to={generatePath(URI.project.viewPage, {
									id: projectId,
									page: 'documents',
								})}
								data-menu="documents"
								className={`${compareStr(
									page,
									'documents',
									'active'
								)} btn-icon`}
								onClick={this.handleMenuClick}
							>
								Documents{' '}
								{this.state.project.numAttachments > 0 && (
									<i className="ri-attachment-2 ms-1"></i>
								)}
							</Button>
						</li>
					</HeaderLight.Actions>
					{this.getModTitle() === 'Info' ? (
						<SecureBootstrapButton
							attributeNo={13}
							attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
							variant="primary"
							size="lg"
							onClick={this.handleSave}
							style={{ width: 250, marginLeft: 'auto' }}
						>
							Save
						</SecureBootstrapButton>
					) : (
						<div></div>
					)}
				</div>
			</HeaderLight>
		);
	}

	getModTitle() {
		const { activeMenu } = this.state;

		return getMatchedValue(activeMenu, {
			summary: 'Status',
			info: 'Info',
			items: 'Items',
			proposals: 'Proposals',
			deposits: 'Deposits',
			invoices: 'Invoices',
			'vendor-orders': 'Purchase Orders',
			tickets: 'Tickets',
			budget: 'Budget',
			settings: 'Settings',
			documents: 'Documents',
			schedule: 'Schedule',
		});
	}

	renderContent() {
		if (compareStr(this.props.params.page, 'summary'))
			return renderSecureContent(
				<ProjectViewSummary project={this.state.project} />
			)(13, SECURITY_ATTRIBUTE_TYPES.DenySpec3);

		if (compareStr(this.props.params.page, 'info')) {
			const ComponentToRender = this.state.closed
				? ProjectViewInfoClosed
				: ProjectViewInfo;

			return (
				<ComponentToRender
					statuses={this.state.statuses}
					project={this.state.project}
					closed={this.state.closed}
					onChange={(data) => {
						this.updatedData = {
							...this.updatedData,
							...data,
						};
					}}
					onBillingAddressChange={(data) => {
						this.updatedBillingAddressData[data.key] = data.value;
					}}
					onSiteAddressChange={(data) => {
						this.updatedSiteAddressData[data.key] = data.value;
					}}
					updatedData={this.updatedData}
					updatedBillingAddress={this.updatedBillingAddressData}
					updatedSiteAddress={this.updatedSiteAddressData}
					onSave={this.handleSave}
				/>
			);
		}

		if (compareStr(this.props.params.page, 'items') && this.state.closed !== '')
			return renderSecureContent(
				<ProjectViewItem
					statuses={this.state.statuses}
					project={this.state.project}
					closed={this.state.closed}
				/>
			)(14, SECURITY_ATTRIBUTE_TYPES.DenyAccess);

		if (
			compareStr(this.props.params.page, 'proposals') &&
			this.state.closed !== ''
		)
			return (
				<ProjectViewProposal
					statuses={this.state.statuses}
					project={this.state.project}
					closed={this.state.closed}
				/>
			);

		if (compareStr(this.props.params.page, 'deposits'))
			return renderSecureContent(
				<ProjectViewDeposit project={this.state.project} />
			)(54, SECURITY_ATTRIBUTE_TYPES.DenyAccess);

		if (compareStr(this.props.params.page, 'invoices'))
			return renderSecureContent(
				<ProjectViewInvoice project={this.state.project} />
			)(52, SECURITY_ATTRIBUTE_TYPES.DenyAccess);

		if (
			compareStr(this.props.params.page, 'settings') &&
			this.state.closed !== ''
		)
			return (
				<ProjectViewSettings
					project={this.state.project}
					closed={this.state.closed}
					onChange={(data) => {
						this.updatedData = {
							...this.updatedData,
							...data,
						};
					}}
					handleNotes={this.handleNotes}
					handleClientPortalChange={this.handleClientPortalChange}
					updatedData={this.updatedData}
					onSave={this.handleSave}
				/>
			);

		if (
			compareStr(this.props.params.page, 'budget') &&
			this.state.closed !== ''
		) {
			return (
				<Budget
					id={this.projId || this.state.project.id}
					proj={this.state.project.proj}
					modalTitle="Project Budget"
				/>
			);
		}

		if (
			compareStr(this.props.params.page, 'vendor-orders') &&
			this.state.closed !== ''
		)
			return renderSecureContent(
				<ProjectViewVendorOrder
					project={this.state.project}
					closed={this.state.closed}
				/>
			)(18, SECURITY_ATTRIBUTE_TYPES.DenyAccess);

		if (compareStr(this.props.params.page, 'tickets'))
			return <ProjectViewTicket project={this.state.project} />;

		if (compareStr(this.props.params.page, 'documents'))
			return (
				<Documents
					objectType="Project"
					objectId={this.projId}
					onRefresh={(numAttachments) => {
						this.setState({
							project: {
								...this.state.project,
								numAttachments: numAttachments,
							},
						});
					}}
				/>
			);

		if (compareStr(this.props.params.page, 'schedule'))
			return <ProjectViewSchedule project={this.state.project} />;
	}

	render() {
		return this.renderView(
			<>
				{this.renderHeader()}

				<div className="content-padding min-height !tw-pt-5">
					<Container fluid>{this.renderContent()}</Container>
				</div>

				<ConfirmModal
					show={this.state.showSalesTaxWarningModal}
					title="Inactive Sales Tax Code"
					message="The Sales Tax Code selected is inactive. This might be OK if you are editing an older or completed project. Do you want to proceed?"
					confirmAction={this.handleWarningConfirm.bind(this)}
					toggleModal={this.toggleConfirmModal.bind(this)}
					labelCancel="Cancel"
				/>
			</>
		);
	}
}

export default WithRouter(ProjectView);
