import React from 'react';
import {
	Button,
	Dropdown,
	DropdownButton,
	Form,
	OverlayTrigger,
	Table,
	Tooltip,
} from 'react-bootstrap';
import ThreadComponent from '../ThreadComponent';
import { WithRouter, getRouteWithParam } from '../../../helpers/Router';
import Switch from '../../components/Switch';
import ListFilter from '../../components/ListFilter';
import InputBar from '../../components/InputBar';
import { Link } from 'react-router-dom';
import { currencyFormat } from '../../../helpers/Number';
import MSG from 'legacy/defaults/Message';
import { ApiService } from '../../../lib/api/HttpService';
import debounce from 'lodash/debounce';
import { Pagination } from '../../../app/components/pagination/Pagination';
import { addDomClass, hasClass, removeDomClass } from '../../../helpers/DOM';
import { formatDate, formatFilterDate } from '../../../helpers/Date';
import { DateRangePicker } from 'rsuite';
import {
	startOfDay,
	endOfDay,
	addDays,
	subDays,
	getMonth,
	getYear,
	lastDayOfMonth,
} from 'date-fns';
import noItems from '../../../assets/images/icons/addpayment.svg';
import { isFiltered } from '../../../helpers/Util';
import { showEmpty, showLoading } from '../../../helpers/Loading';
import URI from '../../../defaults/RoutesDefault';
import { isEmpty } from 'lodash';
import FilterSort from '../../../utilities/modules/FilterSort';
import { encodeURI } from '../../../utilities/String';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../app/context/security';
import SecureA from '../../../app/components/security/SecureA';
import { SendInvoiceModal } from 'features/Invoices/SendInvoiceModal';
import {
	displayAlertLoader,
	hideAlertLoader,
	displayAlert,
	displayAlertError,
	displayAlertSuccess,
} from 'legacy/utilities/Response';
import { Feature } from 'use-feature';
import { FeatureFlags } from 'legacy/app/enums/featureFlags/featureFlags';
import Icon from 'legacy/app/components/Icon/Icon';
import ClientPortalIconInActive from 'legacy/assets/images/icons/client-portal-icon-inactive.svg';
import ClientPortalIconActive from 'legacy/assets/images/icons/client-portal-icon-active.svg';
import ClientPortalIconAdd from 'legacy/assets/images/icons/client-portal-icon-add.svg';
import ClientPortalIconRemove from 'legacy/assets/images/icons/client-portal-icon-remove.svg';
import ClientPortalIconDefault from 'legacy/assets/images/icons/client-portal-icon-default.svg';

class ProjectViewInvoice extends ThreadComponent {
	constructor(props) {
		super(props);

		this.projectId = this.props.params.id;
		this.fs = new FilterSort('project_invoice_list_' + this.projectId);
		this.fs.setDefaultSort('invdt asc');
		this.state = {
			checks: [],
			data: [],
			proposals: [],
			dataIsLoaded: false,
			available: this.fs.getFilter('available') ?? true,
			gross_sum: 0,
			depret_sum: 0,
			balance_sum: 0,
			payment_sum: 0,
			arbalance_sum: 0,
			pageSize: 20,
			page: 1,
			sortProperty: this.fs.getSort() ?? 'invdt desc',
			showTableSearch: this.fs.isSearchActive(),
			searchProperties: this.fs.getSearches(),
			project: {},
			sendInvoiceData: {
				token: '',
				link: '',
			},
			showInvoiceModal: false,
		};

		this.api = new ApiService();
		this.changePageHandler = debounce(this.handleChangePage.bind(this, 1), 200);
	}

	componentInit() {
		this.setFormAction(true);
	}

	async componentDidMount() {
		window.setTimeout(() => {
			this.setState({ hideAlert: true });
		}, 5000);

		if (isEmpty(this.props.project?.proj)) {
			return;
		}

		this.setState(
			{
				project: this.props.project,
				dataIsLoaded: false,
			},
			this.loadData
		);
	}

	async componentDidUpdate(previousProps, previousState) {
		let project = {};
		if (previousProps.project !== this.props.project) {
			project = this.props.project || {};

			this.setState(
				{
					project,
					dataIsLoaded: false,
				},
				this.loadData
			);
		}
	}

	async loadData() {
		await this.fetchItems();
		await this.fetchInvoiceTotal();
		this.enableSortTable();
	}

	async fetchInvoiceTotal() {
		const totals = await this.api.getProjectInvoiceTotal(
			this.state.project?.proj,
			this.state.available
		);

		this.setState({
			gross_sum: totals.grossInvoices,
			depret_sum: totals.depositRetainerApplied,
			balance_sum: totals.balance,
			payment_sum: totals.paymentsAdjustments,
			arbalance_sum: totals.ar,
			dataIsLoaded: true,
		});
	}

	async fetchItems(page, setLoading) {
		if (setLoading) this.setState({ dataIsLoaded: false });
		let data;
		try {
			data = await this.api.get(
				'projectinvoices',
				`${this.state.available ? 'open' : ''}?${this.buildFilters(page)}`
			);
			const proposals = await this.api.get(
				'proposals',
				`?&$filter=proj eq '${encodeURIComponent(this.state.project?.proj)}'`
			);
			this.setState({
				proposals,
				dataIsLoaded: true,
			});

			this.renderData(data);
		} catch {
			data = [];
		} finally {
			this.setState({
				dataIsLoaded: true,
			});

			this.renderData(data);
		}
	}

	handleSwitch = (e) => {
		this.setState(
			{
				available: e.target.checked,
				gross_sum: 0,
				depret_sum: 0,
				balance_sum: 0,
				payment_sum: 0,
				arbalance_sum: 0,
			},
			() => {
				this.handleChangePage(1);
				this.fetchInvoiceTotal();
			}
		);
		this.fs.setFilter('available', e.target.checked);
	};

	renderData(data) {
		this.setState({
			data: data,
		});
	}

	openInvoiceModal = async () => {
		try {
			displayAlertLoader(MSG.loading.info);

			const mappingBatches = Object.values(this.state.checks)
				.map((id) => {
					const obj = this.state.data.find((item) => item.id == id);

					if (obj) {
						return {
							objectId: obj.id,
							objectType: 'Invoice',
						};
					}
				})
				.filter(Boolean);

			const postData = await this.api.postJson('publicmappingbatches', {
				publicMappingObjects: mappingBatches,
			});

			this.setState((prevState) => ({
				showInvoiceModal: true,
				sendInvoiceData: {
					...prevState.sendInvoiceData,
					link: postData.link,
					token: postData.token,
				},
			}));
			hideAlertLoader();
		} catch (error) {
			displayAlertError('No document for this invoice.');
		}
	};

	computeSum(arrayOfNum) {
		return arrayOfNum.reduce((a, b) => a + b);
	}

	handleShowTableSearch = (e) => {
		const filters = document.querySelectorAll('.a-table-search-fields input');
		if (filters) {
			filters.forEach((e) => {
				e.value = '';
			});
		}

		let newTableSearch = !this.state.showTableSearch;
		this.fs.setIsActiveSearch(newTableSearch);
		this.setState({
			showTableSearch: newTableSearch,
		});

		if (JSON.stringify(this.state.searchProperties) !== '{}') {
			this.fs.setSearches({});
			this.setState(
				{
					searchProperties: {},
				},
				this.changePageHandler
			);
		}
	};

	async handleDocument(e, item) {
		e.preventDefault();
		if (this.state.project.clientPortalEnabled) {
			const document = await this.api.getPublicMappings(item.fileId, 'Invoice');

			window.open(document.link, '_blank');
			return;
		}

		let document = await this.api.postJson('publicmappingbatches', {
			publicMappingObjects: [
				{
					objectId: item.id,
					objectType: 'Invoice',
				},
			],
		});
		window.open(document.link, '_blank').focus();
	}

	enableSortTable() {
		const sort = document.querySelectorAll('.a-table-heading .sort');
		const self = this;

		// Add change event
		if (sort) {
			sort.forEach((_e) => {
				_e.addEventListener(
					'click',
					function (e) {
						sort.forEach((_e2) => {
							if (_e !== _e2) {
								removeDomClass('desc', _e2);
								removeDomClass('asc', _e2);
								removeDomClass('active', _e2);
							}
						});

						addDomClass('active', _e);

						if (hasClass('desc', _e)) {
							removeDomClass('desc', _e);
							addDomClass('asc', _e);
						} else if (hasClass('asc', _e)) {
							removeDomClass('asc', _e);
							addDomClass('desc', _e);
						} else {
							addDomClass('desc', _e);
						}

						const sortProperty = `${_e.attributes['data-field'].value} ${
							hasClass('desc', _e) ? 'asc' : 'desc'
						}`;

						// Save sortProperty to localStorage
						self.fs.setSort(sortProperty);

						self.setState(
							{
								sortProperty,
							},
							self.changePageHandler
						);
					},
					false
				);
			});
		}
	}

	async handleProposalClick(id) {
		const document = await this.api.postJson('publicmappingbatches', {
			publicMappingObjects: [
				{
					objectId: id,
					objectType: 'Proposal',
				},
			],
		});
		window.open(document.link, '_blank').focus();
	}

	buildFilters(currentPage) {
		let filtersQuery = `&$filter=proj eq '${this.state.project?.proj}'`;
		let filters = [];

		Object.keys(this.state.searchProperties).forEach((key) => {
			const property = this.state.searchProperties[key];
			if (property.value || property.min || property.ma) {
				if (property.type === 'number') {
					filters.push(
						`(contains(cast(${key}, 'Edm.String'),'${encodeURI(
							property.value
						)}'))`
					);
				} else if (property.type === 'date') {
					if (property.min) filters.push(`${key} ge ${property.min}`);
					if (property.max) filters.push(`${key} le ${property.max}`);
				} else {
					filters.push(`contains(${key}, '${property.value}')`);
				}
			}
		});

		filtersQuery += filters.length > 0 ? ` and ${filters.join(' and ')}` : '';

		let queryString = `$top=${this.state.pageSize + 1}&$skip=${
			((currentPage ?? this.state.page) - 1) * this.state.pageSize
		}${filtersQuery}`;

		if (this.state.sortProperty !== '') {
			queryString += `&$orderby=${this.state.sortProperty}`;
		}

		return queryString;
	}

	onPageSizeChanged = (size) => {
		this.setState(
			{
				pageSize: size,
				page: 1,
			},
			() => {
				this.handleChangePage(1);
			}
		);
	};

	onPageChanged = (page) => {
		this.handleChangePage(page);
	};

	handleChangePage = async (page) => {
		this.fetchItems(page, true);

		this.setState({
			page: page,
		});
	};

	handleSearch = (e) => {
		const key = e.target.attributes['data-field'].value;
		const value = e.target.value;
		const type = e.target.attributes['data-type']
			? e.target.attributes['data-type'].value
			: 'string';

		this.setState(
			{
				searchProperties: {
					...this.state.searchProperties,
					[key]: { value: value, type: type },
				},
				dataIsLoaded: false,
			},
			() => {
				this.fs.setSearches(this.state.searchProperties);
				this.changePageHandler();
			}
		);
	};

	handleDateChange = (key, e) => {
		let tmp = this.state.searchProperties;
		if (e !== null) {
			tmp[key] = {
				min: formatFilterDate(e[0]),
				max: formatFilterDate(e[1]),
				type: 'date',
			};
		} else {
			delete tmp[key];
		}
		this.setState(
			{
				searchProperties: tmp,
				dataIsLoaded: false,
			},
			() => {
				this.fs.setSearches(this.state.searchProperties);
				this.changePageHandler();
			}
		);
	};

	sortClass(name) {
		return `sort ${this.fs.isActiveSort(name)}`;
	}

	isDisplaySearch() {
		return (
			(this.state.data && this.state.data.length > 0) ||
			isFiltered(this.state.searchProperties) ||
			!this.state.dataIsLoaded
		);
	}

	selectedIds = () => {
		return this.state.checks.map((item) => item.id);
	};

	renderActionDropDown() {
		if (this.props.closed) {
			return (
				<DropdownButton
					className="d-flex justify-content-end ms-3 btn-icon"
					align="end"
					id="dact"
					variant="primary"
					size="sm"
					title="Actions"
					disabled
				/>
			);
		} else if (this.state.checks.length === 0) {
			return (
				<OverlayTrigger
					id="otr"
					overlay={<Tooltip id="selprop">Please select an Invoice</Tooltip>}
				>
					<DropdownButton
						disabled={true}
						className="d-flex justify-content-end ms-3 btn-icon"
						align="end"
						id="dact"
						variant="ivory"
						size="sm"
						title="Actions"
					></DropdownButton>
				</OverlayTrigger>
			);
		} else {
			return (
				<DropdownButton
					className="d-flex justify-content-end ms-3 btn-icon"
					align="end"
					id="dact"
					variant="primary"
					size="sm"
					title="Actions"
				>
					<Dropdown.Item
						onClick={this.openInvoiceModal}
						className="tw-flex tw-gap-2"
					>
						<i className="ri-mail-send-line"></i> Send Invoice
					</Dropdown.Item>

					{this.state.project.clientPortalEnabled ? (
						<>
							<Dropdown.Item
								onClick={(e) =>
									this.handleSelectToggleShowInClientPortal(e, 'on')
								}
							>
								<div className="tw-w-full tw-flex tw-gap-2">
									<Icon path={ClientPortalIconAdd} />
									<span>Include on Portal</span>
								</div>
							</Dropdown.Item>
							<Dropdown.Item
								onClick={(e) =>
									this.handleSelectToggleShowInClientPortal(e, 'off')
								}
							>
								<div className="tw-w-full tw-flex tw-gap-2">
									<Icon path={ClientPortalIconRemove} />
									<span className="tw-mr-4">Remove from Portal</span>
								</div>
							</Dropdown.Item>
						</>
					) : (
						<Dropdown.Item>
							<Link
								to={`/projects/${this.state.project.id}/settings`}
								className="tw-no-underline tw-font-bold tw-flex tw-gap-1 tw-text-[#008b6d]"
							>
								<Icon
									path={ClientPortalIconDefault}
									iconClass="tw-w-5 tw-h-5"
								/>
								Enable Portal
							</Link>
						</Dropdown.Item>
					)}
				</DropdownButton>
			);
		}
	}

	renderListFilter() {
		return (
			<>
				<ListFilter expandable={0}>
					<ListFilter.Fields sm={12} md={6} lg={6}>
						<ListFilter.Field className="w-100">
							<Form.Label className="text-end">
								<strong>Show Open/Available Only</strong>
							</Form.Label>
							<span>
								<Switch
									onChange={this.handleSwitch}
									checked={this.state.available}
								></Switch>
							</span>
						</ListFilter.Field>
					</ListFilter.Fields>
					<ListFilter.Actions sm={12} md={6} lg={6}>
						<ListFilter.Actions>
							{this.isDisplaySearch() && (
								<Button
									as={Link}
									to="#"
									variant="ivory"
									size="sm"
									className={`btn-icon btn-action fw-bold tw-mr-4`}
									onClick={this.handleShowTableSearch}
								>
									<i className="ri-search-line"></i> Search
								</Button>
							)}
							{this.renderActionDropDown()}
						</ListFilter.Actions>
					</ListFilter.Actions>
				</ListFilter>
				<ListFilter.Spacer />
			</>
		);
	}

	renderInputBar() {
		return (
			<InputBar className="large">
				<InputBar.Links className="full-width">
					<InputBar.Link>
						<Form.Label htmlFor="inputPassword5" className="ilabel">
							Gross Invoices
						</Form.Label>
						<div className="form-group-extra reversed">
							<Form.Control readOnly disabled type="text" size="sm" />
							<span>{currencyFormat(this.state.gross_sum)}</span>
						</div>
					</InputBar.Link>
					<InputBar.Link>
						<Form.Label htmlFor="inputPassword5" className="ilabel">
							Dep./Ret. Applied
						</Form.Label>
						<div className="form-group-extra reversed">
							<Form.Control readOnly disabled type="text" size="sm" />
							<span>{currencyFormat(this.state.depret_sum)}</span>
						</div>
					</InputBar.Link>
					<InputBar.Link>
						<Form.Label htmlFor="inputPassword5" className="ilabel">
							Balance
						</Form.Label>
						<div className="form-group-extra reversed">
							<Form.Control readOnly disabled type="text" size="sm" />
							<span>{currencyFormat(this.state.balance_sum)}</span>
						</div>
					</InputBar.Link>
					<InputBar.Link>
						<Form.Label htmlFor="inputPassword5" className="ilabel">
							PMTS./ Adj.
						</Form.Label>
						<div className="form-group-extra reversed">
							<Form.Control readOnly disabled type="text" size="sm" />
							<span>{currencyFormat(this.state.payment_sum)}</span>
						</div>
					</InputBar.Link>
					<InputBar.Link>
						<Form.Label htmlFor="inputPassword5" className="ilabel">
							A/R
						</Form.Label>
						<div className="form-group-extra reversed">
							<Form.Control readOnly disabled type="text" size="sm" />
							<span>{currencyFormat(this.state.arbalance_sum)}</span>
						</div>
					</InputBar.Link>
				</InputBar.Links>
			</InputBar>
		);
	}

	handleSelectAllChecks = (e) =>
		this.setState({
			checks: e.target.checked ? this.state.data.map((item) => item.id) : [],
			totalChecked: e.target.checked ? this.state.data.length : 0,
		});

	handleCheck = (id) => {
		const checks = this.state.checks;
		const index = checks.findIndex((_id) => _id === id);

		index > -1 ? checks.splice(index, 1) : checks.push(id);

		this.setState({
			checks: checks,
			totalChecked: checks.length,
		});
	};

	handleToggleShowInClientPortal = async (item) => {
		try {
			const updatedValue = !item.showInClientPortal;
			item.showInClientPortal = updatedValue;

			this.setState((prevState) => ({
				data: prevState.data.map((i) => {
					if (i.id === item.id) {
						i.showInClientPortal = updatedValue;
					}
					return i;
				}),
			}));

			await this.api.postToggleInvoiceShowInClientPortal({
				invNum: item.invnum,
				showInClientPortal: updatedValue,
			});

			displayAlertSuccess(
				`Invoices ${item.invnum} : ${
					updatedValue ? 'Included on' : 'Removed from'
				} Client Portal`
			);
		} catch (error) {
			item.showInClientPortal = !item.showInClientPortal;
			this.forceUpdate?.();

			const errorMessage =
				error.response?.data?.userError ||
				`Failed to update Invoices ${item.invnum}'s Client Portal.`;
			displayAlertError(errorMessage);
		}
	};

	handleSelectToggleShowInClientPortal = async (e, action) => {
		const updatedValue = action === 'on';

		const itemsToUpdate = this.state.data.filter((item) => {
			const isInChecks = this.state.checks.includes(item.id);

			const needsUpdate =
				(updatedValue &&
					(item.showInClientPortal === false ||
						item.showInClientPortal === null)) ||
				(!updatedValue && item.showInClientPortal === true);

			return isInChecks && needsUpdate;
		});

		if (itemsToUpdate.length === 0) {
			displayAlert(
				'info',
				`No changes needed. All selected invoices are already ${
					updatedValue ? 'included' : 'removed'
				} from the Client Portal.`
			);
			return;
		}

		try {
			const promises = itemsToUpdate.map((item) =>
				this.api.postToggleInvoiceShowInClientPortal({
					invNum: item.invnum.toString(),
					showInClientPortal: updatedValue,
				})
			);

			await Promise.all(promises);

			this.setState((prevState) => ({
				data: prevState.data.map((item) => {
					if (this.state.checks.includes(item.id)) {
						item.showInClientPortal = updatedValue;
					}
					return item;
				}),
			}));

			const itemsIds = itemsToUpdate.map((item) => item.invnum).join(', ');

			displayAlertSuccess(
				`Invoice(s) ${itemsIds}: ${
					updatedValue ? 'Included on' : 'Removed from'
				} Client Portal`
			);
		} catch (error) {
			const errorMessage =
				error.response?.data?.userError ||
				`Failed to update Invoice(s)'s Client Portal.`;
			displayAlertError(errorMessage);
		}
	};

	renderContent() {
		const { checks } = this.state;

		return (
			<>
				<>
					<div className="table-gradient sticky-container">
						<Table striped responsive className={`a-table`}>
							<thead>
								<tr key="0" className="a-table-heading">
									<th align="middle" className="mw-70px">
										<div className="d-flex justify-content-center">
											<Form.Check
												label=""
												type="checkbox"
												checked={checks.length}
												className={checks.length ? 'line' : ''}
												onChange={this.handleSelectAllChecks}
											/>
										</div>
									</th>
									<th>
										<span
											className={this.sortClass('invdt')}
											data-field="invdt"
										>
											Date
										</span>
									</th>
									<th>
										<span data-field="document">Document</span>
									</th>
									<th>
										<span
											className={this.sortClass('invnum')}
											data-field="invnum"
										>
											Inv. #
										</span>
									</th>
									<th>
										<span
											className={this.sortClass('propnum')}
											data-field="propnum"
										>
											Prop. #
										</span>
									</th>
									<th>
										<span
											className={this.sortClass('gross')}
											data-field="gross"
										>
											Gross
										</span>
									</th>
									<th>
										<span
											className={this.sortClass('totaldepositapplied')}
											data-field="totaldepositapplied"
										>
											Dep./Ret.
										</span>
									</th>
									<th>
										<span
											className={this.sortClass('invoiceprice')}
											data-field="invoiceprice"
										>
											Balance
										</span>
									</th>
									<th>
										<span
											className={this.sortClass('totalpayment')}
											data-field="totalpayment"
										>
											Payment
										</span>
									</th>
									<th>
										<span
											className={this.sortClass('lastclientchecknumber')}
											data-field="lastclientchecknumber"
										>
											CK.#
										</span>
									</th>
									<th>
										<span
											className={this.sortClass('amountdue')}
											data-field="amountdue"
										>
											A/R Balance
										</span>
									</th>
									<th>
										<span
											className={this.sortClass('previnfo')}
											data-field="previnfo"
										>
											Reverse/Credit
										</span>
									</th>
									<th>
										<span
											className={this.sortClass('txdesc')}
											data-field="txdesc"
										>
											Transaction
											<br />
											Description
										</span>
									</th>
								</tr>
								<tr
									className={`a-table-search-fields ${
										this.state.showTableSearch ? '' : 'd-none'
									}`}
								>
									<th />
									<th>
										<DateRangePicker
											style={{
												minWidth: '200px',
											}}
											placement="auto"
											placeholder="Select date"
											format="MM/dd/yyyy"
											defaultValue={this.fs.getValue('invdt')}
											onChange={this.handleDateChange.bind(this, 'invdt')}
											onClean={this.handleDateChange.bind(this, 'invdt')}
											ranges={[
												{
													label: 'today',
													value: [startOfDay(new Date()), endOfDay(new Date())],
												},
												{
													label: 'yesterday',
													value: [
														startOfDay(addDays(new Date(), -1)),
														endOfDay(addDays(new Date(), -1)),
													],
												},
												{
													label: 'last7Days',
													value: [
														startOfDay(subDays(new Date(), 6)),
														endOfDay(new Date()),
													],
												},
												{
													label: 'Last 30 Days',
													value: [
														startOfDay(subDays(new Date(), 30)),
														endOfDay(new Date()),
													],
												},
												{
													label: 'This month',
													value: [
														startOfDay(
															new Date(
																getYear(new Date()),
																getMonth(new Date()),
																1
															)
														),
														endOfDay(lastDayOfMonth(new Date())),
													],
												},
												{
													label: 'Last month',
													value: [
														startOfDay(
															new Date(
																getYear(new Date()),
																getMonth(new Date()) - 1,
																1
															)
														),
														endOfDay(
															lastDayOfMonth(
																new Date(
																	getYear(new Date()),
																	getMonth(new Date()) - 1,
																	1
																)
															)
														),
													],
												},
											]}
										/>
									</th>
									<th></th>
									<th>
										<Form.Control
											type="text"
											data-field="invnum"
											data-type="number"
											onChange={this.handleSearch}
											defaultValue={this.fs.getValue('invnum')}
										/>
									</th>
									<th>
										<Form.Control
											type="text"
											data-field="propnum"
											onChange={this.handleSearch}
											defaultValue={this.fs.getValue('propnum')}
										/>
									</th>
									<th>
										<Form.Control
											type="text"
											data-field="gross"
											data-type="number"
											onChange={this.handleSearch}
											defaultValue={this.fs.getValue('gross')}
										/>
									</th>
									<th>
										<Form.Control
											type="text"
											data-field="totaldepositapplied"
											data-type="number"
											onChange={this.handleSearch}
											defaultValue={this.fs.getValue('totaldepositapplied')}
										/>
									</th>
									<th>
										<Form.Control
											type="text"
											data-field="invoiceprice"
											data-type="number"
											onChange={this.handleSearch}
											defaultValue={this.fs.getValue('invoiceprice')}
										/>
									</th>
									<th>
										<Form.Control
											type="text"
											data-field="totalpayment"
											data-type="number"
											onChange={this.handleSearch}
											defaultValue={this.fs.getValue('totalpayment')}
										/>
									</th>
									<th>
										<Form.Control
											type="text"
											data-field="lastclientchecknumber"
											onChange={this.handleSearch}
											defaultValue={this.fs.getValue('lastclientchecknumber')}
										/>
									</th>
									<th>
										<Form.Control
											type="text"
											data-field="amountdue"
											data-type="number"
											onChange={this.handleSearch}
											defaultValue={this.fs.getValue('amountdue')}
										/>
									</th>
									<th>
										<Form.Control
											type="text"
											data-field="previnfo"
											onChange={this.handleSearch}
											defaultValue={this.fs.getValue('previnfo')}
										/>
									</th>
									<th>
										<Form.Control
											type="text"
											data-field="txdesc"
											onChange={this.handleSearch}
											defaultValue={this.fs.getValue('txdesc')}
										/>
									</th>
								</tr>
							</thead>
							<tbody>
								{!this.state.dataIsLoaded
									? showLoading()
									: this.state.data.length === 0 &&
									  isFiltered(this.state.searchProperties)
									? showEmpty()
									: this.state.data
											.slice(0, this.state.pageSize)
											.map((item, i) => (
												<tr
													key={i}
													className={this.state.checks[i] ? `active` : ''}
												>
													<td>
														<div className="d-flex justify-content-center">
															<Form.Check
																label=""
																type="checkbox"
																checked={this.state.checks.includes(item.id)}
																onChange={() => {
																	this.handleCheck(item.id);
																}}
															/>
														</div>
													</td>
													<td>{formatDate(item.invdt)}</td>
													<td>
														<div className="tw-flex tw-gap-2">
															<SecureA
																href=""
																attributeNo={52}
																attributeType={
																	SECURITY_ATTRIBUTE_TYPES.DenyAccess
																}
																onClick={(e) => {
																	this.handleDocument(e, item);
																}}
															>
																PDF
															</SecureA>

															<button
																onClick={() =>
																	this.handleToggleShowInClientPortal(item)
																}
																disabled={
																	!this.state.project.clientPortalEnabled
																}
																title={
																	!this.state.project.clientPortalEnabled
																		? 'Enable Portal'
																		: item.showInClientPortal
																		? 'Remove from Portal'
																		: 'Include on Portal'
																}
																className="disabled:tw-cursor-not-allowed"
															>
																{item.showInClientPortal &&
																this.state.project.clientPortalEnabled ? (
																	<Icon path={ClientPortalIconActive} />
																) : (
																	<Icon path={ClientPortalIconInActive} />
																)}
															</button>
														</div>
													</td>
													<td>
														<Link
															to={getRouteWithParam(
																URI.accountsReceivable.clientInvoices
																	.existingInvoiceView,
																{
																	invnum: item.invnum,
																}
															)}
															className="text-charcoal hover-view-icon"
														>
															{item.invnum}
														</Link>
													</td>
													<td>
														<Link
															onClick={(e) => {
																e.preventDefault();
																const proposal = this.state.proposals.find(
																	(prop) => prop.propnum === item.propnum
																);

																if (proposal) {
																	this.handleProposalClick(proposal.id);
																}
															}}
															className="text-charcoal hover-view-icon"
														>
															{item.propnum}
														</Link>
													</td>
													<td>{currencyFormat(item.gross, '$')}</td>
													<td>
														{currencyFormat(item.totalDepositApplied, '$')}
													</td>
													<td>{currencyFormat(item.invoicePrice, '$')}</td>
													<td>{currencyFormat(item.totalPayment, '$')}</td>
													<td>{item.lastClientCheckNumber}</td>
													<td>{currencyFormat(item.amountDue, '$')}</td>
													<td>{currencyFormat(item.prevInfo, '$')}</td>
													<td>{item.txdesc}</td>
												</tr>
											))}
							</tbody>
						</Table>
					</div>

					{this.state.data.length > 0 && (
						<Pagination
							onPageSizeChanged={this.onPageSizeChanged}
							onPageChanged={this.onPageChanged}
							hasPreviousPage={this.state.page > 1}
							hasNextPage={this.state.data.length > this.state.pageSize}
							page={this.state.page}
							pageSize={this.state.pageSize}
						/>
					)}
				</>
				{!isFiltered(this.state.searchProperties) &&
					!this.state.data.length &&
					this.state.dataIsLoaded && (
						<div className="row justify-content-center text-center py-5">
							<div className="col-md-3">
								<img src={noItems} className="mw-100 mb-4" alt="" />

								<h6>View Client Invoices</h6>
								<p>
									This is where you can view the status of your project invoices
									for client billing. To create an invoice, go to{' '}
									<Link
										to={URI.accountsReceivable.clientInvoices.listNew}
										className="text-black"
									>
										Accounts Receivable - Client Invoices
									</Link>
								</p>
							</div>
						</div>
					)}
			</>
		);
	}

	closeInvoiceModal = () => {
		this.setState({ showInvoiceModal: false });
	};

	render() {
		return (
			<>
				<div>
					{/* Filter */}
					{this.renderListFilter()}

					{/* Content */}
					{this.renderContent()}
				</div>

				<div className="mt-5">{this.renderInputBar()}</div>
				{this.state.showInvoiceModal && (
					<SendInvoiceModal
						refreshData={this.fetchItems.bind(this)}
						show={this.state.showInvoiceModal}
						hideModal={this.closeInvoiceModal}
						sendInvoiceData={this.state.sendInvoiceData}
						project={this.state.project}
						selectedInvoices={this.state.checks}
						formData={this.state.data}
					/>
				)}
			</>
		);
	}
}

export default WithRouter(ProjectViewInvoice);
