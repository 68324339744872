import React from 'react';
import { WithRouter } from '../../../../helpers/Router';
import ClipMedia from '../../../components/ClipMedia';
import Header from '../../../components/Header';
import ThreadComponent from '../../ThreadComponent';
import { ApiService } from '../../../../lib/api/HttpService';
import { Col, Container, Image, Row } from 'react-bootstrap';
import CONFIG from '../../../../defaults/Config';
import ClippedItemsModal from '../../modal/ClippedItemsModal';
import DuplicateItemsModal from '../../modal/DuplicateItemModal';
import DeleteClippedItemModal from '../../modal/DeleteClippedItemModal';
import TransferClippedModal from '../../modal/TransferClippedModal';
import {
	clearDisplayAlert,
	displayAlert,
	displayAlertLoader,
	getErrorMessage,
} from '../../../../utilities/Response';
import CreateItemFromClippedItemsModal from '../../modal/CreateItemFromClippedItemsModal';
import CreateClippedItemAsComponent from '../../modal/CreateClippedItemAsComponent';
import { _delay } from '../../../../helpers/Util';
import MSG from '../../../../defaults/Message';
import { cloneDeep, delay, isEmpty, replace } from 'lodash';
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import { adjustData } from '../../../../utilities/modules/ItemComponent';
import { AlertLoader } from '../../../../app/components/bootstrap/BAlerts';
import { Pagination } from '../../../../app/components/pagination/Pagination';
import { mapRtfToDraft } from '../../../../utilities/Rtf';
import { removeHTMLTags } from '../../../../utilities/String';
import loadItemImages from '../../../../utilities/Images';
import BudgetModal from 'legacy/app/components/BudgetBanner/BudgetModal';

class Clipper extends ThreadComponent {
	componentInit() {
		this.title = 'Clipped Items';
	}

	constructor(props) {
		super(props);

		this.state = {
			checks: {},
			createItemModal: false,
			createComponentModal: false,
			editModal: false,
			duplicateModal: false,
			deleteModal: false,
			transferModal: false,
			company2: {},
			selectedItem: {},
			isImagesLoaded: false,
			pageSize: 20,
			page: 1,
			hasPreviousPage: false,
			hasNextPage: false,
			imageData: {},

			showBudgetModal: false,
			budgetModalTitle: null,
			resolveFunc: null,
		};

		this.api = new ApiService();

		// This binding is necessary to make `this` work in the callback
		this.handleChecks = this.handleChecks.bind(this);
		this.setIsChildSpinner(true);
		this.htmlToRtfConverter = new HtmlToRtfBrowser();

		this.compTypePTypeMap = [
			{
				markup: 'markupmerc',
				disc: 'usediscountmerc',
				tax: 'tm',
				desc: 'compviewm',
			},
			{
				markup: 'markupdesign',
				disc: 'usediscountdesign',
				tax: 'td',
				desc: 'compviewd',
			},
			{
				markup: 'markupfreight',
				disc: 'usediscountfreight',
				tax: 'tf',
				desc: 'compviewf',
			},
			{
				markup: 'markupinstall',
				disc: 'usediscountinstall',
				tax: 'ti',
				desc: 'compviewi',
			},
			{
				markup: 'markuplabor',
				disc: 'usediscountlabor',
				tax: 'tl',
				desc: 'compviewl',
			},
			{
				markup: 'markupother',
				disc: 'usediscountother',
				tax: 'to',
				desc: 'compviewo',
			},
		];
	}

	async componentDidMount() {
		window.isMounted = true;
		await this.fetchData();
	}

	componentWillUnmount() {
		window.isMounted = true;
	}

	async fetchData(page) {
		const company2 = await this.api.getCompany2();

		this.setState({
			company2: company2,
		});

		const items = await this.api.getClippedItems(
			`?pageNumber=${page ?? this.state.page}&pageSize=${this.state.pageSize}`
		);
		this.setState({
			hasPreviousPage: items.hasPreviousPage,
			hasNextPage: items.hasNextPage,
		});

		if (items.list) {
			this.setState({
				isImagesLoaded: false,
			});
			this.renderData(items.list);
			await this.loadImages(items.list);
		} else {
			this.renderData([]);
		}
	}

	async loadImages(inventory) {
		loadItemImages({
			data: inventory,
			property: 'primaryImageId',
			endpoint: '?maxWidth=300',
			callback: (imageData) => {
				this.setState({
					isImagesLoaded: true,
					imageData,
				});
			},
		});
	}

	renderData(data) {
		this.setIsLoaded(true);
		this.setState({
			data,
		});
	}

	handleChecks = (e) => {
		// Get the target menu.
		const indx = e.target.getAttribute('data-id');
		// Set the new state.
		this.setState((prevState) => {
			prevState.checks[indx] = e.target.checked;

			return prevState;
		});
	};

	handleSelectItem = async (item, modalKey) => {
		let itemCapture = null;
		if (modalKey === 'createComponentModal' || modalKey === 'createItemModal') {
			displayAlertLoader(MSG.loading.prepare.item);
			itemCapture = await this.api
				.getClippedItems(`/${item.itemCaptureId}`)
				.catch((err) => clearDisplayAlert());

			clearDisplayAlert();
		}

		if (
			(modalKey === 'createComponentModal' || modalKey === 'createItemModal') &&
			(!itemCapture?.project?.id ||
				!itemCapture?.salesCategory?.salesCategoryId)
		) {
			this.setState({
				selectedItem: item,
				editModal: true,
			});
			const message =
				modalKey === 'createItemModal'
					? MSG.error.ClipItemReq
					: MSG.error.ClipCompReq;
			displayAlert('danger', message);
		} else {
			this.setState({
				selectedItem: item,
				[modalKey]: true,
			});
		}
	};

	renderHeader() {
		return (
			<Header>
				<Header.Title>Clipped Items</Header.Title>
			</Header>
		);
	}

	hideEditModal = () => {
		this.setState({
			editModal: false,
			selectedItem: {},
		});
	};

	hideDuplicateModal = () => {
		this.setState({
			duplicateModal: false,
			selectedItem: {},
		});
	};

	hideDeleteModal = () => {
		this.setState({
			deleteModal: false,
			selectedItem: {},
		});
	};

	hideTransferModal = () => {
		this.setState({
			transferModal: false,
			selectedItem: {},
		});
	};

	hideCreateItemModal = () => {
		this.setState({
			createItemModal: false,
			selectedItem: {},
		});
	};

	hideCreateComponenModal = () => {
		this.setState({
			createComponentModal: false,
			selectedItem: {},
		});
	};

	handleUpdate = async () => {
		this.setIsLoaded(false);
		await this.fetchData();
	};

	handleDuplicate = async () => {
		try {
			displayAlertLoader(
				replace(
					MSG.loading.create.ClipItmDuplicate,
					'[name]',
					`<u>${this.state.selectedItem.itemTitle}</u>`
				)
			);

			this.hideDuplicateModal();

			const item = await this.api.getClippedItems(
				`/${this.state.selectedItem.itemCaptureId}`
			);

			const postItem = {
				...item,
				projectId: item.project?.id,
				projectObjectType: item.project?.objectType,
				existingLocationId: item.location?.id,
				existingSalesCategoryId: item.salesCategory?.salesCategoryId,
				vendorId: item.vendor?.id,
				vendorObjectType: item.vendor?.objectType,
			};

			const newItem = await this.api.postItemCapture(postItem);

			if (item.primaryImageId) {
				let getItemImage = await this.api.getFile(item.primaryImageId);

				const fileImage = new File([getItemImage], 'primary_image.jpeg', {
					type: getItemImage.type,
					path: 'primary_image.jpeg',
				});
				//upload file
				let formData = new FormData();
				formData.append('file', fileImage, fileImage.name);
				formData.append('isPrimary', 'true');

				await this.api.saveItemCaptureImage(newItem.id, formData);
			}

			displayAlert('success', MSG.success.create.ClipItmDuplicate);

			setTimeout(async () => {
				window.onbeforeunload = null;
				window.location.reload(false);
			}, 1500);
		} catch (error) {
			displayAlert('danger', getErrorMessage(error));
		}
	};

	handleDelete = async () => {
		try {
			displayAlertLoader(
				replace(
					MSG.loading.delete.ClipItem,
					'[name]',
					`<u>${this.state.selectedItem.itemTitle}</u>`
				)
			);

			this.hideDeleteModal();
			await this.api.deleteItemCapture(this.state.selectedItem.itemCaptureId);

			displayAlert('success', MSG.success.delete.ClipItem);
			delay(async () => {
				window.onbeforeunload = null;
				window.location.reload(false);
			}, 2000);
		} catch (error) {
			displayAlert('danger', getErrorMessage(error));
		}
	};

	handleTransfer = async (user) => {
		try {
			displayAlertLoader(
				replace(
					MSG.loading.create.ClipItmTransfer,
					'[name]',
					`<u>${user.label}</u>`
				)
			);
			this.hideTransferModal();

			await this.api.transferClippedItem({
				whiteboardItemId: this.state.selectedItem.itemCaptureId,
				receivingUserId: user.value,
			});

			displayAlert(
				'success',
				replace(
					MSG.success.create.ClipItmTransfer,
					'[name]',
					`<u>${user.label}</u>`
				)
			);
			setTimeout(async () => {
				window.onbeforeunload = null;
				await this.fetchData();
			}, 1500);
		} catch (error) {
			displayAlert('danger', getErrorMessage(error));
		}
	};

	handleCreateItem = async () => {
		try {
			displayAlertLoader(
				replace(
					MSG.loading.create.ClipItmCreate,
					'[name]',
					`<u>${this.state.selectedItem.itemTitle}</u>`
				)
			);

			this.hideCreateItemModal();

			const itemCapture = await this.api.getClippedItems(
				`/${this.state.selectedItem.itemCaptureId}`
			);

			if (
				!itemCapture.project?.id ||
				!itemCapture.salesCategory?.salesCategoryId
			) {
				this.setState({
					editModal: true,
					createItemModal: false,
				});

				displayAlert('danger', MSG.error.ClipItemReq);

				return;
			}

			const newItem = await this.api.createProjectItem({
				projectId: itemCapture.project?.id,
				isForTime: false,
			});

			let itemImage = null;

			if (itemCapture.primaryImageId) {
				const clipperImage = await this.api.getFile(itemCapture.primaryImageId);

				itemImage = await this.api.saveFile({
					file: clipperImage,
					ObjectType: 'item',
					ObjectId: newItem.id,
					FileType: 1,
					fileContext: 0,
				});
			}

			const [loc] = itemCapture.location?.id
				? await this.api.getLocations(
						'?$filter=id eq ' + itemCapture.location?.id
				  )
				: [];

			const descOnlyRTF = itemCapture.itemDescription;

			const descOnly = removeHTMLTags(await mapRtfToDraft(descOnlyRTF));

			const itemNameFormatted = this.state.company2?.boldItemName
				? '{\\b ' + itemCapture.itemTitle + ' }\\sb70'
				: itemCapture.itemTitle ?? '';

			const descRTF = descOnlyRTF.includes('{\\pard')
				? descOnlyRTF.replace(
						'{\\pard',
						`{\\pard ${itemNameFormatted.replace(' ', '\\~')}\\line`
				  )
				: `${itemNameFormatted}  ${descOnlyRTF}`;

			const desc = itemCapture.itemDescription
				? `${itemCapture.itemTitle}\r\n${descOnly}`
				: `${itemCapture.itemTitle}`;

			await this.api.editProjectItem(newItem.id, {
				itemName: itemCapture.itemTitle,
				locationId: itemCapture.location?.id,
				loc: loc?.loc,
				url: itemCapture.url,
				desc,
				descRtf: descRTF,
				descOnly,
				descOnlyRtf: descOnlyRTF,
				primaryImageId: itemImage?.id || null,
				scat: itemCapture.salesCategory.salesCategoryCode,
				quantity: itemCapture?.quantity ?? 0,
				unitmeasure: itemCapture?.unitOfMeasure ?? '',
			});

			let project = await this.api.getProjects(
				`?$filter=id eq ${itemCapture.project.id}`
			);
			project = project[0];

			const createAutoComponents =
				project.autom ||
				project.autod ||
				project.autof ||
				project.autoi ||
				project.autol ||
				project.autoo;

			let vendorDefaults;

			const newComponent = await this.api.createComponent({
				itemId: newItem.id,
				componentType: 'ctMerchandise',
				associatedComponentNumber: '',
				createAutoComponents,
				setDefaultsFromItem: true,
			});

			const supplier = itemCapture.vendor?.vendorCode;

			const projData = this.getProjectDefault(project, newComponent.comptype);

			let componentParams = {
				...projData,
				supplier,
				catalog: itemCapture.sku,
			};

			if (supplier) {
				vendorDefaults = await this.getVendorDefaults(supplier);
			}

			if (vendorDefaults) {
				componentParams.markup =
					vendorDefaults[this.compTypePTypeMap[newComponent.comptype].markup];
				componentParams.usedisc =
					vendorDefaults[this.compTypePTypeMap[newComponent.comptype].disc];
			}

			const compDescOnlyRTF =
				itemCapture.componentDescription || itemCapture.itemDescription;

			const compDescRTF = compDescOnlyRTF.includes('{\\pard')
				? compDescOnlyRTF.replace(
						'{\\pard',
						`{\\pard ${itemNameFormatted.replace(' ', '\\~')}\\line`
				  )
				: `${itemNameFormatted}  ${compDescOnlyRTF}`;

			if (itemCapture.componentDescription) {
				const desconly = removeHTMLTags(
					await mapRtfToDraft(itemCapture.componentDescription ?? '')
				);
				componentParams = {
					...componentParams,
					desconly,
					desc: `${itemCapture.itemTitle}\r\n${desconly}`,
					descRtf: compDescRTF,
					descOnlyRTF: compDescOnlyRTF,
				};
			} else {
				componentParams = {
					...componentParams,
					desc: `${itemCapture.itemTitle}`,
					descRtf: compDescRTF,
					descOnlyRTF: compDescOnlyRTF,
				};
			}

			const editedComponent = await this.api.editComponent(
				newComponent.id,
				componentParams
			);

			let calcData = await this.handleRecalcComponent(
				newComponent.id,
				itemCapture,
				'item'
			);

			if (createAutoComponents) {
				const company = await this.api.get('company');
				const acomps = await this.api.get(
					'components',
					`?$filter=projectid eq ${itemCapture.project.id} and itemid eq ${newItem.id} and assocwithcomp eq '${newComponent.comp}' and comp ne '${newComponent.comp}'`
				);

				for await (let associatedComponent of acomps) {
					const pType = this.compTypePTypeMap[associatedComponent.comptype];
					const desc = `..... ${company[pType.desc]} for component ${
						newComponent.comp
					}`;
					const compData = {
						desc,
						descrtf: this.htmlToRtfConverter.convertHtmlToRtf(desc),
					};
					if (editedComponent.supplier) {
						compData.supplier = editedComponent.supplier;
					}

					if (vendorDefaults) {
						const markup = vendorDefaults[pType.markup];
						compData.usedisc = vendorDefaults[pType.disc];
						compData.markup = markup;
					}

					await this.api
						.editComponent(associatedComponent.id, compData)
						.catch((error) => {
							displayAlert('danger', error.response.data.userError);
						});
				}
			}

			if (itemCapture.shippingCost) {
				const getshippingTypeComponents = await this.api.getComponents(
					`?$filter=comptype eq ${this.state.company2.itemCaptureShippingCompType} and proj eq '${itemCapture.project.projectCode}' and itemId eq ${newItem.id} and assocwithcomp eq '${newComponent.comp}' and comp ne '${newComponent.comp}'`
				);

				if (getshippingTypeComponents.length) {
					let shippingComp = getshippingTypeComponents[0];
					const calcData2 = await this.api.componentRecalculate(
						shippingComp.id,
						{
							whatChanged: 'cwcEstimatedCost',
							estCost: itemCapture.shippingCost || 0,
						}
					);

					await this.api.editComponent(shippingComp.id, adjustData(calcData2));
				} else {
					const newComponent2 = await this.api.createComponent({
						itemId: newItem.id,
						componentType: this.state.company2.itemCaptureShippingCompType,
						associatedComponentNumber: newComponent.comp,
						createAutoComponents: false,
						setDefaultsFromItem: false,
					});

					const desc = `..... Freight for component ${newComponent.comp}`;
					const compData = {
						desc,
						descrtf: this.htmlToRtfConverter.convertHtmlToRtf(desc),
						supplier: itemCapture.vendor?.vendorCode,
					};

					const calcData2 = await this.api.componentRecalculate(
						newComponent2.id,
						{
							whatChanged: 'cwcEstimatedCost',
							estCost: itemCapture.shippingCost || 0,
						}
					);

					await this.api.editComponent(newComponent2.id, {
						...adjustData(calcData2),
						...compData,
					});
				}
			}

			await this.api.editComponent(newComponent.id, {
				...adjustData(calcData),
				markup: componentParams?.markup,
			});
			await this.api.deleteItemCapture(itemCapture.id);
			displayAlert('success', MSG.success.create.ClipItmCreate);
			_delay(async () => {
				window.onbeforeunload = null;
				window.location.reload(false);
			}, 1500);
		} catch (error) {
			displayAlert('danger', getErrorMessage(error));
		}
	};

	async getVendorDefaults(supplier) {
		const selectedVendor = await this.api.getVendors(
			`?$filter=vendor eq '${supplier}'`
		);

		if (selectedVendor[0]?.usemarkupspecs) {
			return selectedVendor;
		}
		return null;
	}

	handleCreateAsComponent = async (item) => {
		displayAlertLoader(
			replace(
				MSG.loading.create.ClipItmComponent,
				'[name]',
				`<u>${this.state.selectedItem.itemTitle}</u>`
			)
		);
		const itemId = item.value;
		try {
			this.hideCreateComponenModal();

			const itemCapture = await this.api.getClippedItems(
				`/${this.state.selectedItem.itemCaptureId}`
			);

			if (
				!itemCapture.project?.id ||
				!itemCapture.salesCategory?.salesCategoryId
			) {
				this.hideCreateComponenModal();
				this.setState({
					editModal: true,
				});
				displayAlert('danger', MSG.error.ClipCompReq);

				return;
			}

			let project = await this.api.getProjects(
				`?$filter=id eq ${itemCapture.project.id}`
			);
			project = project[0];

			const createAutoComponents =
				project.autom ||
				project.autod ||
				project.autof ||
				project.autoi ||
				project.autol ||
				project.autoo;

			const newComponent = await this.api.createComponent({
				itemId: itemId,
				componentType: 'ctMerchandise',
				associatedComponentNumber: '',
				createAutoComponents,
				setDefaultsFromItem: false,
			});
			const vendor = itemCapture.vendor?.vendorCode;
			newComponent.supplier = vendor;

			let vendorDefaults;
			let markup;

			if (vendor) {
				vendorDefaults = await this.getVendorDefaults(newComponent.supplier);

				if (vendorDefaults) {
					markup =
						vendorDefaults[this.compTypePTypeMap[newComponent.comptype].markup];
				}
			}

			const projData = this.getProjectDefault(
				project,
				newComponent.comptype,
				markup
			);
			projData.supplier = vendor;

			if (vendorDefaults) {
				projData.usedisc =
					vendorDefaults[this.compTypePTypeMap[newComponent.comptype].disc];
			}

			await this.api.editComponent(newComponent.id, projData);

			let calcData = await this.handleRecalcComponent(
				newComponent.id,
				itemCapture,
				'component'
			);

			if (createAutoComponents) {
				const company = await this.api.get('company');
				const acomps = await this.api.get(
					'components',
					`?$filter=projectid eq ${itemCapture.project.id} and itemid eq ${itemId} and assocwithcomp eq '${newComponent.comp}' and comp ne '${newComponent.comp}'`
				);

				for await (let associatedComponent of acomps) {
					const pType = this.compTypePTypeMap[associatedComponent.comptype];
					const desc = `..... ${company[pType.desc]} for component ${
						newComponent.comp
					}`;
					const compData = {
						desc,
						supplier: newComponent.supplier ?? null,
						descrtf: this.htmlToRtfConverter.convertHtmlToRtf(desc),
					};

					if (vendorDefaults) {
						const markup = vendorDefaults[pType.markup];
						compData.usedisc = vendorDefaults[pType.disc];
						compData.markup = markup;
					}

					await this.api
						.editComponent(associatedComponent.id, compData)
						.catch((error) => {
							displayAlert('danger', error.response.data.userError);
						});
				}
			}

			if (itemCapture.shippingCost) {
				const getshippingTypeComponents = await this.api.getComponents(
					`?$filter=comptype eq ${this.state.company2.itemCaptureShippingCompType} and proj eq '${itemCapture.project.projectCode}' and itemId eq ${itemId} and assocwithcomp eq '${newComponent.comp}' and comp ne '${newComponent.comp}'`
				);

				if (getshippingTypeComponents.length) {
					let shippingComp = getshippingTypeComponents[0];
					const calcData2 = await this.api.componentRecalculate(
						shippingComp.id,
						{
							whatChanged: 'cwcEstimatedCost',
							estCost: itemCapture.shippingCost || 0,
						}
					);

					await this.api.editComponent(shippingComp.id, adjustData(calcData2));
				} else {
					const newComponent2 = await this.api.createComponent({
						itemId: itemId,
						componentType: this.state.company2.itemCaptureShippingCompType,
						associatedComponentNumber: newComponent.comp,
						createAutoComponents: false,
						setDefaultsFromItem: false,
					});

					const calcData2 = await this.api.componentRecalculate(
						newComponent2.id,
						{
							whatChanged: 'cwcEstimatedCost',
							estCost: itemCapture.shippingCost || 0,
						}
					);

					await this.api.editComponent(newComponent2.id, adjustData(calcData2));
				}
			}

			let compData = {
				itemName: itemCapture.itemTitle,
				supplier: itemCapture.vendor?.vendorCode ?? null,
				primaryImageId: itemCapture.primaryImageId,
				...adjustData(calcData),
				unitmeasure: itemCapture?.unitOfMeasure,
				markup: projData?.markup,
			};

			if (itemCapture.componentDescription || itemCapture.itemDescription) {
				const compDescOnlyRTF =
					itemCapture.componentDescription || itemCapture.itemDescription;

				const itemNameFormatted = this.state.company2?.boldItemName
					? '{\\b ' + itemCapture.itemTitle + ' }\\sb70'
					: itemCapture.itemTitle ?? '';

				const compDescRTF = compDescOnlyRTF.includes('{\\pard')
					? compDescOnlyRTF.replace(
							'{\\pard',
							`{\\pard ${itemNameFormatted.replace(' ', '\\~')}\\line`
					  )
					: `${itemNameFormatted}  ${compDescOnlyRTF}`;
				const descOnly = removeHTMLTags(
					await mapRtfToDraft(itemCapture.componentDescription ?? '')
				);
				compData = {
					...compData,
					descOnly,
					desc: `${itemCapture.itemTitle}\r\n${descOnly}`,
					descRtf: compDescRTF,
					descOnlyRTF: compDescOnlyRTF,
				};
			}

			await this.api.editComponent(newComponent.id, compData);
			await this.api.deleteItemCapture(itemCapture.id);
			displayAlert('success', MSG.success.create.ClipItmComponent);
			_delay(() => {
				window.onbeforeunload = null;
				window.location.reload(false);
			}, 1500);
		} catch (error) {
			displayAlert('danger', getErrorMessage(error));
		}
	};

	getProjectDefault(project, value, markup) {
		const pType = this.compTypePTypeMap[value];
		if (project[pType.disc] === 1) {
			return {
				estcost: this.updatedData?.estprice ?? this.state.data.estprice,
				markup: markup || project[pType.markup],
				estprice: 0,
				taxable: project[pType.tax],
			};
		} else {
			return {
				estcost: 0,
				markup: markup || project[pType.markup],
				estprice: 0,
				taxable: project[pType.tax],
			};
		}
	}

	async handleRecalcComponent(id, itemCapture, createAsType) {
		let calcData = {};
		calcData = await this.api.componentRecalculate(id, {
			componentId: id,
			whatChanged: 'cwcQuantity',
			quantity: itemCapture.quantity || 0,
		});

		calcData = {
			...calcData,
			whatChanged: 'cwcEstimatedUnitCost',
			estUnitCost: itemCapture.unitCost || 0,
			quantity: itemCapture.quantity || 0,
		};

		calcData = await this.api.componentRecalculate(id, calcData);

		await this.checkBudget(calcData.budgetCheck, createAsType);

		if (calcData.useDisc === 'dmDiscount') {
			calcData = {
				...calcData,
				whatChanged: 'cwcUnitListPrice',
				unitList: itemCapture.unitPrice || 0,
				estUnitCost: itemCapture.unitCost || 0,
				quantity: itemCapture.quantity || 0,
			};
		} else {
			calcData = {
				...calcData,
				whatChanged: 'cwcEstimatedUnitPrice',
				estUnitPrice: itemCapture.unitPrice || 0,
				estUnitCost: itemCapture.unitCost || 0,
				quantity: itemCapture.quantity || 0,
			};
		}

		calcData = await this.api.componentRecalculate(id, calcData);

		await this.checkBudget(calcData.budgetCheck, createAsType);

		return calcData;
	}

	onPageSizeChanged = (size) => {
		this.setState(
			{
				pageSize: size,
				page: 1,
			},
			() => {
				this.handleChangePage(1);
			}
		);
	};

	onPageChanged = (page) => {
		this.handleChangePage(page);
	};

	handleChangePage = async (page) => {
		this.fetchData(page);

		this.setState({
			page: page,
		});
	};

	async checkBudget(budgetCheck, createAsType) {
		if (budgetCheck.isWarning && budgetCheck.reasonMessage) {
			await this.openBudgetWarningModal(createAsType);
		}
	}

	openBudgetWarningModal = (createAsType) => {
		this.setState({
			showBudgetModal: true,
			budgetModalTitle: `Create ${createAsType}`,
		});
		return new Promise((resolve) => {
			this.setState({ resolveFunc: resolve });
		});
	};

	closeBudgetWarningModal = () => {
		this.setState({ showBudgetModal: false, budgetModalTitle: null });
		this.state.resolveFunc();
	};

	renderSteps() {
		return (
			<>
				{this.renderHeader()}

				{this.renderView(
					<div className="content-padding min-height">
						<Container fluid>
							<Container
								fluid
								className="a-clipper-instructions border py-5 roundedx-4 mx-auto"
							>
								<Row
									className="justify-content-center py-5 mx-auto"
									style={{ maxWidth: '1600px' }}
								>
									<Col sm="12" lg="3">
										<Image
											src={CONFIG.clipper1}
											alt="image"
											className="mx-auto d-block mb-4 rounded-circle"
										></Image>
										<div className="text-center">
											<h6 className="fsx-20">1. Install browser extension</h6>
											<p className="mb-0">
												<a href="/">Click here</a> to download the clipper
												extension and install it.
											</p>
										</div>
									</Col>
									<Col
										sm="12"
										lg="1"
										className="a-clipper-arrow text-center fsx-24 text-secondary-ash"
									>
										<div className="d-none d-lg-block">
											<i className="ri-arrow-right-line ri-lg"></i>
										</div>
										<div className="d-block d-lg-none">
											<i className="ri-arrow-down-line ri-lg"></i>
										</div>
									</Col>
									<Col sm="12" lg="3">
										<Image
											src={CONFIG.clipper2}
											alt="image"
											className="mx-auto d-block mb-4 rounded-circle"
										></Image>
										<div className="text-center">
											<h6 className="fsx-20">2. Open any website</h6>
											<p className="mb-0">
												Go to a place where you want to clip items for your
												project.
											</p>
										</div>
									</Col>
									<Col
										sm="12"
										lg="1"
										className="a-clipper-arrow text-center fsx-24 text-secondary-ash"
									>
										<div className="d-none d-lg-block">
											<i className="ri-arrow-right-line ri-lg"></i>
										</div>
										<div className="d-block d-lg-none">
											<i className="ri-arrow-down-line ri-lg"></i>
										</div>
									</Col>
									<Col sm="12" lg="3">
										<Image
											src={CONFIG.clipper3}
											alt="image"
											className="mx-auto d-block mb-4 rounded-circle"
										></Image>
										<div className="text-center">
											<h6 className="fsx-20">3. Source products</h6>
											<p className="mb-0">
												Fill in the information you need and save items for your
												project.
											</p>
										</div>
									</Col>
								</Row>
							</Container>
						</Container>
					</div>
				)}
			</>
		);
	}

	renderGrid() {
		const { isImagesLoaded } = this.state;
		return (
			<>
				{this.renderHeader()}
				{this.renderView(
					<div className="content-padding min-height pt-5">
						{this.state.data?.length ? (
							<>
								<ClipMedia
									noCheck={this.props.noCheck}
									data={this.state.data}
									IsActiveCompareKey="itemCaptureId"
									IsActiveCompareValue={this.state.selectedItem?.itemCaptureId}
									size={'lg'}
									showModal={() =>
										this.setState((prev) => (prev.showModal = true))
									}
									handleSelectItem={this.handleSelectItem}
									source="clipper"
									imageData={this.state.imageData}
									isImagesLoaded={this.state.isImagesLoaded}
								/>
								{this.state.data.length > 0 && (
									<Pagination
										onPageSizeChanged={this.onPageSizeChanged}
										onPageChanged={this.onPageChanged}
										hasPreviousPage={this.state.hasPreviousPage}
										hasNextPage={this.state.hasNextPage}
										page={this.state.page}
										pageSize={this.state.pageSize}
									/>
								)}
							</>
						) : (
							<Container
								fluid
								className="a-clipper-instructions py-5 roundedx-4 mx-auto"
							>
								<Row
									className="justify-content-center py-5 mx-auto"
									style={{ maxWidth: '1600px' }}
								>
									<Col sm="12" lg="3">
										<Image
											src={CONFIG.clipper1}
											alt="image"
											className="mx-auto d-block mb-4 rounded-circle"
										></Image>
										<div className="text-center">
											<h6 className="fsx-20">1. Install browser extension</h6>
											<p className="mb-0">
												<a href="https://chrome.google.com/webstore/detail/design-manager-product-cl/kloodacdcdmefdelbanijdonpljlbjnp">
													Click here
												</a>{' '}
												to download the clipper extension and install it.
											</p>
										</div>
									</Col>
									<Col
										sm="12"
										lg="1"
										className="a-clipper-arrow text-center fsx-24 text-secondary-ash"
									>
										<div className="d-none d-lg-block">
											<i className="ri-arrow-right-line ri-lg"></i>
										</div>
										<div className="d-block d-lg-none">
											<i className="ri-arrow-down-line ri-lg"></i>
										</div>
									</Col>
									<Col sm="12" lg="3">
										<Image
											src={CONFIG.clipper2}
											alt="image"
											className="mx-auto d-block mb-4 rounded-circle"
										></Image>
										<div className="text-center">
											<h6 className="fsx-20">2. Open any website</h6>
											<p className="mb-0">
												Go to a place where you want to clip items for your
												project.
											</p>
										</div>
									</Col>
									<Col
										sm="12"
										lg="1"
										className="a-clipper-arrow text-center fsx-24 text-secondary-ash"
									>
										<div className="d-none d-lg-block">
											<i className="ri-arrow-right-line ri-lg"></i>
										</div>
										<div className="d-block d-lg-none">
											<i className="ri-arrow-down-line ri-lg"></i>
										</div>
									</Col>
									<Col sm="12" lg="3">
										<Image
											src={CONFIG.clipper3}
											alt="image"
											className="mx-auto d-block mb-4 rounded-circle"
										></Image>
										<div className="text-center">
											<h6 className="fsx-20">3. Source products</h6>
											<p className="mb-0">
												Fill in the information you need and save items for your
												project.
											</p>
										</div>
									</Col>
								</Row>
							</Container>
						)}
					</div>
				)}

				<ClippedItemsModal
					show={this.state.editModal}
					hideModal={this.hideEditModal}
					data={this.state.selectedItem}
					handleUpdate={this.handleUpdate}
				/>

				<DuplicateItemsModal
					show={this.state.duplicateModal}
					hideModal={this.hideDuplicateModal}
					handleDuplicate={this.handleDuplicate}
					data={this.state.selectedItem}
				/>

				<DeleteClippedItemModal
					show={this.state.deleteModal}
					hideModal={this.hideDeleteModal}
					handleDelete={this.handleDelete}
					data={this.state.selectedItem}
				/>

				<TransferClippedModal
					show={this.state.transferModal}
					hideModal={this.hideTransferModal}
					handleTransfer={this.handleTransfer}
					data={this.state.selectedItem}
				/>

				<CreateItemFromClippedItemsModal
					show={this.state.createItemModal}
					hideModal={this.hideCreateItemModal}
					handleCreateItem={this.handleCreateItem}
					data={this.state.selectedItem}
				/>

				<CreateClippedItemAsComponent
					show={this.state.createComponentModal}
					hideModal={this.hideCreateComponenModal}
					selectedItem={this.state.selectedItem}
					handleCreateComponent={this.handleCreateAsComponent}
				/>

				{this.state.showBudgetModal && (
					<BudgetModal
						title={this.state.budgetModalTitle}
						onClose={() => this.closeBudgetWarningModal(true)}
					/>
				)}
			</>
		);
	}

	render() {
		return this.state.data ? this.renderGrid() : this.renderSteps();
	}
}

export default WithRouter(Clipper);
