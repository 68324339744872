import React from 'react';
import { generatePath, Link, NavLink } from 'react-router-dom';
import {
	Breadcrumb,
	Button,
	Container,
	Form,
	Row,
	Col,
	Modal,
} from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import { HeaderLight } from '../../../components/Header';
import URI from '../../../../defaults/RoutesDefault';
import { WithRouter } from '../../../../helpers/Router';
import {
	FooterFormAction,
	FormInputAdOns,
	InlineFormGroupField,
} from '../../../components/Form';

import Select from 'react-select';
import DatePicker from 'react-datepicker';
import InputBar from '../../../components/InputBar';
import { ActionBarVertical } from '../../../components/ActionBar';
import WysiwygEditor from '../../../components/WysiwygEditor';
import {
	ApiService,
	POSTING_PROCESS_TYPE,
} from '../../../../lib/api/HttpService';
import { getCookie } from '../../../../utilities/Auth';
import {
	displayAlert,
	displayAlertError,
	displayAlertLoader,
	displayAlertSuccess,
	getErrorMessage,
	hideAlertLoader,
} from '../../../../utilities/Response';
import MSG from '../../../../defaults/Message';
import { navigateOnSuccess } from '../../../../utilities/Router';
import { delay } from 'lodash';
import AsyncVendorsDropdown from '../../../../app/components/dropdowns/AsyncVendorsDropdown';
import AsyncPurchaseOrderDropdown from '../../../../app/components/dropdowns/AsyncPurchaseOrdersDropdown';
import { mapRtfToDraft } from '../../../../utilities/Rtf';
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import { BasicModal } from '../../../../app/components/modal/BasicModal';
import { dateToPayload } from '../../../../helpers/Date';
import SecureDatePicker from '../../../../app/components/security/SecureDatePicker';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';

class VendorDepositAdd extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			showWireModal: false,
			transferType: 'check',
			vendors: [],
			accounts: [],
			checkingAccounts: [],
			updatedData: {
				usercode: getCookie('dmUsercode'),
				type: 2,
				sinvdt: new Date().toISOString(),
				paydt: new Date().toISOString(),
			},
			defaults: {
				modal: {},
				depositDefaults: {},
			},
			modalData: {
				handckdt: new Date().toISOString(),
			},
			showingCheckInfo: false,
			hasExpiredInsurance: false,
			showInsuranceModal: false,
			isSaveNew: false,
		};

		this.api = new ApiService();
		this.id = this.props.params.id;
		this.isEditing = this.id != null;
		this.hasCreatedDeposit = false;
		this.isNew =
			this.props.paths.pathname ===
				generatePath(URI.accountsPayable.vendorInvoice.editNewDeposit, {
					id: this.props.params.id ?? '',
				}) ||
			this.props.paths.pathname ===
				URI.accountsPayable.vendorInvoice.addDeposit;

		this.htmlToRtfConverter = new HtmlToRtfBrowser();
		this.setComponentProp();
	}

	setComponentProp() {
		this.saveSuccessMessage = this.isEditing
			? MSG.success.msg
			: MSG.success.create.APVendorInvDep;
		this.saveLoadingMessage = this.isEditing
			? MSG.loading.update.msg
			: MSG.loading.create.APVendorInvDep;
	}

	componentInit() {
		this.title = `${
			this.isEditing ? 'Edit' : 'Add'
		} Deposit | Accounts Payable`;
		this.setFormAction(true);
	}

	async componentDidMount() {
		this.dMloader(true);
		await this.fetchDataIfEditing();
		await this.generateTxNumIfNeeded();

		const generalLedgerAccounts = await this.api.getGLAccounts(
			'?$filter=specacct eq 2'
		);

		const paytypes = await this.api.getPayTypes();

		const generalLedgerAccountsSelect = generalLedgerAccounts.map((account) => {
			return {
				label: `${account.accountn} [${account.account}]`,
				value: account.account,
				isCC: true,
			};
		});

		const payTypeSelect = paytypes.map((paytype) => {
			return {
				label: `${paytype.payType} [${paytype.defAcct}]`,
				value: paytype.defAcct,
				id: paytype.id,
				isCheckWire: paytype.specAcct === 1,
			};
		});

		const checkingAccounts = await this.api.getGLAccounts(
			'?$filter=fixedtype eq 101'
		);

		const accounts = [...generalLedgerAccountsSelect, ...payTypeSelect];

		const glAccounts = await this.api.getSummaryGLAccounts(
			'?$filter=specacct eq 1'
		);

		const mappedGlAccounts = glAccounts.map((i) => {
			return { label: `${i.value} (${i.key})`, value: i.key };
		});

		this.setState(
			{
				accounts,
				checkingAccounts: checkingAccounts,
			},
			() => {
				if (!this.state.updatedData.paywith && !transactionId) {
					this.setState({
						updatedData: {
							...this.state.updatedData,
							paywith: accounts[0]?.value,
						},
					});
				}
			}
		);

		const params = new URLSearchParams(window.location.search);
		const transactionId = params.get('transactionId');
		if (transactionId) {
			let selectedVendor = null;
			let selectedPO = null;
			let defaultPayWith = null;
			let defaultPaydt = null;
			let defaultSinvdt = null;
			let defaultAccount = null;
			let wireTransferNumber = null;

			const defaults = await this.api.get(
				`Transactions/${transactionId}/invoice/defaults`
			);
			if (defaults.matchedPoId) {
				const POs = await this.api.getProjectPurchaseOrdersSummaries();
				const doesDefaultPOExist = POs.find(
					(p) => p.id === defaults.matchedPoId
				);
				if (doesDefaultPOExist) {
					selectedPO = {
						...doesDefaultPOExist,
						id: doesDefaultPOExist.id,
						label: `${
							doesDefaultPOExist.value ? `${doesDefaultPOExist.value} ` : ''
						}[${doesDefaultPOExist.key}]`,
						name: `${
							doesDefaultPOExist.value ? `${doesDefaultPOExist.value} ` : ''
						}[${doesDefaultPOExist.key}]`,
						value: doesDefaultPOExist.id,
					};
				}
			}

			if (defaults.payWith || defaults.payTypeAccountId) {
				defaultPayWith = accounts.find((p) =>
					!defaults.payTypeAccountId
						? p.value === defaults.payWith
						: p.id === defaults.payTypeAccountId
				);
			}

			defaultAccount = mappedGlAccounts.find(
				(acc) => acc.value === defaultPayWith?.value
			)?.value;

			if (defaults.vendorId) {
				const vendor = await this.api.getAllVendorsSummaries(
					`?$filter=id eq ${defaults.vendorId}`
				);

				if (vendor?.length) {
					selectedVendor = {
						label: `${vendor[0].value ? `${vendor[0].value} ` : ''}[${
							vendor[0].key
						}]`,
						value: vendor[0].key,
					};
				}
			}
			if (defaults.transactionDate) {
				defaultPaydt = new Date(defaults.transactionDate);
				defaultSinvdt = new Date(defaults.transactionDate);
			}
			if (defaultAccount && !defaults.checkNumber) {
				const number = await this.api.getWireTransferNumberForAccountNumber(
					defaultAccount
				);
				wireTransferNumber = number.wireTransferNumber;
			}

			const transactionName = defaults.transactionName
				? defaults.transactionName.substring(0, 30)
				: '';

			const modalData = {
				...this.state.modalData,
				handcknum: defaults.checkNumber || wireTransferNumber,
				handcktxdesc: transactionName,
				handckdt: new Date(defaults.transactionDate).toISOString(),
				handckcashacct: defaultAccount,
			};

			let updatedData = {
				...this.state.updatedData,
				amount: defaults.amount,
				txdesc: transactionName,
				paywith: defaultPayWith?.value || accounts[0]?.value,
				paydt: defaultPaydt || this.state.updatedData.paydt,
				sinvdt: defaultSinvdt || this.state.updatedData.sinvdt,
				supplier: selectedVendor?.value || null,
			};

			updatedData = {
				...updatedData,
				...modalData,
			};

			this.setState(
				{
					selectedVendor,
					transferType:
						defaults.checkNumber === null && defaultAccount ? 'wire' : 'check',
					modalData: {
						...this.state.modalData,
						...modalData,
					},
					defaults: {
						depositDefaults: {
							...defaults,
							sinvdt: defaultSinvdt,
							paydt: defaultPaydt,
						},
						modal: {
							...modalData,
							isWireTransfer:
								defaults.checkNumber === null && defaultAccount !== null
									? true
									: false,
						},
					},
					updatedData: {
						...this.state.updatedData,
						...updatedData,
					},
				},
				async () => {
					if (selectedPO) {
						await this.handlePurchaseOrderChange(selectedPO);
					}
				}
			);
		}

		this.dMloader(false);
	}

	async generateTxNumIfNeeded() {
		if (this.isEditing) {
			return;
		}

		try {
			const txnum = await this.api.getTransactionCounterNext('13');
			this.setState({
				updatedData: {
					...this.state.updatedData,
					txnum: Number(txnum.newTransactionNumber),
				},
			});
		} catch {
			// Do nothing for now
		}
	}

	async fetchDataIfEditing() {
		if (!this.isEditing) {
			return;
		}

		let invoice,
			remarksHTML = '';
		const filter = `?$filter=txnum eq ${this.id}`;
		if (this.isNew) {
			const invoices = await this.api.getTemporaryVendorInvoices(filter);
			invoice = invoices[0];
		} else {
			const invoices = await this.api.getVendorInvoices(filter);
			invoice = {
				...invoices[0],
				netAmount: invoices[0]?.amount,
			};
		}

		if (!invoice) {
			return;
		}

		if (invoice?.notesrtf) {
			remarksHTML = await mapRtfToDraft(invoice?.notesrtf);
		}

		const selectedVendor = await this.api.getVendorByCode(
			invoice.supplier,
			true
		);

		const selectedPO = await this.api.getProjectPurchaseOrderByProjAndPO({
			project: invoice.proj,
			ponum: invoice.ponum,
			isOption: true,
		});

		if (invoice.checkInfo?.length) {
			this.setState({
				showingCheckInfo: true,
			});
		}

		this.setState({
			remarksHTML,
			selectedPO,
			selectedVendor,
			transferType: invoice.handcknum?.toLowerCase().startsWith('wt')
				? 'wire'
				: 'check',
			updatedData: {
				...this.state.updatedData,
				txnum: invoice.txnum,
				sinvdt: invoice.sinvdt,
				paydt: invoice.paydt,
				amount: invoice.netAmount,
				proj: invoice.proj,
				ponum: invoice.ponum,
				supplier: invoice.supplier,
				txdesc: invoice.txdesc,
				paywith: invoice.paywith,
				handcknum: invoice.handcknum,
				handcktxdesc: invoice.handcktxdesc,
				handckdt: invoice.handckdt,
				handckcashacct: invoice.handckcashacct,
				fiscalmonth: invoice.fiscalmonth,
				type: invoice.type,
				notes: invoice.notes,
				notesRtf: invoice.notesrtf,
			},
			modalData: {
				...this.state.modalData,
				handcknum: invoice.handcknum,
				handcktxdesc: invoice.handcktxdesc,
				handckdt: invoice.handckdt ?? new Date().toISOString(),
				handckcashacct: invoice.handckcashacct,
			},
			checkInfo: invoice.checkInfo,
		});
	}

	handleTransferChange = (e) => {
		this.setState({ transferType: e.target.value });
	};

	getCheckingAccounts() {
		return this.state.checkingAccounts.map((item) => {
			return {
				value: item.account,
				label: `${item.account} - ${item.accountn}`,
			};
		});
	}

	getSelectedCheckingAccount() {
		return (
			this.state.checkingAccounts
				.filter((item) => {
					return (
						item.account ===
						(this.state.modalData.handckcashacct ??
							this.state.updatedData.handckcashacct)
					);
				})
				.map((item) => {
					return {
						value: item.account,
						label: `${item.account} - ${item.accountn}`,
					};
				})[0] ?? null
		);
	}

	formatDate(timestamp) {
		const date = new Date(timestamp);
		let month = date.getMonth() + 1;
		month = month < 10 ? `0${month}` : month;

		let day = date.getDate();
		day = day < 10 ? `0${day}` : day;

		return `${day}/${month}/${date.getFullYear()}`;
	}

	handleDateChange = (date, name) => {
		this.setState({
			updatedData: {
				...this.state.updatedData,
				[name]: date,
			},
		});
	};

	handleModalDateChange = (date, name) => {
		this.setState({
			modalData: {
				...this.state.modalData,
				[name]: date,
			},
		});
	};

	handlePurchaseOrderChange = async (e) => {
		displayAlertLoader(MSG.loading.info);
		const [purchaseOrder] = await this.api.getProjectPurchaseOrders(
			`?$filter=id eq ${e?.value}`
		);
		let selectedVendor = this.state.selectedVendor;
		let supplier = this.state.updatedData?.supplier;
		const storedDefaults = this.state.defaults.depositDefaults;

		try {
			if (!this.state.updatedData.txnum || !purchaseOrder) {
				return;
			}

			if (purchaseOrder?.vendorName && purchaseOrder?.supplier) {
				selectedVendor = {
					label: `${purchaseOrder?.vendorName} [${purchaseOrder?.supplier}]`,
					value: purchaseOrder?.supplier,
				};
				supplier = purchaseOrder?.supplier;
			}

			const hasExpiredInsurance = await this.hasVendorExpiredInsurance(
				purchaseOrder?.supplier
			);

			this.setState({
				selectedVendor,
				selectedPO: e,
				updatedData: {
					...this.state.updatedData,
					proj: purchaseOrder.proj,
					ponum: purchaseOrder.ponum,
					supplier,
				},
				hasExpiredInsurance,
			});

			const response = await this.api.createTemporaryVendorInvoiceGrid({
				txnum: this.state.updatedData.txnum,
				projectCode: purchaseOrder.proj,
				poNumber: purchaseOrder.ponum,
				type: 2,
			});

			if (response.vendorCode) {
				this.setState({
					selectedVendor: {
						value: response.vendorCode,
						label: `${purchaseOrder?.vendorName} [${response.vendorCode}]`,
					},
					updatedData: {
						...this.state.updatedData,
						supplier: response.vendorCode,
					},
				});
			}

			const isValidDate = (d) => '0001-01-01T00:00:00' !== d && '' !== d;

			const getValidDate = (field) =>
				isValidDate(response.orderDate)
					? response.orderDate
					: this.state.updatedData?.[field];

			this.setState({
				updatedData: {
					...this.state.updatedData,
					amount:
						storedDefaults?.amount !== null &&
						storedDefaults?.amount !== undefined
							? storedDefaults?.amount
							: response.amountDue,
					sinvdt: storedDefaults?.sinvdt
						? storedDefaults.sinvdt
						: getValidDate('sinvdt'),
					paydt: storedDefaults?.paydt
						? storedDefaults.paydt
						: getValidDate('paydt'),
				},
			});
			hideAlertLoader();
		} catch (error) {
			displayAlertError(getErrorMessage(error));
		}
	};

	handleChange = async (e, meta = {}) => {
		const params = new URLSearchParams(window.location.search);
		const transactionId = params.get('transactionId');

		let key, value;
		if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.id;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}

		if (key === 'supplier') {
			const hasExpiredInsurance = await this.hasVendorExpiredInsurance(
				e?.value
			);
			this.setState({
				selectedVendor: e,
				hasExpiredInsurance,
			});
		}

		this.setState(
			transactionId && meta?.name === 'paywith'
				? {
						transferType: this.state.defaults.modal.isWireTransfer,
						updatedData: {
							...this.state.updatedData,
							...this.state.defaults.modal,
							[key]: value,
						},
				  }
				: {
						updatedData: {
							...this.state.updatedData,
							[key]: value,
						},
				  }
		);
	};

	handleModalChange = (e, meta = {}) => {
		let key, value;
		if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.id;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}

		this.setState({
			modalData: {
				...this.state.modalData,
				[key]: value,
			},
		});
	};

	handleNotesChange = (e) =>
		this.setState({
			updatedData: {
				...this.state.updatedData,
				notes: e.target.value,
				notesRtf: this.htmlToRtfConverter.convertHtmlToRtf(e.target.html),
			},
		});

	validate() {
		if (this.isCheckWire()) {
			const requiredFields = [
				{ id: 'handckdt', label: 'Check Date' },
				{ id: 'handckcashacct', label: 'Checking Account' },
			];
			const isWireTransfer = this.state.transferType === 'wire';

			if (!isWireTransfer) {
				requiredFields.push({ id: 'handcknum', label: 'Check Number' });
			}

			const missingFields = requiredFields
				.filter((field) => !this.state.modalData[field.id])
				.map((field) => field.label);

			if (missingFields.length > 0) {
				displayAlert(
					'danger',
					`Missing required fields: ${missingFields.join(', ')}`
				);
			} else {
				return true;
			}

			return false;
		}

		return true;
	}

	confirmWireChanges = async () => {
		let showWireModal = false;
		if (!this.validate()) {
			showWireModal = true;

			return;
		}

		const modalData = this.state.modalData;
		const isWireTransfer = this.state.transferType === 'wire';
		if (isWireTransfer) {
			const response = await this.api.getWireTransferNumberForAccountNumber(
				modalData.handckcashacct
			);
			modalData.handcknum = response.wireTransferNumber;
		}

		this.setState({
			updatedData: {
				...this.state.updatedData,
				...modalData,
			},
			showWireModal: showWireModal,
		});
	};

	cancelWireChanges = () => {
		const params = new URLSearchParams(window.location.search);
		const transactionId = params.get('transactionId');
		const modalData = this.isEditing
			? {
					handcknum: this.state.updatedData.handcknum,
					handcktxdesc: this.state.updatedData.handcktxdesc,
					handckdt: this.state.updatedData.handckdt,
					handckcashacct: this.state.updatedData.handckcashacct,
			  }
			: transactionId
			? { ...this.state.modalData }
			: {
					handckdt: new Date().toISOString(),
			  };

		this.setState({
			transferType: this.state.updatedData.handcknum
				?.toLowerCase()
				.startsWith('wt')
				? 'wire'
				: 'check',
			modalData: modalData,
			showWireModal: false,
		});
	};

	handleSave = async (isNew) => {
		const params = new URLSearchParams(window.location.search);
		const transactionId = params.get('transactionId');
		const isCheckWire = this.isCheckWire();
		try {
			const isWireTransfer = this.state.transferType === 'wire';
			const params = {
				...this.state.updatedData,
				transactionId: transactionId ? Number(transactionId) : null,
			};
			const listUrl = this.isNew
				? URI.accountsPayable.vendorInvoice.listNew
				: URI.accountsPayable.vendorInvoice.listExisting;

			displayAlertLoader(this.saveLoadingMessage);
			params.isWireTransfer = isWireTransfer;
			params.sinvnum = `Deposit on PO# ${this.state.updatedData.proj}-${this.state.updatedData.ponum}`;
			params.fiscalmonth = dateToPayload(
				this.state.updatedData.fiscalmonth ?? new Date()
			);
			params.sinvdt = dateToPayload(this.state.updatedData.sinvdt);
			params.paydt = dateToPayload(this.state.updatedData.paydt);
			params.handckdt = dateToPayload(this.state.updatedData.handckdt);

			const isCC =
				this.state.accounts.find(
					(account) => account.value === this.state.updatedData.paywith
				)?.isCC ?? false;

			params.ccacct = isCC ? this.state.updatedData.paywith : null;

			if (!isCheckWire) {
				delete params.handcknum;
				delete params.handcktxdesc;
				delete params.handckdt;
				delete params.handckcashacct;
				params.isWireTransfer = false;
			}

			if (isCheckWire) {
				delete params.ccacct;
			}

			if (this.isEditing) {
				if (this.isNew) {
					await this.api.updateTemporaryVendorInvoice(params);
				} else {
					params.revisestxnum = this.id;
					params.txnum = Number(this.id);
					await this.api.createTemporaryVendorInvoiceDeposit(params);
					await this.api.createTemporaryVendorInvoice(params);
					await this.api.postVendorInvoiceFiscalMonth({
						processType: POSTING_PROCESS_TYPE.REVISE_TRANSACTION,
						txNum: this.id,
						fiscalmonth: this.state.updatedData.fiscalmonth,
					});
				}
			} else {
				if (!this.hasCreatedDeposit) {
					const txnum =
						!this.state.updatedData.txnum ??
						(await this.api.getTransactionCounterNext('13'));

					await this.api.createTemporaryVendorInvoiceDeposit({
						txnum: this.state.updatedData.txnum || txnum,
						usercode: this.state.updatedData.usercode,
						amount: this.state.updatedData.amount,
					});
				}

				this.hasCreatedDeposit = true;

				await this.api.createTemporaryVendorInvoice(params);
			}

			displayAlertSuccess(this.saveSuccessMessage);

			const _delay = isNew ? 1200 : 700;
			delay(() => navigateOnSuccess(this, isNew, false, listUrl), _delay);
		} catch (error) {
			displayAlertError(getErrorMessage(error));
		}
	};

	isCheckWire() {
		return (
			this.state.accounts.find(
				(account) => account.value === this.state.updatedData.paywith
			)?.isCheckWire === true
		);
	}

	renderHeaderTitle() {
		return `${this.isEditing ? 'Edit' : 'Add'} Deposit`;
	}

	async hasVendorExpiredInsurance(vendor) {
		const insurances = await this.api.getInsurancesByCode(vendor);
		const hasExpiredInsurance = insurances?.some((i) => {
			const hasExpired = i.expirationdt
				? new Date(i.expirationdt) < new Date()
				: false;

			return i.active && hasExpired;
		});

		return hasExpiredInsurance;
	}

	showConfirmationModal(isNew) {
		this.setState({
			showInsuranceModal: true,
			isSaveNew: isNew,
		});
	}

	handleCloseModal() {
		this.setState({
			showInsuranceModal: false,
		});
	}

	handleConfirmSave() {
		this.handleSave(this.state.isSaveNew);
		this.handleCloseModal();
	}

	componentWillUnmount() {
		this.dMloader(false);
	}

	renderHeader() {
		const listURL = this.isNew
			? URI.accountsPayable.vendorInvoice.listNew
			: URI.accountsPayable.vendorInvoice.listExisting;

		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={listURL}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb>
						<Breadcrumb.Item
							linkProps={{
								to: listURL,
							}}
							linkAs={Link}
						>
							Accounts Payable
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: listURL,
							}}
							linkAs={Link}
						>
							Vendor Deposits & Invoices
						</Breadcrumb.Item>
						<Breadcrumb.Item>{this.renderHeaderTitle()}</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content actions={true}>
					<HeaderLight.Title>{this.renderHeaderTitle()}</HeaderLight.Title>

					<div>
						<Button
							as={Link}
							variant="ivory"
							name="cancel"
							className=""
							to={listURL}
						>
							Cancel
						</Button>
						{!this.isEditing && (
							<Button
								variant="primary ms-2"
								onClick={() => {
									return this.state?.hasExpiredInsurance
										? this.showConfirmationModal(true)
										: this.handleSave(true);
								}}
							>
								Save & New
							</Button>
						)}

						<Button
							variant="primary ms-2"
							onClick={() => {
								return this.state?.hasExpiredInsurance
									? this.showConfirmationModal(false)
									: this.handleSave(false);
							}}
						>
							Save
						</Button>
					</div>
				</HeaderLight.Content>
			</HeaderLight>
		);
	}

	render() {
		return (
			<>
				{this.renderHeader()}

				<div className="content-padding min-height">
					<Container fluid>{this.renderContent()}</Container>
				</div>

				<FooterFormAction>
					<Button
						variant="primary"
						size="lg"
						onClick={() => {
							return this.state?.hasExpiredInsurance
								? this.showConfirmationModal(false)
								: this.handleSave(false);
						}}
					>
						Save
					</Button>
				</FooterFormAction>
			</>
		);
	}

	renderWireModal() {
		return (
			<Modal
				show={this.state.showWireModal}
				size="md"
				aria-labelledby="action-modal"
				className="a-modal mx-sm-5 mx-lg-0"
				backdrop="static"
				centered
				onHide={() => {
					this.setState({ showWireModal: false });
				}}
			>
				<Modal.Header closeButton onClick={this.cancelWireChanges}>
					<Modal.Title>Check / Wire Details</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="content-padding min-height">
						<Container fluid className="ms-0">
							<Form.Group>
								<Row className="align-items-center mb-3">
									<Col>
										<Form.Check
											inline
											label="Hand Check"
											name="ttype"
											type="radio"
											id={`check`}
											className="vtop"
											onClick={this.handleTransferChange}
											value="check"
											checked={this.state.transferType === 'check'}
										/>

										<Form.Check
											inline
											label="Wire Transfer"
											name="ttype"
											type="radio"
											id={`wire`}
											className="vtop mx-3"
											onClick={this.handleTransferChange}
											value="wire"
											checked={this.state.transferType === 'wire'}
										/>
									</Col>
								</Row>
								<div
									className={
										this.state.transferType === 'check' ? 'd-block' : 'd-none'
									}
								>
									<InlineFormGroupField
										label="Check Number"
										classNameParent="mb-3 align-items-center"
										className="justify-content-start col-sm-4"
										colField={8}
									>
										<Form.Control
											type="text"
											placeholder="Please enter"
											id="handcknum"
											value={
												this.state.modalData.handcknum ??
												this.state.updatedData.handcknum ??
												''
											}
											onChange={this.handleModalChange}
										/>
									</InlineFormGroupField>

									<InlineFormGroupField
										label="Check Date"
										classNameParent="mb-3 align-items-center"
										className="justify-content-start col-sm-4"
										colField={8}
									>
										<div className="react-select-header">
											<DatePicker
												className="form-control"
												placeholderText="Select"
												showMonthDropdown="true"
												showYearDropdown="true"
												selected={
													this.state.modalData.handckdt
														? new Date(this.state.modalData.handckdt)
														: null
												}
												onChange={(date) => {
													this.handleModalDateChange(date, 'handckdt');
												}}
											/>
										</div>
									</InlineFormGroupField>
								</div>
								<div
									className={
										this.state.transferType !== 'check' ? 'd-block' : 'd-none'
									}
								>
									<InlineFormGroupField
										label="Transfer Date"
										classNameParent="mb-3 align-items-center"
										className="justify-content-start col-sm-4"
										colField={8}
									>
										<div className="react-select-header">
											<DatePicker
												className="form-control"
												placeholderText="Select"
												showMonthDropdown="true"
												showYearDropdown="true"
												selected={
													this.state.modalData.handckdt
														? new Date(this.state.modalData.handckdt)
														: null
												}
												onChange={(date) => {
													this.handleModalDateChange(date, 'handckdt');
												}}
											/>
										</div>
									</InlineFormGroupField>
								</div>

								<InlineFormGroupField
									label="Checking Account"
									classNameParent="mb-3 align-items-center"
									className="justify-content-start col-sm-4"
									colField={8}
								>
									<Select
										menuPortalTarget={document.body}
										styles={{
											menuPortal: (base) => ({
												...base,
												zIndex: 99999,
											}),
										}}
										options={this.getCheckingAccounts()}
										className="react-select"
										placeholder="Please select"
										name="handckcashacct"
										value={this.getSelectedCheckingAccount()}
										onChange={this.handleModalChange}
									/>
								</InlineFormGroupField>

								<InlineFormGroupField
									label="Trans. Desc."
									classNameParent="mb-3 align-items-center"
									className="justify-content-start col-sm-4"
									colField={8}
								>
									<Form.Control
										type="text"
										placeholder="Please enter"
										id="handcktxdesc"
										maxLength={30}
										value={
											this.state.modalData.handcktxdesc ??
											this.state.updatedData.handcktxdesc ??
											''
										}
										onChange={this.handleModalChange}
									/>
								</InlineFormGroupField>
							</Form.Group>
						</Container>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={this.confirmWireChanges}>
						OK
					</Button>
					<Button variant="primary" onClick={this.cancelWireChanges}>
						Cancel
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	renderInputBar() {
		return (
			<InputBar className="full multiple">
				<InputBar.Links>
					<InputBar.Link>
						<Form.Label htmlFor="inputPassword5" className="ilabel">
							Subtotal
						</Form.Label>
						<FormInputAdOns text="USD">
							<Form.Control type="text" placeholder="0.00" size="sm" />
						</FormInputAdOns>
					</InputBar.Link>
					<InputBar.Link>
						<Form.Label htmlFor="inputPassword5" className="ilabel">
							Less Deposit
						</Form.Label>
						<FormInputAdOns text="USD">
							<Form.Control type="text" placeholder="0.00" size="sm" />
						</FormInputAdOns>
					</InputBar.Link>
					<InputBar.Link>
						<Form.Label htmlFor="inputPassword5" className="ilabel">
							Amount Due
						</Form.Label>
						<FormInputAdOns text="USD">
							<Form.Control type="text" placeholder="0.00" size="sm" />
						</FormInputAdOns>
					</InputBar.Link>
				</InputBar.Links>
			</InputBar>
		);
	}

	renderVerticalActionBar() {
		return (
			<ActionBarVertical>
				<ActionBarVertical.Links>
					<li>
						<NavLink
							to={URI.accountsPayable.vendorInvoice.add}
							className="d-flex align-items-center mb-2"
						>
							<i className="ri-edit-fill mx-0"></i>
						</NavLink>
					</li>
					<li>
						<NavLink
							to={URI.accountsPayable.vendorInvoice.add}
							className="d-flex align-items-center mb-2"
						>
							<i className="ri-restart-line mx-0"></i>
						</NavLink>
					</li>
					<li>
						<NavLink
							to={URI.accountsPayable.vendorInvoice.add}
							className="d-flex align-items-center"
						>
							<i className="ri-refresh-line mx-0"></i>
						</NavLink>
					</li>
				</ActionBarVertical.Links>
			</ActionBarVertical>
		);
	}

	renderContent() {
		const { remarksHTML } = this.state;
		const DatePickerComponent = this.isNew ? DatePicker : SecureDatePicker;

		return (
			<>
				<Form.Group>
					<Row xs={1} lg={2} className="py-4">
						<Col className="mb-3 mb-lg-0">
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">PO/WO Number</Form.Label>
								</Col>
								<Col>
									<AsyncPurchaseOrderDropdown
										menuPortalTarget={document.body}
										styles={{
											menuPortal: (base) => ({
												...base,
												zIndex: 99999,
											}),
										}}
										className="react-select"
										placeholder="Please select"
										onChange={this.handlePurchaseOrderChange}
										defaultValue={this.state.selectedPO}
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Deposit Date</Form.Label>
								</Col>
								<Col lg={3}>
									<div className="react-select-header">
										<DatePicker
											className="form-control"
											wrapperClassName="no-icon"
											placeholderText="Select"
											showMonthDropdown="true"
											showYearDropdown="true"
											selected={
												this.state.updatedData.sinvdt
													? new Date(this.state.updatedData.sinvdt)
													: null
											}
											onChange={(date) => {
												this.handleDateChange(date, 'sinvdt');
											}}
										/>
									</div>
								</Col>

								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Due Date</Form.Label>
								</Col>
								<Col lg={3}>
									<div className="react-select-header">
										<DatePicker
											className="form-control"
											wrapperClassName="no-icon"
											placeholderText="Select"
											showMonthDropdown="true"
											showYearDropdown="true"
											selected={
												this.state.updatedData.paydt
													? new Date(this.state.updatedData.paydt)
													: null
											}
											onChange={(date) => {
												this.handleDateChange(date, 'paydt');
											}}
										/>
									</div>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Amount</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Please enter"
										id="amount"
										value={this.state.updatedData.amount ?? ''}
										onChange={this.handleChange}
									/>
								</Col>
							</Row>

							{!this.isNew && (
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Fiscal Month</Form.Label>
									</Col>
									<Col lg={3}>
										<div className="react-select-header">
											<DatePickerComponent
												selected={
													this.state.updatedData.fiscalmonth
														? new Date(this.state.updatedData.fiscalmonth)
														: null
												}
												onChange={(date) => {
													this.handleDateChange(date, 'fiscalmonth');
												}}
												className="form-control"
												placeholderText="Please select"
												showMonthYearPicker
												dateFormat="MM/yyyy"
											/>
										</div>
									</Col>
								</Row>
							)}
						</Col>

						<Col>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Vendor</Form.Label>
								</Col>
								<Col>
									<AsyncVendorsDropdown
										menuPortalTarget={document.body}
										styles={{
											menuPortal: (base) => ({
												...base,
												zIndex: 99999,
											}),
										}}
										className="react-select"
										placeholder="Select vendor"
										name="supplier"
										onChange={this.handleChange}
										defaultValue={this.state.selectedVendor}
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Trans. Desc.</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Please enter"
										id="txdesc"
										maxLength={30}
										onChange={this.handleChange}
										value={this.state.updatedData.txdesc ?? ''}
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Pay With</Form.Label>
								</Col>
								<Col className="col-lg-3">
									<Select
										menuPortalTarget={document.body}
										styles={{
											menuPortal: (base) => ({
												...base,
												zIndex: 99999,
											}),
										}}
										options={this.state.accounts}
										className="react-select"
										placeholder="Please select"
										name="paywith"
										onChange={this.handleChange}
										value={this.state.accounts.find(
											(account) =>
												account.value === this.state.updatedData.paywith
										)}
									/>
								</Col>
								<Col className="col-lg-3">
									<Button
										onClick={() => {
											this.setState({
												showWireModal: true,
											});
										}}
										variant="primary"
										size="sm"
										className="btn-icon"
										style={{ fontSize: '11px' }}
										disabled={!this.isCheckWire()}
									>
										{this.state.updatedData?.handcknum
											? `Edit Ck/WT # ${this.state.updatedData?.handcknum}`
											: 'Enter Hand Check / Wire Details'}
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row xs={1} lg={2} className="py-4">
						<Col className="mb-3 mb-lg-0">
							<Row xs={12} lg={2}>
								<Col lg={{ span: 10, offset: 2 }}>
									<Row>
										<Col xs={12} className="mb-2">
											<Form.Label className="mb-0">Notes</Form.Label>
										</Col>
										<Col xs={12}>
											<WysiwygEditor onChange={this.handleNotesChange}>
												{remarksHTML ?? ''}
											</WysiwygEditor>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				</Form.Group>

				{this.renderWireModal()}

				{this.state.showingCheckInfo && (
					<BasicModal
						message={`This invoice cannot be edited because it has a payment associated with it: ${this.state.checkInfo}`}
						onPrimary={() => {
							this.props.navigate(
								URI.accountsPayable.vendorInvoice.listExisting
							);
						}}
						hasSecondary={false}
					/>
				)}
				<ConfirmationModal
					confirmAction={() => this.handleConfirmSave()}
					show={this.state.showInsuranceModal}
					toggleModal={() => this.handleCloseModal(this)}
					labelCancel="Cancel"
					labelOK="Ok"
					message="WARNING: the vendor has an active Insurance Policy that has expired."
					title="Vendor deposit, Invoice or Operating Expense"
				/>
			</>
		);
	}
}

export default WithRouter(VendorDepositAdd);
