import React from 'react';
import { Modal, Spinner, Table } from 'react-bootstrap';
import { formatFiscalMonth, formatNumber, formatUserDate } from './utils';
import { useGetCheckbookDetailDeposit } from 'api/hooks/useGetCheckbookDetailDeposit';
import { useGetCheckbookDetailDepositPaydist } from 'api/hooks/useGetCheckbookDetailDepositPaydist';

interface ICheckbookDetailCheckProps {
	userdate?: string | null;
	account?: string | null;
	depositslip?: string | null;
	checknum?: string | null;
}

const CheckbookDetailDeposit = ({
	userdate,
	account,
	depositslip,
	checknum,
}: ICheckbookDetailCheckProps) => {
	const {
		data: depositCheckbookDetail = [],
		isLoading: isDepositDetailLoading,
	} = useGetCheckbookDetailDeposit({
		account: account ?? '',
		depositslip: depositslip ?? '',
		userdate: formatUserDate(userdate),
	});

	const isGetDepositPaydistEnabled =
		depositCheckbookDetail.length > 0 &&
		depositCheckbookDetail[0]?.crgroupnum > -1;

	const {
		data: paydistDepositCheckbookDetail = [],
		isLoading: isDepositPaydistLoading,
	} = useGetCheckbookDetailDepositPaydist(
		{
			crgroupnum: depositCheckbookDetail?.[0]?.crgroupnum?.toString(),
		},
		{
			enabled: isGetDepositPaydistEnabled,
		}
	);

	return (
		<>
			<Modal.Header>
				<Modal.Title as="h5">
					Checkbook Deposit Detail {checknum ? `[Check #${checknum}]` : ''}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{isDepositDetailLoading || isDepositPaydistLoading ? (
					<div className="tw-flex tw-items-center tw-justify-center">
						<Spinner variant="success" />
					</div>
				) : (
					<>
						<div className="tw-overflow-x-auto">
							<Table striped bordered>
								<thead>
									<tr>
										<th>Account</th>
										<th>Client</th>
										<th>Fiscal Month</th>
										<th>Amount</th>
									</tr>
								</thead>
								<tbody>
									{depositCheckbookDetail.map((x) => (
										<tr key={x.checknum}>
											<td>{x.account}</td>
											<td>{x.clientn}</td>
											<td>{formatFiscalMonth(x.fiscalmonth)}</td>
											<td>{formatNumber(x.amount)}</td>
										</tr>
									))}
								</tbody>
							</Table>
						</div>

						<div className="tw-overflow-x-auto">
							<Table striped bordered>
								<thead>
									<tr>
										<th>TX #</th>
										<th>Type</th>
										<th>Description</th>
										<th>Amount</th>
									</tr>
								</thead>
								<tbody>
									{paydistDepositCheckbookDetail.map((x) => (
										<tr key={x.txnum}>
											<td>{x.txnum}</td>
											<td>{x.type}</td>
											<td>{x.txdesc}</td>
											<td>{formatNumber(x.amount)}</td>
										</tr>
									))}
								</tbody>
							</Table>
						</div>
					</>
				)}
			</Modal.Body>
		</>
	);
};

CheckbookDetailDeposit.displayName = 'CheckbookDetailDeposit';

export { CheckbookDetailDeposit };
