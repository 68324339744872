import React from 'react';
import { Button, Col, Modal, Row, Table, Form } from 'react-bootstrap';
import { WithRouter } from '../../../../helpers/Router';
import { setLocalStorage } from '../../../../utilities/LocalStorage';
import { displayAlert } from '../../../../utilities/Response';
import ThreadComponent from '../../ThreadComponent';
import { truncateCharCount } from '../../../../helpers/String';
import { getCheckAllClass } from '../../../../utilities/ModuleHelper';
import { getComponentName } from '../../../../utilities/ComponentTypes';
import { showLoading } from '../../../../helpers/Loading';
import { Link } from 'react-router-dom';
import debounce from 'lodash/debounce';
import {
	sortLocalData,
	tableSortingEnableSort,
	tableSortingSetData,
} from '../../../../utilities/modules/TableSorting';
import { isEmpty, toLower } from 'lodash';
import { Event } from '../../../../utilities/DOM';
import { SearchExpressionButton } from '../../../components/SearchExpressionButton';
import { delay } from 'lodash/function';
import Select from 'react-select';
import FilterSort from '../../../../utilities/modules/FilterSort';
import { tableSearch } from '../../../../utilities/modules/TableSearch';
import { AlertLoader } from '../../../../app/components/bootstrap/BAlerts';
import MSG from '../../../../defaults/Message';

class ProjectVendorOrdersAddPurchaseOrderAddComponentModal extends ThreadComponent {
	constructor(props) {
		super(props);

		this.fs = new FilterSort(
			`project_vporder_add_item_modal_${this.props.params.id}_` +
				(this.props.orderType ?? 'po')
		);
		this.fs.setDefaultSort('refNum asc');
		this.state = {
			data: [],
			processedData: [],
			show: false,
			checks: [],
			totalChecked: 0,
			page: 1,
			pageSize: { value: 10, label: '10' },
			isShowAll: props?.isShowAll ?? false,
			updatedShowAll: false,
			showTableSearch: this.fs.isSearchActive(),
			searchProperties: this.fs.getSearches(),
		};

		this.sortPattern = (key) => [
			(d) => {
				if (typeof d[key] === 'string') {
					return toLower(d[key]);
				}

				if (key === 'comptype') {
					return toLower(getComponentName(d[key]));
				}

				return d[key] ?? '';
			},
		];

		this.availablePageSize = [
			{ value: '10', label: '10' },
			{ value: '20', label: '20' },
			{ value: '50', label: '50' },
			{ value: '75', label: '75' },
			{ value: '100', label: '100' },
		];
		this.changePageHandler = debounce(this.handleChangePage.bind(this, 1), 200);
		this.searchHandler = debounce(this.handleDoSearch.bind(this, 1), 200);
	}

	async componentDidMount() {
		const { data, show, isShowAll } = this.props;
		// await this.paginateLocalData(
		//     sortLocalData(this, data, this.sortPattern)
		// )
		this.doSearchData(data, true);
		tableSortingSetData(data, 'po_selected_modal');
		this.setState({
			show,
			isShowAll,
			checks: [],
		});
	}

	componentDidUpdate(previousProps, previousState) {
		if (
			previousProps.data !== this.props.data ||
			previousProps.show !== this.props.show ||
			previousProps.imageIsLoaded !== this.props.imageIsLoaded ||
			previousProps.isShowAll !== this.props.isShowAll
		) {
			const { data, show, isShowAll } = this.props;
			this.setState({
				show,
				isShowAll,
				checks: [],
				totalChecked: 0,
			});

			tableSortingSetData(data, 'po_selected_modal');
			// this.paginateLocalData(sortLocalData(this, data, this.sortPattern))
			this.doSearchData(data, true);
			delay(() => {
				const sort = document.querySelectorAll(
					'.a-table-heading.unselected .sort'
				);

				if (
					sort?.length &&
					!window.enableSortTableModal &&
					!this.state.updatedShowAll
				) {
					window.enableSortTableModal = true;
					this.enableSortTable();
				}
			}, 250);
		}
	}

	doSearchData = (data, isSort = false) =>
		tableSearch({
			data,
			searchProps: this.state.searchProperties,
			callback: async (data) => {
				if (isSort) {
					await this.paginateLocalData(
						sortLocalData(this, data, this.sortPattern)
					);
				} else {
					this.paginateLocalData(data);
				}
			},
			customFilter: (item, key) => {
				if (key === 'comptype') {
					return getComponentName(item[key]);
				}
				return item[key];
			},
		});

	handleChangePage = async (page) => {
		this.setState(
			{
				page,
			},
			() => {
				this.doSearchData(this.props.data, true);
			}
		);
	};

	paginateLocalData = async (data) => {
		const newData = (data ?? []).slice(
			(this.state.page - 1) * this.state.pageSize.value,
			this.state.page * this.state.pageSize.value
		);

		this.setState({
			data: newData,
			processedData: data ?? [],
		});
	};

	handleDoSearch = () => {
		// reset the current page to 1 when searching
		this.setState(
			{
				page: 1,
			},
			() => {
				this.doSearchData(this.props.data, true);
			}
		);
	};

	handleShowTableSearch = (e) => {
		this.fs.setIsActiveSearch(!this.state.showTableSearch);
		this.setState(
			{
				showTableSearch: !this.state.showTableSearch,
			},
			() => {
				if (!this.state.showTableSearch) {
					this.fs.setSearches({});
					Event('.a-table-search-fields.unselected .form-control').value('');
					this.setState(
						{
							searchProperties: {},
						},
						() => {
							this.doSearchData(this.props.data, true);
						}
					);
				}
			}
		);
	};

	handleSearch = (e) => {
		const key = e.target.attributes['data-field'].value;
		const value = e.target.value;
		const type = e.target.attributes['data-type']?.value ?? 'string';
		const exp = e.target.attributes['placeholder']?.value ?? '>=';
		const custom = Boolean(e.target.attributes['data-custom']?.value);

		this.setState(
			{
				searchProperties: {
					...this.state.searchProperties,
					[key]: { value, type, exp, custom },
				},
				dataIsLoaded: false,
			},
			() => {
				this.fs.setSearches(this.state.searchProperties);
				this.searchHandler();
			}
		);
	};

	handleSelectAllChecks = () => {
		let checks = [];
		if (this.state.checks.length !== this.state.data.length) {
			checks = this.state.data
				.filter((item) => {
					return item.completeShipTo && item.vendorName;
				})
				.map((item) => {
					return item.id;
				});

			if (!checks?.length) {
				displayAlert(
					'warning',
					'A Component cannot be selected unless it has had both a Vendor and a Ship To Code entered for it.'
				);

				return;
			}
		}

		this.setState({
			checks: checks,
			totalChecked: checks.length,
		});
	};

	enableSortTable = () =>
		tableSortingEnableSort({
			// data: this.props.data,
			classRef: this,
			targetTable: '.a-table-heading.unselected',
			dataKey: 'po_selected_modal',
			pattern: this.sortPattern,
			callback: (data, sort) => {
				this.doSearchData(data);

				this.setState({
					sortProperty: sort,
				});

				// Save sortProperty to localStorage
				this.fs.setSort(sort);
			},
		});

	handleCheck = (id) => {
		const component = this.state.data.filter((item) => item.id === id)[0];

		let canCheck = component.completeShipTo && component.vendorName;
		if (!canCheck) {
			displayAlert(
				'warning',
				'A Component cannot be selected unless it has had both a Vendor and a Ship To Code entered for it.'
			);

			return;
		}

		const checks = this.state.checks;

		const associatedComponents = this.state.data.filter(
			(item) =>
				item.item === component.item && item.assocwithcomp === component.comp
		);

		if (associatedComponents.length) {
			const parentIndex = checks.findIndex((_id) => _id === id);
			associatedComponents.forEach((component) => {
				const index = checks.findIndex((_id) => _id === component.id);
				parentIndex > -1 ? checks.splice(index, 1) : checks.push(component.id);
			});
		} else {
			const index = checks.findIndex((_id) => _id === id);
			index > -1 ? checks.splice(index, 1) : checks.push(id);
		}

		this.setState({
			checks: checks,
			totalChecked: checks.length,
		});
	};

	addItemClick = (e) => {
		let ids = this.state.checks;
		setLocalStorage('isDraft', 'true');

		if (!ids.length) {
			displayAlert('danger', 'Please select at least one item', 2000);
		} else {
			if (this.props.addItemCall) {
				this.setUpdatedShowAll(false);
				this.props.addItemCall(ids);
			}
		}
	};

	formatDesc(item) {
		const itemName = item.itemName || '';
		const itemDesc = item.desc || '';

		let result = '';

		if (itemName && itemDesc) {
			const truncatedDesc = truncateCharCount(itemDesc, 100);
			result = truncatedDesc.includes(itemName)
				? truncatedDesc
				: `${itemName} ${truncatedDesc}`;
		} else {
			result = itemName || itemDesc || '';
		}

		return result;
	}

	handleFilter = (name) => (e) => {
		this.setState(
			{
				[name]: e,
				page: 1,
			},
			this.changePageHandler
		);
	};

	sortClass(name) {
		return `sort ${this.fs.isActiveSort(name)}`;
	}

	isNextDisabled() {
		const { processedData, page, pageSize } = this.state;
		const total = page * pageSize.value;

		return Number(processedData?.length) <= total;
	}

	setUpdatedShowAll = (updatedShowAll) => {
		this.setState({
			updatedShowAll,
		});

		if (!updatedShowAll) {
			window.enableSortTableModal = false;
		}
	};

	renderPagination() {
		return (
			<div className={'d-flex flex-wrap justify-content-center'}>
				<div className={'d-flex flex-row align-items-center pagination'}>
					<Button
						variant="ivory"
						size="sm"
						className={'btn-icon pagination-btn'}
						disabled={this.state.page === 1}
						onClick={() => this.handleChangePage(this.state.page - 1)}
					>
						<i className="ri-arrow-left-s-line"></i> Previous
					</Button>
					<span className={'pagination-span'}>{this.state.page}</span>
					<Button
						variant="ivory"
						size="sm"
						className={'btn-icon pagination-btn'}
						disabled={this.isNextDisabled()}
						onClick={() => this.handleChangePage(this.state.page + 1)}
					>
						Next <i className="ri-arrow-right-s-line ms-1"></i>
					</Button>
				</div>
				<Select
					onChange={this.handleFilter('pageSize')}
					key={`${Math.floor(Math.random() * 1000)}-min`}
					options={this.availablePageSize}
					defaultValue={this.state.pageSize}
					className="react-select pagination-select mx-3"
					placeholder="Please select"
				/>
			</div>
		);
	}

	render() {
		const { data, checks, totalChecked } = this.state;
		return (
			<Modal
				size="xxl"
				show={this.state.show}
				onHide={() => {
					this.setUpdatedShowAll(false);
					this.props.hideModal();
				}}
				aria-labelledby="example-modal-sizes-title-lg"
				className="right a-modal modal-quarter fixed-footer"
				backdrop="static"
			>
				<Modal.Header className="bg-ivory py-4 px-5">
					<Row className="w-100">
						<Col className="text-start">
							<Button
								as={Link}
								to="#"
								variant="ivory"
								size="sm"
								className={`d-inline-block ms-3 mt-2 btn-icon btn-action fw-bold ${
									this.state.showTableSearch ? 'bg-primary-ash text-white' : ''
								}`}
								onClick={this.handleShowTableSearch}
							>
								<i className="ri-search-line"></i> Search
							</Button>
							<Form.Check
								inline
								label="Show All"
								name="group-check-show-all"
								type="checkbox"
								id={`group-check-show-all`}
								className="ms-3 align-middle pt-2"
								onClick={(e) => {
									this.setUpdatedShowAll(true);
									this.props.showAll(e);
								}}
								defaultChecked={this.state.isShowAll}
							/>
						</Col>
						<Col className="text-end">
							<Button
								variant="trans-light border-secondary-ash"
								onClick={() => {
									this.setUpdatedShowAll(false);
									this.props.hideModal();
								}}
								className="d-inline-block w-150px"
							>
								Cancel
							</Button>
							{this.state.data.length > 0 && (
								<Button
									variant="primary"
									onClick={this.addItemClick.bind(null)}
									className="d-inline-block w-150px ms-3"
								>
									Save
								</Button>
							)}
						</Col>
					</Row>
				</Modal.Header>
				<Modal.Body className="bg-ivory">
					<Row className="w-100 px-5">
						{!this.props.imageIsLoaded && (
							<Col sm={12}>
								<AlertLoader message={MSG.loading.images} />
							</Col>
						)}
						<Col sm={12}>
							<div className="table-gradient mb-5 sticky-container">
								<Table striped responsive className="a-table">
									<thead>
										<tr key="0" className="a-table-heading unselected">
											<th className="ps-4">
												<Form.Check
													inline
													label=""
													type="checkbox"
													checked={totalChecked > 0}
													className={getCheckAllClass(totalChecked, data)}
													onClick={this.handleSelectAllChecks.bind(this)}
												/>
											</th>
											<th>Image</th>
											<th className="mw-100px">
												<span
													className={this.sortClass('refNum')}
													data-field="refNum"
												>
													Ref No.
												</span>
											</th>
											<th className="mw-100px">
												<span
													className={this.sortClass('vendorName')}
													data-field="vendorName"
												>
													{this.props.orderType && this.props.orderType == 'wo'
														? 'Workroom'
														: 'Vendor'}
												</span>
											</th>
											{this.props.orderType && this.props.orderType == 'wo' ? (
												<th>
													<span
														className={this.sortClass('completeVendor')}
														data-field="completeVendor"
													>
														Ordered From
													</span>
												</th>
											) : (
												''
											)}
											<th className="mw-180px">
												<span
													className={this.sortClass('desc')}
													data-field="desc"
												>
													Description
												</span>
											</th>
											<th className="mw-110px">
												<span
													className={this.sortClass('quantity')}
													data-field="quantity"
												>
													Quantity
												</span>
											</th>
											<th className="mw-140px">
												<span
													className={this.sortClass('estcost')}
													data-field="estcost"
												>
													Estimated Cost
												</span>
											</th>
											<th className="mw-100px">
												<span
													className={this.sortClass('comptype')}
													data-field="comptype"
												>
													Type
												</span>
											</th>
											<th>
												<span
													className={this.sortClass('completeLocation')}
													data-field="completeLocation"
												>
													Location
												</span>
											</th>
											<th className="mw-100px">
												<span
													className={this.sortClass('completeShipTo')}
													data-field="completeShipTo"
												>
													Ship To
												</span>
											</th>
											<th>
												<span
													className={this.sortClass('catalog')}
													data-field="catalog"
												>
													Catalog No.
												</span>
											</th>
											<th>
												<span
													className={this.sortClass('latestProposalNo')}
													data-field="latestProposalNo"
												>
													Last Proposal No
												</span>
											</th>
										</tr>
										<tr
											className={`a-table-search-fields unselected ${
												this.state.showTableSearch ? '' : 'd-none'
											}`}
										>
											<th></th>
											<th></th>
											<th>
												<Form.Control
													type="text"
													data-field="refNum"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('refNum')}
												/>
											</th>
											<th>
												<Form.Control
													type="text"
													data-field="vendorName"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('vendorName')}
												/>
											</th>
											{this.props.orderType && this.props.orderType == 'wo' ? (
												<th>
													<Form.Control
														type="text"
														data-field="completeVendor"
														onChange={this.handleSearch}
														defaultValue={this.fs.getValue('completeVendor')}
													/>
												</th>
											) : (
												''
											)}
											<th>
												<Form.Control
													type="text"
													data-field="desc"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('desc')}
												/>
											</th>
											<th className="position-relative">
												<Form.Control
													type="number"
													data-field="quantity"
													placeholder={this.fs.getSearchExpression('quantity')}
													data-type="number"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('quantity')}
												/>
												<SearchExpressionButton
													operator={this.fs.getSearchExpression('quantity')}
													callback={(exp) => {
														this.fs.setSearchExperation('quantity', exp, this);
													}}
												/>
											</th>
											<th className="position-relative">
												<Form.Control
													type="number"
													data-field="estcost"
													placeholder={this.fs.getSearchExpression('estcost')}
													data-type="number"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('estcost')}
												/>
												<SearchExpressionButton
													operator={this.fs.getSearchExpression('estcost')}
													callback={(exp) => {
														this.fs.setSearchExperation('estcost', exp, this);
													}}
												/>
											</th>
											<th>
												<Form.Control
													type="text"
													data-field="comptype"
													data-custom="comptype"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('comptype')}
												/>
											</th>
											<th className="mw-100px">
												<Form.Control
													type="text"
													data-field="completeLocation"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('completeLocation')}
												/>
											</th>
											<th className="mw-100px">
												<Form.Control
													type="text"
													data-field="completeShipTo"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('completeShipTo')}
												/>
											</th>
											<th className="mw-100px">
												<Form.Control
													type="text"
													data-field="catalog"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('catalog')}
												/>
											</th>
											<th className="mw-100px">
												<Form.Control
													type="text"
													data-field="latestProposalNo"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('latestProposalNo')}
												/>
											</th>
										</tr>
									</thead>
									<tbody>
										{(() => {
											if (this.props.dataIsLoaded) {
												return data.length > 0 ? (
													(data || []).map((item, i) => (
														<tr
															key={i}
															className={
																checks.includes(item.id) ? `active` : ''
															}
														>
															<td className="ps-4">
																<Form.Check
																	inline
																	label=""
																	type="checkbox"
																	checked={checks.includes(item.id)}
																	onChange={() => {
																		this.handleCheck(item.id);
																	}}
																/>
															</td>
															<td>
																{item.imageThumb && (
																	<img
																		src={URL.createObjectURL(item.imageThumb)}
																		alt={item.itemName}
																		className="img-sm"
																	/>
																)}
															</td>
															<td>{item.refNum}</td>
															<td>{item.vendorName}</td>
															{this.props.orderType &&
															this.props.orderType == 'wo' ? (
																<td>{item.completeVendor}</td>
															) : (
																''
															)}
															<td>{this.formatDesc(item)}</td>
															<td>{`${item.quantity}  ${
																item?.unitmeasure || ''
															}`}</td>
															<td>{item.estcost?.toFixed(2)}</td>
															<td>{getComponentName(item.comptype)}</td>
															<td>{item.completeLocation}</td>
															<td>{item.completeShipTo}</td>
															<td>{item.catalog}</td>
															<td>{item.latestProposalNo}</td>
														</tr>
													))
												) : (
													<tr>
														<td colSpan={12} className="fw-bold">
															No available components
														</td>
													</tr>
												);
											} else {
												return showLoading();
											}
										})()}
									</tbody>
								</Table>
								{this.props.data?.length > 1 && this.renderPagination()}
							</div>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		);
	}
}

export default WithRouter(ProjectVendorOrdersAddPurchaseOrderAddComponentModal);
