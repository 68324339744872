import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import {
	getCheckbookDetailDepositPaydist,
	type IGetCheckbookDetailDepositPaydistParams,
	type ICheckbookDetailDepositPaydist,
} from 'api/resources/generalLedgerTransactions';
import { ECacheKeys } from 'cache/CacheKeys';

export const useGetCheckbookDetailDepositPaydist = (
	params: IGetCheckbookDetailDepositPaydistParams,
	options?: UseQueryOptions<ICheckbookDetailDepositPaydist[]>
) => {
	const query = useQuery({
		queryKey: [
			ECacheKeys.GeneralLedgerTransactions,
			'CheckbookDetailDepositPaydist',
			params.crgroupnum,
		],
		queryFn: () => getCheckbookDetailDepositPaydist(params),
		refetchInterval: false,
		refetchOnWindowFocus: false,
		...(options || {}),
	});

	return query;
};
