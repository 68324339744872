import { apiClient } from 'api/apiClient';

const RESOURCE = '/Plaid';

export enum EPlaidAccountStatus {
	DELETED = 'Deleted',
	NEEDREAUTHENTICATION = 'NeedReauthentication',
	Active = 'Active',
}

export interface IPlaidAccount {
	id: number;
	plaidAccountId: string;
	instanceId: string;
	payTypeAccountId: number | null;
	status: `${EPlaidAccountStatus}`;
	defaultAccountsNumber: string | null;
	name: string;
	maskedNumber: string;
	type: string;
}

export interface IPlaidConnectParams {
	publicToken?: string;
	institutionId?: string;
	maskedNumber?: string;
	reauthenticatedAccountId?: number;
	defaultAccountsNumber?: string | null;
	payTypeAccountId?: number | null;
}

export interface IPatchPlaidAccountParams {
	id: number;
	defaultAccountsNumber?: string | null;
	newStatus?: string;
	payTypeAccountId?: number | null;
}

export const getPlaidAccounts = async (
	filter?: string
): Promise<IPlaidAccount[]> => {
	const { data } = await apiClient.get(
		`${RESOURCE}/accounts${filter ? `?$filter=${filter}` : ''}`.trim()
	);

	return data;
};

export const connectPlaidAccount = async ({
	publicToken,
	reauthenticatedAccountId,
	institutionId,
	maskedNumber,
	defaultAccountsNumber,
	payTypeAccountId,
}: IPlaidConnectParams): Promise<IPlaidAccount[]> => {
	const { data } = await apiClient.post(`${RESOURCE}/connect`, {
		publicToken,
		institutionId,
		maskedNumber,
		defaultAccountsNumber,
		payTypeAccountId,
		reauthenticatedAccountId,
	});

	return data;
};

export const patchPlaidAccount = async (
	params: IPatchPlaidAccountParams
): Promise<IPlaidAccount> => {
	const { id, ...rest } = params;
	const { data } = await apiClient.patch(`${RESOURCE}/accounts/${id}`, rest);
	return data;
};
