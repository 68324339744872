export enum ESignUpStatus {
	NotScheduled = 'NotScheduled',
	Scheduled = 'Scheduled',
	Started = 'Started',
	Completed = 'Completed',
	Failed = 'Failed',
}

export enum ESignUpFormStatus {
	InProgress = 0,
	Incomplete = 1,
	Failed = 2,
}
