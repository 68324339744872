import React, {
	useState,
	useCallback,
	useEffect,
	createContext,
	useMemo,
} from 'react';
import {
	displayAlertSuccess,
	displayAlertError,
} from 'legacy/utilities/Response';
import {
	useCompanySettings,
	useMutateCompanySettings,
	DEFAULT_TIMEZONE_CODE,
} from '../../../pages/settings/company/hooks';
import { UseQueryOptions } from '@tanstack/react-query';

type Settings = {
	isLoading?: boolean;
	hasTimeZone: boolean;
	timeZoneCode: number;
	logoImageId: string;
};

export type ChangeFunction = (e: React.ChangeEvent<HTMLSelectElement>) => void;

export type SubmitFunction = () => void;

export type TimeZoneContextType = {
	timeZoneCode: number;
	isLoading?: boolean;
	isTimeZoneSet: boolean;
	handleSubmit: SubmitFunction;
	handleChange: ChangeFunction;
};
export const TimeZoneContext = createContext<TimeZoneContextType>(
	{} as TimeZoneContextType
);

const TimeZoneContextProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [timeZoneCode, setTimeZoneCode] = useState<number>(0);
	const [optionsCompany2, setOptionsCompany2] = useState<UseQueryOptions>({});
	const settings = useCompanySettings({
		optionsCompany1: {
			enabled: false,
			refetchOnWindowFocus: false,
		},
		optionsCompany2,
	}) as Settings;
	const companySettingsMutation = useMutateCompanySettings();

	const handleSubmit = useCallback(async () => {
		try {
			await companySettingsMutation.mutateAsync({
				dirtyFields: {
					timeZoneCode,
				},
				originalLogoImageId: settings.logoImageId,
				data: {
					logoImageId: settings.logoImageId,
					timeZoneCode,
				},
			});
			displayAlertSuccess('Timezone was successfully saved.');
		} catch {
			displayAlertError('Error saving changes.');
		}
	}, [companySettingsMutation.mutateAsync, timeZoneCode, settings]);

	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLSelectElement>) =>
			setTimeZoneCode(Number(e.target.value)),
		[]
	);

	useEffect(() => {
		if (settings.hasTimeZone === false) {
			setTimeZoneCode(DEFAULT_TIMEZONE_CODE);
		} else if (settings.hasTimeZone) {
			setOptionsCompany2({ enabled: false, refetchOnWindowFocus: false });
		}
	}, [settings.hasTimeZone]);

	const contextValue = useMemo(
		() => ({
			isLoading: !!settings.isLoading,
			timeZoneCode,
			isTimeZoneSet: !!settings.hasTimeZone,
			handleChange,
			handleSubmit,
		}),
		[handleChange, handleSubmit, timeZoneCode, settings]
	);
	return (
		<TimeZoneContext.Provider value={contextValue}>
			{children}
		</TimeZoneContext.Provider>
	);
};

TimeZoneContextProvider.displayName = 'TimeZoneContextProvider';

export default TimeZoneContextProvider;
