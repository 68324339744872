import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { ECacheKeys } from 'cache/CacheKeys';
import type { IUserAccount } from 'api/resources/user';
import { getUserAccount } from 'api/resources/user';

export const useGetUserAccount = (
	filter: string,
	options?: UseQueryOptions<IUserAccount[]>
) => {
	const query = useQuery<IUserAccount[]>({
		queryKey: [ECacheKeys.UserData],
		refetchInterval: false,
		refetchOnWindowFocus: false,
		queryFn: () => getUserAccount(filter),
		...(options || {}),
	});

	return query;
};
