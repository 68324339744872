import { UserRoleContext } from 'contexts/UserRoleContext';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';
import { useContext } from 'react';
import { UserRoleSelect } from 'app/widgets/UserRoleSelect/UserRoleSelect';

export const UserRoleChecker = () => {
	const {
		shouldShowRoleModal,
		handleChange,
		handleSubmit,
		userRole,
		isLoading,
	} = useContext(UserRoleContext);

	return !shouldShowRoleModal ? null : (
		<ConfirmationModal
			hideCancel
			dataTestId="role-modal"
			disabledOK={isLoading || userRole === null}
			okBtnStyle="primary"
			disableCancel={isLoading}
			title="Please select your Role in the Company"
			show={shouldShowRoleModal}
			confirmAction={handleSubmit}
		>
			<div>
				<UserRoleSelect selected={userRole} onChange={handleChange} />
			</div>
		</ConfirmationModal>
	);
};

UserRoleChecker.displayName = 'UserRoleChecker';
