import React from 'react';
import { useCheckbookReconcile } from './hooks/useCheckbookReconcile';
import { Button } from 'react-bootstrap';
import cn from 'classnames';
import { CheckbookReconcileStatusItem } from './CheckbookReconcileStatusItem';
import { Feature } from 'use-feature';
import { FeatureFlags } from 'legacy/app/enums/featureFlags/featureFlags';

const CheckbookReconcileStatus = () => {
	const {
		tab,
		outstandingChecks,
		clearedChecks,
		outstandingDeposits,
		clearedDeposits,
		totals,
		selectedChecks,
		selectedDeposits,
		isSearchEnabled,
		handleOnMarkCleared,
		handleOnMarkUncleared,
		setIsSearchEnabled,
		openFinanceChargesModal,
	} = useCheckbookReconcile();

	const handleSearchClick = () => {
		setIsSearchEnabled((prevState) => !prevState);
	};

	const checksOutstandingCount = outstandingChecks.length;
	const depositsOutstandingCount = outstandingDeposits.length;

	const selectedChecksArray = Object.keys(selectedChecks).filter(
		(key) => selectedChecks[key]
	);

	const selectedDepositsArray = Object.keys(selectedDeposits).filter(
		(key) => selectedDeposits[key]
	);

	const checksOutstandingSelectedCount = selectedChecksArray.reduce(
		(acc, cur) => {
			const exists = outstandingChecks.some(
				({ recnum }) => recnum === Number(cur)
			);
			if (exists) {
				return acc + 1;
			}
			return acc;
		},
		0
	);

	const checksOutstandingSelectedTotal = selectedChecksArray.reduce(
		(acc, cur) => {
			const check = outstandingChecks.find(
				({ recnum }) => recnum === Number(cur)
			);

			return acc + (check?.amount ?? 0);
		},
		0
	);

	const checksClearedSelectedCount = selectedChecksArray.reduce((acc, cur) => {
		const exists = clearedChecks.some(({ recnum }) => recnum === Number(cur));
		if (exists) {
			return acc + 1;
		}
		return acc;
	}, 0);

	const checksClearedSelectedTotal = selectedChecksArray.reduce((acc, cur) => {
		const check = clearedChecks.find(({ recnum }) => recnum === Number(cur));

		return acc + (check?.amount ?? 0);
	}, 0);

	const depositsOutstandingSelectedCount = selectedDepositsArray.reduce(
		(acc, cur) => {
			const exists = outstandingDeposits.some(
				({ recnum }) => recnum === Number(cur)
			);
			if (exists) {
				return acc + 1;
			}
			return acc;
		},
		0
	);

	const depositsClearedSelectedCount = selectedDepositsArray.reduce(
		(acc, cur) => {
			const exists = clearedDeposits.some(
				({ recnum }) => recnum === Number(cur)
			);
			if (exists) {
				return acc + 1;
			}
			return acc;
		},
		0
	);

	const depositsOutstandingSelectedTotal = selectedDepositsArray.reduce(
		(acc, cur) => {
			const deposit = outstandingDeposits.find(
				({ recnum }) => recnum === Number(cur)
			);

			return acc + (deposit?.amount ?? 0);
		},
		0
	);

	const depositsClearedSelectedTotal = selectedDepositsArray.reduce(
		(acc, cur) => {
			const deposit = clearedDeposits.find(
				({ recnum }) => recnum === Number(cur)
			);

			return acc + (deposit?.amount ?? 0);
		},
		0
	);

	return (
		<div className="tw-grid tw-grid-cols-[2fr_1fr] tw-bg-[#faf9f7] tw-p-4 tw-border tw-border-[#dedcd7] tw-rounded-md">
			<div className="tw-grid tw-grid-cols-[1fr_1px_1fr_1px_1fr] tw-gap-8 tw-justify-start">
				<CheckbookReconcileStatusItem
					heading="Total Outstanding"
					checksCounter={checksOutstandingCount}
					checksTotal={totals?.outstandingChecks ?? 0}
					depositsCounter={depositsOutstandingCount}
					depositsTotal={totals?.depositsInTransit ?? 0}
				/>
				<div className="tw-border tw-h-auto tw-border-[#dedcd7]"></div>
				<CheckbookReconcileStatusItem
					heading="Total Cleared"
					checksCounter={totals?.checkClearedCount ?? 0}
					checksTotal={totals?.totalClearedChecks ?? 0}
					depositsCounter={totals?.depositsClearedCount ?? 0}
					depositsTotal={totals?.totalClearedDeposits ?? 0}
				/>
				<div className="tw-border tw-h-auto tw-border-[#dedcd7]"></div>
				{tab === 'outstanding' ? (
					<CheckbookReconcileStatusItem
						heading="Total Selected"
						checksCounter={checksOutstandingSelectedCount}
						checksTotal={checksOutstandingSelectedTotal}
						depositsCounter={depositsOutstandingSelectedCount}
						depositsTotal={depositsOutstandingSelectedTotal}
					/>
				) : (
					<CheckbookReconcileStatusItem
						heading="Total Selected"
						checksCounter={checksClearedSelectedCount}
						checksTotal={checksClearedSelectedTotal}
						depositsCounter={depositsClearedSelectedCount}
						depositsTotal={depositsClearedSelectedTotal}
					/>
				)}
			</div>

			<div className="tw-flex tw-items-center tw-justify-end tw-gap-2">
				<Button
					variant="ivory"
					className={cn('btn-icon btn-action', {
						'bg-primary-ash text-white': isSearchEnabled,
					})}
					onClick={handleSearchClick}
				>
					<i className="ri-search-line"></i> Search
				</Button>
				<Feature name={FeatureFlags.CheckbookFinanceCharges}>
					<Button
						variant="ivory"
						type="button"
						onClick={openFinanceChargesModal}
					>
						Finance Charges
					</Button>
				</Feature>
				{tab === 'outstanding' ? (
					<Button variant="primary" onClick={handleOnMarkCleared}>
						Mark Cleared
					</Button>
				) : (
					<Button variant="primary" onClick={handleOnMarkUncleared}>
						Mark Uncleared
					</Button>
				)}
			</div>
		</div>
	);
};

CheckbookReconcileStatus.displayName = 'CheckbookReconcileStatus';

export { CheckbookReconcileStatus };
