import React from 'react';
import { Modal, Spinner, Table } from 'react-bootstrap';
import {
	formatDate,
	formatFiscalMonth,
	formatNumber,
	formatUserDate,
} from './utils';
import { useGetCheckbookDetailCheck } from 'api/hooks/useGetCheckbookDetailCheck';
import { CHECK_TYPES_LABELS } from './constants';

interface ICheckbookDetailCheckProps {
	checknum?: string | null;
	supplier?: string | null;
	userdate?: string | null;
	account?: string | null;
}

const CheckbookDetailCheck = ({
	checknum,
	supplier,
	userdate,
	account,
}: ICheckbookDetailCheckProps) => {
	const { data: checkbookDetailChecks = [], isLoading } =
		useGetCheckbookDetailCheck({
			account,
			checknum,
			supplier,
			userdate: formatUserDate(userdate),
		});

	const [checkbookDetailCheck] = checkbookDetailChecks;
	return (
		<>
			<Modal.Header>
				<Modal.Title as="h5">Check Detail [Check #${checknum}]</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{isLoading ? (
					<div className="tw-flex tw-items-center tw-justify-center">
						<Spinner variant="success" />
					</div>
				) : (
					<div className="tw-overflow-x-auto">
						<Table striped bordered>
							<thead>
								<tr>
									<th>TX #</th>
									<th>Type</th>
									<th>Invoice No.</th>
									<th>Invoice Date</th>
									<th>Fiscal Month</th>
									<th>Description</th>
									<th>PO No.</th>
									<th>Amount</th>
									<th>Discount</th>
								</tr>
							</thead>
							<tbody>
								{checkbookDetailCheck && (
									<tr>
										<td>{checkbookDetailCheck.txnum}</td>
										<td>
											{typeof checkbookDetailCheck.type === 'number'
												? CHECK_TYPES_LABELS[
														checkbookDetailCheck.type as keyof typeof CHECK_TYPES_LABELS
												  ]
												: checkbookDetailCheck.type}
										</td>
										<td>{checkbookDetailCheck.sinvnum}</td>
										<td>{formatDate(checkbookDetailCheck.sinvdt ?? '')}</td>
										<td>
											{formatFiscalMonth(
												checkbookDetailCheck.fiscalmonth ?? ''
											)}
										</td>
										<td>{checkbookDetailCheck.txdesc}</td>
										<td>{checkbookDetailCheck.pon}</td>
										<td className="tw-text-right">
											{formatNumber(checkbookDetailCheck.amount ?? 0)}
										</td>
										<td className="tw-text-right">
											{formatNumber(checkbookDetailCheck.discountamount ?? 0)}
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</div>
				)}
			</Modal.Body>
		</>
	);
};

CheckbookDetailCheck.displayName = 'CheckbookDetailCheck';

export { CheckbookDetailCheck };
