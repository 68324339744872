import React from 'react';
import { Col, Row, Form, Button, Table } from 'react-bootstrap';
import Select from 'react-select';
import { WithRouter } from '../../../../helpers/Router';
import MyDropzone from '../../../components/MyDropzone';
import WysiwygEditor from '../../../components/WysiwygEditor';
import ThreadComponent from '../../ThreadComponent';

class CatalogItemComponentAddInfo extends ThreadComponent {
	constructor(props) {
		super(props);
		this.state = {
			selectData: [
				{ value: 'option-1', label: 'Option 1' },
				{ value: 'option-2', label: 'Option 2' },
				{ value: 'option-3', label: 'Option 3' },
			],
			additionalRows: 1,
		};
	}

	updateRows = () => {
		this.setState((prev) => {
			prev.additionalRows = prev.additionalRows + 1;
			return prev;
		});
	};

	render() {
		return (
			<>
				<Row xs={1} lg={2} className="mb-4">
					<Col className="mb-3 mb-lg-0">
						<Form.Group>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Name</Form.Label>
								</Col>
								<Col>
									<Form.Control type="text" placeholder="Name" />
								</Col>
							</Row>

							<Row className="mb-4">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0 pt-2">Description</Form.Label>
								</Col>
								<Col>
									<WysiwygEditor></WysiwygEditor>
								</Col>
							</Row>

							<Row className="align-items-center mb-4">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Type</Form.Label>
								</Col>
								<Col lg={5}>
									<Select
										options={this.state.selectData}
										className="react-select"
										placeholder="Please select"
									/>
								</Col>
								<Col>
									<div className="d-grid">
										<Button variant="primary" size="md">
											Get from Inventory..
										</Button>
									</div>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Vendor</Form.Label>
								</Col>
								<Col>
									<Select
										options={this.state.selectData}
										className="react-select"
										placeholder="Please select"
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Vendor Deposit %</Form.Label>
								</Col>
								<Col>
									<Row>
										<Col>
											<Form.Control type="text" placeholder="Please enter" />
										</Col>
										<Col></Col>
									</Row>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Ship To</Form.Label>
								</Col>
								<Col>
									<Select
										options={this.state.selectData}
										className="react-select"
										placeholder="Please select"
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0"></Col>
								<Col lg={5}>
									<Form.Check
										inline
										label="Buying Terms"
										name="group2"
										type="checkbox"
										id={`inline-radio-2`}
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">% Type</Form.Label>
								</Col>
								<Col>
									<Row>
										<Col>
											<Select
												options={this.state.selectData}
												className="react-select"
												placeholder="Please select"
											/>
										</Col>
										<Col>
											<Form.Control type="text" placeholder="Please enter" />
										</Col>
									</Row>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Mfg. Cat.</Form.Label>
								</Col>
								<Col>
									<Row>
										<Col>
											<Form.Control type="text" placeholder="Please enter" />
										</Col>
										<Col></Col>
									</Row>
								</Col>
							</Row>
						</Form.Group>
					</Col>
					<Col>
						<MyDropzone></MyDropzone>
					</Col>
				</Row>

				<div className="bg-secondary-grey ps-4 roundedx-4 mb-4">
					<Row xs={1} lg={2} className="py-4">
						<Col>
							<h6 className="fw-bold mb-0">Cost / Pricing</h6>
						</Col>
					</Row>
				</div>

				<div className="mb-4">
					<div className="table-gradient">
						<Table responsive borderless>
							<thead>
								<tr>
									<th className="mw-100px">Unit Cost</th>
									<th></th>
									<th className="mw-100px">Qty</th>
									<th className="mw-100px">Units</th>
									<th></th>
									<th className="mw-100px">Extended Cost</th>
									<th></th>
									<th className="mw-100px">Markup</th>
									<th></th>
									<th className="mw-100px">Sell Price</th>
									<th></th>
									<th className="mw-100px"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<Form.Control type="text" placeholder="0.00" size="sm" />
									</td>
									<td className="align-middle text-secondary-ash px-1">
										<i className="ri-close-line ri-lg"></i>
									</td>
									<td>
										<Form.Control type="text" placeholder="0.00" size="sm" />
									</td>
									<td>
										<Form.Select size="sm" placeholder="Select">
											<option></option>
											<option>Unit</option>
										</Form.Select>
									</td>
									<td className="align-middle text-secondary-ash px-1">
										<span className="fsx-24">=</span>
									</td>
									<td>
										<Form.Control type="text" placeholder="0.00" size="sm" />
									</td>
									<td className="align-middle text-secondary-ash px-1">
										<i className="ri-add-line ri-lg"></i>
									</td>
									<td>
										<Form.Control type="text" placeholder="0.00" size="sm" />
									</td>
									<td className="align-middle text-secondary-ash px-1">
										<span className="fsx-24">=</span>
									</td>
									<td>
										<Form.Control type="text" placeholder="0.00" size="sm" />
									</td>
									<td style={{ verticalAlign: 'middle' }}>
										<i className="ri-lock-fill"></i>
									</td>
									<td style={{ verticalAlign: 'middle' }}>
										<Form.Check
											inline
											label="Taxable"
											name="group4"
											type="checkbox"
											id={`inline-radio-4`}
										/>
									</td>
								</tr>
							</tbody>
						</Table>
					</div>
				</div>

				<div className="bg-secondary-grey ps-4 roundedx-4 mb-4">
					<Row xs={1} lg={2} className="py-4">
						<Col>
							<h6 className="fw-bold mb-0">
								Freight / Design Fee / Additional Charges
							</h6>
						</Col>
					</Row>
				</div>

				<Row className="mb-4">
					<Col lg={12}>
						<div className="table-gradient">
							<Table responsive borderless>
								<thead>
									<tr>
										<th className="mw-100px"></th>
										<th className="mw-100px">Cost</th>
										<th className="mw-100px">Markup %</th>
										<th className="mw-100px">Price</th>
										<th className="mw-150px"></th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<Form.Control type="text" placeholder="Please enter" />
										</td>
										<td>
											<Form.Control type="text" placeholder="Please enter" />
										</td>
										<td>
											<Form.Control type="text" placeholder="Please enter" />
										</td>
										<td>
											<Form.Control type="text" placeholder="Please enter" />
										</td>
										<td style={{ verticalAlign: 'middle' }}>
											<Form.Check
												inline
												label="Taxable"
												name="group4"
												type="checkbox"
												id={`inline-radio-4`}
											/>
										</td>
										<td></td>
									</tr>
									{(() => {
										let list = [];
										for (let i = 0; i < this.state.additionalRows; i++) {
											list.push(
												<tr>
													<td>
														<Form.Control
															type="text"
															placeholder="Please enter"
														/>
													</td>
													<td>
														<Form.Control
															type="text"
															placeholder="Please enter"
														/>
													</td>
													<td>
														<Form.Control
															type="text"
															placeholder="Please enter"
														/>
													</td>
													<td>
														<Form.Control
															type="text"
															placeholder="Please enter"
														/>
													</td>
													<td
														style={{
															verticalAlign: 'middle',
														}}
													>
														<Form.Check
															inline
															label="Taxable"
															name="group4"
															type="checkbox"
															id={`inline-radio-4`}
														/>
													</td>
													<td>
														<Button
															variant="primary"
															size="md"
															onClick={this.updateRows}
														>
															<i className="ri-add-fill"></i> Add
														</Button>
													</td>
												</tr>
											);
										}

										return list;
									})()}
								</tbody>
							</Table>
						</div>
					</Col>
				</Row>
			</>
		);
	}
}

export default WithRouter(CatalogItemComponentAddInfo);
