import { apiClient } from 'api/apiClient';

const USERS_ACCOUNTS_RESOURCE = '/useraccounts';
const USERS_RESOURCE = '/users';
const FILTER = '?$filter=password eq ';

export interface IUserAccount {
	password: string;
	username: string;
	usercode: number;
	attributes: string;
	lastlogintime: string;
	lastloginlicfilename: string | null;
	firstlogintime: string;
	admin: boolean;
	usertitle: string | null;
	useremail: string | null;
	defemailtext: string | null;
	emp: string;
	disabled: boolean;
	smtpserver: string | null;
	smtpport: number | null;
	smtpusername: string | null;
	smtppassword: string | null;
	smtpssl: boolean;
	smtpspa: boolean;
	ssmaTimeStamp: string;
	authorizationTemplateType: string;
	userId: number;
	employeeId: number;
	id: number;
}

export interface ICurrentUser {
	clientUserId: number;
	username: string;
	clientId: number;
	clientSerialCode: string;
	clientName: string;
	clientIsTrial: boolean;
	clientTrialExpired: boolean;
	clientAllowInWeb: boolean;
	clientWebOnly: boolean;
	clientClosed: boolean;
	enabled: boolean;
	email: string;
	isAdmin: boolean;
	status: string;
	fullName: string;
	deactivated: boolean;
	isSuperAdmin: boolean;
	superAdminUser: string;
	impersonationMode: boolean;
	isOk: boolean;
	resourceNotAuhtorized: boolean;
	resourceNotFound: boolean;
	resourceInvalidPayload: boolean;
	resourceForbidden: boolean;
	userError: null;
	internalError: null;
	errorCode: null;
}

export const getUserAccount = async (
	userName: string
): Promise<IUserAccount[]> => {
	const { data } = await apiClient.get(
		`${USERS_ACCOUNTS_RESOURCE}${FILTER}'${userName}'`
	);
	return data;
};

export const getCurrentUser = async (): Promise<ICurrentUser> => {
	const { data } = await apiClient.get(`${USERS_RESOURCE}/current`);
	return data;
};

export interface IDefaultInstanceParams {
	userCode: string;
	instanceId: string;
}

export const patchDefaultInstance = async ({
	userCode,
	instanceId,
}: IDefaultInstanceParams) => {
	const { data } = await apiClient.patch(
		`${USERS_RESOURCE}/default-instance/${userCode}/${instanceId}`,
		{}
	);
	return data;
};

export interface IUserInstance {
	instanceId: string;
	instanceName: string | null;
	isDemoData?: boolean | null;
	defaultInstance?: boolean | null;
}

export const getInstances = async (
	userName: string
): Promise<IUserInstance[]> => {
	const { data } = await apiClient.get(
		`${USERS_RESOURCE}/${userName}/instances`
	);
	return data.instances;
};
