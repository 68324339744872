import { apiClient } from 'api/apiClient';

const RESOURCE = '/financecharges';

export interface IGetFinanceChargesParams {
	isCheckbook: boolean;
	txNum?: number;
	statementEndingDate?: string;
	account: string;
}

export interface IPostFinanceChargesData {
	isCheckbook: boolean;
	account: string;
	statementEndingDate: string;
	financeChargesList: IJournalEntry[];
}

export interface IPostVoidFinanceChargesData {
	transactionNumber: string;
	account: string;
	statementDate: string;
	isCheckbook: boolean;
}

export interface IJournalEntry {
	account: string;
	accountName?: string;
	camt?: number;
	damt?: number;
}

export interface IFinanceCharge {
	description: string;
	temporaryJournalEntryDetailsList: IJournalEntry[];
	totalService: number;
	txNum: number;
}

export const getFinanceCharges = async (
	params: IGetFinanceChargesParams
): Promise<IFinanceCharge> => {
	const { data } = await apiClient.get(`${RESOURCE}`, {
		params,
	});

	return data;
};

export const postFinanceCharges = async (
	data: IPostFinanceChargesData
): Promise<void> => {
	await apiClient.post(`${RESOURCE}`, data);
};

export const postVoidFinanceCharges = async (
	data: IPostVoidFinanceChargesData
): Promise<void> => {
	await apiClient.post(`/temporaryjournalentries/void`, data);
};
