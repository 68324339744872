import React, { useEffect, useRef } from 'react';
import BaseButton from '../Buttons/BaseButton';
import { useNavigate } from 'react-router-dom';

interface ISignUpPresentationProps {
	videoSrc: string;
	showButton: boolean;
}

const SignUpPresentation = ({
	videoSrc,
	showButton,
}: ISignUpPresentationProps) => {
	const iframeRef = useRef<HTMLIFrameElement>(null);
	const navigate = useNavigate();

	const handleIframeMessage = () => {
		const iframe = iframeRef.current;

		if (iframe && iframe.contentWindow) {
			iframe.contentWindow.postMessage({ action: 'play' }, videoSrc);
		}
	};

	useEffect(() => {
		window.heap.track('Sign Up', {
			Step: 'Video Displaying',
		});
	}, []);

	return (
		<div className="tw-mt-[100px] tw-flex tw-flex-col tw-h-full">
			<div className="tw-text-white tw-text-center md:tw-max-w-[65%] tw-m-auto tw-mb-2">
				<h5>Thank you for requesting a free trial!</h5>
				<h5>Here’s what to expect next:</h5>
				<ul className="tw-list-disc tw-text-base tw-list-inside">
					<li>Trial setup may take a minute or two.</li>
					<li>
						A product specialist will reach out to help answer any questions you
						have.
					</li>
				</ul>
				<span className="tw-text-base">
					While we work on setting up your account, you can explore the key
					features of Design Manager in our interactive demo below. You’ll learn
					about clipping products, creating proposals and generating reports.
				</span>
			</div>
			<div className="tw-z-[9999] tw-h-full">
				<iframe
					src={`${videoSrc}?hideMenu=true`}
					title="Welcome Video"
					allow="autoplay"
					allowFullScreen
					onLoad={handleIframeMessage}
					className="tw-border-none  tw-w-[90%] tw-h-[45%] md:tw-w-[55%] md:tw-h-[55%] tw-justify-self-center"
					ref={iframeRef}
				/>
				{showButton && (
					<BaseButton
						onClick={() => {
							window.heap.clearEventProperties();
							window.heap.addEventProperties({
								'Sign Up': 'true',
								Step: 'Completed / Display Dashboard',
							});

							navigate('/');
						}}
						text="Skip > > >"
						buttonClass="tw-text-black tw-border tw-border-white tw-rounded-lg tw-px-4 tw-py-2 tw-font-medium tw-text-sm hover:tw-bg-white-600 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-white-500  tw-bg-white tw-p-4 tw-rounded-lg tw-shadow-lg tw-place-self-end tw-block tw-mr-[5%]  md:tw-mr-[22.5%] mt-1"
					/>
				)}
			</div>
		</div>
	);
};

SignUpPresentation.displayName = 'SignUpPresentation';

export { SignUpPresentation };
