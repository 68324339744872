import React from 'react';
import { Modal, Spinner, Table } from 'react-bootstrap';
import { formatNumber } from './utils';
import { useGetCheckbookDetailJournalEntry } from 'api/hooks/useGetCheckbookDetailJournalEntry';

interface ICheckbookDetailJournalEntryProps {
	txnum?: number | null;
}

const CheckbookDetailJournalEntry = ({
	txnum,
}: ICheckbookDetailJournalEntryProps) => {
	const { data: journalEntryCheckbookDetail = [], isLoading } =
		useGetCheckbookDetailJournalEntry({
			txnum,
		});

	return (
		<>
			<Modal.Header>
				<Modal.Title as="h5">Journal Entry Detail [TX #{txnum}]</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{isLoading ? (
					<div className="tw-flex tw-items-center tw-justify-center">
						<Spinner variant="success" />
					</div>
				) : (
					<div className="tw-overflow-x-auto">
						<Table striped bordered>
							<thead>
								<tr>
									<th>Account No.</th>
									<th>Account Name</th>
									<th>Debit</th>
									<th>Credit</th>
								</tr>
							</thead>
							<tbody>
								{journalEntryCheckbookDetail.map((item, index) => {
									const { account, accountn, amount: value } = item;
									const amount = value ?? 0;
									const debit = amount > 0 ? formatNumber(amount) : '';
									const credit =
										amount < 0 ? formatNumber(Math.abs(amount)) : '';

									return (
										<tr key={`${account}-${index}`}>
											<td>{account}</td>
											<td>{accountn}</td>
											<td className="tw-text-right">{debit}</td>
											<td className="tw-text-right">{credit}</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
				)}
			</Modal.Body>
		</>
	);
};

CheckbookDetailJournalEntry.displayName = 'CheckbookDetailJournalEntry';

export { CheckbookDetailJournalEntry };
