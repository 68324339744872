import { useQuery } from '@tanstack/react-query';
import type { UseQueryOptions } from '@tanstack/react-query';

import { getFinanceCharges } from 'api/resources/financeCharges';
import type {
	IFinanceCharge,
	IGetFinanceChargesParams,
} from 'api/resources/financeCharges';
import { ECacheKeys } from 'cache/CacheKeys';

const initialNumber = -999;

export const useGetFinanceCharges = (
	params: IGetFinanceChargesParams,
	options?: UseQueryOptions<IFinanceCharge>
) => {
	const query = useQuery<IFinanceCharge>({
		queryKey: [
			ECacheKeys.FinanceCharges,
			params.statementEndingDate,
			params.account,
			params.isCheckbook,
		],
		queryFn: () => getFinanceCharges(params),
		...{
			refetchInterval: false,
			refetchOnWindowFocus: false,
			...options,
			initialData: {
				description: '',
				temporaryJournalEntryDetailsList: [],
				totalService: initialNumber,
				txNum: initialNumber,
			},
		},
	});

	return query;
};
