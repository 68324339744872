const DEFAULT_PAGE_NUMBER = 1;

const getValidPageNumber = (pageNumber: string) => {
	const page = parseInt(pageNumber, 10);
	if (!Number.isInteger(page) || page < 1) {
		return DEFAULT_PAGE_NUMBER;
	}
	return page;
};

const updateQueryParam = (key: string, value: string) => {
	const url = new URL(window.location.href);
	url.searchParams.set(key, value);
	window.history.replaceState({}, '', url);
};

const getQueryParam = (name: string) => {
	const params = new URLSearchParams(window.location.search);
	return params.get(name);
};

export { getValidPageNumber, getQueryParam, updateQueryParam };
