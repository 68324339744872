import { apiClient } from 'api/apiClient';

const RESOURCE = '/GeneralLedgerTransactions';

export interface IGetCheckbookDetailCheckParams {
	checknum?: string | null;
	supplier?: string | null;
	userdate?: string | null;
	account?: string | null;
}

export interface IGetCheckbookDetailDepositParams {
	userdate: string;
	account: string;
	depositslip: string;
}

export interface IGetCheckbookDetailDepositPaydistParams {
	crgroupnum: string;
}

export interface IGetCheckbookDetailJournalEntryParams {
	txnum?: number | null;
}

export interface IPostCheckbookVoidParams {
	checkNum?: string | null;
	checkDt?: string | null;
	cashAcctNum?: string | null;
	voidFMo?: string | null;
	isUndoVoid?: boolean;
	isCleared?: boolean;
}

export interface ICheckbookDetailCheck {
	type: number | null;
	txnum: number | null;
	fiscalmonth: string | null;
	discountamount: number | null;
	amount: number | null;
	pon: string | null;
	txdesc: string | null;
	sinvnum: string | null;
	sinvdt: string | null;
	supplier: string | null;
	checknum: string | null;
	account: string | null;
	userdate: string | null;
}

export interface ICheckbookDetailDeposit {
	crgroupnum: number;
	amount: number;
	client: string;
	clientn: string;
	orgname: string;
	checknum: string;
	fiscalmonth: string;
	userdate: string;
	account: string;
	depositslip: string;
}

export interface ICheckbookDetailDepositPaydist {
	txnum: number;
	type: number;
	amount: number;
	proj: string;
	projn: string;
	txdesc: string;
	crgroupnum: number;
}

export interface ICheckbookDetailJournalEntry {
	account?: string | null;
	accountn?: string | null;
	amount?: number | null;
	txnum?: number | null;
}

export interface ICheckbookVoid {
	txnum: number;
}

export const getCheckbookDetailCheck = async ({
	checknum,
	supplier,
	userdate,
	account,
}: IGetCheckbookDetailCheckParams): Promise<ICheckbookDetailCheck[]> => {
	const { data } = await apiClient.get(
		`${RESOURCE}/payable/checkbook/detail/check?$filter=checknum eq '${checknum}' and supplier eq '${supplier}' and userdate eq ${userdate} and account eq '${account}'`
	);
	return data;
};

export const getCheckbookDetailDeposit = async ({
	userdate,
	account,
	depositslip,
}: IGetCheckbookDetailDepositParams): Promise<ICheckbookDetailDeposit[]> => {
	const { data } = await apiClient.get(
		`${RESOURCE}/payable/checkbook/detail/deposit?$filter=userdate eq ${userdate} and account eq '${account}' and depositslip eq '${depositslip}'`
	);
	return data;
};

export const getCheckbookDetailDepositPaydist = async ({
	crgroupnum,
}: IGetCheckbookDetailDepositPaydistParams): Promise<
	ICheckbookDetailDepositPaydist[]
> => {
	const { data } = await apiClient.get(
		`${RESOURCE}/payable/checkbook/detail/deposit/paydist?$filter=crgroupnum eq ${crgroupnum}`
	);
	return data;
};

export const getCheckbookDetailJournalEntry = async ({
	txnum,
}: IGetCheckbookDetailJournalEntryParams): Promise<
	ICheckbookDetailJournalEntry[]
> => {
	const { data } = await apiClient.get(
		`${RESOURCE}/payable/checkbook/detail/je?$filter=txnum eq ${txnum}`
	);
	return data;
};

export const postCheckbookVoid = async (
	params: IPostCheckbookVoidParams
): Promise<ICheckbookVoid> => {
	const { data } = await apiClient.post(
		`${RESOURCE}/payable/checkbook/void`,
		params
	);
	return data;
};
