import { CellContext, Row } from '@tanstack/react-table';
import type {
	ITemporaryReconciliationCheck,
	ITemporaryReconciliationDeposit,
} from 'api/resources/temporaryReconciliations';
import BaseButton from 'legacy/app/components/Buttons/BaseButton';
import { FeatureFlags } from 'legacy/app/enums/featureFlags/featureFlags';
import { formatDate } from 'legacy/utilities/formatDate';
import { useFeature } from 'use-feature';

type TTemporaryReconciliation =
	| ITemporaryReconciliationCheck
	| ITemporaryReconciliationDeposit;

interface ICheckbookReconcileTableLinkCellProps {
	info: CellContext<TTemporaryReconciliation, unknown>;
	handleOnClick: (row?: Row<TTemporaryReconciliation>) => void;
}

const CheckbookReconcileLinkCell = ({
	info,
	handleOnClick,
}: ICheckbookReconcileTableLinkCellProps) => {
	const isFeatureEnabled = useFeature(FeatureFlags.CheckbookDetailsModal);

	const onClick = () => {
		handleOnClick(info.row);
	};

	return isFeatureEnabled ? (
		<BaseButton
			onClick={onClick}
			text={formatDate(info.row.original.userdate ?? '')}
			buttonClass="tw-underline"
		/>
	) : (
		formatDate(info.row.original.userdate ?? '')
	);
};

CheckbookReconcileLinkCell.displayName = 'CheckbookReconcileLinkCell';

export { CheckbookReconcileLinkCell };
