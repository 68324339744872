import { useGetCompanyRoles } from 'api/hooks/useGetCompanyRoles';
import { displayAlertError } from 'legacy/utilities/Response';
import React, { useEffect } from 'react';
import Select, { SingleValue } from 'react-select';

export const UserRoleSelect = ({
	onChange,
	selected,
}: {
	selected: number | null;
	onChange: (newValue: SingleValue<{ label: string; value: number }>) => void;
}) => {
	const {
		data: companyRoles,
		isLoading: isLoadingCompanyRoles,
		error,
	} = useGetCompanyRoles();

	useEffect(() => {
		if (error) {
			displayAlertError(
				'There was an error fetching the company roles, please try again'
			);
		}
	}, [error]);

	const options =
		companyRoles?.map((option) => {
			return {
				label: option.value,
				value: option.key,
			};
		}) || [];

	const value =
		selected !== null
			? options.find((option) => option.value === selected)
			: null;

	return (
		<Select
			inputId="roles-select"
			isLoading={isLoadingCompanyRoles}
			options={options}
			value={value}
			onChange={onChange}
		/>
	);
};

UserRoleSelect.displayName = 'UserRoleSelect';
