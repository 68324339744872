import { useCallback, useEffect, useState } from 'react';
import { useGetRunReports } from 'api/hooks/useGetRunReports';
import { useSession } from 'legacy/app/context/session';
import { getCookie } from 'legacy/utilities/Auth';
import {
	IGetRunReportResponse,
	IPostRunReportsParams,
} from 'api/resources/runReports';
import {
	displayAlertError,
	displayAlertLoader,
	hideAlertLoader,
} from 'legacy/utilities/Response';
import { usePostRunReports } from 'api/hooks/usePostRunReports';
import { usePostPublicMappings } from 'api/hooks/usePostPublicMappings';

const RETRY_INTERVAL_MS = 1000;

interface ICheckbookDepositReportParams {
	accountNumber: string;
	slipNumber: string;
	depositDate: string;
}

enum EReportStatus {
	Complete = 'Complete',
	Error = 'Error',
}

const useCheckbookDepositReport = () => {
	const { userId } = useSession();
	const instanceId = getCookie('dmInstanceId');
	const [reportId, setReportId] = useState<string | null>(null);
	const { mutateAsync: postRunReports } = usePostRunReports();
	const { mutateAsync: postPublicMappings } = usePostPublicMappings();

	const { data: reportArray, error } = useGetRunReports(reportId ?? '', {
		enabled: !!reportId,
		refetchIntervalInBackground: false,
		refetchInterval: (data) => {
			const [response] = data || [];
			return response?.status === EReportStatus.Complete ||
				response?.status === EReportStatus.Error ||
				response?.hasTimedOut
				? false
				: RETRY_INTERVAL_MS;
		},
	});

	const displayError = useCallback(
		() => displayAlertError('Could not generate the report. Try again later.'),
		[]
	);

	const handleOnGetReportSuccess = useCallback(
		async (runReportResponse: IGetRunReportResponse[]) => {
			const [firstReportResponse] = runReportResponse;
			const { status, completedFileId: fileId } = firstReportResponse;
			if (status === EReportStatus.Complete && fileId) {
				try {
					const { link } = await postPublicMappings({
						fileId,
						label: 'Checkbook Deposit Slip',
					});
					if (link) {
						window.open(link, '_blank');
					}
				} catch {
					displayError();
				} finally {
					hideAlertLoader();
				}
			}
		},
		[postPublicMappings, displayError]
	);

	useEffect(() => {
		const handleReport = async (data: IGetRunReportResponse[]) => {
			const [response] = data || [];
			if (response?.hasTimedOut || response?.status === EReportStatus.Error) {
				displayError();
				return;
			}

			await handleOnGetReportSuccess(data);
		};
		if (reportArray) {
			handleReport(reportArray);
		}
	}, [reportArray, handleOnGetReportSuccess, displayError]);

	useEffect(() => {
		if (error) {
			displayError();
		}
	}, [error, displayError]);

	const downloadCheckbookDepositReport = async ({
		accountNumber,
		slipNumber,
		depositDate,
	}: ICheckbookDepositReportParams) => {
		try {
			const payload: IPostRunReportsParams = {
				reportId: 1055,
				reportFormatId: 1137,
				reportObjectType: 'Report',
				instanceId,
				userId,
				objectId: null,
				runReportParameters: [
					{
						parameterId: 297,
						value: `{"cash_account": "${accountNumber}"}`,
					},
					{
						parameterId: 298,
						value: `{"text": "${slipNumber}"}`,
					},
					{
						parameterId: 299,
						value: `{"range1": "${depositDate}", "range2":"${depositDate}"}`,
					},
					{
						parameterId: 300,
						value: '{"text": ""}',
					},
				],
			};

			displayAlertLoader('Please wait for your report to be generated.');
			const { id } = await postRunReports(payload);
			if (id) {
				setReportId(id);
			}
		} catch {
			displayAlertError('Could not generate the report. Try again later.');
		}
	};

	return { downloadCheckbookDepositReport };
};

export { useCheckbookDepositReport };
