export const COOKIE_EXPIRATION = 7;

export function isLoggedIn(): any {
	const token = getCookie('dmAuthToken');
	const instanceId = getCookie('dmInstanceId');
	const usercode = getCookie('dmUsercode');

	const validToken = token !== '' ? isTokenValid(token) : false;
	return instanceId && validToken && usercode;
}

export function clearUserData(): any {
	deleteCookie('dmAuthToken');
	deleteCookie('dmInstanceId');
	deleteCookie('dmUsercode');
	deleteCookie('dmUsername');
}

export function isTokenValid(token: string): any {
	const parsedToken = parseJwt(token);
	return parsedToken.exp > parsedToken.auth_time ? true : false;
}

export function deleteCookie(name: string) {
	const expires = 'expires=Thu, 01 Jan 1970 00:00:01 GMT';
	document.cookie = name + '=;' + expires + ';path=/';
}

export function parseJwt(token: string): {
	nbf: number;
	exp: number;
	iss: string;
	aud: string[];
	client_id: string;
	sub: string;
	auth_time: number;
	idp: string;
	name: string;
	DmInstances: string;
	scope: string[];
	amr: string[];
	SuperadminUsername?: string;
	ImpersonationMode?: boolean;
} {
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
	);

	return JSON.parse(jsonPayload);
}

export function setCookie(cname: string, cvalue: string, exdays = 0) {
	let expires = '';
	if (exdays > 0) {
		const d = new Date();
		d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
		expires = ';expires=' + d.toUTCString();
	}
	document.cookie = `${cname}=${cvalue}${expires};path=/`;
}

export function getCookie(cname: string) {
	const name = cname + '=';
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

type TDmInstance = {
	Name: string;
	Id: string;
};

type TTokenPayload = {
	nbf: number;
	exp: number;
	iss: string;
	aud: string[];
	client_id: string;
	sub: string;
	auth_time: number;
	idp: string;
	name: string;
	DmInstances: TDmInstance[];
	scope: string[];
	amr: string[];
};

export const decodeToken = (token: string): TTokenPayload | null => {
	try {
		const payloadBase64 = token.split('.')[1];
		if (!payloadBase64) {
			throw new Error('Invalid token format');
		}
		const payloadJson = atob(payloadBase64);
		const parsedPayload = JSON.parse(payloadJson);

		if (
			parsedPayload.DmInstances &&
			typeof parsedPayload.DmInstances === 'string'
		) {
			try {
				parsedPayload.DmInstances = JSON.parse(parsedPayload.DmInstances);
			} catch {
				parsedPayload.DmInstances = [];
				throw new Error('Failed to parse DmInstances');
			}
		}

		return parsedPayload as TTokenPayload;
	} catch (error) {
		throw error instanceof Error ? error : new Error('Invalid token format');
	}
};
