import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Table, Col, Row, Form } from 'react-bootstrap';
import Select from 'react-select';
import ThreadComponent from '../../ThreadComponent';
import { WithRouter } from '../../../../helpers/Router';
import WysiwygEditor from '../../../components/WysiwygEditor';
import { Is } from '../../../../helpers/Util';
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import { mapRtfToDraft } from '../../../../utilities/Rtf';
import { removeSpaces } from '../../../../utilities/String';
import { isEmpty } from 'lodash';
import { ApiService } from 'legacy/lib/api/HttpService';
import { displayAlertError } from 'legacy/utilities/Response';
import Spinner from 'legacy/app/components/help/Spinner';

class ItemAddSpecification extends ThreadComponent {
	constructor(props) {
		super(props);
		this.updatedData = this.props.updatedData;
		this.api = new ApiService();
		this.state = {
			data: {},
			loading: false,
			selectedAttr: {},
			specDescHtml: '',
			fieldCount: this.updatedData?.fieldCount ?? 1,
			attributes: {},
		};

		this.htmlToRtfConverter = new HtmlToRtfBrowser();
	}

	async componentDidMount() {
		this.setState({
			loading: true,
		});
		let data = {
			...this.props.item,
			...this.props.data,
		};

		try {
			data = await this.addVendorsData(data);
			data = await this.addSpecificationTemplates(data);
			data = this.setData(data);
			this.setState({
				loading: false,
			});
		} catch (error) {
			this.setState({
				loading: false,
				error: true,
			});
			return displayAlertError(
				'There was an error getting all the information needed, please try again'
			);
		}
		let options = {
			specDescHtml: await mapRtfToDraft(
				this.updatedData?.specdescrtf ?? data?.specdescrtf ?? ''
			),
		};

		if (data.defaultTemplate?.value) {
			let selected = {};
			_.each(data.specificationTemplates, function (tmp) {
				if (tmp.speccode == data.defaultTemplate.value) {
					selected = tmp;
				}
			});

			options.selectedAttr = selected;
		}

		this.setState({
			...options,
			data: data,
		});
	}

	async addVendorsData(data) {
		// Vendors
		const vendors = await this.api.getVendors('?$filter=inactive eq false');
		data.defVendorValue = '';
		data.defVendorLabel = 'Please select';
		let vendorsList = [];
		for (let vendor of vendors) {
			vendorsList.push({
				value: vendor.vendor,
				label: vendor.vendorn + ` [${vendor.vendor}]`,
			});
			if (!Is.empty(data) && data.workroom && data.workroom === vendor.vendor) {
				data.defVendorValue = vendor.vendor;
				data.defVendorLabel = vendor.vendorn + ` [${vendor.vendor}]`;
			}
		}
		data.vendorsList = vendorsList;
		data.allVendors = vendors;
		return data;
	}

	async addStatusData(data) {
		// Status Data
		const statusCodes = await this.api.getStatusCodes('');

		data.defStatusValue = '';
		data.defStatusLabel = 'Specifying';

		let statusList = [];
		for (let status of statusCodes) {
			statusList.push({
				value: status.statusNumber,
				label: status.statusName,
			});

			if (
				!Is.empty(data) &&
				data.statusnumber &&
				data.statusnumber === status.statusNumber
			) {
				data.defStatusValue = status.statusNumber;
				data.defStatusLabel = status.statusName;
			}
		}

		data.statusList = statusList;

		return data;
	}

	async addSpecificationTemplates(data) {
		const templates = await this.api.getSpecificationTemplates('');
		data.specificationTemplates = templates;

		return data;
	}

	setData(data) {
		let selectTemplate = [];
		for (let tmp of data.specificationTemplates ?? []) {
			selectTemplate.push({
				value: tmp.speccode,
				label: tmp.speccodename,
			});

			if (!Is.empty(data) && tmp.speccode === data.speccode) {
				data.defaultTemplate = {
					value: tmp.speccode,
					label: tmp.speccodename,
				};
			}
		}

		data.allVendors = data.allVendors ?? [];

		for (let vendor of data.allVendors) {
			if (!Is.empty(data) && vendor.vendor === data.specmfg) {
				data.defaultMfg = {
					value: vendor.vendor,
					label: vendor.vendorn + ` [${vendor.vendor}]`,
				};
			}

			if (!Is.empty(data) && vendor.vendor === data.specsourcesup) {
				data.defaultSrc = {
					value: vendor.vendor,
					label: vendor.vendorn + ` [${vendor.vendor}]`,
				};
			}
		}

		data.templateList = selectTemplate;

		let fieldCount = 0;

		if (isEmpty(this.updatedData)) {
			for (let i = 1; i <= 10; i++) {
				if (
					removeSpaces(data['specd' + i]) ||
					removeSpaces(data['spect' + i])
				) {
					fieldCount++;
				}

				const specdval = data['specd' + i];
				const spectval = data['spect' + i];
				this.updatedData['specd' + i] = specdval;
				this.updatedData['spect' + i] = spectval;
			}

			this.props.onDataChange({ key: 'fieldCount', value: fieldCount });
			this.setState({
				fieldCount,
			});
		}

		return data;
	}

	handleChange = async (e, meta = {}) => {
		const _self = this;
		let key, value;
		if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.id;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}

		this.props.onDataChange({ key: key, value: value });

		if (key === 'specdesc') {
			const valueHtml = this.htmlToRtfConverter.convertHtmlToRtf(e.target.html);
			this.props.onDataChange({ key: `${key}rtf`, value: valueHtml });
		}

		if (key === 'speccode') {
			let selected = {};
			_.each(this.state.data.specificationTemplates, function (tmp) {
				if (tmp.speccode == value) {
					selected = tmp;
				}
			});

			let specFields = 0;
			let keys = Object.keys(selected);
			_.each(keys, function (idx) {
				if (idx != undefined && idx.indexOf('spect') === 0) {
					specFields++;
				}

				if (
					idx != undefined &&
					(idx.indexOf('spect') === 0 ||
						idx.indexOf('specd') === 0 ||
						idx == 'specmaterial')
				) {
					_self.props.onDataChange({ key: idx, value: selected[idx] });
				}
			});

			this.setState({
				selectedAttr: selected,
				fieldCount: specFields,
				specDescHtml: await mapRtfToDraft(selected.specdescrtf ?? ''),
			});
		}
	};

	defaultManufacturer() {
		const { data } = this.state;
		let manufacturer = data.defaultMfg;
		if (data.vendorsList && data.vendorsList.length > 0) {
			const selectedManufacturer = data.vendorsList.filter((manufacturer) => {
				return manufacturer?.value === this.updatedData.specmfg;
			})[0];
			if (selectedManufacturer) {
				manufacturer = selectedManufacturer;
			}
		}
		return manufacturer;
	}

	defaultSource() {
		const { data } = this.state;
		let source = data.defaultSrc;
		if (data.vendorsList && data.vendorsList.length > 0) {
			const selectedSource = data.vendorsList.filter((source) => {
				return source?.value === this.updatedData.specsourcesup;
			})[0];
			if (selectedSource) {
				source = selectedSource;
			}
		}
		return source;
	}

	defaultTemplate() {
		const { data } = this.state;
		let template = data.defaultTemplate;
		if (data.templateList && data.templateList.length > 0) {
			const selectedTemplate = data.templateList.filter((template) => {
				return template?.value === this.updatedData.speccode;
			})[0];
			if (selectedTemplate) {
				template = selectedTemplate;
			}
		}
		return template;
	}

	handleFieldAdd = () => {
		this.setState((prevState) => {
			const value = prevState.fieldCount + 1;
			this.props.onDataChange({ key: 'fieldCount', value });
			return {
				fieldCount: value,
			};
		});
	};

	handleFieldDelete = (i) => {
		let { fieldCount, data } = this.state;
		let ctr = 1;
		for (let i2 = 1; i2 <= 10; i2++) {
			if (
				i !== i2 &&
				(removeSpaces(this.updatedData['specd' + i2]) ||
					removeSpaces(this.updatedData['spect' + i2]))
			) {
				const specdval = this.updatedData['specd' + i2];
				const spectval = this.updatedData['spect' + i2];
				this.updatedData['specd' + ctr] = specdval;
				this.updatedData['spect' + ctr] = spectval;

				this.props.onDataChange({ key: 'specd' + ctr, value: specdval });
				this.props.onDataChange({ key: 'spect' + ctr, value: spectval });
				ctr++;
			}
		}

		for (; ctr <= 10; ctr++) {
			this.updatedData['specd' + ctr] = data['specd' + ctr] = '';
			this.updatedData['spect' + ctr] = data['spect' + ctr] = '';

			this.props.onDataChange({ key: 'specd' + ctr, value: '' });
			this.props.onDataChange({ key: 'spect' + ctr, value: '' });
		}

		fieldCount = fieldCount - 1;
		this.setState({
			data,
			fieldCount: fieldCount,
		});
		this.props.onDataChange({ key: 'fieldCount', value: fieldCount });
	};

	render() {
		const { data } = this.state;
		if (this.state.loading) {
			return <Spinner isChild />;
		}
		if (this.state.error) {
			return null;
		}
		return (
			<>
				<Form.Group>
					<Row xs={1} lg={2} className="py-4">
						<Col className="mb-3 mb-lg-0">
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Spec/Template No.</Form.Label>
								</Col>
								<Col lg={5}>
									<Select
										key={`${Math.floor(Math.random() * 1000)}-min`}
										options={data.templateList}
										name="speccode"
										value={this.defaultTemplate()}
										className="react-select"
										placeholder="Please select"
										onChange={this.handleChange}
									/>
								</Col>
								<Col>
									<Form.Check
										inline
										label="Material"
										name="group2"
										type="checkbox"
										id="specmaterial"
										defaultChecked={
											this.updatedData.specmaterial ??
											this.state.selectedAttr.specmaterial
										}
										onChange={this.handleChange}
									/>
								</Col>
							</Row>
							<Row className="align-items-center">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Plan No.</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Please enter"
										id="planno"
										defaultValue={this.updatedData.planno ?? data.planno}
										onChange={this.handleChange}
									/>
								</Col>
							</Row>
						</Col>
						<Col>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Manufacturer</Form.Label>
								</Col>
								<Col>
									<Select
										key={`${Math.floor(Math.random() * 1000)}-min`}
										options={data.vendorsList}
										defaultValue={this.defaultManufacturer()}
										className="react-select"
										placeholder="Please select"
										name="specmfg"
										onChange={this.handleChange}
									/>
								</Col>
							</Row>

							<Row className="align-items-center">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Source</Form.Label>
								</Col>
								<Col>
									<Select
										key={`${Math.floor(Math.random() * 1000)}-min`}
										options={data.vendorsList}
										defaultValue={this.defaultSource()}
										name="specsourcesup"
										className="react-select"
										placeholder="Please select"
										onChange={this.handleChange}
									/>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row xs={1} className="py-4">
						<Col lg={12} className="mb-2">
							<Form.Label className="mb-0">Instructions</Form.Label>
						</Col>
						<Col lg={8} className="mb-3 mb-lg-0">
							<WysiwygEditor
								id="specdesc"
								onChange={this.handleChange}
								name="specdesc"
							>
								{this.state.specDescHtml}
							</WysiwygEditor>
						</Col>
					</Row>

					<div className="bg-secondary-grey px-4 roundedx-4 mb-4">
						<Row xs={1} lg={2} className="py-3 align-items-center">
							<Col>
								<h6 className="fw-bold mb-0">Attributes</h6>
							</Col>
							<Col className="text-end">
								<Button
									variant="primary"
									size="md"
									onClick={this.handleFieldAdd}
									disabled={this.state.fieldCount >= 10}
								>
									<i className="ri-add-fill"></i> Add
								</Button>
							</Col>
						</Row>
					</div>

					<Row className="mb-4">
						<Col lg={12}>
							<div className="table-gradient">
								<Table responsive borderless>
									<thead>
										<tr>
											<th></th>
											<th className="mw-100px">Title</th>
											<th className="mw-100px">Description</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{Array.from(Array(this.state.fieldCount || 0).keys()).map(
											(key, i) => {
												return (
													<tr key={key}>
														<td></td>
														<td>
															<Form.Control
																type="text"
																placeholder="Please enter"
																id={'spect' + (i + 1)}
																value={
																	this.state['spect' + (i + 1)] ||
																	this.props.updatedData['spect' + (i + 1)]
																}
																name={'spect' + (i + 1)}
																onChange={(e) => {
																	this.setState({
																		['spect' + (i + 1)]: e.target.value,
																	});

																	this.props.onDataChange({
																		key: 'spect' + (i + 1),
																		value: e.target.value,
																	});
																}}
															/>
														</td>
														<td>
															<Form.Control
																type="text"
																placeholder="Please enter"
																id={'specd' + (i + 1)}
																value={
																	this.state['specd' + (i + 1)] ||
																	this.props.updatedData['specd' + (i + 1)]
																}
																name={'specd' + (i + 1)}
																onChange={(e) => {
																	this.setState({
																		['specd' + (i + 1)]: e.target.value,
																	});

																	this.props.onDataChange({
																		key: 'specd' + (i + 1),
																		value: e.target.value,
																	});
																}}
															/>
														</td>
														<td>
															<Button
																variant="light"
																className="btn-icon"
																size="md"
																onClick={() => {
																	this.handleFieldDelete(i + 1);
																}}
															>
																<i className="ri-subtract-line"></i>
																Remove
															</Button>
														</td>
													</tr>
												);
											}
										)}
									</tbody>
								</Table>
							</div>
						</Col>
					</Row>
				</Form.Group>
			</>
		);
	}
}

export default WithRouter(ItemAddSpecification);
