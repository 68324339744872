import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import {
	getCheckbookDetailJournalEntry,
	type IGetCheckbookDetailJournalEntryParams,
	type ICheckbookDetailJournalEntry,
} from 'api/resources/generalLedgerTransactions';
import { ECacheKeys } from 'cache/CacheKeys';

export const useGetCheckbookDetailJournalEntry = (
	params: IGetCheckbookDetailJournalEntryParams,
	options?: UseQueryOptions<ICheckbookDetailJournalEntry[]>
) => {
	const query = useQuery({
		queryKey: [
			ECacheKeys.GeneralLedgerTransactions,
			'CheckbookDetailJournalEntry',
			params.txnum,
		],
		queryFn: () => getCheckbookDetailJournalEntry(params),
		refetchInterval: false,
		refetchOnWindowFocus: false,
		...(options || {}),
	});

	return query;
};
