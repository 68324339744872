import { useCallback, useEffect } from 'react';
import { useFeature } from 'use-feature';

interface IFeatureFlagCheckerProps {
	feature: string;
	callback: (value: boolean) => void;
}

const FeatureFlagChecker = ({
	callback,
	feature,
}: IFeatureFlagCheckerProps) => {
	const enabled = useFeature(feature);

	const callbackFunc = useCallback(() => {
		callback(enabled);
	}, [enabled, callback]);

	useEffect(() => {
		callbackFunc();
	}, [callbackFunc]);

	return null;
};

FeatureFlagChecker.displayName = 'FeatureFlagChecker';

export { FeatureFlagChecker };
