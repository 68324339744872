import { apiClient } from 'api/apiClient';

export interface IAuthorizationTemplate {
	id: number;
	type: `${EAuthorizationType}`;
	attributes: string;
}

export enum EAuthorizationType {
	Admin = 'Admin',
	Custom = 'Custom',
	Designer = 'Designer',
	Purchaser = 'Purchaser',
	Bookkeeper = 'Bookkeeper',
	DesignAssistant = 'DesignAssistant',
}

const RESOURCE = '/AuthorizationTemplates';

export const getAuthorizationTemplates = async (): Promise<
	IAuthorizationTemplate[]
> => {
	const { data } = await apiClient.get(RESOURCE);
	return data.authorizationTemplates;
};
