import { TFormValues } from 'legacy/templates/modules/sign-up/types/TFormValues';

export const employeeOptions = [
	{ value: 1, label: 'Just Me' },
	{ value: 5, label: '2-5' },
	{ value: 10, label: '6-10' },
	{ value: 11, label: '11+' },
];

export const requiredFields: Array<keyof TFormValues> = [
	'firstName',
	'lastName',
	'companyName',
	'email',
	'username',
	'password',
	'phone',
	'employees',
	'companyRole',
];
