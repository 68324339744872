import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { CheckbookDetailCheck } from './CheckbookDetailCheck';
import { CheckbookDetailJournalEntry } from './CheckbookDetailJournalEntry';
import { CheckbookDetailDeposit } from './CheckbookDetailDeposit';
import { ECheckbookEntryType, ECheckbookType } from './enums';
import BaseButton from 'legacy/app/components/Buttons/BaseButton';
import { CheckbookVoidModal } from './CheckbookVoidModal';
import cn from 'classnames';
import CreateDepositSlipListExistingModal from 'legacy/templates/modules/modal/CreateDepositSlipListExistingModal';
import { useCheckbookDepositReport } from '../hooks/useCheckbookDepositReport';
import { formatUserDate } from './utils';

interface ICheckbook {
	type?: number | null;
	entrytype?: number | null;
	checknum?: string | null;
	supplier?: string | null;
	userdate?: string | null;
	account?: string | null;
	accountLabel?: string | null;
	depositslip?: string | null;
	txnum?: number | null;
	isVoid?: boolean | null;
}

interface ICheckbookDetailsModalProps {
	context: 'checkbook' | 'reconciliation';
	checkbook: ICheckbook;
	onClose: () => void;
	onVoidConfirm?: () => void;
}

const CheckbookDetailsModal = ({
	context,
	checkbook,
	onClose,
	onVoidConfirm,
}: ICheckbookDetailsModalProps) => {
	const {
		account,
		accountLabel,
		checknum,
		depositslip,
		entrytype,
		supplier,
		txnum,
		userdate,
		type,
		isVoid = null,
	} = checkbook;
	const [showVoidModal, setShowVoidModal] = useState(false);
	const [showDepositSlipModal, setShowDepositSlipModal] = useState(false);
	const { downloadCheckbookDepositReport } = useCheckbookDepositReport();

	const isCheck =
		entrytype === ECheckbookEntryType.Check || type === ECheckbookType.Check;
	const isDeposit =
		entrytype === ECheckbookEntryType.Deposit ||
		type === ECheckbookType.Deposit;
	const isJournalEntry =
		entrytype === ECheckbookEntryType.JournalEntry ||
		type === ECheckbookType.JournalEntry;

	const isVoidEnabled = context === 'checkbook' && isCheck && isVoid === false;
	const isUnvoidEnabled = context === 'checkbook' && isCheck && isVoid === true;
	const isPrintDepositSlipEnabled = context === 'checkbook' && isDeposit;

	const openVoidModal = () => {
		setShowVoidModal(true);
	};

	const closeVoidModal = () => {
		setShowVoidModal(false);
	};

	const openDepositModal = () => {
		setShowDepositSlipModal(true);
	};

	const closeDepositModal = () => {
		setShowDepositSlipModal(false);
	};

	const onPostDepositSlip = async (data: { slipNumber: string }) => {
		const params = {
			accountNumber: account ?? '',
			slipNumber: data.slipNumber,
			depositDate: formatUserDate(userdate),
		};

		await downloadCheckbookDepositReport(params);
	};

	return (
		<>
			<Modal show={true} size="lg" centered>
				<>
					{isCheck && (
						<CheckbookDetailCheck
							account={account}
							checknum={checknum}
							supplier={supplier}
							userdate={userdate}
						/>
					)}
					{isDeposit && (
						<CheckbookDetailDeposit
							account={account}
							checknum={checknum}
							depositslip={depositslip}
							userdate={userdate}
						/>
					)}
					{isJournalEntry && <CheckbookDetailJournalEntry txnum={txnum} />}
				</>
				<Modal.Footer style={{ justifyContent: 'space-between' }}>
					<div>
						{isPrintDepositSlipEnabled && (
							<BaseButton
								onClick={openDepositModal}
								text={
									<div className="tw-grid tw-grid-flow-col tw-gap-1">
										<i className="ri-printer-line tw-font-medium tw-text-[#006a53]"></i>
										<span>Print Deposit Slip</span>
									</div>
								}
								buttonClass="tw-self-start tw-px-5 tw-py-2.5 tw-border-[1px] tw-border-[#e7faf3] tw-bg-[#e7faf3] tw-text-[#006a53] tw-rounded tw-self-start"
							/>
						)}
					</div>
					<div className="tw-grid tw-m-0 tw-p-0 tw-grid-flow-col tw-gap-2">
						{(isVoidEnabled || isUnvoidEnabled) && (
							<BaseButton
								onClick={openVoidModal}
								buttonClass="tw-px-5 tw-py-2.5 tw-border-[1px] tw-border-gray-300 tw-rounded"
								text={
									<div className="tw-grid tw-grid-flow-col tw-gap-1">
										<i
											className={cn('tw-font-medium tw-text-gray-700', {
												'ri-eye-fill': isVoidEnabled,
												'ri-eye-off-line': isUnvoidEnabled,
											})}
										></i>
										<span>{isVoidEnabled ? 'Void' : 'Unvoid'}</span>
									</div>
								}
							/>
						)}
						<BaseButton
							onClick={onClose}
							text="Close"
							buttonClass="tw-px-5 tw-py-2.5 tw-border-[1px] tw-border-[#008B6D] tw-bg-[#008B6D] tw-text-white tw-rounded"
						/>
					</div>
				</Modal.Footer>
			</Modal>
			{showVoidModal && (
				<CheckbookVoidModal
					onClose={closeVoidModal}
					onConfirm={onVoidConfirm}
					isUndoVoid={isUnvoidEnabled}
					checkbook={{
						account,
						checknum,
						userdate,
					}}
				/>
			)}
			{showDepositSlipModal && (
				<CreateDepositSlipListExistingModal
					cashAccount={accountLabel}
					slipNumber={depositslip}
					depositDate={formatUserDate(userdate)}
					show={showDepositSlipModal}
					hideModal={closeDepositModal}
					onPostDepositSlip={onPostDepositSlip}
				/>
			)}
		</>
	);
};

CheckbookDetailsModal.displayName = 'CheckbookDetailsModal';

export { CheckbookDetailsModal };
