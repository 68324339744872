import React from 'react';
import ThreadComponent from '../../ThreadComponent';
import { WithRouter } from '../../../../helpers/Router';
import WysiwygEditor from '../../../components/WysiwygEditor';
import { Col, Row } from 'react-bootstrap';
import {
	FormEvent,
	setLocalStorageKeyValueObject,
} from '../../../../utilities/FormEvent';
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import { mapRtfToDraft } from '../../../../utilities/Rtf';

class ProjectProposalAddRemarks extends ThreadComponent {
	constructor(props) {
		super(props);

		this.updatedData = props.updatedData;
		this.state = {
			proposal: {},
			remarksHtml: '',
		};

		this.formEvent = new FormEvent(`project_proposal_edit`);
		this.htmlToRtfConverter = new HtmlToRtfBrowser();
	}

	async componentDidMount() {
		this.setFormAction(true);

		if (Object.keys(this.props.proposal).length > 0) {
			let proposal = this.props.proposal || {};
			this.setState({
				proposal,
				remarksHtml: await mapRtfToDraft(
					this.updatedData?.remarksrtf ?? proposal?.remarksrtf ?? ''
				),
			});
		}
	}

	async componentDidUpdate(previousProps, previousState) {
		if (previousProps.proposal !== this.props.proposal) {
			let proposal = this.props.proposal || {};
			this.setState({
				proposal,
				remarksHtml: await mapRtfToDraft(
					this.updatedData?.remarksrtf ?? proposal?.remarksrtf ?? ''
				),
			});
		}
	}

	handleChange = (object) => {
		setLocalStorageKeyValueObject(
			'project_proposal_edit',
			'remarks',
			object.target.value
		);
		const htmlValue = this.htmlToRtfConverter.convertHtmlToRtf(
			object.target.html
		);
		setLocalStorageKeyValueObject(
			'project_proposal_edit',
			'remarksrtf',
			htmlValue
		);
		this.props.onDataChange({ key: 'remarks', value: object.target.value });
		this.props.onDataChange({ key: 'remarksrtf', value: htmlValue });
	};

	render() {
		return (
			<>
				{/* Content */}
				<Row lg={2}>
					<Col>
						<WysiwygEditor
							onChange={this.handleChange.bind(this)}
							children={this.state.remarksHtml}
						/>
					</Col>
				</Row>
			</>
		);
	}
}

export default WithRouter(ProjectProposalAddRemarks);
