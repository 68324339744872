import {
	Col,
	Row,
	Form,
	Container,
	Breadcrumb,
	Button,
	Alert,
} from 'react-bootstrap';
import { SingleValue } from 'react-select';
import { observer } from 'mobx-react';
import ThreadComponent from '../../../ThreadComponent';
import { addHours } from 'date-fns';
import { routeParam, WithRouter } from '../../../../helpers/Router';
import { Link, NavLink } from 'react-router-dom';
import URI from '../../../../defaults/RoutesDefault';
import DatePicker from 'react-datepicker';

import {
	FooterFormAction,
	InlineFormGroupField,
} from '../../../../templates/components/Form';
import WysiwygEditor from '../../../../templates/components/WysiwygEditor';

import { HeaderLight } from '../../../components/header/HeaderComponent';
import { ApiService } from '../../../../lib/api/HttpService';
import { RTFJS } from 'rtf.js';
import { stringToArrayBuffer } from '../../../../helpers/String';
import { delay, isEmpty } from 'lodash';
import { currencyFormat } from '../../../../helpers/Number';

//@ts-ignore
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import {
	displayAlert,
	displayAlertLoader,
	displayAlertSuccess,
} from '../../../../utilities/Response';
import format from 'date-fns/format';
import Spinner from '../../../components/help/Spinner';
import AsyncProjectsDropdown from '../../../components/dropdowns/AsyncProjectsDropdown';
import AsyncEmployeesDropdown from '../../../components/dropdowns/AsyncEmployeesDropdown';
import MSG from '../../../../defaults/Message';
import AsyncActivitiesDropdown from '../../../components/dropdowns/AsyncActivitiesDropdown';
import { setDraft } from '../../../../utilities/FormEvent';
import SecureBootstrapButton from '../../../components/security/SecureBootstrapButton';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../context/security';
import { renderSecureHideContent } from '../../../../utilities/modules/SecureContent';
import BudgetBannerProvider from 'legacy/app/context/BudgetBannerContext';
import BudgetBanner, {
	BudgetBannerContext,
} from 'legacy/app/components/BudgetBanner/BudgetBanner';

interface Props {
	params?: any;
	paths: any;
	navigate?: any;
}

interface Rate {
	resultingTimeEntry: {
		ratePerHour: string;
		rate: string;
		costPerHour: string;
		cost: string;
		taxable: boolean;
		nonbillable: boolean;
	};
}

interface State {
	data: {
		employee: any;
		project: any;
		activity: any;
		entrydate: Date;
		hastimedata: boolean;
		startdatetime: Date;
		enddatetime: Date;
		desc: string;
		descHTML: string;
		descRTF: any;
		hours: string;
		rate: string;
		rateperhour: string;
		cost: string;
		costperhour: string;
		nonbillable: boolean;
		taxable: boolean;
		item: string;
		comp: string;
	};
	startDate: Date;
	isLoading: boolean;
	// projects: Array<any>
	activities: Array<any>;
	employees: Array<any>;
	dataIsLoaded: boolean;
	selectedProject?: any;
}

class TimeEntryAddEditView extends ThreadComponent<Props, State> {
	readonly formGroupField = {
		colLabel: 2,
		colField: 10,
	};
	readonly api: ApiService;
	salesTaxcode: any;
	htmlToRtfConverter: any;

	constructor(props: Props) {
		super(props);

		this.state = {
			data: {
				employee: null,
				project: null,
				activity: null,
				entrydate: new Date(),
				hastimedata: false,
				startdatetime: new Date(),
				enddatetime: addHours(new Date(), 3),
				desc: '',
				descRTF: '',
				descHTML: '',
				hours: '0',
				rateperhour: '0',
				rate: '0',
				cost: '0',
				costperhour: '0',
				nonbillable: false,
				taxable: false,
				item: '',
				comp: '',
			},
			startDate: new Date(),
			isLoading: false,
			dataIsLoaded: false,
			activities: [],
			employees: [],
			selectedProject: null,
		};

		this.api = new ApiService();
		this.htmlToRtfConverter = new HtmlToRtfBrowser();
		this.salesTaxcode = [];
	}

	componentDidUpdate(_: Props, prevState: State): void {
		if (prevState.data.hastimedata !== this.state.data.hastimedata) {
			if (this.state.data.hastimedata) {
				this.calculateWorkingHours();
			} else {
				this.state.data.hours = '0';
			}
		}

		const { hours, rate, cost, project, activity, employee, nonbillable } =
			this.state.data;
		const { data } = prevState;

		if (
			(data.hours !== hours ||
				data.rate !== rate ||
				data.cost !== cost ||
				data.project !== project ||
				data.activity !== activity ||
				data.employee !== employee ||
				data.nonbillable !== nonbillable) &&
			(project?.value ||
				activity?.value ||
				employee?.value ||
				hours ||
				rate ||
				cost)
		) {
			this.checkBudget();
		}
	}

	checkBudget() {
		const {
			entrydate,
			activity,
			employee,
			project,
			nonbillable,
			taxable,
			item,
			comp,
			rateperhour,
			costperhour,
		} = this.state.data;

		let { rate, cost, hours } = this.state.data;
		rate = rate === 'NaN' ? '0' : rate;
		cost = cost === 'NaN' ? '0' : cost;
		hours = hours === 'NaN' ? '0' : hours;

		const isEdit = !!this.props.params.id;
		const params = {
			action: isEdit ? 'TryUpdate' : 'TryCreate',
			proj: project?.value || this.props.paths?.state?.proj?.value,
			employee: employee?.value || this.props.paths?.state?.employee?.value,
			activity: activity?.value ?? '',
			entryDate: format(entrydate, 'yyyy-MM-dd'),
			hasTimeData: false,
			itemBucketWillBeCreated: false,
			nonbillable,
			taxable,
			hours: this.defaultToZero(hours),
			rate: this.defaultToZero(rate),
			cost: this.defaultToZero(cost),
			ratePerHour: this.defaultToZero(rateperhour),
			costPerHour: this.defaultToZero(costperhour),
			...(isEdit && {
				timeTxNum: Number(this.props.params.id),
				item,
				comp,
			}),
		};

		this.api.postTimeEntryAction(params);
	}

	async componentDidMount() {
		this.setTitle('Time Entry').hasActionBar(true).setBodyClass();
		this.setState({
			isLoading: true,
		});

		this.salesTaxcode = await this.api.getSalesTaxCodesSummaries();

		let timeEntryData: any = [];
		if (this.props.params.id) {
			timeEntryData = await this.api.getTimeEntries(
				`?$filter=txnum eq ${this.props.params.id}`
			);
		}

		if (!isEmpty(timeEntryData)) {
			const data = timeEntryData[0];

			const project = data.proj
				? await this.getProjectData({ value: data.proj })
				: null;
			const employee = data.employee
				? await this.getEmployeeData({ value: data.employee })
				: null;

			const activity: any = await this.api.getTimeActivityByCode(data.activity);

			if (activity) {
				activity.value = activity?.activity;
				activity.label = `${activity?.activityn} [${activity?.activity}]`;
			}

			const isTaxable = data.taxable || this.getTaxable();

			this.setState({
				data: {
					project,
					employee,

					activity,
					entrydate: data.entrydate ? new Date(data.entrydate) : new Date(),
					hastimedata: data.hastimedata,
					startdatetime: data.startdatetime
						? new Date(data.startdatetime)
						: new Date(),
					enddatetime: data.enddatetime
						? new Date(data.enddatetime)
						: new Date(),
					desc: data.desc,
					descRTF: data.descrtf,
					descHTML: await this.mapRtfToDraft(timeEntryData?.[0]?.descrtf || ''),
					hours: data.hours,
					rateperhour: data.rateperhour,
					rate: data.rate,
					cost: data.cost,
					costperhour: data.costperhour,
					nonbillable: data.nonbillable,
					taxable: isTaxable,
					item: data.item,
					comp: data.comp,
				},
			});
		} else {
			const location = this.props.paths;
			const data = this.state.data;
			if (location.state?.proj) {
				data.project = await this.getProjectData({
					value: location.state?.proj,
				});
			}
			if (location.state?.employee) {
				data.employee = await this.getEmployeeData({
					value: location.state?.employee,
				});
			}
			this.setState({
				data: data,
			});
		}
		this.setState({
			isLoading: false,
		});
	}

	getTaxable(): boolean {
		const taxcode = this.salesTaxcode.find(
			(code: any) => code.taxc === this.state.data.project?.taxc
		);

		if (!taxcode) {
			return false;
		}

		return taxcode['to'] ?? false;
	}

	componentWillMount(): void {
		window.localStorage.removeItem('authToken');
		window.localStorage.removeItem('timeEntryData');
	}

	makeRateCostPair(timebillingtier: Number) {
		switch (timebillingtier) {
			case 1:
				return { rate: 'baserateperhr', cost: 'basecostperhr' };
			case 2:
				return { rate: 'tier2rateperhr', cost: 'tier2costperhr' };
			case 3:
				return { rate: 'tier3rateperhr', cost: 'tier3costperhr' };
		}
	}

	areDatesOnSameDay(date1: any, date2: any) {
		return (
			date1.getDate() === date2.getDate() &&
			date1.getMonth() === date2.getMonth() &&
			date1.getFullYear() === date2.getFullYear()
		);
	}

	resetHoursAndTime(hours = '0') {
		const newTime = addHours(this.state.data.entrydate, 0);
		return this.setState({
			data: {
				...this.state.data,
				hours,
				startdatetime: newTime,
				enddatetime: newTime,
			},
		});
	}

	calculateTimeDifference(startDate: any, endDate: any, doNotRound?: boolean) {
		const timeDiffInMillis = endDate - startDate;
		const totalHours = timeDiffInMillis / (1000 * 60 * 60);
		return doNotRound ? Number(totalHours) : Number(totalHours.toFixed(2));
	}

	calculateWorkingHours() {
		const hoursDifference = this.calculateTimeDifference(
			this.state.data.startdatetime,
			this.state.data.enddatetime
		);

		const { rateperhour, costperhour } = this.state.data;
		const hours = hoursDifference.toString();
		this.setState({
			data: {
				...this.state.data,
				hours,
				rate: `${parseFloat(hours) * parseFloat(rateperhour ?? 0)}`,
				cost: `${parseFloat(hours) * parseFloat(costperhour ?? 0)}`,
			},
		});
	}

	handleSelect = async (
		option: SingleValue<{ value?: string; label?: string; taxc?: any }>,
		field: string
	) => {
		const data = this.state.data;

		if (!location.pathname.includes('edit')) {
			let activity = await this.api.getTimeActivityByCode(
				this.state.data.activity?.value
			);
			if (activity) {
				activity.value = activity?.activity;
				activity.label = `${activity?.activityn} [${activity?.activity}]`;
			}

			if (field === 'activity') {
				this.setState({
					data: {
						...this.state.data,
						activity: option,
					},
				});
				activity = await this.api.getTimeActivityByCode(option?.value);
				if (activity) {
					activity.value = activity?.activity;
					activity.label = `${activity.activityn} [${activity.activity}]`;
				}

				data.taxable = this.getTaxable();

				const desc = activity.desc ?? '';
				const descHTML = await this.mapRtfToDraft(activity.descrtf || '');

				data.desc = desc;
				data.descRTF = this.htmlToRtfConverter.convertHtmlToRtf(descHTML);
				data.descHTML = descHTML;

				this.setState({
					data: data,
				});
			} else if (field === 'project') {
				// Get the project and set the taxable
				const project = await this.getProjectData(option);
				option = {
					...option,
					taxc: project?.taxc,
				};
			} else if (field === 'employee') {
				//
			}
		}

		this.setState(
			{
				data: {
					...data,
					[field]: option,
				},
			},
			async () => {
				setDraft(true);
				const startDateTime =
					this.state.data.startdatetime.toLocaleString('en-US');
				const endDateTime = this.state.data.enddatetime.toLocaleString('en-US');

				try {
					// @ts-ignore
					const data: Rate = await this.api.postTimeEntryAction({
						action: 'GetRate',
						proj:
							this.state.data.project?.value ||
							this.props?.paths?.state?.proj?.value,
						employee:
							this.state.data?.employee?.value ||
							this.props?.paths?.state?.employee?.value,
						activity: this.state.data.activity?.value ?? '',
						entryDate: format(this.state.data.entrydate, 'yyyy-MM-dd'),
						hasTimeData: this.state.data.hastimedata,
						startDateTime: startDateTime,
						endDateTime: endDateTime,
						desc: this.state.data.desc,
						descrtf: this.state.data.descRTF,
						hours: this.defaultToZero(this.state.data.hours),
						rateperhour: this.defaultToZero(this.state.data.rateperhour),
						rate: this.defaultToZero(this.state.data.rate),
						cost: this.defaultToZero(this.state.data.cost),
						costperhour: this.defaultToZero(this.state.data.costperhour),
						nonbillable: this.state.data.nonbillable,
						taxable: this.state.data.taxable,
					});

					// This should be computed by the BE
					//
					// const rateCostPair = this.makeRateCostPair(
					//     project.timebillingtier
					// )
					//
					// const rateperhour =
					//     rateCostPair && rawEmployee
					//         ? rawEmployee[rateCostPair.rate]
					//         : data.resultingTimeEntry.ratePerHour
					// const costperhour =
					//     rateCostPair && rawEmployee
					//         ? rawEmployee[rateCostPair.cost]
					//         : data.resultingTimeEntry.costPerHour

					// @ts-ignore
					this.setState({
						data: {
							...this.state.data,
							rateperhour: data.resultingTimeEntry.ratePerHour,
							rate: data.resultingTimeEntry.rate,
							costperhour: data.resultingTimeEntry.costPerHour,
							cost: data.resultingTimeEntry.cost,
							taxable: data.resultingTimeEntry.taxable,
							nonbillable: data.resultingTimeEntry.nonbillable,
						},
					});
				} catch (error: any) {
					if (error.response === undefined) {
						displayAlert('danger', error.message);
					} else {
						displayAlert('danger', error.response.data.userError);
					}
				}
			}
		);
	};

	async getProjectData(data: any) {
		if (data) {
			const project = await this.api.getProjectByCode(data?.value);

			return isEmpty(project)
				? null
				: {
						value: project.proj,
						label: `${project.projn} [${project.proj}]`,
						timebillingtier: project.timebillingtier,
						taxo: project.taxo,
						taxc: project.taxc,
				  };
		}

		return null;
	}

	async getEmployeeData(data: any) {
		if (data) {
			const response = await this.api.getClientEmployeeSummaryByCode(
				data?.value
			);

			if (response) {
				const { id, key, value } = response;
				const employee = {
					id: id.toString(),
					value: key,
					label: `${value} [${key}]`,
					name: value,
				};
				return !isEmpty(employee) ? employee : null;
			}
		}

		return null;
	}

	handleDateField = (
		date: any,
		field: 'startdatetime' | 'entrydate' | 'enddatetime'
	) => {
		this.setState(
			(prevState) => {
				if (field === 'entrydate') {
					const newDate = date.toDateString();

					prevState.data['startdatetime'] = new Date(
						`${newDate} ${prevState.data.startdatetime.toTimeString()}`
					);
					prevState.data['enddatetime'] = new Date(
						`${newDate} ${prevState.data.enddatetime.toTimeString()}`
					);
				}
				if (field === 'startdatetime') {
					prevState.data['startdatetime'] = date;
					prevState.data['enddatetime'] = addHours(date, 3);
				}
				prevState.data[field] = date;
			},
			() => {
				setDraft(true);
				this.calculateWorkingHours();
			}
		);
	};

	async mapRtfToDraft(rtf: any): Promise<string> {
		if (!rtf) {
			return '';
		}

		const doc = new RTFJS.Document(stringToArrayBuffer(rtf), {});
		const htmlElements = await doc.render();

		return htmlElements
			.map((element) => {
				return element.outerHTML;
			})
			.join(' ');
	}

	handleDescChange = (e: any) => {
		this.setState((prevState) => ({
			data: {
				...prevState.data,
				desc: e.target.value,
				descRTF: this.htmlToRtfConverter.convertHtmlToRtf(e.target.html),
			},
		}));
		setDraft(true);
	};

	handleInputChange = (e: any) => {
		if (
			e.target.name === 'rateperhour' ||
			e.target.name === 'hours' ||
			e.target.name === 'costperhour'
		) {
			if (parseFloat(e.target.value) < 0) return;
			if (e.target.name === 'rateperhour') {
				this.setState((prevState) => ({
					data: {
						...prevState.data,
						rate: `${
							parseFloat(prevState.data.hours) * parseFloat(e.target.value)
						}`,
					},
				}));
			}

			if (e.target.name === 'hours') {
				if (e.target.value.length === 0) {
					return this.resetHoursAndTime(e.target.value);
				}
				const hourstoAdd = parseFloat(e.target.value);
				const newEndDateTime = new Date(
					this.state.data.startdatetime.getTime() + hourstoAdd * 60 * 60 * 1000
				);

				this.setState((prevState) => ({
					data: {
						...prevState.data,
						// hmmm why are we doing this here?
						// hastimedata: true,
						rate: `${
							parseFloat(e.target.value) *
							parseFloat(this.state.data.rateperhour)
						}`,
						cost: `${
							parseFloat(e.target.value) *
							parseFloat(this.state.data.costperhour)
						}`,
						enddatetime: newEndDateTime,
					},
				}));
			}

			if (e.target.name === 'costperhour') {
				this.setState((prevState) => ({
					data: {
						...prevState.data,
						cost: `${
							parseFloat(prevState.data.hours) * parseFloat(e.target.value)
						}`,
					},
				}));
			}
		}

		this.setState((prevState) => ({
			data: {
				...prevState.data,
				[e.target.name]: e.target.value,
			},
		}));
		setDraft(true);
	};

	handleCheck = (e: any) => {
		this.setState((prevState) => ({
			data: {
				...prevState.data,
				[e.target.name]: e.target.checked,
			},
		}));
		setDraft(true);
	};

	setIsLoading(isLoading: boolean): void {
		this.setState({
			isLoading: isLoading,
		});
	}

	redirectPage(timeEntryData: any, res: any, element: any) {
		if (
			!this.hasError(res) &&
			(element.target.name === 'saveNew' || !timeEntryData.id)
		) {
			window.location.href = routeParam(URI.timeEntry.add, {
				id: res.id,
			});
		}
	}

	errorMessage(response: any) {
		const errorDiv: any = document.getElementById('user-error-area');
		const errorTextDiv: any = document.getElementById('userError');
		errorDiv.style.display = 'none';

		if (response.response && response.response.status !== 200) {
			errorDiv.style.display = 'block';
			errorTextDiv.innerHTML =
				response.response.data.userError || response.message;
		}
	}

	hasError(response: any) {
		response = response || {};
		this.errorMessage(response);

		if (response.response && response.response.status !== 200) {
			this.setIsLoading(false);
			return true;
		}

		return false;
	}

	defaultToZero(value: string) {
		return Number.isNaN(value) || !value || value === '' || value == '0.00'
			? '0'
			: value;
	}

	handleClickSave = async (e: any) => {
		displayAlertLoader(MSG.loading.update.msg);
		setDraft(false);

		const startDateTime = this.state.data.startdatetime.toLocaleString('en-US');
		const endDateTime = this.state.data.enddatetime.toLocaleString('en-US');

		const hoursDifference = this.calculateTimeDifference(
			this.state.data.startdatetime,
			this.state.data.enddatetime
		);

		const hours = this.state.data.hours
			? parseFloat(
					this.defaultToZero(
						this.calculateTimeDifference(
							this.state.data.startdatetime,
							this.state.data.enddatetime,
							true
						)
							.toFixed(4)
							.toString()
					)
			  )
			: '0';

		const isTimeEntered = this.state.data.hastimedata;

		if (isTimeEntered && hoursDifference <= 0) {
			return displayAlert('danger', 'The start time is after the end time.');
		}

		try {
			await this.api.postTimeEntryAction({
				action: 'GetRate',
				proj:
					this.state.data.project?.value ||
					this.props?.paths?.state?.proj?.value,
				employee:
					this.state.data?.employee?.value ||
					this.props?.paths?.state?.employee?.value,
				activity: this.state.data.activity?.value ?? '',
				entryDate: format(this.state.data.entrydate, 'yyyy-MM-dd'),
				hasTimeData: this.state.data.hastimedata,
				startDateTime: startDateTime,
				endDateTime: endDateTime,
				desc: this.state.data.desc,
				descrtf: this.state.data.descRTF,
				hours,
				rateperhour: this.defaultToZero(this.state.data.rateperhour),
				rate: this.defaultToZero(this.state.data.rate),
				cost: this.defaultToZero(this.state.data.cost),
				costperhour: this.defaultToZero(this.state.data.costperhour),
				nonbillable: this.state.data.nonbillable,
				taxable: this.state.data.taxable,
			});

			if (this.props.params.id) {
				const tryUpdate: any = await this.api.postTimeEntryAction({
					action: 'TryUpdate',
					timeTxNum: parseInt(this.props.params.id),
					proj:
						this.state.data.project?.value ||
						this.props?.paths?.state?.proj?.value,
					employee:
						this.state.data?.employee?.value ||
						this.props?.paths?.state?.employee?.value,
					activity: this.state.data.activity?.value ?? '',
					entryDate: format(this.state.data.entrydate, 'yyyy-MM-dd'),
					hasTimeData: this.state.data.hastimedata,
					startDateTime: startDateTime,
					endDateTime: endDateTime,
					itemBucketWillBeCreated: false,
					desc: this.state.data.desc,
					descrtf: this.state.data.descRTF,
					hours,
					rateperhour: this.defaultToZero(this.state.data.rateperhour),
					rate: this.defaultToZero(this.state.data.rate),
					cost: this.defaultToZero(this.state.data.cost),
					costperhour: this.defaultToZero(this.state.data.costperhour),
					nonbillable: this.state.data.nonbillable,
					taxable: this.state.data.taxable,
					item: this.state.data.item,
					comp: this.state.data.comp,
				});

				if (
					tryUpdate.result === 'dmriError' ||
					tryUpdate.result === 'dmriARInvoice'
				) {
					throw new Error(tryUpdate.message);
				}

				const update: any = await this.api.postTimeEntryAction({
					action: 'Update',
					timeTxNum: parseInt(this.props.params.id),
					proj:
						this.state.data.project?.value ||
						this.props?.paths?.state?.proj?.value,
					employee:
						this.state.data?.employee?.value ||
						this.props?.paths?.state?.employee?.value,
					activity: this.state.data.activity?.value ?? '',
					entryDate: format(this.state.data.entrydate, 'yyyy-MM-dd'),
					hasTimeData: this.state.data.hastimedata,
					itemBucketWillBeCreated: false,
					startDateTime: startDateTime,
					endDateTime: endDateTime,
					desc: this.state.data.desc,
					descrtf: this.state.data.descRTF,
					hours,
					rateperhour: this.defaultToZero(this.state.data.rateperhour),
					rate: this.defaultToZero(this.state.data.rate),
					cost: this.defaultToZero(this.state.data.cost),
					costperhour: this.defaultToZero(this.state.data.costperhour),
					nonbillable: this.state.data.nonbillable,
					taxable: this.state.data.taxable,
					item: this.state.data.item,
					comp: this.state.data.comp,
				});
				if (
					update.result === 'dmriError' ||
					update.result === 'dmriARInvoice'
				) {
					throw new Error(update.message);
				}
			} else {
				const tryUpdate: any = await this.api.postTimeEntryAction({
					action: 'TryCreate',
					proj:
						this.state.data.project?.value ||
						this.props?.paths?.state?.proj?.value,
					employee:
						this.state.data?.employee?.value ||
						this.props?.paths?.state?.employee?.value,
					activity: this.state.data.activity?.value ?? '',
					entryDate: format(this.state.data.entrydate, 'yyyy-MM-dd'),
					hasTimeData: this.state.data.hastimedata,
					startDateTime: startDateTime,
					endDateTime: endDateTime,
					itemBucketWillBeCreated: false,
					desc: this.state.data.desc,
					descrtf: this.state.data.descRTF,
					hours,
					rateperhour: this.defaultToZero(this.state.data.rateperhour),
					rate: this.defaultToZero(this.state.data.rate),
					cost: this.defaultToZero(this.state.data.cost),
					costperhour: this.defaultToZero(this.state.data.costperhour),
					nonbillable: this.state.data.nonbillable,
					taxable: this.state.data.taxable,
				});

				if (tryUpdate.result === 'dmriError') {
					throw new Error(tryUpdate.message);
				}

				await this.api.postTimeEntryAction({
					action: 'Create',
					proj:
						this.state.data.project?.value ||
						this.props?.paths?.state?.proj?.value,
					employee:
						this.state.data?.employee?.value ||
						this.props?.paths?.state?.employee?.value,
					activity: this.state.data.activity?.value ?? '',
					entryDate: format(this.state.data.entrydate, 'yyyy-MM-dd'),
					hasTimeData: this.state.data.hastimedata,
					itemBucketWillBeCreated: false,
					startDateTime: startDateTime,
					endDateTime: endDateTime,
					desc: this.state.data.desc,
					descrtf: this.state.data.descRTF,
					hours,
					rateperhour: this.defaultToZero(this.state.data.rateperhour),
					rate: this.defaultToZero(this.state.data.rate),
					cost: this.defaultToZero(this.state.data.cost),
					costperhour: this.defaultToZero(this.state.data.costperhour),
					nonbillable: this.state.data.nonbillable,
					taxable: this.state.data.taxable,
				});
			}

			displayAlertSuccess('Time Entry successfully saved');

			delay(() => {
				if (e.target.name === 'saveNew') {
					window.location.href = routeParam(URI.timeEntry.add, {});
				} else if (history?.length && history.length > 1) {
					history.back();
				} else {
					this.props.navigate(URI.timeEntry.base, {
						state: this.props.paths.state,
					});
				}
			}, 1200);
		} catch (error: any) {
			if (error.response === undefined) {
				displayAlert('danger', error.message);
			} else {
				displayAlert('danger', error.response.data.userError);
			}
		}

		this.setState({
			isLoading: false,
		});
	};

	getStartDateTime = () =>
		this.state.data.startdatetime.toLocaleString('en-US');

	getEndDateTime = () => this.state.data.enddatetime.toLocaleString('en-US');

	tryUpdate = async () =>
		await this.api.postTimeEntryAction({
			action: 'TryUpdate',
			timeTxNum: parseInt(this.props.params.id),
			proj:
				this.state.data.project?.value || this.props?.paths?.state?.proj?.value,
			employee:
				this.state.data?.employee?.value ||
				this.props?.paths?.state?.employee?.value,
			activity: this.state.data.activity?.value ?? '',
			entryDate: format(this.state.data.entrydate, 'yyyy-MM-dd'),
			hasTimeData: false,
			itemBucketWillBeCreated: false,
			desc: this.state.data.desc,
			descrtf: this.state.data.descRTF,
			hours: this.defaultToZero(this.state.data.hours),
			rateperhour: this.defaultToZero(this.state.data.rateperhour),
			rate: this.defaultToZero(this.state.data.rate),
			cost: this.defaultToZero(this.state.data.cost),
			costperhour: this.defaultToZero(this.state.data.costperhour),
			nonbillable: this.state.data.nonbillable,
			taxable: this.state.data.taxable,
			item: this.state.data.item,
			comp: this.state.data.comp,
		});

	tryCreate = async () =>
		await this.api.postTimeEntryAction({
			action: 'TryCreate',
			proj:
				this.state.data.project?.value || this.props?.paths?.state?.proj?.value,
			employee:
				this.state.data?.employee?.value ||
				this.props?.paths?.state?.employee?.value,
			activity: this.state.data.activity?.value ?? '',
			entryDate: format(this.state.data.entrydate, 'yyyy-MM-dd'),
			hasTimeData: false,
			itemBucketWillBeCreated: false,
			desc: this.state.data.desc,
			descrtf: this.state.data.descRTF,
			hours: this.defaultToZero(this.state.data.hours),
			rateperhour: this.defaultToZero(this.state.data.rateperhour),
			rate: this.defaultToZero(this.state.data.rate),
			cost: this.defaultToZero(this.state.data.cost),
			costperhour: this.defaultToZero(this.state.data.costperhour),
			nonbillable: this.state.data.nonbillable,
			taxable: this.state.data.taxable,
		});

	render(): JSX.Element {
		return (
			<>
				{this.renderHeader()}
				<div className="content-padding min-height has-action-bar">
					<Container fluid className="px-0">
						{this.state.isLoading ? (
							<Spinner isChild={true} />
						) : (
							this.renderContent()
						)}
					</Container>
				</div>

				<FooterFormAction>
					<SecureBootstrapButton
						attributeNo={15}
						attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
						variant="primary"
						size="lg"
						onClick={this.handleClickSave}
						name="save"
						disabled={this.state.isLoading}
					>
						{this.state.isLoading ? 'Saving...' : 'Save'}
					</SecureBootstrapButton>
				</FooterFormAction>
			</>
		);
	}

	renderHeader(): JSX.Element {
		const { isLoading } = this.state;

		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={URI.timeEntry.base}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb className="ms-4">
						<Breadcrumb.Item
							linkProps={{ to: URI.timeEntry.base }}
							linkAs={Link}
						>
							Time Entry
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{ to: URI.timeEntry.add }}
							linkAs={Link}
						>
							{location.pathname.includes('edit') ? 'Edit' : 'Add'} Time Entry
						</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content>
					<HeaderLight.Title>
						{location.pathname.includes('edit') ? 'Edit' : 'Add'} Time Entry
					</HeaderLight.Title>

					<div>
						{/* Submit Button */}
						<Button
							variant="ivory"
							name="Cancel"
							className="me-2"
							onClick={() => {
								this.props.navigate(URI.timeEntry.base, {
									state: this.props.paths.state,
								});
							}}
							disabled={isLoading}
						>
							Cancel
						</Button>
						<SecureBootstrapButton
							attributeNo={15}
							attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
							variant="primary me-2"
							onClick={this.handleClickSave}
							name="saveNew"
							disabled={isLoading}
						>
							{isLoading ? 'Saving...' : 'Save & New'}
						</SecureBootstrapButton>

						<SecureBootstrapButton
							attributeNo={15}
							attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
							variant="primary"
							onClick={this.handleClickSave}
							name="save"
							disabled={isLoading}
						>
							{isLoading ? 'Saving...' : 'Save'}
						</SecureBootstrapButton>
					</div>
				</HeaderLight.Content>

				<BudgetBannerProvider>
					<BudgetBanner
						context={BudgetBannerContext.Header}
						dataTestId="budget-banner-time-entry"
						className="mt-3 mb-2"
					/>
				</BudgetBannerProvider>
			</HeaderLight>
		);
	}

	renderContent(): JSX.Element {
		return (
			<Container className="ms-0">
				<Row>
					<Col>
						<Alert
							key={'danger'}
							variant={'danger'}
							id="user-error-area"
							style={{ display: 'none' }}
						>
							Oops, something went wrong.
							<br />
							Error:{' '}
							<strong>
								<span id="userError"></span>
							</strong>
						</Alert>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
						<div className="form-fields">
							<InlineFormGroupField
								label="Project"
								classNameParent="mb-3"
								{...this.formGroupField}
							>
								<AsyncProjectsDropdown
									menuPortalTarget={document.body}
									styles={{
										menuPortal: (base: any) => ({
											...base,
											zIndex: 9999,
										}),
									}}
									className="react-select"
									placeholder="Please select"
									defaultValue={
										this.state.data.project || this.props?.paths?.state?.proj
									}
									onChange={(option: any) => {
										this.handleSelect(option, 'project');
									}}
								/>
							</InlineFormGroupField>

							<InlineFormGroupField
								label="Activity"
								classNameParent="mb-3"
								{...this.formGroupField}
							>
								<AsyncActivitiesDropdown
									menuPortalTarget={document.body}
									styles={{
										menuPortal: (base: any) => ({
											...base,
											zIndex: 9999,
										}),
									}}
									defaultValue={this.state.data?.activity}
									className="react-select"
									placeholder="Please select"
									onChange={(option: any) => {
										this.handleSelect(option, 'activity');
									}}
								/>
							</InlineFormGroupField>
						</div>
					</Col>

					<Col lg={6}>
						<div className="form-fields ps-lg-4">
							<InlineFormGroupField
								label="Employee"
								classNameParent="mb-3"
								{...this.formGroupField}
								colField={8}
							>
								<div>
									<AsyncEmployeesDropdown
										menuPortalTarget={document.body}
										styles={{
											menuPortal: (base: any) => ({
												...base,
												zIndex: 9999,
											}),
										}}
										defaultValue={
											this.state.data.employee ||
											this.props?.paths?.state?.employee
										}
										className="react-select"
										placeholder="Please select"
										onChange={(option: any) => {
											this.handleSelect(option, 'employee');
										}}
									/>
								</div>
							</InlineFormGroupField>
						</div>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
						<div className="form-fields">
							<InlineFormGroupField
								label="Date/Time"
								classNameParent="mb-3"
								{...this.formGroupField}
								colField={5}
							>
								<DatePicker
									onChange={(date) => {
										this.handleDateField(date, 'entrydate');
									}}
									showMonthDropdown={true}
									showYearDropdown={true}
									className="form-control form-control-sm"
									placeholderText="Select"
									autoComplete="off"
									selected={this.state.data.entrydate}
								/>
							</InlineFormGroupField>
						</div>
					</Col>
				</Row>

				<Row className="mb-4">
					<Col lg={6}>
						<div className="form-fields">
							<InlineFormGroupField
								label=""
								classNameParent="mb-3"
								{...this.formGroupField}
								colField={5}
							>
								<Form.Check
									inline
									label="Enter Time"
									type="checkbox"
									name="hastimedata"
									className={'fw-600'}
									checked={this.state.data.hastimedata}
									onChange={this.handleCheck}
								/>
							</InlineFormGroupField>
						</div>
					</Col>

					<Col lg={6}>
						{this.state.data.hastimedata && (
							<InlineFormGroupField
								label="Time"
								classNameParent="mb-3r"
								{...this.formGroupField}
								colField={6}
							>
								<div className="d-flex flex-wrap flex-lg-nowrap align-items-center">
									<DatePicker
										selected={this.state.data.startdatetime}
										onChange={(date) => {
											this.handleDateField(date, 'startdatetime');
										}}
										showTimeSelect
										showTimeSelectOnly
										className="form-control form-control-sm"
										placeholderText="Select"
										autoComplete="off"
										timeCaption="Time"
										dateFormat="h:mm aa"
									/>

									<span className="px-3">To</span>

									<DatePicker
										selected={this.state.data.enddatetime}
										onChange={(date) => {
											this.handleDateField(date, 'enddatetime');
										}}
										showTimeSelect
										showTimeSelectOnly
										className="form-control form-control-sm"
										placeholderText="Select"
										autoComplete="off"
										timeCaption="Time"
										dateFormat="h:mm aa"
									/>
								</div>
							</InlineFormGroupField>
						)}
					</Col>
				</Row>

				<Row className="mb-4">
					<Col lg={6}>
						<div className="form-fields">
							<InlineFormGroupField
								label="Description"
								classNameParent="mb-3"
								{...this.formGroupField}
								colField={10}
								className="fw-500 d-flex justify-content-sm-end align-items-start px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end pt-sm-2"
							>
								<WysiwygEditor onChange={this.handleDescChange}>
									{this.state.data.descHTML || ''}
								</WysiwygEditor>
							</InlineFormGroupField>
						</div>
					</Col>
				</Row>

				{renderSecureHideContent(
					<Row>
						<Col lg={6}>
							<div className="form-fields">
								<InlineFormGroupField
									label="Hours"
									classNameParent="mb-3"
									{...this.formGroupField}
									colField={10}
								>
									<div className="d-flex justify-content-sm-start">
										<div className="d-flex align-items-center">
											<div className="d-flex flex-wrap flex-sm-nowrap align-items-center">
												<Form.Control
													type="number"
													className="no-spinner"
													placeholder="Hours"
													value={this.state.data.hours}
													name="hours"
													min={0}
													onBlur={(e) => {
														if (e.target.value.length === 0) {
															this.resetHoursAndTime();
														}
													}}
													onChange={this.handleInputChange}
												/>
												<span className="px-2 fsx-22 text-secondary-ash">
													<i className="ri-close-line"></i>
												</span>
											</div>
										</div>
										<div>
											<InlineFormGroupField
												label="Rate"
												classNameParent="mb-3"
												{...this.formGroupField}
											>
												<div className="d-flex flex-wrap flex-sm-nowrap align-items-center">
													<Form.Control
														type="number"
														className="no-spinner"
														placeholder="Rate"
														value={this.state.data.rateperhour}
														name="rateperhour"
														min={0}
														onChange={this.handleInputChange}
													/>
													<span className="px-2 fsx-28 text-secondary-ash">
														=
													</span>
												</div>
											</InlineFormGroupField>
											<InlineFormGroupField
												label="Cost"
												classNameParent="mb-3"
												{...this.formGroupField}
											>
												<div className="d-flex flex-wrap flex-sm-nowrap align-items-center">
													<Form.Control
														type="number"
														className="no-spinner"
														placeholder="Cost"
														value={this.state.data.costperhour}
														name="costperhour"
														onChange={this.handleInputChange}
														min={0}
													/>
													<span className="px-2 fsx-28 text-secondary-ash">
														=
													</span>
												</div>
											</InlineFormGroupField>
										</div>
									</div>
								</InlineFormGroupField>
							</div>
						</Col>
						<Col lg={3}>
							<div>
								<InlineFormGroupField
									label="Total Price"
									classNameParent="mb-3"
									{...this.formGroupField}
								>
									<div className="d-flex flex-wrap flex-sm-nowrap align-items-center">
										<Form.Control
											type="text"
											placeholder="Price"
											value={currencyFormat(this.state.data.rate)}
											name="rate"
											readOnly
										/>
									</div>
								</InlineFormGroupField>
								<InlineFormGroupField
									label="Total Cost"
									classNameParent="mb-3"
									{...this.formGroupField}
								>
									<Form.Control
										type="text"
										placeholder="Cost"
										value={currencyFormat(this.state.data.cost)}
										name="cost"
										readOnly
									/>
								</InlineFormGroupField>
							</div>
						</Col>
					</Row>
				)(15, SECURITY_ATTRIBUTE_TYPES.DenySpec1)}

				<Row>
					<Col lg={6}>
						<InlineFormGroupField
							label=""
							classNameParent="mb-3"
							{...this.formGroupField}
							colField={8}
						>
							<div>
								<Form.Check
									inline
									label="Non-Billable"
									name="nonbillable"
									type="checkbox"
									id={`check-billable-item-1`}
									className="me-3 fw-600"
									checked={this.state.data.nonbillable}
									onChange={this.handleCheck}
								/>
								<Form.Check
									inline
									label="Taxable"
									name="taxable"
									type="checkbox"
									id={`check-billable-item-2`}
									className={'fw-600'}
									checked={this.state.data.taxable}
									onChange={this.handleCheck}
								/>
							</div>
						</InlineFormGroupField>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default WithRouter(observer(TimeEntryAddEditView));
