import React from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';

export type TModalSize = 'sm' | 'lg' | 'xl';

interface ModalProps {
	show: boolean;
	toggleModal?: () => void; // use toggleModal if you prefer less control than onCancel, etc
	confirmAction?: () => void;
	onCancel?: () => void; // use onCancel if you want more control than toggleModal
	message?: string;
	title?: string;
	labelOK?: string;
	labelCancel?: string;
	okBtnStyle?: string;
	disabledOK?: boolean;
	children?: React.ReactNode;
	hideCancel?: boolean;
	hideOK?: boolean;
	size?: TModalSize;
	dataTestId?: string;
	disableCancel?: boolean;
	footer?: boolean;
}

const ConfirmationModal = ({
	show,
	dataTestId,
	title = 'Delete Confirm',
	labelCancel = 'Close',
	labelOK = 'OK',
	confirmAction,
	onCancel,
	hideCancel = false,
	hideOK = false,
	okBtnStyle = 'danger',
	disabledOK = false,
	disableCancel = false,
	message,
	toggleModal,
	children,
	size,
	footer = true,
}: ModalProps) => {
	return (
		<Modal
			data-testid={dataTestId}
			size={size}
			show={show}
			onHide={() => {
				if (disableCancel) {
					return;
				}
				toggleModal && toggleModal();
				onCancel && onCancel();
			}}
			aria-labelledby="action-modal"
			className="a-modal"
			backdrop="static"
			centered
		>
			{title && (
				<Modal.Header>
					<h5 className="text-center mx-auto fw-bold pt-4">{title}</h5>
				</Modal.Header>
			)}
			{(children || message) && (
				<Modal.Body>
					{message && (
						<Row className="mb-3">
							<Col className="px-5">
								<div className="text-center mx-auto fw-bold pt-4">
									<h6>{message}</h6>
								</div>
							</Col>
						</Row>
					)}

					{children}
				</Modal.Body>
			)}
			{footer && (
				<Modal.Footer className="bg-ivory py-3 px-5">
					<Row className="w-100">
						<Col className="d-flex" lg={{ span: 10, offset: 1 }}>
							{!hideOK && (
								<Button
									type="button"
									variant={okBtnStyle}
									onClick={() => {
										if (confirmAction) {
											confirmAction();
										}
									}}
									className="w-100 m-2"
									disabled={disabledOK}
								>
									{labelOK}
								</Button>
							)}

							{!hideCancel && (
								<Button
									disabled={disableCancel}
									type="button"
									variant="trans-light border-secondary-ash"
									onClick={() => {
										toggleModal && toggleModal();
										onCancel && onCancel();
									}}
									className="w-100 m-2"
								>
									{labelCancel}
								</Button>
							)}
						</Col>
					</Row>
				</Modal.Footer>
			)}
		</Modal>
	);
};

ConfirmationModal.displayName = 'ConfirmationModal';

export default ConfirmationModal;
