import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { ECacheKeys } from 'cache/CacheKeys';
import { getSignUpStatus } from 'api/resources/signUp';
import type { ISignUpStatusResponse } from 'api/resources/signUp';
import { ESignUpStatus } from 'legacy/app/enums/signUp/SignUpStatus';

export const useGetSignUpStatus = (
	clientId: number | null,
	options?: UseQueryOptions<ISignUpStatusResponse>
) => {
	const query = useQuery<ISignUpStatusResponse>({
		queryKey: [ECacheKeys.SignUp, 'signUpStatus'],
		refetchInterval: (data) => {
			if (data?.instanceStatus === ESignUpStatus.Completed) return false;
			return 5000;
		},
		refetchOnWindowFocus: false,
		queryFn: () => getSignUpStatus(clientId),
		...(options || {}),
	});

	return query;
};
