import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { IUserNameAvailability } from 'api/resources/signUp';
import { ECacheKeys } from 'cache/CacheKeys';
import { getUserNameIsAvailable } from 'api/resources/signUp';

export const useGetIsUsernameAvailable = (
	username: string,
	returnSuggestions = false,
	options?: UseQueryOptions<IUserNameAvailability>
) => {
	const query = useQuery<IUserNameAvailability>({
		queryKey: [ECacheKeys.Invoices, username],
		refetchInterval: false,
		refetchOnWindowFocus: false,
		queryFn: () => getUserNameIsAvailable(username, returnSuggestions),
		...(options || {}),
	});

	return query;
};
