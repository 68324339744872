import React from 'react';
import {
	Form,
	Table,
	Button,
	Dropdown,
	DropdownButton,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ThreadComponent from '../ThreadComponent';
import { getRouteWithParam, WithRouter } from '../../../helpers/Router';
import ListFilter from '../../components/ListFilter';
import URI from '../../../defaults/RoutesDefault';
import { getStatusClassName, isFiltered } from '../../../helpers/Util';
import { ApiService } from '../../../lib/api/HttpService';
import Select from 'react-select';
import { Pagination } from '../../../app/components/pagination/Pagination';
import debounce from 'lodash/debounce';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import { addDomClass, hasClass, removeDomClass } from '../../../helpers/DOM';
import {
	formatDate,
	formatDateTime,
	formatFilterDate,
} from '../../../helpers/Date';
import SendProposalModal from '../modal/SendProposalModal';
import {
	displayAlert,
	displayAlertError,
	displayAlertLoader,
	displayAlertSuccess,
	hideAlertLoader,
} from 'legacy/utilities/Response';
import noItems from '../../../assets/images/icons/proposal.svg';
import { currencyFormat } from '../../../helpers/Number';
import { showEmpty, showLoading } from '../../../helpers/Loading';
import DeleteModal from '../../../app/components/modal/DeleteModal';
import HandleDeleteWorker from '../../../utilities/DeleteWorker';
import MSG from '../../../defaults/Message';
import { isEmpty, isArray } from 'lodash';
import {
	addDays,
	endOfDay,
	getMonth,
	getYear,
	lastDayOfMonth,
	startOfDay,
	subDays,
} from 'date-fns';
import { DateRangePicker } from 'rsuite';
import { getCheckAllClass } from '../../../utilities/ModuleHelper';
import ProposalHistoryModal from '../modal/ProposalHistoryModal';
import { showErrorMessage } from '../../../app/components/validators/Helpers';
import FilterSort from '../../../utilities/modules/FilterSort';
import SecureBootstrapButton from '../../../app/components/security/SecureBootstrapButton';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../app/context/security';
import SecureDropdownItem from '../../../app/components/security/SecureDropdownItem';
import { Feature } from 'use-feature';
import { FeatureFlags } from '../../../app/enums/featureFlags/featureFlags';
import Icon from 'legacy/app/components/Icon/Icon';
import ClientPortalIconInActive from 'legacy/assets/images/icons/client-portal-icon-inactive.svg';
import ClientPortalIconActive from 'legacy/assets/images/icons/client-portal-icon-active.svg';
import ClientPortalIconAdd from 'legacy/assets/images/icons/client-portal-icon-add.svg';
import ClientPortalIconRemove from 'legacy/assets/images/icons/client-portal-icon-remove.svg';
import ClientPortalIconDefault from 'legacy/assets/images/icons/client-portal-icon-default.svg';

const INVALID_FILE_ID = '00000000-0000-0000-0000-000000000000';

class ProjectViewProposal extends ThreadComponent {
	constructor(props) {
		super(props);

		this.projectId = this.props.params.id;
		this.fs = new FilterSort('project_proposal_list_' + this.projectId);
		this.fs.setDefaultSort('propnum desc');
		this.state = {
			checks: [],
			totalChecked: [],
			data: [],
			dataIsLoaded: false,
			status: [
				{ value: 'active', label: 'Active' },
				{ value: 'inactive', label: 'Inactive' },
				{ value: 'completed', label: 'Completed' },
				{ value: '', label: 'All' },
			],
			statusFilter: this.fs.getFilter('statusFilter') ?? {
				value: 'active',
				label: 'Active',
			},
			hideAlert: false,
			pid: 0,
			showProposalModal: false,
			showModal: false,
			showHistoryModal: false,
			pageSize: 20,
			page: 1,
			reportFormats: [],
			newFileIds: {},
			sortProperty: this.fs.getSort() ?? 'propnum desc',
			showTableSearch: this.fs.isSearchActive(),
			searchProperties: this.fs.getSearches(),
			sendProposalData: {
				token: '',
				link: '',
				contact: '',
				address: {},
			},
			project: {},
		};

		this.api = new ApiService();
		this.changePageHandler = debounce(this.handleChangePage.bind(this, 1), 200);

		this.objName = 'Project Proposal';
		this.objReference = 'Projects';

		this.deleteObjectParams = {
			deleteType: 'dmriTestOnly',
			objectType: 'objProposal',
			objectCodeOrId: '',
		};
	}

	componentInit() {
		this.setActionBar(true);
	}

	async componentDidMount() {
		window.setTimeout(() => {
			this.setState({ hideAlert: true });
		}, 5000);

		if (isEmpty(this.props.project?.proj)) {
			return;
		}

		try {
			this.setState(
				{
					project: this.props.project,
					dataIsLoaded: false,
				},
				this.loadData
			);

			// eslint-disable-next-line no-empty
		} catch (e) {}
	}

	async componentDidUpdate(previousProps, previousState) {
		let project = {};
		if (previousProps.project !== this.props.project) {
			project = this.props.project || {};

			this.setState(
				{
					project,
					dataIsLoaded: false,
				},
				this.loadData
			);
		}
	}

	async loadData() {
		const getAddresses = await this.api.getAddresses(
			`?$filter=code eq '${encodeURIComponent(
				this.state.project?.proj
			)}' and addressType eq 0`
		);
		this.setState((prevState) => ({
			sendProposalData: {
				...prevState.sendProposalData,
				address: getAddresses,
			},
		}));

		await this.fetchItems();
		this.enableSortTable();
	}

	async fetchItems(page, setLoading = false) {
		if (setLoading) this.setState({ dataIsLoaded: false });
		const data = await this.api.get('proposals', `?${this.buildFilters(page)}`);

		for (let p of data) {
			p.status = this.props.statuses.get(p.itemsStatusNumber)
				? this.props.statuses.get(p.itemsStatusNumber).name
				: 'Mixed';
			p.notificationSent = p.lastNotificationId > 0;
		}

		const fetchReportFormats = await this.api.get(
			'reportFormats/types/proposal'
		);

		this.setTotalChecked(data);

		const lastNotificationIds = data
			.map((i) => i.lastNotificationId)
			.filter((val) => val !== null && val !== undefined);

		if (lastNotificationIds?.length) {
			const notifInfo = await this.api.get(
				`notifications`,
				`?$filter=id in (${lastNotificationIds.join()})`
			);

			for (let item of data) {
				let notif = notifInfo.find((i) => i.id === item.lastNotificationId);

				const toInfo = this.getNotifPayload(notif?.notificationPayload, 'To');

				item.sentInfo =
					toInfo && isArray(toInfo) ? toInfo.join('\n') : toInfo ?? '';

				let sentStatus = '';
				const sentInfo = notif?.sentInfo ? `Sent to: ${notif.sentInfo}` : '';
				if (item.notificationSent) {
					item.sentDateTime = formatDateTime(item.sentDateTimeInUserTimeZone);

					sentStatus = sentInfo;
				}

				if (item.lastNotificationId !== null && !item.notificationSent) {
					item.sentInfo = 'Pending...';
				}

				item.sentStatus = sentStatus;
			}
		}

		this.setState({
			reportFormats: fetchReportFormats.reportFormats,
			dataIsLoaded: true,
			data: data,
		});
	}

	setTotalChecked = (data) =>
		this.setState({
			totalChecked: (data || []).filter((d) => this.state.checks.includes(d.id))
				?.length,
		});

	getNotifPayload(payload, key) {
		payload = payload?.replace(/[\n\r\t]/g, '');
		if (payload?.length) {
			try {
				payload = JSON.parse(payload);
				return payload[key];
			} catch (error) {
				// Ignore
				console.error(error);
			}
		}

		return '';
	}

	handleShowTableSearch = (e) => {
		const filters = document.querySelectorAll('.a-table-search-fields input');
		if (filters) {
			filters.forEach((e) => {
				e.value = '';
			});
		}

		let newTableSearch = !this.state.showTableSearch;
		this.fs.setIsActiveSearch(newTableSearch);
		this.setState({
			showTableSearch: newTableSearch,
		});

		if (JSON.stringify(this.state.searchProperties) !== '{}') {
			this.fs.setSearches({});
			this.setState(
				{
					searchProperties: {},
				},
				this.changePageHandler
			);
		}
	};

	handleFilter = (name) => (e) => {
		this.setState({ [name]: e, dataIsLoaded: false }, () => {
			this.fetchItems();
		});

		this.fs.setFilter(name, e);
	};

	async handleDocument(e, item) {
		e.preventDefault();
		displayAlertLoader();

		let fileId =
			item.fileId === INVALID_FILE_ID
				? null
				: item.fileId || this.state.newFileIds[item.id];

		try {
			if (!fileId) {
				const data = await this.api.getProjectProposals(
					`?$filter=id eq ${item.id}`
				);
				const fetchedProposalFileId =
					data[0].fileId !== INVALID_FILE_ID && data[0].fileId
						? data[0].fileId
						: null;
				if (!fetchedProposalFileId) {
					return displayAlertError(
						'The PDF file is not available. Please try again later.'
					);
				}
				fileId = data[0].fileId;
				this.setState({
					newFileIds: {
						...this.state.newFileIds,
						[item.id]: fileId,
					},
				});
			}

			if (this.state.project.clientPortalEnabled) {
				const document = await this.api.getPublicMappings(fileId, 'Proposal');

				window.open(document.link, '_blank');
				hideAlertLoader();
				return;
			}

			let document = await this.api.postJson('publicmappingbatches', {
				publicMappingObjects: [
					{
						objectId: item.id,
						objectType: 'Proposal',
					},
				],
			});

			window.open(document.link, '_blank').focus();
			hideAlertLoader();
		} catch {
			displayAlertError(
				'There was an error getting the PDF file, please try again'
			);
		}
	}

	enableSortTable() {
		const sort = document.querySelectorAll('.a-table-heading .sort');
		const self = this;

		// Add change event
		if (sort) {
			sort.forEach((_e) => {
				_e.addEventListener(
					'click',
					function (e) {
						sort.forEach((_e2) => {
							if (_e !== _e2) {
								removeDomClass('desc', _e2);
								removeDomClass('asc', _e2);
								removeDomClass('active', _e2);
							}
						});

						addDomClass('active', _e);

						if (hasClass('desc', _e)) {
							removeDomClass('desc', _e);
							addDomClass('asc', _e);
						} else if (hasClass('asc', _e)) {
							removeDomClass('asc', _e);
							addDomClass('desc', _e);
						} else {
							addDomClass('desc', _e);
						}

						const sortProperty = `${_e.attributes['data-field'].value} ${
							hasClass('desc', _e) ? 'asc' : 'desc'
						}`;

						// Save sortProperty to localStorage
						self.fs.setSort(sortProperty);
						self.setState(
							{
								sortProperty,
							},
							self.changePageHandler
						);
					},
					false
				);
			});
		}
	}

	doTableSearch(data) {}

	handleSelectAllChecks = (e) =>
		this.setState({
			checks: e.target.checked
				? this.state.data.map((item) => {
						return item.id;
				  })
				: [],
			totalChecked: e.target.checked ? this.state.data.length : 0,
		});

	handleCheck = (id) => {
		const checks = this.state.checks;
		const index = checks.findIndex((_id) => _id === id);

		index > -1 ? checks.splice(index, 1) : checks.push(id);

		this.setState({
			checks: checks,
			totalChecked: checks.length,
		});
	};

	buildFilters(currentPage) {
		let filtersQuery = `&$filter=proj eq '${this.state.project?.proj}'`;
		let filters = [];

		// active, inactive, completed
		if (this.state.statusFilter.value === 'active') {
			filters.push(`inactive eq false AND completed eq false`);
		} else if (this.state.statusFilter.value === 'inactive') {
			filters.push(`inactive eq true AND completed eq false`);
		} else if (this.state.statusFilter.value === 'completed') {
			filters.push(`completed eq true`);
		}

		Object.keys(this.state.searchProperties).forEach((key) => {
			const property = this.state.searchProperties[key];
			if (property.value || property.min) {
				if (property.type === 'number') {
					filters.push(`${key} eq ${property.value}`);
				} else if (property.type === 'date') {
					if (property.min) filters.push(`${key} ge ${property.min}`);
					if (property.max) filters.push(`${key} le ${property.max}`);
				} else {
					filters.push(`contains(${key}, '${property.value}')`);
				}
			}
		});

		filtersQuery += filters.length > 0 ? ` and ${filters.join(' and ')}` : '';

		let queryString = `$top=${this.state.pageSize + 1}&$skip=${
			((currentPage ?? this.state.page) - 1) * this.state.pageSize
		}${filtersQuery}`;

		if (this.state.sortProperty !== '') {
			queryString += `&$orderby=${this.state.sortProperty}`;
		}

		return queryString;
	}

	onPageSizeChanged = (size) => {
		this.setState(
			{
				pageSize: size,
				page: 1,
			},
			() => {
				this.handleChangePage(1);
			}
		);
	};

	onPageChanged = (page) => {
		this.handleChangePage(page);
	};

	handleChangePage = async (page) => {
		this.fetchItems(page, true);

		this.setState({
			page: page,
		});
	};

	handleSearch = (e) => {
		const key = e.target.attributes['data-field'].value;
		const value = e.target.value;
		const type = e.target.attributes['data-type']
			? e.target.attributes['data-type'].value
			: 'string';

		this.setState(
			{
				searchProperties: {
					...this.state.searchProperties,
					[key]: { value: value, type: type },
				},
				dataIsLoaded: false,
			},
			() => {
				this.fs.setSearches(this.state.searchProperties);
				this.changePageHandler();
			}
		);
	};

	handleDateChange = (key, e) => {
		let tmp = this.state.searchProperties;
		if (e !== null) {
			tmp[key] = {
				min: formatFilterDate(e[0]),
				max: formatFilterDate(e[1]),
				type: 'date',
			};
		} else {
			delete tmp[key];
		}
		this.setState(
			{
				searchProperties: tmp,
				dataIsLoaded: false,
			},
			() => {
				this.fs.setSearches(this.state.searchProperties);
				this.changePageHandler();
			}
		);
	};

	openProposalModal = async () => {
		try {
			displayAlertLoader(MSG.loading.info);
			const mappingBatches = Object.values(this.state.checks)
				.map((id) => {
					const obj = find(this.state.data, { id });
					if (obj) {
						return {
							objectId: obj.id,
							objectType: 'Proposal',
						};
					}
				})
				.filter(Boolean);

			const postData = await this.api.postJson('publicmappingbatches', {
				publicMappingObjects: mappingBatches,
			});

			this.setState((prevState) => ({
				showProposalModal: true,
				sendProposalData: {
					...prevState.sendProposalData,
					link: postData.link,
					token: postData.token,
				},
			}));
			hideAlertLoader();
		} catch (error) {
			displayAlert('danger', 'No document for this invoice.');
		}
	};

	closeProposalModal = () => {
		this.setState({ showProposalModal: false });
	};

	renderMessage = () => {
		return Object.values(this.state.checks)
			.map((id) => {
				const key = findIndex(this.state.data, { id });
				if (key !== -1) {
					const calculatedDeposit =
						this.state.data[key].requestedDeposit -
						this.state.data[key].receivedDeposit;
					return `Proposal ${this.props?.project?.proj}-${
						this.state.data[key].propnum
					} ${this.state.data[key].propname}, Deposit $${
						calculatedDeposit < 0 ? '0.00' : calculatedDeposit.toFixed(2)
					}`;
				}
			})
			.filter(Boolean);
	};

	getDocName = (styleId) => {
		const getDocument = this.state.reportFormats.filter((row) => {
			return row.id === styleId;
		});

		if (getDocument.length) {
			return getDocument[0].name;
		}

		return '';
	};

	// Refresh data
	refreshDataAfterDelete = (e) => {
		const headingCheck = document.getElementById('inline-check-th-0');

		this.setState({
			checks: [],
			showModal: false,
			deleteResponses: [],
		});

		if (typeof headingCheck != 'undefined' && headingCheck != null) {
			headingCheck.checked = false;
		}

		this.fetchItems(this.state.page, true);
	};

	hideModal = (e) => {
		this.setState({
			showModal: false,
		});
	};

	async handleTestDeleteObjects(deactivateOnly) {
		if (!this.state.checks.length) {
			return;
		}

		let workers = [];

		displayAlertLoader(MSG.loading.prepare.item);

		for (const id of this.state.checks) {
			const params = {
				deleteType: 'dmriTestOnly',
				objectType: this.deleteObjectParams.objectType,
				objectCodeOrId: id,
			};
			workers.push(await this.api.postJson('deleteobjects', params));
		}

		hideAlertLoader();

		if (workers) {
			let customMessage =
				'Some of the selected Proposals cannot be deleted because they either have a deposit received or one or more items have been purchased or invoiced. Do you wish to delete the proposals allowed?';

			if (
				workers.length > 1 &&
				workers.findIndex((response) => response.fullDeletePossible) === -1
			) {
				customMessage =
					'These Proposals cannot be deleted because it either has a deposit received or one or more items have been purchased or invoiced.';
			}

			HandleDeleteWorker(
				workers,
				{
					moduleSingular: this.objName,
					modulePlural: this.objName + 's',
					reference: this.objReference,
					objectType: this.deleteObjectParams.objectType,
					customMessage: customMessage,
					canComplete: false,
					canDeactivate: false,
				},
				false,
				(modalState) => {
					this.setState(modalState);
				}
			);
		}
	}

	handleOpenHistoryModal = (e) => {
		e.preventDefault();
		if (this.state.totalChecked > 1) {
			showErrorMessage(MSG.error.onlyOneSelected);
			return;
		}

		this.setState({
			showHistoryModal: true,
		});
	};

	sortClass(name) {
		return `sort ${this.fs.isActiveSort(name)}`;
	}

	isDisplaySearch() {
		return (
			(this.state.data && this.state.data.length > 0) ||
			isFiltered(this.state.searchProperties) ||
			!this.state.dataIsLoaded
		);
	}

	openProposalStatus = (e) => {
		window.location.href = getRouteWithParam(URI.project.proposal.setStatus, {
			id: this.props.params.id,
			pid: this.state.checks[0],
		});
	};

	renderActionDropDown() {
		if (this.props.closed) {
			return (
				<DropdownButton
					className="d-flex justify-content-end ms-3 btn-icon"
					align="end"
					id="dact"
					variant="primary"
					size="sm"
					title="Actions"
					disabled
				/>
			);
		} else if (this.state.checks.length === 0) {
			return (
				<OverlayTrigger
					id="otr"
					overlay={<Tooltip id="selprop">Please select a Proposal!</Tooltip>}
				>
					<DropdownButton
						disabled={true}
						className="d-flex justify-content-end ms-3 btn-icon"
						align="end"
						id="dact"
						variant="ivory"
						size="sm"
						title="Actions"
					></DropdownButton>
				</OverlayTrigger>
			);
		} else {
			return (
				<DropdownButton
					className="d-flex justify-content-end ms-3 btn-icon"
					align="end"
					id="dact"
					variant="primary"
					size="sm"
					title="Actions"
				>
					{/* show v1
                    <Dropdown.Item disabled={this.state.totalChecked == 1 ? false : true} to={getRouteWithParam(URI.project.proposal.status, { id: this.props.params.id, pid: this.state.pid })}><i className="ri-time-fill"></i> Show Status</Dropdown.Item>
                    */}

					<Dropdown.Item onClick={this.openProposalModal}>
						<i className="ri-mail-send-line"></i> Send
					</Dropdown.Item>

					{this.state.project.clientPortalEnabled ? (
						<>
							<Dropdown.Item
								onClick={(e) =>
									this.handleSelectToggleShowInClientPortal(e, 'on')
								}
							>
								<div className="tw-w-full tw-flex tw-gap-2">
									<Icon path={ClientPortalIconAdd} />
									<span>Include on Portal</span>
								</div>
							</Dropdown.Item>
							<Dropdown.Item
								onClick={(e) =>
									this.handleSelectToggleShowInClientPortal(e, 'off')
								}
							>
								<div className="tw-w-full tw-flex tw-gap-2">
									<Icon path={ClientPortalIconRemove} />
									<span className="tw-mr-4">Remove from Portal</span>
								</div>
							</Dropdown.Item>
						</>
					) : (
						<Dropdown.Item>
							<Link
								to={`/projects/${this.state.project.id}/settings`}
								className="tw-no-underline tw-font-bold tw-flex tw-gap-1 tw-text-[#008b6d]"
							>
								<Icon
									path={ClientPortalIconDefault}
									iconClass="tw-w-5 tw-h-5"
								/>
								Enable Portal
							</Link>
						</Dropdown.Item>
					)}

					<Dropdown.Item
						disabled={this.state.totalChecked == 1 ? false : true}
						as={Link}
						to={getRouteWithParam(URI.project.proposal.history, {
							id: this.props.params.id,
							pid: this.state.checks[0],
						})}
						onClick={this.handleOpenHistoryModal}
					>
						<i className="ri-history-line"></i> Show History
					</Dropdown.Item>
					<Feature name={FeatureFlags.ProjectProposalStatus}>
						<Dropdown.Item
							disabled={this.state.totalChecked == 1 ? false : true}
							onClick={this.openProposalStatus}
						>
							<i className="ri-check-double-line"></i> Show Status
						</Dropdown.Item>
					</Feature>
					<SecureDropdownItem
						attributeNo={17}
						attributeType={SECURITY_ATTRIBUTE_TYPES.DenyDel}
						onClick={this.handleTestDeleteObjects.bind(this)}
						href="#"
						className={this.state.checks.length === 0 ? 'disabled' : ''}
					>
						<i className="ri-close-line"></i> Delete
					</SecureDropdownItem>
				</DropdownButton>
			);
		}
	}

	RangePicker(name) {
		return (
			<DateRangePicker
				style={{
					minWidth: '200px',
				}}
				placement="auto"
				placeholder="Select date"
				format="MM/dd/yyyy"
				onChange={this.handleDateChange.bind(this, name)}
				onClean={this.handleDateChange.bind(this, name)}
				defaultValue={this.fs.getValue(name)}
				ranges={[
					{
						label: 'today',
						value: [startOfDay(new Date()), endOfDay(new Date())],
					},
					{
						label: 'yesterday',
						value: [
							startOfDay(addDays(new Date(), -1)),
							endOfDay(addDays(new Date(), -1)),
						],
					},
					{
						label: 'last7Days',
						value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
					},
					{
						label: 'Last 30 Days',
						value: [startOfDay(subDays(new Date(), 30)), endOfDay(new Date())],
					},
					{
						label: 'This month',
						value: [
							startOfDay(
								new Date(getYear(new Date()), getMonth(new Date()), 1)
							),
							endOfDay(lastDayOfMonth(new Date())),
						],
					},
					{
						label: 'Last month',
						value: [
							startOfDay(
								new Date(getYear(new Date()), getMonth(new Date()) - 1, 1)
							),
							endOfDay(
								lastDayOfMonth(
									new Date(getYear(new Date()), getMonth(new Date()) - 1, 1)
								)
							),
						],
					},
				]}
			/>
		);
	}

	renderListFilter() {
		return (
			<>
				<ListFilter>
					<ListFilter.Fields sm={12} md={9}>
						<ListFilter.Field>
							<Form.Label className="text-end">
								<strong>Show</strong>
							</Form.Label>
							<Select
								onChange={this.handleFilter('statusFilter')}
								styles={{
									container: (provided) => ({
										...provided,
										width: 230,
									}),
								}}
								key={`${Math.floor(Math.random() * 1000)}-min`}
								options={this.state.status}
								defaultValue={this.state.statusFilter}
								className="react-select"
								placeholder="Please select"
							/>
						</ListFilter.Field>
					</ListFilter.Fields>
					<ListFilter.Actions
						sm={12}
						md={3}
						replaceclassmatch="justify-content-xl-end"
						replaceclassmatchwith="justify-content-lg-end"
					>
						{this.isDisplaySearch() && (
							<ListFilter.Action className="me-2 me-sm-3">
								<Button
									as={Link}
									to="#"
									variant="ivory"
									size="sm"
									className={`btn-icon btn-action fw-bold ${
										this.state.showTableSearch
											? 'bg-primary-ash text-white'
											: ''
									}`}
									onClick={this.handleShowTableSearch}
								>
									<i className="ri-search-line"></i> Search
								</Button>
							</ListFilter.Action>
						)}
						<ListFilter.Action>
							{this.props.closed ? (
								<Button
									disabled
									variant="primary"
									size="sm"
									className="btn-icon"
								>
									<i className="ri-add-fill"></i> Add
								</Button>
							) : (
								<SecureBootstrapButton
									attributeNo={17}
									attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAdd}
									as={Link}
									to={getRouteWithParam(URI.project.proposal.add, {
										id: this.props.params.id,
									})}
									variant="primary"
									size="sm"
									className="btn-icon"
								>
									<i className="ri-add-fill"></i> Add
								</SecureBootstrapButton>
							)}
						</ListFilter.Action>

						<ListFilter.Action className="ms-auto ms-lg-0">
							{this.renderActionDropDown()}
						</ListFilter.Action>
					</ListFilter.Actions>
				</ListFilter>
				<ListFilter.Spacer />
			</>
		);
	}

	renderPagination() {
		if (this.state.data.length) {
			return (
				<Pagination
					onPageSizeChanged={this.onPageSizeChanged}
					onPageChanged={this.onPageChanged}
					hasPreviousPage={this.state.page > 1}
					hasNextPage={this.state.data.length > this.state.pageSize}
					page={this.state.page}
					pageSize={this.state.pageSize}
				/>
			);
		}
	}

	handleSelectToggleShowInClientPortal = async (e, action) => {
		const updatedValue = action === 'on';

		const proposalsToUpdate = this.state.data.filter(
			(item) =>
				this.state.checks.includes(item.id) &&
				(item.showInClientPortal == null ||
					item.showInClientPortal !== updatedValue)
		);

		if (proposalsToUpdate.length === 0) {
			displayAlert(
				'info',
				`No changes needed. All selected proposals are already ${
					updatedValue ? 'included' : 'removed'
				} from the Client Portal.`
			);
			return;
		}

		try {
			const promises = proposalsToUpdate.map((proposal) =>
				this.api.postToggleShowInClientPortal(
					proposal.id,
					updatedValue,
					'proposals'
				)
			);

			await Promise.all(promises);

			this.setState((prevState) => ({
				data: prevState.data.map((item) =>
					this.state.checks.includes(item.id) &&
					item.showInClientPortal !== updatedValue
						? { ...item, showInClientPortal: updatedValue }
						: item
				),
			}));

			const proposalIds = proposalsToUpdate.map((p) => p.propnum).join(', ');
			displayAlertSuccess(
				`Proposal(s) ${proposalIds}: ${
					updatedValue ? 'Included on' : 'Removed from'
				} Client Portal`
			);
		} catch (error) {
			const errorMessage =
				error.response?.data?.userError ||
				`Failed to update Proposal(s)'s Client Portal.`;
			displayAlertError(errorMessage);
		}
	};

	handleToggleShowInClientPortal = async (item) => {
		try {
			const updatedValue = !item.showInClientPortal;
			item.showInClientPortal = updatedValue;

			this.forceUpdate?.();

			await this.api.postToggleShowInClientPortal(
				item.id,
				updatedValue,
				'proposals'
			);

			displayAlert(
				'success',
				`Proposal ${item.propnum} : ${
					updatedValue ? 'Included on' : 'Removed from'
				} Client Portal`
			);
		} catch (error) {
			item.showInClientPortal = !item.showInClientPortal;
			this.forceUpdate?.();

			const errorMessage =
				error.response?.data?.userError ||
				`Failed to update Proposal ${item.propnum}'s Client Portal.`;
			displayAlert('danger', errorMessage);
		}
	};

	renderContent() {
		const { data, totalChecked, checks } = this.state;
		return (
			<>
				{(this.state.data && this.state.data.length > 0) ||
				isFiltered(this.state.searchProperties) ||
				!this.state.dataIsLoaded ? (
					<>
						<div className="table-gradient sticky-container">
							<Table striped responsive className="a-table">
								<thead>
									<tr key="0" className="a-table-heading">
										<th align="middle" className="mw-70px">
											<div className="d-flex justify-content-center">
												<Form.Check
													label=""
													type="checkbox"
													checked={checks.length}
													className={checks.length ? 'line' : ''}
													onChange={this.handleSelectAllChecks}
												/>
											</div>
										</th>
										<th>
											<span data-field="status">Sent Status</span>
										</th>
										<th>
											<span data-field="document">Document</span>
										</th>
										<th>
											<span
												className={this.sortClass('propnum')}
												data-field="propnum"
											>
												Proposal No.
											</span>
										</th>
										{this.state.project.clientPortalProposalsApproval && (
											<th>
												<span data-field="portalStatus">Portal Status</span>
											</th>
										)}
										<th>
											<span
												className={this.sortClass('propdate')}
												data-field="propdate"
											>
												Date
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('propname')}
												data-field="propname"
											>
												Name/Phase
											</span>
										</th>
										<th>
											<span data-field="status">Item(s) Status</span>
										</th>
										<th className="mw-90px">
											<span
												className={this.sortClass('style')}
												data-field="style"
											>
												Style
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('totalProposed')}
												data-field="totalProposed"
											>
												Total Proposed
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('requestedDeposit')}
												data-field="requestedDeposit"
											>
												Requested Deposit
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('receivedDeposit')}
												data-field="receivedDeposit"
											>
												Received Deposit
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('lastPaymentInfo')}
												data-field="lastPaymentInfo"
											>
												Last Payment Info.
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('lastpaymentdate')}
												data-field="lastpaymentdate"
											>
												Last Payment Date
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('totalInvoiced')}
												data-field="totalInvoiced"
											>
												Invoiced
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('appliedDeposit')}
												data-field="appliedDeposit"
											>
												Deposit Applied/Refunded
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('availableDeposit')}
												data-field="availableDeposit"
											>
												Deposit Available
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('adddep')}
												data-field="adddep"
											>
												Additional Deposit Required
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('adddepdt')}
												data-field="adddepdt"
											>
												Required Date
											</span>
										</th>
									</tr>
									<tr
										className={`a-table-search-fields ${
											this.state.showTableSearch ? '' : 'd-none'
										}`}
									>
										<th></th>
										<th></th>
										<th></th>
										<th>
											<Form.Control
												type="text"
												data-field="propnum"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('propnum')}
											/>
										</th>
										<th>{this.RangePicker('propdate')}</th>
										<th>
											<Form.Control
												type="text"
												data-field="propname"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('propname')}
											/>
										</th>
										<th></th>
										<th>
											<Form.Control
												type="number"
												data-field="style"
												data-type="number"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('style')}
											/>
										</th>
										<th>
											<Form.Control
												type="number"
												data-field="totalProposed"
												data-type="number"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('totalProposed')}
											/>
										</th>
										<th>
											<Form.Control
												type="number"
												data-field="requestedDeposit"
												data-type="number"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('requestedDeposit')}
											/>
										</th>
										<th>
											<Form.Control
												type="number"
												data-field="receivedDeposit"
												data-type="number"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('receivedDeposit')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="lastPaymentInfo"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('lastPaymentInfo')}
											/>
										</th>
										<th>{this.RangePicker('lastpaymentdate')}</th>
										<th>
											<Form.Control
												type="number"
												data-field="totalInvoiced"
												onChange={this.handleSearch}
												data-type="number"
												defaultValue={this.fs.getValue('totalInvoiced')}
											/>
										</th>
										<th>
											<Form.Control
												type="number"
												data-field="appliedDeposit"
												onChange={this.handleSearch}
												data-type="number"
												defaultValue={this.fs.getValue('appliedDeposit')}
											/>
										</th>
										<th>
											<Form.Control
												type="number"
												data-field="availableDeposit"
												onChange={this.handleSearch}
												data-type="number"
												defaultValue={this.fs.getValue('availableDeposit')}
											/>
										</th>
										<th>
											<Form.Control
												type="number"
												data-field="adddep"
												onChange={this.handleSearch}
												data-type="number"
												defaultValue={this.fs.getValue('adddep')}
											/>
										</th>
										<th>{this.RangePicker('adddepdt')}</th>
									</tr>
								</thead>
								<tbody>
									{!this.state.dataIsLoaded
										? showLoading()
										: this.state.data.length === 0 &&
										  isFiltered(this.state.searchProperties)
										? showEmpty()
										: this.state.data
												.slice(0, this.state.pageSize)
												.map((item, i) => (
													<tr
														key={i}
														className={this.state.checks[i] ? `active` : ''}
													>
														<td>
															<div className="d-flex justify-content-center">
																<Form.Check
																	label=""
																	type="checkbox"
																	checked={this.state.checks.includes(item.id)}
																	onChange={() => {
																		this.handleCheck(item.id);
																	}}
																/>
															</div>
														</td>
														<td>
															<div className="d-flex">
																<strong
																	className={
																		item.notificationSent
																			? `dot-primary`
																			: `dot-primary-red`
																	}
																></strong>
																{item.sentDateTime
																	? item.sentDateTime + '\n'
																	: ''}
																{item.sentStatus ? (
																	<>{item.sentStatus}</>
																) : (
																	<>{item.sentInfo}</>
																)}
															</div>
														</td>

														<td>
															<div className="tw-flex tw-gap-2">
																<a
																	href=""
																	onClick={(e) => {
																		this.handleDocument(e, item);
																	}}
																>
																	{(item.fileId &&
																		item.fileId !== INVALID_FILE_ID) ||
																	this.state.newFileIds[item.id]
																		? 'PDF'
																		: 'Pending'}
																</a>

																<button
																	onClick={() =>
																		this.handleToggleShowInClientPortal(item)
																	}
																	disabled={
																		!this.state.project.clientPortalEnabled
																	}
																	title={
																		!this.state.project.clientPortalEnabled
																			? 'Enable Portal'
																			: item.showInClientPortal
																			? 'Remove from Portal'
																			: 'Include on Portal'
																	}
																	className="disabled:tw-cursor-not-allowed"
																>
																	{item.showInClientPortal &&
																	this.state.project.clientPortalEnabled ? (
																		<Icon path={ClientPortalIconActive} />
																	) : (
																		<Icon path={ClientPortalIconInActive} />
																	)}
																</button>
															</div>
														</td>

														<td>
															{this.props.closed ? (
																item.propnum
															) : (
																<Link
																	to={getRouteWithParam(
																		URI.project.proposal.edit,
																		{
																			id: this.props.params.id,
																			pid: item.id,
																		}
																	)}
																	className="text-charcoal hover-view-icon"
																>
																	{item.propnum}
																</Link>
															)}
														</td>

														{this.state.project
															.clientPortalProposalsApproval && (
															<td className="tw-text-center">
																{this.state.project.clientPortalEnabled ? (
																	<div
																		className={`tw-inline-block tw-w-3 tw-h-3 tw-rounded-full ${
																			item.approvalStatus === 'Approved'
																				? 'tw-bg-green-700'
																				: item.approvalStatus === 'Rejected'
																				? 'tw-bg-red-500'
																				: 'tw-bg-gray-300'
																		}`}
																		title={item.approvalStatus}
																	/>
																) : (
																	<div
																		className="tw-inline-block tw-w-3 tw-h-3 tw-rounded-full tw-bg-gray-400"
																		title="Enable Portal"
																	/>
																)}
															</td>
														)}
														<td>{formatDate(item.propdate)}</td>
														<td>{item.propname}</td>
														<td>
															<strong
																className={`dot`}
																style={{
																	'--color':
																		this.props.statuses.get(
																			item.itemsStatusNumber
																		)?.color ?? 'white',
																}}
															>
																{!isEmpty(this.props.statuses)
																	? this.props.statuses.get(
																			item.itemsStatusNumber
																	  )?.name
																	: ''}
															</strong>
														</td>
														<td>{this.getDocName(item.style)}</td>
														<td>{currencyFormat(item.totalProposed, '$')}</td>
														<td>
															{currencyFormat(item.requestedDeposit, '$')}
														</td>
														<td>{currencyFormat(item.receivedDeposit, '$')}</td>
														<td>{item.lastPaymentInfo}</td>
														<td>{formatDate(item.lastPaymentDate)}</td>
														<td>{currencyFormat(item.totalInvoiced)}</td>
														<td>{currencyFormat(item.appliedDeposit)}</td>
														<td>{currencyFormat(item.availableDeposit)}</td>
														<td>{currencyFormat(item.adddep)}</td>
														<td>{formatDate(item.adddepdt)}</td>
													</tr>
												))}
								</tbody>
							</Table>
						</div>
						{this.renderPagination()}
					</>
				) : (
					''
				)}
				{this.state.dataIsLoaded &&
					!isFiltered(this.state.searchProperties) &&
					!this.state.data.length && (
						<div className="row justify-content-center text-center py-5">
							<div className="col-md-3">
								<img src={noItems} className="mw-100 mb-4" alt="" />

								<h6>Create a Proposal</h6>
								<p>
									This is where you will manage the project proposals that you
									put in front of clients.
								</p>

								<SecureBootstrapButton
									attributeNo={17}
									attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAdd}
									as={Link}
									to={getRouteWithParam(URI.project.proposal.add, {
										id: this.props.params.id,
									})}
									variant="primary"
									size="md"
									className="btn-icon mt-4"
								>
									<i className="ri-add-line ri-lg"></i> Add Proposal
								</SecureBootstrapButton>
							</div>
						</div>
					)}
			</>
		);
	}

	render() {
		return (
			<>
				{/* Filter */}
				{this.renderListFilter()}

				{/* Content */}
				{this.renderContent()}

				<ProposalHistoryModal
					show={this.state.showHistoryModal}
					id={this.state.checks[0]}
					hideModal={() => this.setState({ showHistoryModal: false })}
				/>

				<SendProposalModal
					refreshData={this.refreshDataAfterDelete.bind(this)}
					show={this.state.showProposalModal}
					hideModal={this.closeProposalModal}
					sendProposalData={this.state.sendProposalData}
					message={this.renderMessage()}
					project={this.state.project}
				/>

				<DeleteModal
					refreshData={this.refreshDataAfterDelete.bind(this)}
					show={this.state.showModal}
					hideModal={this.hideModal}
					responses={this.state.deleteResponses}
				/>
			</>
		);
	}
}

export default WithRouter(ProjectViewProposal);
