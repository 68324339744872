import { Component } from 'react';
import { getClassNames } from '../../helpers/DOM';

class Header extends Component {
	static Title(props) {
		if (
			props.hasOwnProperty('className') &&
			props.className.includes('project')
		) {
			return (
				<div>
					<h1
						data-testid="header-title-h1"
						className={getClassNames(
							props,
							`text-${props.variant || 'white'} ${props.className || ''}`
						)}
					>
						{props.children}
					</h1>
					<h2
						data-testid="header-title-h2"
						className={getClassNames(
							props,
							`text-${props.variant || 'white'} ${props.className || ''}`
						)}
					>
						{props.client}
					</h2>
				</div>
			);
		} else {
			return (
				<h2
					className={getClassNames(
						props,
						`text-${props.variant || 'white'} ${props.className || ''}`
					)}
					data-testid="header-title-h2"
				>
					{props.children}
				</h2>
			);
		}
	}

	static Subtitle(props) {
		return (
			<h3
				className={getClassNames(
					props,
					`text-${props.variant || 'white'} ${props.className || ''}`
				)}
			>
				{props.children}
			</h3>
		);
	}

	static Actions(props) {
		function display() {
			// Check if we wrap in <ul></ul>
			if (props.list === undefined || props.list)
				return (
					<ul className="d-flex justify-content-end list-unstyled mb-0 p-0">
						{props.children}
					</ul>
				);
			else return <>{props.children}</>;
		}

		return <div className="a-header-cta">{display()}</div>;
	}

	static Buttons(props) {
		return (
			<ul
				className="a-header-buttons d-flex list-unstyled mb-0 p-0"
				data-testid="heading-region-buttons"
			>
				{props.children}
			</ul>
		);
	}

	static Breadcumbs(props) {
		return (
			<div className="a-header-breadcrumb mb-4" data-testid="breadcrumbs">
				<div className="d-flex align-items-center">{props.children}</div>
			</div>
		);
	}

	static LeftContent(props) {
		return <div className="d-block">{props.children}</div>;
	}

	static TitleContent(props) {
		return (
			<div
				className={getClassNames(
					props,
					`a-header-title d-flex justify-content-between align-items-end align-items-md-center mb-1 ${
						props.className || ''
					}`
				)}
			>
				{props.children}
			</div>
		);
	}

	render() {
		return (
			<div
				className={getClassNames(
					this.props,
					`a-header bg-${this.props.variant || 'secondary-green'} ${
						this.props.className || ''
					} gradient`
				)}
				{...this.props}
				data-testid="heading-region"
			>
				{!this.props.buttons
					? Header.TitleContent(this.props)
					: this.props.children}
			</div>
		);
	}
}

/**
 * 
 * Displays white header.
 * Sample Usage:
    <HeaderLight>
        <HeaderLight.Breadcumbs>
            <NavLink to={'/'} className="text-primary active d-flex align-items-center text-decoration-none fw-bold">
                <i className="ri-arrow-left-s-line"></i> Back
            </NavLink>
            <Breadcrumb className='ms-4'>
                <Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link}>Sample</Breadcrumb.Item>
                <Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link}>Sample</Breadcrumb.Item>
                <Breadcrumb.Item>Screen</Breadcrumb.Item>
            </Breadcrumb>   
        </HeaderLight.Breadcumbs>
        <HeaderLight.Content>
            <HeaderLight.Title>Title</HeaderLight.Title>
        </HeaderLight.Content>
        <HeaderLight.Actions>
            <li><Link to={URI.project.add} data-menu="info" className={`btn text-charcoal`} onClick={this.handleMenuClick}>Info</Link></li>
            <li><Link to={URI.project.add} data-menu="defaults" className={`btn text-charcoal`} onClick={this.handleMenuClick}>Defaults</Link></li>
            <li><Link to={URI.project.add} data-menu="notes" className={`btn text-charcoal`} onClick={this.handleMenuClick}>Notes</Link></li>
            <li><Link to={URI.project.add} data-menu="budget" className={`btn text-charcoal`} onClick={this.handleMenuClick}>Budget</Link></li>
        </HeaderLight.Actions>
    </HeaderLight>
 */
class HeaderLight extends Header {
	static Title(props) {
		return (
			<Header.Title
				client={`${props.client || ''}`}
				className={`${props.className || ''}`}
				variant={props.variant || 'charcoal'}
			>
				{props.children}
			</Header.Title>
		);
	}

	static Actions(props) {
		return (
			<ul
				className={`a-header-buttons d-flex list-unstyled mb-0 p-0 ${
					props.className || ''
				}`}
				data-testid="heading-region-light-actions"
			>
				{props.children}
			</ul>
		);
	}

	static Content(props) {
		return (
			<div
				className={`a-header-title d-flex justify-content-between align-items-center ${
					props.actions ? 'mb-4' : ''
				} ${props.className}`}
			>
				{props.children}
			</div>
		);
	}

	render() {
		return (
			<div
				style={{ ...(this.props.style || {}) }}
				className={`a-header bg-${
					this.props.variant || 'ivory'
				} gradient light ${this.props.className ?? ''}`}
				data-testid="heading-region-light"
			>
				{this.props.children}
			</div>
		);
	}
}

export default Header;

export { HeaderLight };
