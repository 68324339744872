import Axios from 'axios';
import type { AxiosError, AxiosRequestConfig } from 'axios';
import { INVALID_REQUESTS } from 'legacy/lib/api/types/UserError';
import { getCookie } from 'utils/cookies/cookies';
import { logUserOutAndRedirectToLogin } from 'utils/misc/logUserOutAndRedirectToLogin';
import { v4 } from 'uuid';

const DEFAULT_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1`;
export const PUBLIC_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/public/v1`;
const AUTH_BASE_URL = process.env.REACT_APP_API_AUTH_BASE_URL;
const API_CLIENT_ID = '42919f39-4705-447e-b93f-de5373c57b62';

// Declare the baseURL once for our API and set it in Axios
export const axios = Axios.create({
	baseURL: DEFAULT_BASE_URL,
});

// Set auth headers once for all requests
axios.interceptors.request.use(async (config) => {
	const token = getCookie('dmAuthToken');
	const instanceId = getCookie('dmInstanceId') || '';
	config.baseURL = config.baseURL || DEFAULT_BASE_URL;
	config.headers.Accept =
		config.responseType === 'blob'
			? 'application/x-www-form-urlencoded'
			: 'application/json';
	config.headers['X-InstanceId'] = instanceId;
	config.headers.Authorization = `Bearer ${token}`;
	config.headers['X-Correlation-Id'] = v4();
	config.headers['X-AppVersion'] = process.env.REACT_APP_VERSION || 'not set';
	config.headers['X-ApiClientId'] = API_CLIENT_ID;

	return config;
});

// We check to see if another user is logged in and if so, log the current user out
axios.interceptors.response.use(
	(response) => response,
	(error) => {
		if (
			(error as AxiosError<{ UserError: string }>)?.response?.status === 403 &&
			(error as AxiosError<{ UserError: string }>)?.response?.data
				?.UserError === INVALID_REQUESTS.LOGGED_OUT
		) {
			return logUserOutAndRedirectToLogin();
		}
		throw error;
	}
);

// The purpose of this object is to abstract away our fetching functions
// and make it easy in the future to swap out Axios for another library
// if we ever need it to. Keep in mind that we intentionally do not
// pass the `config` object directly to the Axios functions, as it may  not
// be compatible with other libraries.
export const apiClient = {
	get: (route: string, config?: AxiosRequestConfig) =>
		axios.get(route, {
			responseType: config?.responseType || 'json',
			signal: config?.signal,
			params: config?.params,
			baseURL: config?.baseURL,
		}),
	post: (route: string, data: unknown, config?: AxiosRequestConfig) =>
		axios.post(route, data, {
			signal: config?.signal,
		}),
	postPublic: (route: string, data: unknown, config?: AxiosRequestConfig) =>
		axios.post(route, data, {
			signal: config?.signal,
			baseURL: PUBLIC_BASE_URL,
		}),
	postAuth: (route: string, data: unknown, config?: AxiosRequestConfig) => {
		const authData = {
			...(data as object),
			// eslint-disable-next-line camelcase
			client_id: API_CLIENT_ID,
			// eslint-disable-next-line camelcase
			grant_type: 'password',
		};
		return axios.post(route, authData, {
			signal: config?.signal,
			baseURL: AUTH_BASE_URL,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		});
	},
	patch: (route: string, data: unknown, config?: AxiosRequestConfig) =>
		axios.patch(route, data, {
			signal: config?.signal,
		}),
	put: (route: string, data: unknown, config?: AxiosRequestConfig) =>
		axios.put(route, data, {
			signal: config?.signal,
		}),
	delete: (route: string, config?: AxiosRequestConfig) =>
		axios.delete(route, {
			signal: config?.signal,
		}),
};
