import { Col, Form } from 'react-bootstrap';
import React, {
	forwardRef,
	memo,
	useCallback,
	useEffect,
	useImperativeHandle,
	useState,
} from 'react';
import { RTFJS } from 'rtf.js';
import { stringToArrayBuffer } from 'legacy/helpers/String';
import WysiwygEditor from '../../../../components/WysiwygEditor';
import { fetchImage } from '../../StockItemsService';
import DatePicker from 'react-datepicker';
import AsyncSalesCategoriesDropdown from 'legacy/app/components/dropdowns/AsyncSalesCategoriesDropdown';
import AsyncVendorsDropdown from 'legacy/app/components/dropdowns/AsyncVendorsDropdown';
import AsyncProjectsDropdown from 'legacy/app/components/dropdowns/AsyncProjectsDropdown';
import AsyncSelect from 'react-select/async';
import AsyncDropdownLoadingIndicator from 'legacy/app/components/dropdowns/utils/AsyncDropdownLoadingIndicator';
import FormCurrencyInput from 'legacy/app/components/form/FormCurrencyInput';
import { TTypeOption } from 'legacy/app/components/dropdowns/types/TTypeOption';
import ImageDragAndDrop from 'legacy/app/components/ImageDragAndDrop/ImageDragAndDrop';

interface IFormField {
	type:
		| 'text'
		| 'wysiwyg'
		| 'dropzone'
		| 'checkbox'
		| 'datepicker'
		| 'item'
		| 'select'
		| 'currency';
	title?: string;
	titleClass?: string;
	ids: string[];
	required?: boolean;
	isTwoColumn?: boolean;
	readOnly?: boolean;
	value?: any;
	handler?: (data: Map<string, any>, value: any) => Map<string, any>;
	isHidden?: boolean;
	formatter?: (value: any) => string;
	maxLength?: number;
}
interface ITextFieldModel extends IFormField {
	type: 'text';
	inputRegex?: RegExp;
}
interface ISelectFieldModel extends IFormField {
	type: 'select';
	subtype: 'salesCategory' | 'supplier' | 'project' | 'custom';
	options?: TTypeOption[];
	isLoading?: boolean;
}
interface IWysiwygFieldModel extends IFormField {
	type: 'wysiwyg';
}
interface IDropzoneFieldModel extends IFormField {
	type: 'dropzone';
	file?: File;
}
interface ICheckboxFieldModel extends IFormField {
	type: 'checkbox';
}
interface IDatepickerFieldModel extends IFormField {
	type: 'datepicker';
}
interface ICurrencyFieldModel extends IFormField {
	type: 'currency';
}

type TFormFieldType =
	| ITextFieldModel
	| ISelectFieldModel
	| IWysiwygFieldModel
	| IDropzoneFieldModel
	| ICheckboxFieldModel
	| IDatepickerFieldModel
	| ICurrencyFieldModel;

function isTextField(model: IFormField): model is ITextFieldModel {
	return model.type == 'text';
}
function isWysiwygField(model: IFormField): model is IWysiwygFieldModel {
	return model.type == 'wysiwyg';
}
function isDropzoneField(model: IFormField): model is IDropzoneFieldModel {
	return model.type == 'dropzone';
}
function isCheckboxField(model: IFormField): model is ICheckboxFieldModel {
	return model.type == 'checkbox';
}
function isDatepickerField(model: IFormField): model is IDatepickerFieldModel {
	return model.type == 'datepicker';
}
function isSelectFieldModel(model: IFormField): model is ISelectFieldModel {
	return model.type == 'select';
}
function isCurrencyField(model: IFormField): model is ICurrencyFieldModel {
	return model.type == 'currency';
}

export type TFormColumn = {
	key: string;
	fields: TFormFieldType[];
	isTwoColumn?: boolean;
	sm: number;
	lg: number;
	md?: number;
};

type TFormSection = {
	key: string;
	columns: TFormColumn[];
};

type TForm = {
	sections: TFormSection[];
};

interface ITextFieldProps {
	title: string;
	readOnly: boolean;
	value: string;
	wrapperClass?: string;
	inputRegex: RegExp;
	colClass?: string;
	titleClass: string;
	formatter: (value: any) => string;
	maxLength: number;
	onChange: (value: any) => void;
}
const TextField = forwardRef<any, any>((props: ITextFieldProps, ref) => {
	const [value, setValue] = useState(props.value);
	const handleChange = (e: any) => {
		if (props.inputRegex && !props.inputRegex.test(e.target.value)) {
			return;
		}

		setValue(e.target.value);
		props.onChange(e);
	};

	useEffect(() => {
		setValue(props.value);
	}, [props]);

	useImperativeHandle(ref, () => ({
		updateValue(value: any) {
			setValue(value);
		},
	}));

	return (
		<div
			ref={ref}
			className={`${
				props.wrapperClass ?? 'd-flex flex-row align-items-center'
			}`}
		>
			<Col
				sm={3}
				className={props.colClass ?? 'text-end me-2 align-content-center'}
			>
				<Form.Label className={`m-0 ${props.titleClass}`}>
					{props.title}
				</Form.Label>
			</Col>
			<Form.Control
				type="text"
				placeholder="Please enter"
				onChange={handleChange}
				disabled={props.readOnly}
				value={props.formatter ? props.formatter(value) : value ?? ''}
				maxLength={props.maxLength}
			/>
		</div>
	);
});
TextField.displayName = 'TextField';

interface ICurrencyField {
	title: string;
	readOnly: boolean;
	value: string;
	colClass?: string;
	titleClass: string;
	onChange: (value: any) => void;
}
const CurrencyField = forwardRef<any, any>((props: ICurrencyField, ref) => {
	const [value, setValue] = useState(props.value);
	const handleChange = (e: any) => {
		setValue(e.target.value);
		props.onChange(e);
	};

	useEffect(() => {
		setValue(props.value);
	}, [props]);

	useImperativeHandle(ref, () => ({
		updateValue(value: any) {
			setValue(value);
		},
	}));

	return (
		<div ref={ref} className="d-flex flex-row align-items-center">
			<Col
				sm={3}
				className={props.colClass ?? 'text-end me-2 align-content-center'}
			>
				<Form.Label className={`m-0 ${props.titleClass ?? ''}`}>
					{props.title}
				</Form.Label>
			</Col>
			<FormCurrencyInput
				sizeClass="lg"
				placeholder="Please enter"
				onValueChange={(value) => {
					const e = {
						target: {
							value: value,
						},
					};
					handleChange(e);
				}}
				disabled={props.readOnly}
				readOnly={props.readOnly}
				value={value}
			/>
		</div>
	);
});
CurrencyField.displayName = 'CurrencyField';

interface IWysiwygField {
	title?: string;
	value: string;
	hideLabel?: boolean;
	colClass?: string;
	className?: string;
	onChange: (value: any) => void;
}
const WysiwygField = (props: IWysiwygField) => {
	const [value, setValue] = useState<string>('');

	useEffect(() => {
		const resolveValue = async () => {
			if (value != '') {
				return;
			}

			const newValue = props.value ? await mapRtfToDraft(props.value) : '';
			setValue(newValue);
		};

		resolveValue();
	}, [props.value, value]);

	const mapRtfToDraft = async (rtf: string) => {
		if (!rtf || !rtf.length) {
			return '';
		}

		const doc = new RTFJS.Document(stringToArrayBuffer(rtf), {});
		const htmlElements = await doc.render();
		return htmlElements
			.map((element: any) => {
				return element.outerHTML;
			})
			.join(' ');
	};
	return (
		<div className={`d-flex flex-row ${props.className}`}>
			{!props.hideLabel && (
				<Col
					sm={3}
					className={props.colClass ?? 'text-end me-2 align-content-start'}
				>
					<Form.Label className="m-0">{props.title}</Form.Label>
				</Col>
			)}
			<Col>
				<WysiwygEditor
					onChange={(e: any) => {
						props.onChange(e);
					}}
				>
					{value}
				</WysiwygEditor>
			</Col>
		</div>
	);
};
WysiwygField.displayName = 'WysiwygField';

interface ISelectField {
	title?: string;
	titleClass?: string;
	value: string;
	colClass?: string;
	labelClass?: string;
	options?: TTypeOption[];
	className?: string;
	onChange: (value: any) => void;
	select: (props: {
		onChange: (value: any) => void;
		value: any;
		options: TTypeOption[];
	}) => JSX.Element;
}
const SelectField = (props: ISelectField) => {
	const [value, setValue] = useState<TTypeOption>();
	const [options, setOptions] = useState<any[]>([]);

	useEffect(() => {
		const options = props.options ?? [];
		setOptions(options);

		const value = options.find((option: any) => option.value == props.value);
		setValue(value);
	}, [props]);

	const handleChange = (e: any) => {
		const value = options.find((option: any) => option.value == e);
		setValue(value);
		props.onChange(e);
	};

	return (
		<div className={`d-flex flex-row ${props.className}`}>
			<Col
				sm={3}
				className={
					props.colClass ??
					'text-end me-2 align-content-start align-content-center'
				}
			>
				<Form.Label
					className={`m-0 ${(props.titleClass || props.labelClass) ?? ''}`}
				>
					{props.title}
				</Form.Label>
			</Col>
			<Col>
				{props.select({
					onChange: (e: any) => {
						handleChange(e);
					},
					value: value,
					options: options,
				})}
			</Col>
		</div>
	);
};
SelectField.displayName = 'SelectField';

interface IDropzone {
	id: string;
	file?: File;
	onChange: (value: any) => void;
}
const Dropzone = memo(
	(props: IDropzone) => {
		const [file, setFile] = useState<File | Blob | undefined>(props.file);
		const [imageId, setImageId] = useState<string | undefined>(props.id);

		const getImage = useCallback(async () => {
			if (!props.id || file) {
				return;
			}

			if (imageId) {
				const image = await fetchImage(imageId);
				setFile(image as Blob);
				props.onChange(image);
			}
		}, [file, props, imageId]);
		const handleAcceptedFiles = (file: File) => {
			setFile(file);

			props.onChange(file);
		};
		const handleRemoveFiles = async () => {
			setFile(undefined);
			setImageId(undefined);
			props.onChange(undefined);
		};

		useEffect(() => {
			getImage();
		}, [getImage, props]);

		return (
			<ImageDragAndDrop
				image={file}
				handleDelete={handleRemoveFiles}
				handleFile={handleAcceptedFiles}
			/>
		);
	},
	(oldProps, newProps) => {
		return (
			oldProps.id == newProps.id && oldProps.file?.size === newProps.file?.size
		);
	}
);
Dropzone.displayName = 'Dropzone';

interface ICheckbox {
	title?: string;
	value: boolean;
	className?: string;
	colClass?: string;
	defaultChecked?: boolean;
	onChange: (value: any) => void;
}
const Checkbox = (props: ICheckbox) => {
	return (
		<div className={`d-flex flex-row ${props.className}`}>
			<Col
				sm={3}
				className={
					props.colClass ??
					'text-end me-2 align-content-start align-content-center'
				}
			/>
			<Col>
				<Form.Check
					inline
					label={props.title}
					type="checkbox"
					defaultChecked={props.value}
					onClick={(e) => {
						props.onChange((e.target as HTMLInputElement).checked);
					}}
				/>
			</Col>
		</div>
	);
};
Checkbox.displayName = 'Checkbox';

interface IDatepickerField {
	title?: string;
	titleClass?: string;
	value: string;
	className?: string;
	onChange: (value: any) => void;
}
const DatepickerField = (props: IDatepickerField) => {
	const [value, setValue] = useState(new Date(props.value));
	const handleChange = (e: any) => {
		setValue(new Date(e));
		props.onChange(e);
	};
	return (
		<div className={`d-flex flex-row ${props.className ?? ''}`}>
			<Col
				sm={3}
				className="text-end me-2 align-content-start align-content-center"
			>
				<Form.Label className={`m-0 ${props.titleClass ?? ''}`}>
					{props.title}
				</Form.Label>
			</Col>
			<Col>
				<div className="form-field">
					<DatePicker
						selected={value}
						onChange={handleChange}
						className="form-control"
						dateFormat="MM/dd/yyyy"
						placeholderText="Select"
						showMonthDropdown={true}
						showYearDropdown={true}
					/>
				</div>
			</Col>
		</div>
	);
};
DatepickerField.displayName = 'DatepickerField';

interface IFieldProps {
	model: TFormFieldType;
	labelClass?: string;
	colClass?: string;
	wrapperClass?: string;
	onChange?: (value: any) => void;
	selectClassName?: string;
	hideTextEditorLabel?: boolean;
}

const Field = forwardRef<any, IFieldProps>(
	(
		{
			model,
			onChange,
			selectClassName,
			wrapperClass,
			labelClass,
			hideTextEditorLabel,
			colClass,
		},
		ref
	) => {
		if (isTextField(model)) {
			return (
				<TextField
					ref={ref}
					colClass={colClass}
					title={model.title}
					readOnly={model.readOnly}
					value={model.value}
					onChange={(e: any) => {
						onChange?.(e.target.value);
					}}
					wrapperClass={wrapperClass}
					inputRegex={model.inputRegex}
					titleClass={model.titleClass || labelClass}
					formatter={model.formatter}
					maxLength={model.maxLength}
				/>
			);
		} else if (isWysiwygField(model)) {
			return (
				<WysiwygField
					title={model.title}
					colClass={colClass}
					hideLabel={hideTextEditorLabel}
					onChange={(e: any) => {
						onChange?.(e.target);
					}}
					value={model.value}
				/>
			);
		} else if (isDropzoneField(model)) {
			return (
				<Dropzone
					id={model.value}
					onChange={(e: File) => {
						onChange?.(e);
					}}
					file={model.file}
				></Dropzone>
			);
		} else if (isSelectFieldModel(model)) {
			return (
				<SelectField
					className={selectClassName}
					colClass={colClass}
					title={model.title}
					titleClass={model.titleClass}
					labelClass={labelClass}
					value={model.value}
					options={model.options}
					onChange={(e: any) => {
						onChange?.(e);
					}}
					select={
						// eslint-disable-next-line react/no-unstable-nested-components
						(props: any) => {
							switch (model.subtype) {
								case 'salesCategory':
									return (
										<AsyncSalesCategoriesDropdown
											onChange={(e: any) => {
												props.onChange(e.value);
											}}
											placeholder="Please select"
											className="react-select"
											menuPortalTarget={document.body}
											styles={{
												menuPortal: (base: any) => ({
													...base,
													zIndex: 99999,
												}),
											}}
											defaultValue={props.value}
											isDisabled={model.readOnly}
										/>
									);
								case 'supplier':
									return (
										<AsyncVendorsDropdown
											onChange={(e: any) => {
												props.onChange(e?.value || null);
											}}
											placeholder="Please select"
											className="react-select"
											menuPortalTarget={document.body}
											styles={{
												menuPortal: (base: any) => ({
													...base,
													zIndex: 99999,
												}),
											}}
											defaultValue={props.value}
											isDisabled={model.readOnly}
											isClearable={true}
										/>
									);
								case 'project':
									return (
										<AsyncProjectsDropdown
											onChange={(e: any) => {
												props.onChange(e.value);
											}}
											placeholder="Please select"
											className="react-select"
											menuPortalTarget={document.body}
											styles={{
												menuPortal: (base: any) => ({
													...base,
													zIndex: 99999,
												}),
											}}
											defaultValue={props.value}
											isDisabled={model.readOnly}
										/>
									);
								case 'custom':
									return (
										<AsyncSelect
											defaultOptions={props.options}
											className="react-select"
											placeholder="Please select"
											menuPortalTarget={document.body}
											styles={{
												menuPortal: (base: any) => ({
													...base,
													zIndex: 99999,
												}),
											}}
											value={props.value}
											onChange={(e: any) => {
												props.onChange(e.value);
											}}
											isDisabled={model.readOnly}
											isLoading={model.isLoading ?? false}
											components={{
												LoadingIndicator: AsyncDropdownLoadingIndicator,
											}}
										/>
									);
							}
						}
					}
				/>
			);
		} else if (isCheckboxField(model)) {
			return (
				<Checkbox
					colClass={colClass}
					title={model.title}
					value={model.value}
					onChange={(e: any) => {
						onChange?.(e);
					}}
				/>
			);
		} else if (isDatepickerField(model)) {
			return (
				<DatepickerField
					title={model.title}
					value={model.value}
					onChange={(e: any) => {
						onChange?.(e);
					}}
					titleClass={model.titleClass}
				/>
			);
		} else if (isCurrencyField(model)) {
			return (
				<CurrencyField
					colClass={colClass}
					ref={ref}
					title={model.title}
					readOnly={model.readOnly}
					value={model.value}
					onChange={(e: any) => {
						onChange?.(e.target.value);
					}}
					titleClass={model.titleClass}
				/>
			);
		}

		return <></>;
	}
);
Field.displayName = 'Field';

export {
	Field,
	type TForm,
	type IFormField,
	type IFieldProps,
	isTextField,
	isCheckboxField,
	isDatepickerField,
	isDropzoneField,
	isSelectFieldModel,
	isWysiwygField,
};
