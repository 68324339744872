import React from 'react';
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import { WithRouter } from '../../../helpers/Router';
import ThreadComponent from '../ThreadComponent';
import WysiwygEditor from '../../components/WysiwygEditor';
import Select from 'react-select';
import { ApiService } from '../../../lib/api/HttpService';
import {
	displayAlert,
	displayAlertLoader,
	getErrorMessage,
} from '../../../utilities/Response';
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import MSG from '../../../defaults/Message';
import { mapRtfToDraft } from '../../../utilities/Rtf';

class PaymentActionModifyAmountModal extends ThreadComponent {
	constructor(props) {
		super(props);
		this.state = {
			data: {
				posdesc: '',
				posdescrtf: '',
			},
			initialNotes: '',
			checkAmount: 0,
		};

		this.api = new ApiService();
		this.htmlToRtfConverter = new HtmlToRtfBrowser();
		this.updatedData = {};
	}

	async componentDidUpdate(previousProps, previousState) {
		if (previousProps.data !== this.props.data) {
			let data = this.props.data;
			this.updatedData = data;
			const descHtmlDraft = await mapRtfToDraft(data.posdescrtf ?? '');
			this.updatedData.posdescrtf = descHtmlDraft;
			this.setState({
				data,
				posdescrtf: descHtmlDraft,
			});
			this.getAmountValue();
		}
	}

	stringToArrayBuffer(string) {
		const buffer = new ArrayBuffer(string.length);
		const bufferView = new Uint8Array(buffer);
		for (let i = 0; i < string.length; i++) {
			bufferView[i] = string.charCodeAt(i);
		}
		return buffer;
	}

	getAmountValue() {
		const amtdue = this.updatedData.amtdue ?? this.state.data.amtdue ?? 0;
		const discount = this.updatedData.discount ?? this.state.data.discount ?? 0;

		const total = amtdue - discount;
		this.setState({
			checkAmount: total,
		});

		return total;
	}

	handleAction = async (e) => {
		const data = this.updatedData;
		const discount = parseFloat(data.discount ?? this.state.data.discount);
		const amntdue = parseFloat(data.amtdue ?? this.state.data.amtdue);
		const vendorData = {
			txnum: this.state.data.txnum,
			amtdue: amntdue - discount,
			discount: discount,
			possidemark: data.possidemark ?? this.state.data.possidemark,
			posdesc: data.posdesc ?? this.state.data.posdesc,
			posdescrtf: this.htmlToRtfConverter.convertHtmlToRtf(
				data.posdescrtf ?? this.state.data.posdescrtf
			),
			tag: true,
		};

		displayAlertLoader(MSG.loading.update.msg);
		await this.api
			.editVendorPayments(vendorData)
			.then((resp) => {
				displayAlert('success', MSG.success.update.APPayment);
				this.updatedData = {};
				this.props.hideModal(true, false);
			})
			.catch((err) => {
				displayAlert(
					'danger',
					getErrorMessage(err, MSG.error.update.APPayment)
				);
			});
	};

	handleChange = (e, meta = {}) => {
		let key, value;
		if (e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.name;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}

		this.updatedData[key] = value;
		this.getAmountValue();
	};

	handleWysiwygChange = (e) => {
		this.updatedData['posdesc'] = e.target.value;
		this.updatedData['posdescrtf'] = e.target.html;
	};

	handleCloseModal = (e) => {
		this.props.hideModal();
	};

	render() {
		return (
			<Modal
				size="lg"
				show={this.props.show}
				aria-labelledby="action-modal"
				className="a-modal"
				backdrop="static"
				centered
			>
				<Modal.Body>
					<Row className="mb-3">
						<Col className="px-5">
							<h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3">
								Modify Amount
							</h5>
							<Form.Group className="pt-3">
								<Row xs={1} lg={2}>
									<Col>
										<Row className="align-items-center mb-3">
											<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
												<Form.Label className="mb-0">Pay Amount</Form.Label>
											</Col>
											<Col>
												<Row>
													<Col>
														<Form.Control
															type="number"
															className="no-spinner"
															placeholder="0.00"
															name="amtdue"
															value={
																this.updatedData?.amtdue ??
																this.state.data.amtdue
															}
															onChange={this.handleChange}
														/>
													</Col>
												</Row>
											</Col>
										</Row>

										<Row className="align-items-center mb-3">
											<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
												<Form.Label className="mb-0">Less Discount</Form.Label>
											</Col>
											<Col>
												<Row>
													<Col>
														<Form.Control
															type="number"
															className="no-spinner"
															placeholder="0.00"
															name="discount"
															value={
																this.updatedData?.discount ??
																this.state.data.discount
															}
															onChange={this.handleChange}
														/>
													</Col>
												</Row>
											</Col>
										</Row>

										<Row className="align-items-center mb-3">
											<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
												<Form.Label className="mb-0">Check Amount</Form.Label>
											</Col>
											<Col>
												<Row>
													<Col>
														<Form.Control
															type="text"
															placeholder="0.00"
															readOnly
															value={this.state.checkAmount}
														/>
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
									<Col>
										<Row className="align-items-center mb-3">
											<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
												<Form.Label className="mb-0">Pay With</Form.Label>
											</Col>
											<Col>
												<Row lg={1}>
													<Col>
														<Select
															options={this.props.payTypes}
															className="react-select"
															placeholder="Please select"
															name="possidemark"
															value={
																this.updatedData?.possidemark ||
																this.state.data.possidemark
																	? this.props.payTypes.find((option) => {
																			const compStr =
																				this.updatedData?.possidemark ??
																				this.state.data.possidemark;
																			return option.value === compStr;
																	  })
																	: null
															}
															onChange={this.handleChange}
														/>
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>
								<Row xs={1} lg={2}>
									<Col lg={9}>
										<Row className="align-items-start mb-3">
											<Col lg={2} className="text-lg-end mb-2 mb-lg-0">
												<Form.Label className="mb-0">Notes</Form.Label>
											</Col>
											<Col lg={9}>
												<WysiwygEditor
													onChange={this.handleWysiwygChange.bind(this)}
												>
													{this.updatedData?.posdescrtf ??
														this.state.posdescrtf}
												</WysiwygEditor>
											</Col>
										</Row>
									</Col>
								</Row>
							</Form.Group>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer className="bg-ivory py-3 px-5">
					<Row className="w-100">
						<Col className="d-flex" lg={{ span: 10, offset: 1 }}>
							<Button
								variant="primary"
								onClick={this.handleAction}
								className="w-100 m-2"
							>
								OK
							</Button>
							<Button
								variant="trans-light border-secondary-ash"
								onClick={this.handleCloseModal}
								className="w-100 m-2"
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default WithRouter(PaymentActionModifyAmountModal);
