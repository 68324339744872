import { useQueryClient } from '@tanstack/react-query';
import { useGetCompanyRole } from 'api/hooks/useGetCompanyRole';
import { usePatchCompanyRole } from 'api/hooks/usePatchCompanyRole';
import { ECacheKeys } from 'cache/CacheKeys';
import { TimeZoneContext } from 'legacy/app/components/TimeZoneContextProvider/TimeZoneContextProvider';
import {
	displayAlertSuccess,
	displayAlertError,
} from 'legacy/utilities/Response';
import { useState, useContext, useCallback, useMemo, useEffect } from 'react';
import { SingleValue } from 'react-select';
import { UserRoleContext } from './UserRoleContext';

export const UserRoleProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [userRole, setUserRole] = useState<number | null>(null);
	const { isTimeZoneSet } = useContext(TimeZoneContext);
	const { data: currentRole, isLoading: isLoadingCurrentRole } =
		useGetCompanyRole();

	const { mutateAsync: patchCompanyRole, isLoading: isUpdatingCompanyRole } =
		usePatchCompanyRole();

	const queryClient = useQueryClient();

	const handleSubmit = useCallback(async () => {
		try {
			await patchCompanyRole({
				newCompanyRole: userRole as number,
			});
			await queryClient.invalidateQueries({
				queryKey: [ECacheKeys.CompanyRole],
			});
			displayAlertSuccess('User Role was successfully saved.');
		} catch {
			displayAlertError(
				'There was an error updating the user role, please try again'
			);
		}
	}, [patchCompanyRole, userRole, queryClient]);

	const handleChange = useCallback(
		(e: SingleValue<{ label: string; value: number }>) =>
			setUserRole(e?.value as number),
		[]
	);

	const contextValue = useMemo(
		() => ({
			userRole,
			handleChange,
			isLoading: isLoadingCurrentRole || isUpdatingCompanyRole,
			shouldShowRoleModal:
				isTimeZoneSet &&
				!isLoadingCurrentRole &&
				currentRole?.companyRole === null,
			handleSubmit,
		}),
		[
			userRole,
			handleChange,
			isLoadingCurrentRole,
			isUpdatingCompanyRole,
			isTimeZoneSet,
			currentRole,
			handleSubmit,
		]
	);

	useEffect(() => {
		if (currentRole) {
			setUserRole(currentRole.companyRole);
		}
	}, [currentRole]);

	return (
		<UserRoleContext.Provider value={contextValue}>
			{children}
		</UserRoleContext.Provider>
	);
};
UserRoleProvider.displayName = 'UserRoleContextProvider';
