import React from 'react';
import { Row, Col, Modal, Button, Container, Form } from 'react-bootstrap';
import { WithRouter } from '../../../../helpers/Router';
import ThreadComponent from '../../ThreadComponent';
import { ApiService } from '../../../../lib/api/HttpService';
import { InlineFormGroupField } from '../../../components/Form';
import Select from 'react-select';
import WysiwygEditor from '../../../components/WysiwygEditor';
import {
	displayAlert,
	displayAlertLoader,
} from '../../../../utilities/Response';
import { mapRtfToDraft } from '../../../../utilities/Rtf';
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import { currencyFormat } from '../../../../helpers/Number';
import MSG from '../../../../defaults/Message';
import { setDraft } from '../../../../utilities/FormEvent';
import AsyncSalesCategoriesDropdown from '../../../../app/components/dropdowns/AsyncSalesCategoriesDropdown';
import AsyncProjectsDropdown from '../../../../app/components/dropdowns/AsyncProjectsDropdown';
import AsyncLocationsDropdown from '../../../../app/components/dropdowns/AsyncLocationsDropdown';
import { fixNumberValue, isNumberValid } from '../../../../utilities/Number';
import { isEmpty, delay } from 'lodash';

class VendorMiscAddDistributionModal extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			accounts: [],
			data: {
				mcost: props.account?.mcost ?? props.defaultAmount ?? 0,
				misccomptype: 0,
				miscusedisc: 0,
				misclistprice: 0,
				miscmarkup: 0,
				account: props.account?.account,
			},
			projects: [],
			locations: [],
			items: [],
			companyTypes: [],
			fiscalMonth: new Date(),
			isNonProj: true,
			isNewItem: true,
			descHtml: '',
			selectedType: null,
			locationsQry: `?$filter=projectId eq -1&$orderby=locn asc`,
			defaultProject: {},
		};

		this.api = new ApiService();
		this.updatedData = {
			misccomptype: 0,
			miscusedisc: 0,
			account: props.account?.account,
		};

		this.typeOptions = [
			{ value: 0, label: 'Markup' },
			{ value: '-1', label: 'Discount' },
			{ value: 1, label: 'Fee' },
		];
		this.htmlToRtfConverter = new HtmlToRtfBrowser();

		this.setIsChildSpinner(true);
	}

	async componentDidMount() {
		if (this.props.defaultAmount) {
			this.updatedData.mcost = this.props.defaultAmount;
		}
		try {
			let accounts = await this.api.getSummaryGLAccounts();
			accounts = accounts.map((i) => {
				return {
					label: `${i.value} (${i.key})`,
					value: i.key,
				};
			});

			let companyTypes = await this.api.get('company');
			companyTypes = [
				{
					value: 0,
					label: companyTypes.compviewm,
					taxable: 'taxm',
					markup: 'markupmerc',
					markupval: companyTypes.markupmerc,
					type: companyTypes.usediscountmerc,
				},
				{
					value: 1,
					label: companyTypes.compviewd,
					taxable: 'taxd',
					markup: 'markupdesign',
					markupval: companyTypes.markupdesign,
					type: companyTypes.usediscountdesign,
				},
				{
					value: 2,
					label: companyTypes.compviewf,
					taxable: 'taxf',
					markup: 'markupfreight',
					markupval: companyTypes.markupfreight,
					type: companyTypes.usediscountfreight,
				},
				{
					value: 3,
					label: companyTypes.compviewi,
					taxable: 'taxi',
					markup: 'markupinstall',
					markupval: companyTypes.markupinstall,
					type: companyTypes.usediscountinstall,
				},
				{
					value: 4,
					label: companyTypes.compviewl,
					taxable: 'taxl',
					markup: 'markuplabor',
					markupval: companyTypes.markuplabor,
					type: companyTypes.usediscountlabor,
				},
			];

			this.setState(
				{
					accounts,
					companyTypes,
					selectedType: companyTypes[0],
				},
				this.setDefaults
			);

			let data = this.state.data;
			const URLParams = new URLSearchParams(window.location.search);
			const isProj = URLParams.get('isProj');
			let isNonProj = true;

			let locations = [];
			let items = [];
			let projId = null;
			let selectedType = companyTypes[0];
			const { userCode, txnum, account } = this.props;
			this.updatedData.usercode = userCode;
			this.updatedData.txnum = txnum;

			if (account && account.seqnum) {
				const filter = `?$filter=usercode eq ${userCode} and txnum eq ${txnum} and seqnum eq ${account.seqnum}`;
				data = await this.api.getTemporaryVendorInvoiceDetailsOperatingExpense(
					filter
				);
				data = data[0];
				this.updatedData.seqnum = account.seqnum;
				this.updatedData.item = data.item;

				isNonProj = !(data.proj && data.proj !== '');
				if (!isNonProj) {
					this.updatedData.proj = data.proj;
					this.updatedData.mcost = data.mcost;
					this.updatedData.miscscat = data.miscscat;
					const project = await this.api.getProjectByCode(data.proj, true);

					const [proj] = await this.api.getProjects(
						`?$filter=id eq ${project?.id}`
					);
					this.proj = proj;
					projId = project?.id;

					selectedType = companyTypes.find((i) => {
						return i.value === data.misccomptype;
					});

					this.updatedData.misccomptype = selectedType.value;
					this.updatedData.miscusedisc = data.miscusedisc;

					if (selectedType) {
						const typeOpt = this.typeOptions.find(
							(t) => t.value == data.miscusedisc
						);

						data.misctaxable =
							this.proj?.[selectedType.taxable] ?? selectedType.taxable;
					}

					const { id } = project;

					items = await this.api.getProjectItems(`?$filter=projectid eq ${id}`);

					items = items.map((i) => {
						return {
							value: i.item,
							label: `[${i.item}] ${i.desc ?? i.shortdesc}`,
							latestinvno: i.latestinvno,
							scat: i.scat,
						};
					});

					locations = await this.api.getLocations(
						`?$filter=proj eq '${data.proj}'`
					);
					locations = locations.map((i) => {
						return { value: i.loc, label: `[${i.loc}] ${i.locn}` };
					});
				}
			}

			this.setState(
				{
					data,
					isNonProj: isProj ? false : isNonProj,
					items,
					locations,
					descHtml: await mapRtfToDraft(data?.miscdescrtf ?? ''),
					isNewItem: !(data?.item?.toLowerCase() !== 'new' && data?.item),
					selectedType,
					locationsQry: `?$filter=(projectId eq ${projId} or projectId eq null)&$orderby=locn asc`,
				},
				this.setDefaults
			);
			this.setIsFound(true).setIsLoaded(true);
		} catch (error) {
			this.setError({
				code: 500,
				message: isObject(error) ? JSON.stringify(error) : error,
			})
				.setIsFound(false)
				.setIsLoaded(true);
		}
	}

	async setDefaults() {
		const category = this.props.params.txnum
			? this.state.data.miscscat
			: this.state.data.scat;
		if (category) {
			const sc = await this.api.getSalesCategoryByCode(category);

			const scOption = {
				value: sc.cmacct,
				label: `${sc.scatn} [${sc.scat}]`,
				name: sc.scatn,
				scatValue: sc.scat,
			};

			this.setState({
				defaultSalesCategory: scOption,
			});

			this.updatedData.miscscat = sc.cmacct;

			this.handleChange(scOption, {
				action: 'select-option',
				name: 'accountProj',
				option: undefined,
			});
		}

		if (this.state.data?.proj) {
			const defaultProject = await this.api.getProjectByCode(
				this.state.data?.proj,
				true
			);
			this.setState({
				defaultProject,
			});

			if (this.state.data?.miscloc) {
				const defaultLocation = await this.api.getLocationByCode(
					this.state.data?.miscloc,
					defaultProject?.id,
					true
				);
				this.setState({
					defaultLocation,
				});
			}
		}

		this.setState({
			data: {
				...this.state.data,
				misclistprice: this.calcClientPrice(),
			},
		});
	}

	handleChange = async (e, meta = {}) => {
		let key, value;
		let { data, selectedType } = this.state;
		let clientPrice = this.state.data.misclistprice ?? 0;
		const prevSelectedType = selectedType;
		if (e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
			if (key === 'accountProj' && value) {
				const [salesCategory] = await this.api.getSalesCategories(
					`?$filter=id eq ${value}`
				);
				value = salesCategory?.cmacct;

				this.updatedData.scat = salesCategory?.scat;
				this.updatedData.miscscat = salesCategory?.scat;
				data.scat = salesCategory?.scat;
				data.miscscat = salesCategory?.scat;

				if (!isEmpty(this.state.defaultProject)) {
					this.setItems(this.state.defaultProject.id);
				}
			} else if (key === 'misccomptype') {
				selectedType = e;
			}
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.name;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}

		this.updatedData[key] = value;
		key = key === 'accountProj' ? 'account' : key;
		key = key === 'mcostProj' ? 'mcost' : key;
		data[key] = value;
		let defaultSalesCategory =
			key === 'account' ? e : this.state.defaultSalesCategory;
		const defaultLocation = key === 'miscloc' ? e : this.state.defaultLocation;

		if (key === 'item') {
			data['usewip'] = Boolean(!this.state.data.usewip && e.latestinvno > 0);

			const sc = await this.api.getSalesCategories(
				`?$filter=scat eq '${e.scat}'`
			);

			if (!isEmpty(sc)) {
				const salesCat = sc[0];
				defaultSalesCategory = {
					id: salesCat.id,
					label: `${salesCat.scatn} [${salesCat.scat}]`,
					value: `${salesCat.id}`,
					name: `${salesCat.scatn}`,
				};

				data.scat = salesCat.scat;
				data.miscscat = salesCat.scat;
				this.updatedData.scat = salesCat.scat;
				this.updatedData.miscscat = salesCat.scat;
			}
		}

		this.setState(
			{
				data,
				selectedType,
				defaultSalesCategory,
				defaultLocation,
			},
			() => {
				this.setState((prevState) => ({
					data: {
						...prevState.data,
						misclistprice: this.calcClientPrice(),
					},
				}));
			}
		);

		if (
			key === 'mcost' ||
			key === 'mcostProj' ||
			key === 'miscmarkup' ||
			key === 'misccomptype' ||
			key === 'miscusedisc'
		) {
			if (key == 'misccomptype') {
				const selectedCompType = this.state.companyTypes.find(
					(compt) => compt.value == value
				);

				if (selectedCompType) {
					const typeOpt = this.typeOptions.find(
						(t) => t.value == selectedCompType.type
					);
					this.updatedData.miscusedisc = typeOpt.value;
					this.updatedData.miscmarkup = selectedCompType.markupval;
					this.setState(
						(prevState) => ({
							data: {
								...prevState.data,
								miscusedisc: typeOpt.value,
								miscmarkup: selectedCompType.markupval,
								misctaxable:
									this.proj?.[selectedCompType.taxable] ??
									selectedCompType.taxable,
							},
						}),
						() => {
							this.setState((prevState) => ({
								data: {
									...prevState.data,
									misclistprice: this.calcClientPrice(),
								},
							}));
						}
					);
				}
			}
		}
	};

	calcClientPrice() {
		let clientPrice = 0;
		let cost = Number(this.state.data.mcost) ?? 0;
		let listPrice = Number(this.props.listTotalAmount);

		let miscUseDisc = this.state.data.miscusedisc;
		let markup = Number(this.state.data.miscmarkup);
		const percentageType = this.typeOptions.find(
			(opt) => opt.value == miscUseDisc
		).label;

		const markUpToDecimal = Number(markup / 100);
		switch (percentageType) {
			case 'Discount':
				clientPrice = cost - cost * markUpToDecimal;
				if (clientPrice < 0) {
					clientPrice = Math.abs(clientPrice);
				}
				break;
			case 'Fee':
				clientPrice = cost * markUpToDecimal;
				break;
			default:
				clientPrice = cost + cost * markUpToDecimal;
		}
		return clientPrice;
	}

	handleWysiwygChange = (e) => {
		this.updatedData['miscdesc'] = e.target.value;
		this.updatedData['miscdescrtf'] = this.htmlToRtfConverter.convertHtmlToRtf(
			e.target.html
		);
	};

	handleProjectSelect = async (e, meta = {}) => {
		this.setState({
			defaultProject: e,
			locationsQry: `?$filter=(projectId eq ${e?.id} or projectId eq null)&$orderby=locn asc`,
		});

		const [proj] = await this.api.getProjects(`?$filter=id eq ${e?.id}`);
		this.proj = proj;

		let key = meta.name;
		let value = e.value;
		const id = e.id;
		this.updatedData[key] = value;

		let { data, selectedType } = this.state;
		data[key] = value;
		data.misctaxable = this.proj[selectedType?.taxable];
		data.miscmarkup = proj[selectedType?.markup];
		data.usewip = proj?.bypasswip;
		this.updatedData.misctaxable = proj[selectedType?.taxable];
		this.updatedData.miscmarkup = proj[selectedType?.markup];
		this.updatedData.usewip = proj?.bypasswip;

		// Change markup data based on project selected
		const companyTypes = [
			{
				value: 0,
				label: this.state.companyTypes[0].label,
				taxable: 'taxm',
				markup: 'markupmerc',
				markupval: proj.markupmerc,
				type: proj.usediscountmerc,
			},
			{
				value: 1,
				label: this.state.companyTypes[1].label,
				taxable: 'taxd',
				markup: 'markupdesign',
				markupval: proj.markupdesign,
				type: proj.usediscountdesign,
			},
			{
				value: 2,
				label: this.state.companyTypes[2].label,
				taxable: 'taxf',
				markup: 'markupfreight',
				markupval: proj.markupfreight,
				type: proj.usediscountfreight,
			},
			{
				value: 3,
				label: this.state.companyTypes[3].label,
				taxable: 'taxi',
				markup: 'markupinstall',
				markupval: proj.markupinstall,
				type: proj.usediscountinstall,
			},
			{
				value: 4,
				label: this.state.companyTypes[4].label,
				taxable: 'taxl',
				markup: 'markuplabor',
				markupval: proj.markuplabor,
				type: proj.usediscountlabor,
			},
		];

		if (this.props.isNew) {
			data.item = null;
		}
		this.setItems(id);

		let locations = await this.api.getLocations(`?$filter=proj eq '${value}'`);
		locations = locations.map((i) => {
			return { value: i.loc, label: `[${i.loc}] ${i.locn}` };
		});

		this.setState((prevState) => ({
			companyTypes,
			locations,
			data: {
				...prevState.data,
				misclistprice: this.calcClientPrice(),
			},
		}));
	};

	async setItems(id) {
		let items = await this.api.getProjectItems(`?$filter=projectid eq ${id}`);

		items = items.map((i) => {
			return {
				value: i.item,
				label: `[${i.item}] ${i.desc ?? i.shortdesc ?? ''}`,
				latestinvno: i.latestinvno,
				scat: i.scat,
			};
		});

		this.setState({
			items,
		});
	}

	handleAction = async (e) => {
		if (!this.state.isNonProj && !this.updatedData.proj) {
			displayAlert('danger', 'The Project Code is required.');
			return;
		}

		if (
			!this.state.isNonProj &&
			!this.state.isNewItem &&
			!this.updatedData.item
		) {
			displayAlert('danger', 'Selecting the existing item is required.');
			return;
		}

		this.updatedData.txnum = this.props.txnum;
		this.updatedData.usercode = this.props.userCode;

		if (!this.state.isNonProj) {
			this.updatedData.scat =
				this.updatedData?.scat ?? this.state.defaultSalesCategory?.scatValue;
			this.updatedData.account = this.updatedData.accountProj;
			this.updatedData.mcost =
				this.updatedData.mcostProj ?? this.updatedData.mcost;
			delete this.updatedData.accountProj;
			delete this.updatedData.mcostProj;
		}

		displayAlertLoader(MSG.loading.update.msg);

		const locations = await this.api.getLocations(
			`?$filter=loc eq '${this.updatedData.miscloc}' and proj eq '${this.updatedData.proj}'`
		);
		if (
			locations.length === 0 &&
			this.updatedData.miscloc &&
			this.updatedData.proj
		) {
			await this.api.postLocation({
				loc: this.updatedData.miscloc,
				locn: this.updatedData.miscloc,
				proj: this.updatedData.proj,
			});
		}
		if (this.props.account && this.props.account.seqnum) {
			await this.api
				.updateTemporaryVendorInvoiceDetailsOperatingExpense(this.updatedData)
				.then((resp) => {
					displayAlert('success', 'Successfully updated account distribution');

					this.handleSuccess();
				})
				.catch((error) => {
					const errorMessage =
						error.response?.data?.userError ||
						'Unable to update account distribution';
					displayAlert('danger', errorMessage);
				});
		} else {
			await this.api
				.createTemporaryVendorInvoiceDetailsOperatingExpense(this.updatedData)
				.then((resp) => {
					displayAlert('success', 'Successfully created account distribution');

					this.handleSuccess();
				})
				.catch((error) => {
					const errorMessage =
						error.response?.data?.userError ||
						'Unable to create account distribution';
					displayAlert('danger', errorMessage);
				});
		}
	};

	handleSuccess() {
		this.props.onAddAccount(this.updatedData);
		this.props.hideModal(true);

		setDraft(true);
	}

	handleRadioChange = (e) => {
		const key = e.target.name;

		if (key === 'isNewItem') {
			delete this.updatedData.item;
			let newState = this.state;
			delete newState.data.item;
			this.setState(newState);
		} else if (key === 'isNonProj') {
			this.updatedData = {
				misccomptype: 0,
				miscusedisc: 0,
				account: this.updatedData.account,
				mcost: this.updatedData.mcost,
				seqnum: this.updatedData.seqnum,
				proj: null,
				miscscat: null,
				scat: null,
			};
			let newState = this.state;
			newState.selectedType = this.state.companyTypes[0];
			newState.defaultProject = {};
			newState.defaultSalesCategory = {};
			newState.defaultLocation = {};
			newState.data = {
				mcost: this.props.account?.mcost ?? this.props.defaultAmount ?? 0,
				misccomptype: 0,
				miscusedisc: 0,
				misclistprice: 0,
				miscmarkup: 0,
				account: this.props.account?.account,
			};
			this.setState(newState);
		}
		this.setState((prevState) => (prevState[key] = e.target.value === '1'));
	};

	handleHideModal = (e) => {
		this.updatedData = {};
		this.setState({
			data: {},
		});

		this.props.hideModal();
	};

	render() {
		return (
			<Modal
				show={true}
				size="xl"
				aria-labelledby="action-modal"
				className="a-modal mx-sm-5 mx-lg-0"
				backdrop="static"
				centered
				onHide={this.handleHideModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Vendor Invoice Distribution [Expense / Misc.]
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="content-padding min-height">
						<Container fluid className="ms-0" style={{ minHeight: '200px' }}>
							{this.renderView(
								<Form.Group>
									<Row className="align-items-center mb-3">
										<InlineFormGroupField
											label="Type / Account Info."
											classNameParent="mb-3 align-items-center"
											className="justify-content-start"
										>
											<div>
												<Form.Check
													inline
													label="Non-Project"
													name="isNonProj"
													type="radio"
													id={`typeacinf1`}
													className="vtop"
													onClick={this.handleRadioChange}
													value={1}
													checked={this.state.isNonProj}
													disabled={!this.props.isNew}
												/>

												<Form.Check
													inline
													label="Project Related"
													name="isNonProj"
													type="radio"
													id={`typeacinf2`}
													className="vtop mx-3"
													onClick={this.handleRadioChange}
													value={0}
													checked={!this.state.isNonProj}
													disabled={!this.props.isNew}
												/>
											</div>
										</InlineFormGroupField>
									</Row>
									<div
										id="nonproj"
										className={this.state.isNonProj ? 'd-block' : 'd-none'}
									>
										<InlineFormGroupField
											label="Account No."
											classNameParent="mb-3 align-items-center"
											className="justify-content-start"
										>
											<Select
												menuPortalTarget={document.body}
												styles={{
													menuPortal: (base) => ({
														...base,
														zIndex: 99999,
													}),
												}}
												options={this.state.accounts}
												className="react-select"
												placeholder="Please select"
												name="account"
												value={this.state.accounts.filter((i) => {
													return i.value === this.state.data?.account;
												})}
												onChange={this.handleChange}
											/>
										</InlineFormGroupField>

										<InlineFormGroupField
											label="Amount / Cost"
											classNameParent="mb-3 align-items-center"
											className="justify-content-start"
										>
											<Form.Control
												type="text"
												placeholder="0.00"
												name="mcost"
												onChange={(e) => {
													const val = e.target.value;
													if (
														!isNumberValid(val, {
															decimal: 2,
														})
													) {
														return;
													}

													this.handleChange(e);
												}}
												onBlur={(e) => {
													e.target.value = fixNumberValue(e.target.value);
													this.handleChange(e);
												}}
												value={this.state.data?.mcost}
											/>
										</InlineFormGroupField>
									</div>
									<div
										id="proj"
										className={!this.state.isNonProj ? 'd-block' : 'd-none'}
									>
										<InlineFormGroupField
											label="Sales Category"
											classNameParent="mb-3 align-items-center"
											className="justify-content-start"
										>
											<AsyncSalesCategoriesDropdown
												menuPortalTarget={document.body}
												styles={{
													menuPortal: (base) => ({
														...base,
														zIndex: 99999,
													}),
												}}
												className="react-select"
												placeholder="Please select"
												name="accountProj"
												defaultValue={this.state.defaultSalesCategory}
												onChange={this.handleChange}
												valueKey="id"
												isDisabled={!this.props.isNew}
											/>
										</InlineFormGroupField>

										<InlineFormGroupField
											label="Amount / Cost"
											classNameParent="mb-3 align-items-center"
											className="justify-content-start"
										>
											<Form.Control
												type="text"
												placeholder="0.00"
												name="mcostProj"
												value={this.state.data?.mcost}
												onChange={(e) => {
													const val = e.target.value;
													if (
														!isNumberValid(val, {
															decimal: 2,
														})
													) {
														return;
													}

													this.handleChange(e);
												}}
												onBlur={(e) => {
													e.target.value = fixNumberValue(e.target.value);
													this.handleChange(e);
												}}
											/>
										</InlineFormGroupField>

										<hr />
										<div className="fw-bold mb-3">
											Item / Component Information
										</div>

										<InlineFormGroupField
											label="Project"
											classNameParent="mb-3 align-items-center"
											className="justify-content-start"
										>
											<AsyncProjectsDropdown
												menuPortalTarget={document.body}
												styles={{
													menuPortal: (base) => ({
														...base,
														zIndex: 99999,
													}),
												}}
												className="react-select"
												placeholder="Please select"
												name="proj"
												value={this.state.defaultProject}
												onChange={this.handleProjectSelect}
												isDisabled={!this.props.isNew}
											/>
										</InlineFormGroupField>

										<Row className="align-items-center mb-3">
											<Col sm={3}>
												<Form.Check
													inline
													label="New Item"
													name="isNewItem"
													type="radio"
													id={`newitem`}
													className="vtop"
													onClick={this.handleRadioChange}
													value={1}
													checked={this.state.isNewItem}
													disabled={!this.props.isNew}
												/>

												<Form.Check
													inline
													label="Existing Item"
													name="isNewItem"
													type="radio"
													id={`exitem`}
													className="vtop mx-3"
													onClick={this.handleRadioChange}
													value={0}
													checked={!this.state.isNewItem}
													disabled={!this.props.isNew}
												/>
											</Col>
											<Col
												sm={2}
												className={!this.state.isNewItem ? 'd-block' : 'd-none'}
											>
												<Select
													menuPortalTarget={document.body}
													styles={{
														menuPortal: (base) => ({
															...base,
															zIndex: 99999,
														}),
													}}
													options={this.state.items}
													className="react-select"
													placeholder="Please select"
													name="item"
													value={this.state.items.filter((i) => {
														return i.value === this.state.data?.item;
													})}
													onChange={this.handleChange}
													isDisabled={!this.props.isNew}
												/>
											</Col>
										</Row>

										<InlineFormGroupField
											label="Location"
											classNameParent="mb-3 align-items-center"
											className={`justify-content-start ${
												this.state.isNewItem ? 'd-block' : 'd-none'
											}`}
										>
											<AsyncLocationsDropdown
												menuPortalTarget={document.body}
												styles={{
													menuPortal: (base) => ({
														...base,
														zIndex: 99999,
													}),
												}}
												key={`${Math.floor(Math.random() * 10000)}-min`}
												// options={this.state.locations}
												className={`react-select ${
													this.state.isNewItem ? 'd-block' : 'd-none'
												}`}
												placeholder="Please select"
												name="miscloc"
												value={this.state.defaultLocation}
												onChange={this.handleChange}
												urlQuery={this.state.locationsQry}
											/>
										</InlineFormGroupField>

										<Row className="align-items-center mb-3">
											<Col>
												<InlineFormGroupField
													label="Type"
													classNameParent="mb-3 align-items-center"
													className="justify-content-start col-sm-4"
													colField={8}
												>
													<Select
														menuPortalTarget={document.body}
														styles={{
															menuPortal: (base) => ({
																...base,
																zIndex: 99999,
															}),
														}}
														options={this.state.companyTypes}
														className="react-select"
														placeholder="Please select"
														name="misccomptype"
														value={this.state.selectedType}
														onChange={this.handleChange}
													/>
												</InlineFormGroupField>
											</Col>
											<Col>
												<InlineFormGroupField
													label="%Type"
													classNameParent="mb-3 align-items-center"
													className="justify-content-start col-sm-4"
													colField={8}
												>
													<Select
														menuPortalTarget={document.body}
														styles={{
															menuPortal: (base) => ({
																...base,
																zIndex: 99999,
															}),
														}}
														options={this.typeOptions}
														className="react-select"
														placeholder="Please select"
														name="miscusedisc"
														value={this.typeOptions.filter((i) => {
															return i.value == this.state.data?.miscusedisc;
														})}
														onChange={this.handleChange}
													/>
												</InlineFormGroupField>
											</Col>
											<Col>
												<InlineFormGroupField
													label="Markup%"
													classNameParent="mb-3 align-items-center"
													className="justify-content-start col-sm-4"
													colField={8}
												>
													<Form.Control
														type="text"
														placeholder="Please enter"
														name="miscmarkup"
														value={this.state.data?.miscmarkup}
														onChange={this.handleChange}
													/>
												</InlineFormGroupField>
											</Col>
										</Row>
										<Row>
											<Col sm={4}>
												<InlineFormGroupField
													label="Client Price"
													classNameParent="mb-3 align-items-center"
													className="justify-content-start col-sm-4"
													colField={8}
												>
													<Form.Control
														type="text"
														placeholder="Please enter"
														name="misclistprice"
														value={currencyFormat(
															this.state.data?.misclistprice ?? 0
														)}
														disabled={true}
													/>
												</InlineFormGroupField>

												<InlineFormGroupField
													label="Taxable"
													classNameParent="mb-3 align-items-center flex-row-reverse"
													className="justify-content-start col-sm-11"
													colField={1}
												>
													<Form.Check
														inline
														label=""
														name="misctaxable"
														type="checkbox"
														checked={this.state.data?.misctaxable}
														onChange={this.handleChange}
													/>
												</InlineFormGroupField>

												<InlineFormGroupField
													label="Cost is Non-Taxable"
													classNameParent="mb-3 align-items-center flex-row-reverse"
													className="justify-content-start col-sm-11"
													colField={1}
												>
													<Form.Check
														inline
														label=""
														name="miscnontaxcost"
														type="checkbox"
														checked={this.state.data?.miscnontaxcost}
														onChange={this.handleChange}
													/>
												</InlineFormGroupField>

												<InlineFormGroupField
													label="Bypass WIP"
													classNameParent="mb-3 align-items-center flex-row-reverse"
													className="justify-content-start col-sm-11"
													colField={1}
												>
													<Form.Check
														inline
														label=""
														name="usewip"
														type="checkbox"
														checked={this.state.data?.usewip}
														onChange={this.handleChange}
													/>
												</InlineFormGroupField>
											</Col>
											<Col sm={8}>
												<Form.Label className="mb-1">Description</Form.Label>
												<WysiwygEditor onChange={this.handleWysiwygChange}>
													{this.state.descHtml}
												</WysiwygEditor>
											</Col>
										</Row>
									</div>
								</Form.Group>
							)}
						</Container>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={this.handleAction}>
						OK
					</Button>
					<Button variant="primary" onClick={this.handleHideModal}>
						Cancel
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default WithRouter(VendorMiscAddDistributionModal);
