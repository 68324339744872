import React, {
	Dispatch,
	SetStateAction,
	createContext,
	useState,
} from 'react';
import { usePostReconciliationsDelete } from 'api/hooks/usePostReconciliationsDelete';
import { type IDeleteReconciliationsParams as IDeleteParams } from 'api/resources/reconciliations';
import { EAlertTypes } from 'legacy/app/enums/alertTypes/alertTypes';
import { displayAlert } from 'legacy/utilities/Response';
import { useQueryClient } from '@tanstack/react-query';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';
import { ECacheKeys } from 'cache/CacheKeys';
import { TReconciliation } from 'legacy/lib/api/HttpService';

interface ISelectedAccount {
	cashAccount: string;
	statementDate: string;
}

interface IDateRangeSearch {
	statementDate: {
		startDate: Date | null;
		endDate: Date | null;
	};
	computerDate: {
		startDate: Date | null;
		endDate: Date | null;
	};
}

interface ICheckbookHistoryContext {
	handleOnDelete: (account: ISelectedAccount) => void;
	dateRangeSearch: IDateRangeSearch;
	setDateRangeSearch: Dispatch<SetStateAction<IDateRangeSearch>>;
	filterByDateRangeFn: (item: TReconciliation) => boolean;
}

export const CheckbookHistoryContext = createContext<ICheckbookHistoryContext>({
	handleOnDelete: async () => undefined,
	dateRangeSearch: {
		statementDate: { startDate: null, endDate: null },
		computerDate: { startDate: null, endDate: null },
	},
	filterByDateRangeFn: () => false,
	setDateRangeSearch: () => undefined,
});

interface IReconcileContextProviderProps {
	children: React.ReactNode;
}

const CheckbookHistoryProvider = ({
	children,
}: IReconcileContextProviderProps) => {
	const queryClient = useQueryClient();
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [selectedAccount, setSelectedAccount] =
		useState<ISelectedAccount | null>(null);

	const [dateRangeSearch, setDateRangeSearch] = useState<IDateRangeSearch>({
		statementDate: {
			startDate: null,
			endDate: null,
		},
		computerDate: {
			startDate: null,
			endDate: null,
		},
	});

	const filterByDateFn = (
		dateString: string,
		startDate: Date,
		endDate: Date
	) => {
		const date = new Date(dateString);
		const isAfterStartDate = startDate ? date >= startDate : true;
		const isBeforeEndDate = endDate ? date <= endDate : true;
		return isAfterStartDate && isBeforeEndDate;
	};

	const filterByDateRangeFn = ({
		statementdate,
		computerdate,
	}: TReconciliation) => {
		const { statementDate, computerDate } = dateRangeSearch;
		if (statementDate?.startDate && statementDate?.endDate) {
			const { startDate, endDate } = statementDate;
			return filterByDateFn(statementdate, startDate, endDate);
		}
		if (computerDate?.startDate && computerDate?.endDate) {
			const { startDate, endDate } = computerDate;
			return filterByDateFn(computerdate, startDate, endDate);
		}
		return true;
	};

	const { mutateAsync: postReconciliationsDelete } =
		usePostReconciliationsDelete();

	const openConfirmationModal = () => {
		setShowConfirmationModal(true);
	};

	const deleteReconciliation = async () => {
		if (!selectedAccount) {
			return;
		}
		try {
			const payload: IDeleteParams = {
				cashAccount: selectedAccount.cashAccount,
				statementDate: selectedAccount.statementDate,
			};

			await postReconciliationsDelete(payload);
			closeConfirmationModal();
			displayAlert(EAlertTypes.Success, 'Reconciliation deleted successfully!');
			await queryClient.invalidateQueries([ECacheKeys.Reconciliations]);
		} catch {
			displayAlert(EAlertTypes.Danger, 'Error: could not save changes');
		}
	};

	const closeConfirmationModal = () => {
		setShowConfirmationModal(false);
		setSelectedAccount(null);
	};

	const handleOnDelete = (account: ISelectedAccount) => {
		setSelectedAccount(account);
		openConfirmationModal();
	};

	return (
		<CheckbookHistoryContext.Provider
			value={{
				handleOnDelete,
				dateRangeSearch,
				setDateRangeSearch,
				filterByDateRangeFn,
			}}
		>
			{children}
			<ConfirmationModal
				show={showConfirmationModal}
				title="Checkbook Reconciliation History"
				confirmAction={deleteReconciliation}
				okBtnStyle="primary"
				labelOK="Yes"
				labelCancel="No"
				onCancel={closeConfirmationModal}
				toggleModal={closeConfirmationModal}
			>
				<div className="tw-grid tw-grid-flow-col tw-items-center tw-justify-center tw-gap-2 tw-px-2">
					<i className="ri-question-line tw-text-xl tw-text-blue-700"></i>
					<span className="tw-text-sm">
						Are you sure you want to delete the highlighted reconciliation and
						un-clear all checks and deposits in the batch?
					</span>
				</div>
			</ConfirmationModal>
		</CheckbookHistoryContext.Provider>
	);
};

CheckbookHistoryProvider.displayName = 'CheckbookHistoryProvider';

export { CheckbookHistoryProvider };
