const MSG = {
	loading: {
		cashReceipts:
			'Voiding cash receipts entry/entries. Please wait <span class="dot-load"></span>',
		images: 'Loading images. Please wait<span class="dot-load"></span>',
		info: 'Loading information. Please wait<span class="dot-load"></span>',
		hold: 'Updating onhold status. Please wait<span class="dot-load"></span>',
		status: 'Updating status. Please wait<span class="dot-load"></span>',
		update: {
			msg: 'Saving changes. Please wait<span class="dot-load"></span>',
			APPaymentOffset:
				'Updating offset of selected invoice(s). Please wait<span class="dot-load"></span>',
			APVendorInvoiceVoid:
				'Voiding vendor invoice(s). Please wait<span class="dot-load"></span>',
			GLJeVoid:
				'Voiding journal entry. Please wait<span class="dot-load"></span>',
		},
		create: {
			msg: 'Creating item. Please wait<span class="dot-load"></span>',
			Project: 'Creating project. Please wait<span class="dot-load"></span>',
			GLAccount: 'Creating account. Please wait<span class="dot-load"></span>',
			GLJe: 'Creating journal entry. Please wait<span class="dot-load"></span>',
			GLJeTn:
				'Generating new tansaction number. Please wait<span class="dot-load"></span>',
			ClipItmDuplicate:
				'Duplicating item &nbsp;[name]. Please wait<span class="dot-load"></span>',
			ClipItmCreate:
				'Creating item for &nbsp;[name]. Please wait<span class="dot-load"></span>',
			ClipItmComponent:
				'Creating item &nbsp;[name]&nbsp; as component. Please wait<span class="dot-load"></span>',
			ClipItmTransfer:
				'Transferring item to &nbsp;[name]. Please wait<span class="dot-load"></span>',
			ARCashReceipt:
				'Creating cash receipt. Please wait<span class="dot-load"></span>',
			ARClientInv:
				'Creating client invoice. Please wait<span class="dot-load"></span>',
			APVendorInv:
				'Creating vendor invoice. Please wait<span class="dot-load"></span>',
			APVendorInvDep:
				'Creating deposit. Please wait<span class="dot-load"></span>',
			APVendorInvMisc:
				'Creating miscellaneous. Please wait<span class="dot-load"></span>',
			GlossaryTA:
				'Creating time activity. Please wait<span class="dot-load"></span>',
			GlossaryLoc:
				'Creating location. Please wait<span class="dot-load"></span>',
			GlossarySC:
				'Creating sales category. Please wait<span class="dot-load"></span>',
			GlossarySTC:
				'Creating ship to code. Please wait<span class="dot-load"></span>',
			ProjectPO:
				'Creating purchase order. Please wait<span class="dot-load"></span>',
			ProjectBudget:
				'Creating budget. Please wait<span class="dot-load"></span>',
		},
		delete: {
			msg: 'Deleting item(s). Please wait<span class="dot-load"></span>',
			record: 'Deleting record(s). Please wait<span class="dot-load"></span>',
			ARClientInv:
				'Deleting client invoice. Please wait<span class="dot-load"></span>',
			APVendorInv:
				'Deleting vendor invoice. Please wait<span class="dot-load"></span>',
			CashReceipt:
				'Deleting cash receipt. Please wait<span class="dot-load"></span>',
			ClipItem:
				'Deleting &nbsp;[name]. Please wait<span class="dot-load"></span>',
			ProjectBudget:
				'Deleting budget item. Please wait<span class="dot-load"></span>',
			Client: 'Deleting client(s). Please wait<span class="dot-load"></span>',
			Vendor: 'Deleting vendor(s). Please wait<span class="dot-load"></span>',
			Employee:
				'Deleting employee(s). Please wait<span class="dot-load"></span>',
			InventoryAdjustment:
				'Deleting inventory adjustment(s). Please wait<span class="dot-load"></span>',
		},
		prepare: {
			item: 'Preparing item(s). Please wait<span class="dot-load"></span>',
		},
		redirect: {
			GLJe: 'Redirecting to the journal entry. Please wait<span class="dot-load"></span>',
		},
		email: {
			ARClientInv:
				'Sending client invoice to email. Please wait<span class="dot-load"></span>',
			PO: 'Sending purchase order to email. Please wait<span class="dot-load"></span>',
		},
	},
	error: {
		invalidEmail: 'Please enter a valid email address.',
		noSelected: 'No record is selected. Please select an item first.',
		onlyOneSelected: 'Please select only one item.',
		requiredFields: 'Please fill in the required fields.',
		GLJe: {
			editNoAcc: 'Please select an account.',
			editNoOpt: 'Choose between credit or debit option.',
			editInvAmt: 'Please enter a valid amount.',
			editSameCode:
				'Another record exists with the same code as the new one that you are trying to save. Please change the code and try to save the record again.',
			editNoDate: 'Please enter an Entry Date.',
			editReqTwo:
				'At least two accounts must be entered into the distribution for each journal entry.',
			editNotBalanced:
				'A Journal Entry must be balanced. This means that the Net Debit and Credits must equal to zero.',
		},
		ClipItemReq: 'Project and sales category are required to create an item.',
		update: {
			APPayment: 'Payment could not be updated.',
			APPaymentModify: 'Select one (1) item only to modify amount.',
		},
		ClipCompReq:
			'Project and sales category are required to create a component',
		InvoiceFailedTag: "Invoice with Ref No: [IDS] can't be updated.",
		InvoiceSelectSameClient: 'Please select invoices with the same client.',
		CashReceiptNotFound: 'Cash receipt not found',
		VendorInvoiceDetailsNotFound: 'Vendor invoice details not found',
		delete: {
			APVendorInv: "Invoice with Ref No: [IDS] can't be deleted.",
			ProjectBudget: 'Budget item "[IDS]" can\'t be deleted.',
		},
		create: {
			Project: 'Failed saving the project.',
			User: 'There was an error while creating user',
		},
		userNameCheck:
			'There was an error while checking if username is available.',
		clientPortalToken: {
			open: 'Failed to open client portal.',
			generate: 'Failed to generate client portal token.',
		},
	},
	success: {
		msg: 'Changes were successfully saved.',
		create: {
			Project: 'Project was successfully created.',
			GLJe: 'Journal Entry was successfully created.',
			GLJeTn: 'Successfully generated transaction number.',
			GLAcc: 'Account was successfully created.',
			ClipItmDuplicate: 'Item was successfully duplicated.',
			ClipItmTransfer: 'Item was successfully tranferred to &nbsp;[name].',
			ClipItmCreate: 'Item was successfully created.',
			ClipItmComponent: 'Item was successfully created as component.',
			ARCashReceipt: 'Cash receipt was successfully created.',
			GlossaryTA: 'Time entry was successfully created.',
			GlossaryLoc: 'Location was successfully created.',
			GlossarySC: 'Sales category was successfully created.',
			GlossarySTC: 'Ship to code was successfully created.',
			ARClientInv: 'Client invoice was successfully created.',
			APVendorInv: 'Vendor invoice was successfully created.',
			APVendorInvDep: 'Deposit was successfully created.',
			APVendorInvMisc: 'Miscellaneous was successfully created.',
			ProjectPO: 'Purchase order was successfully created.',
			ProjectPOAccept: 'Successfully accepted purchase orders.',
			ProjectBudget: 'Budget was successfully created.',
			User: 'User was successfully created.',
		},
		delete: {
			ClipItem: 'Item was successfully deleted.',
			CashReceipt: 'Cash receipt was successfully deleted.',
			ARClientInv: 'Client invoice was successfully deleted.',
			APVendorInv: 'Vendor invoice "[IDS]" was successfully deleted.',
			ProjectBudget: 'Budget item "[IDS]" was successfully deleted.',
		},
		update: {
			APPayment: 'Payment was successfully updated.',
			APVendorInvoiceVoid: 'Vendor invoice was successfully voided.',
		},
		email: {
			ARClientInv: 'Successfully sent invoice to email.',
			PO: 'Successfully sent purchase order to email.',
		},
		report: {
			msg: 'Successfully generated PDF.',
		},
	},
	try: {
		GLJe: 'Please try saving again.',
	},
};

export default MSG;
