import React from 'react';
import {
	startOfDay,
	endOfDay,
	addDays,
	subDays,
	getYear,
	getMonth,
	lastDayOfMonth,
} from 'date-fns';
import { DateRangePicker } from 'rsuite';
import { DateRange } from 'rsuite/esm/DateRangePicker';

interface ICheckbookHistoryDateRangePickerProps {
	startDate: Date | null;
	endDate: Date | null;
	onChange: (dates: [Date | null, Date | null] | null) => void;
}

const CheckbookHistoryDateRangePicker = ({
	startDate,
	endDate,
	onChange,
}: ICheckbookHistoryDateRangePickerProps) => {
	const currentDate = new Date();
	const year = getYear(currentDate);
	const month = getMonth(currentDate);
	const endOfTheDay = endOfDay(currentDate);
	return (
		<DateRangePicker
			style={{
				minWidth: '200px',
			}}
			value={startDate && endDate ? ([startDate, endDate] as DateRange) : null}
			onChange={onChange}
			placement="auto"
			placeholder="Select date"
			format="MM/dd/yyyy"
			ranges={[
				{
					label: 'today',
					value: [startOfDay(currentDate), endOfTheDay],
				},
				{
					label: 'yesterday',
					value: [
						startOfDay(addDays(currentDate, -1)),
						endOfDay(addDays(currentDate, -1)),
					],
				},
				{
					label: 'last7Days',
					value: [startOfDay(subDays(currentDate, 6)), endOfTheDay],
				},
				{
					label: 'Last 30 Days',
					value: [startOfDay(subDays(currentDate, 30)), endOfTheDay],
				},
				{
					label: 'This month',
					value: [
						startOfDay(new Date(year, month, 1)),
						endOfDay(lastDayOfMonth(currentDate)),
					],
				},
				{
					label: 'Last month',
					value: [
						startOfDay(new Date(year, month - 1, 1)),
						endOfDay(lastDayOfMonth(new Date(year, month - 1, 1))),
					],
				},
			]}
		/>
	);
};

CheckbookHistoryDateRangePicker.displayName = 'CheckbookHistoryDateRangePicker';

export { CheckbookHistoryDateRangePicker };
