import {
	Breadcrumb,
	Dropdown,
	Col,
	Container,
	Row,
	Table,
	Form,
	Button,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ThreadComponent from '../../ThreadComponent';
import {
	getActiveMenuUri,
	routeParam,
	WithRouter,
} from '../../../../helpers/Router';
import URI from '../../../../defaults/RoutesDefault';
import Header from '../../../components/Header';
import ListFilter from '../../../components/ListFilter';
import { currencyFormat } from '../../../../helpers/Number';
import Select from 'react-select';
import { ApiService } from '../../../../lib/api/HttpService';
import { formatDate, formatFilterDate } from '../../../../helpers/Date';
import {
	displayAlert,
	displayAlertError,
	displayAlertLoader,
	getErrorMessage,
	hideAlertLoader,
} from '../../../../utilities/Response';
import { getCookie } from '../../../../utilities/Auth';
import PaymentActionOffsetModal from '../../modal/PaymentActionOffsetModal';
import PaymentActionModifyAmountModal from '../../modal/PaymentActionModifyAmountModal';
import PaymentActionCreditCardPaymentModal from '../../modal/PaymentActionCreditCardPaymentModal';
import PaymentActionOnlinePaymentModal from '../../modal/PaymentActionOnlinePaymentModal';
import PaymentActionCheckWirePaymentModal from '../../modal/PaymentActionCheckWirePaymentModal';
import { debounce, includes, isEmpty } from 'lodash';
import { showEmpty, showLoading } from '../../../../helpers/Loading';
import { isFiltered } from '../../../../helpers/Util';
import { DateRangePicker } from 'rsuite';
import {
	startOfDay,
	endOfDay,
	addDays,
	subDays,
	getMonth,
	getYear,
	lastDayOfMonth,
} from 'date-fns';
import { getCheckAllClass } from '../../../../utilities/ModuleHelper';
import {
	addDomClass,
	hasClass,
	removeDomClass,
} from '../../../../utilities/DOM';
import MSG from '../../../../defaults/Message';
import FilterSort from '../../../../utilities/modules/FilterSort';
import PaymentActionPrintedChecksModal from '../../modal/PaymentActionPrintedChecksModal';
import { encodeURI } from '../../../../utilities/String';
import SecureDropdownItem from '../../../../app/components/security/SecureDropdownItem';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security';
import { renderSecureContent } from '../../../../utilities/modules/SecureContent';

const ACTION_TYPES = {
	offset: 'offset',
	modifyAmount: 'modify-amount',
	creditCard: 'credit-card-payment',
	online: 'online-payment',
	checkWire: 'check-wire-payment',
	printedCheck: 'printed-check',
};

const PAGE_SIZE_OPTIONS = [
	{ value: 10, label: '10' },
	{ value: 20, label: '20' },
	{ value: 50, label: '50' },
	{ value: 75, label: '75' },
	{ value: 100, label: '100' },
];

class PaymentList extends ThreadComponent {
	constructor(props) {
		super(props);

		this.fs = new FilterSort('ap_payment_list');
		this.fs.setDefaultSort('supplier asc');
		this.state = {
			checks: {},
			checkedItems: [],
			totalChecked: 0,
			data: [],
			tmpData: [],
			payTypes: [],
			glAccounts1: [],
			glAccounts2: [],
			selectedPayType: this.fs.getFilter('selectedPayType') ?? {},
			showRetainerModal: false,
			activeMenu: getActiveMenuUri(
				this.props.paths.pathname,
				['new', 'existing'],
				'new'
			),
			dataIsLoaded: false,
			totalAmount: 0,
			activeModal: '',
			checkedData: [],
			modifyAmountData: {},
			page: 1,
			pageSize: { value: 10, label: '10' },
			sortProperty: this.fs.getSort() ?? 'supplier asc',
			showPrintChecksModal: false,
			showTableSearch: this.fs.isSearchActive(),
			searchProperties: this.fs.getSearches(),
		};

		this.api = new ApiService();
		this.userCode = getCookie('dmUsercode');

		this.apiPath = 'vendorpayments';
		this.changePageHandler = debounce(this.handleChangePage.bind(this, 1), 200);
		this.getVendorPaymentList = this.getVendorPaymentList.bind(this);

		this.isGrid = true;
	}

	componentInit() {
		this.setTitle('Payments').setActionBar(true);
	}

	mapAccountsToOptions = (accounts) => {
		return accounts.map((account) => ({
			value: account.account,
			label: `${account.account} - ${account.accountn}`,
			nextchecknum: account.nextchecknum,
		}));
	};

	async componentDidMount() {
		await this.fetchInitialData();
	}

	renderData(data) {
		this.setState({
			dataIsLoaded: true,
			data,
		});
	}

	fetchInitialData = async (isGrid) => {
		this.setState({
			page: 1,
			checkedData: [],
		});
		let payTypes = await this.api.getPayTypes();
		payTypes = payTypes.map((p) => {
			return { value: p.payType, label: p.payType };
		});
		payTypes.unshift({ value: '', label: 'All' });

		const glAccountsData1 = await this.api.getGLAccountsReportSummaries(
			'?$filter=specacct eq 1'
		);
		const glAccounts1 = this.mapAccountsToOptions(glAccountsData1);

		const glAccountsData2 = await this.api.getGLAccountsReportSummaries(
			'?$filter=specacct eq 2'
		);
		const glAccounts2 = this.mapAccountsToOptions(glAccountsData2);

		this.enableSearchAndSortTable();
		this.setState(
			{
				payTypes,
				glAccounts1,
				glAccounts2,
				selectedPayType: !isEmpty(this.state.selectedPayType)
					? this.state.selectedPayType
					: payTypes[0],
			},
			() => this.getVendorPaymentList(null, isGrid)
		);
	};

	getVendorPaymentList = async (page, isGrid) => {
		this.setState({
			dataIsLoaded: false,
		});

		// @todo: we should not be accessing the DOM directly :/
		const el = document.getElementById('inline-check-th-0');

		if (el) {
			el.checked = false;
		}

		const payData = { payWith: this.state.selectedPayType?.value ?? '' };

		if (this.isGrid || isGrid) {
			await this.api.createVendorPaymentGrids(payData);
			await this.api.temporaryCheckDisbursments(
				`?$filter=usercode eq ${this.userCode}&$orderby=checknum asc`
			);
		}

		await this.getVendorPayments(page);
	};

	async getGlAccounts1() {
		const glAccountsData1 = await this.api.getGLAccountsReportSummaries(
			'?$filter=specacct eq 1'
		);
		const glAccounts1 = this.mapAccountsToOptions(glAccountsData1);

		this.setState({
			glAccounts1,
		});
	}

	async getVendorPayments(page) {
		const payments =
			(await this.api
				.getVendorPayments(`?${this.buildFilters(page)}`)
				.catch((error) => {
					let message;
					if (error._backedError.response) {
						message = error._backedError.response.data.userError;
					} else {
						message = getErrorMessage(error);
					}

					displayAlert('danger', message);

					this.setState({
						data: [],
					});
				})) ?? [];

		this.renderData(payments);
	}

	setTotalAmount() {
		let checkedBoxes = document.querySelectorAll(
			'input[name=chk-payment-item]:checked'
		);

		let totalAmount = 0;
		checkedBoxes.forEach(
			(node) => (totalAmount += parseFloat(node.dataset.amount))
		);

		this.setState({ totalAmount });
	}

	handleShowModal = (name) => {
		const { checks, checkedItems } = this.state;
		const checkedData = checkedItems;

		if (checkedItems.length === 0) {
			displayAlert('danger', MSG.error.noSelected);
		} else if (name === ACTION_TYPES.modifyAmount && checkedItems.length > 1) {
			displayAlert('danger', MSG.error.update.APPaymentModify);
		} else if (name === ACTION_TYPES.modifyAmount) {
			// Get the checked item id.
			const id = parseInt(this.state.checkedItems[0]);
			const data = _.find(this.state.data, { txnum: id });

			this.setState({
				activeModal: name,
				modifyAmountData: data,
			});
		} else if (name === ACTION_TYPES.printedCheck) {
			// Get the checked item id.
			const id = parseInt(this.state.checkedItems[0]);
			const data = _.find(this.state.data, { txnum: id });
			this.setState({
				activeModal: name,
				modifyAmountData: data,
			});
		} else {
			this.setState({
				activeModal: name,
				checkedData,
			});
		}
	};

	handleHideModal = (updateData = false, isGrid = false, refetchData) => {
		if (updateData) {
			this.setState(
				{
					activeModal: '',
				},
				() => {
					this.isGrid = isGrid;
					this.getGlAccounts1();
					this.setTotalAmount();
					if (refetchData) {
						this.fetchInitialData(true);
					}
				}
			);
		} else {
			this.setState({
				activeModal: '',
			});
		}
	};

	handleFilter = (name) => (e) => {
		this.setState(
			{
				[name]: e,
				page: 1,
			},
			this.changePageHandler
		);
	};

	handleShowTableSearch = (e) => {
		const filters = document.querySelectorAll('.a-table-search-fields input');
		if (filters) {
			filters.forEach((e) => {
				e.value = '';
			});
		}

		let newTableSearch = !this.state.showTableSearch;
		this.setState({
			showTableSearch: newTableSearch,
		});
		this.fs.setIsActiveSearch(newTableSearch);

		if (JSON.stringify(this.state.searchProperties) !== '{}') {
			this.fs.setSearches({});
			this.setState(
				{
					searchProperties: {},
				},
				this.changePageHandler
			);
		}
	};

	buildFilters(currentPage) {
		let queryString = `$top=${this.state.pageSize.value + 1}&$skip=${
			((currentPage ?? this.state.page) - 1) * this.state.pageSize.value
		}`;
		let filters = [];

		Object.keys(this.state.searchProperties).forEach((key) => {
			const property = this.state.searchProperties[key];
			if (property.value || property.min || property.max) {
				if (key === 'txnum') {
					filters.push(
						`(contains(cast(${key}, 'Edm.String'),'${encodeURI(
							property.value
						)}'))`
					);
				} else if (property.type === 'number') {
					filters.push(`${key} eq ${property.value}`);
				} else if (property.type === 'date') {
					if (property.min) filters.push(`${key} ge ${property.min}`);
					if (property.max) filters.push(`${key} le ${property.max}`);
				} else {
					if (key === 'amtdue' || key === 'invamt' || key === 'paidtodate') {
						filters.push(`${key} eq ${property.value}`);
					} else {
						if (property.opt)
							filters.push(
								`(${key} ${property.opt} '${encodeURI(property.value)}')`
							);
						else
							filters.push(`contains(${key}, '${encodeURI(property.value)}')`);
					}
				}
			}
		});

		if (this.state.sortProperty !== '') {
			queryString += `&$orderby=${this.state.sortProperty}`;
		}

		let filtersQuery = `&$filter=usercode eq ${this.userCode}`;
		if (filters.length > 0) {
			filtersQuery = `&$filter=usercode eq ${this.userCode} and `;

			for (let i = 0; i < filters.length; i++) {
				if (
					filters[i].includes('contains(proj') ||
					filters[i].includes('contains(ponum')
				) {
					filtersQuery +=
						filtersQuery.length > 0
							? `${filters[i]} ${i + 1 >= filters.length ? '' : 'or'} `
							: filters[i];
				} else {
					filtersQuery += `${filters[i]} ${
						i + 1 >= filters.length ? '' : 'and'
					} `;
				}
			}
		}
		queryString += `${filtersQuery}`;
		return queryString;
	}

	onPageSizeChanged = (size) => {
		this.setState(
			{
				pageSize: size,
				page: 1,
			},
			() => {
				this.handleChangePage(1);
			}
		);
	};

	onPageChanged = (page) => {
		this.handleChangePage(page);
	};

	handleChangePage = async (page) => {
		this.isGrid = false;
		this.getVendorPaymentList(page);

		this.setState({
			page: page,
		});
	};

	handleSearch = (e) => {
		const key = e.target.attributes['data-field'].value;
		const value = e.target.value;
		const type = e.target.attributes['data-type']
			? e.target.attributes['data-type'].value
			: 'string';

		if (key === 'projponum') {
			const valueArr = value.replace(/\s/gi, '').split('-');
			let searchFilters = {
				proj: {
					value: valueArr[0] ?? '',
					type: type,
					opt: 'eq',
				},
				ponum: {
					value: valueArr[1] ?? '',
					type: type,
					opt: 'eq',
				},
			};

			if (valueArr.length === 1) {
				searchFilters = {
					proj: {
						value: valueArr[0] ?? '',
						type: type,
					},
					ponum: {
						value: valueArr[0] ?? '',
						type: type,
					},
				};
			}

			this.setState(
				{
					searchProperties: {
						...this.state.searchProperties,
						...searchFilters,
					},
					dataIsLoaded: false,
				},
				() => {
					this.fs.setSearches(this.state.searchProperties);
					this.changePageHandler();
				}
			);
		} else {
			this.setState(
				{
					searchProperties: {
						...this.state.searchProperties,
						[key]: { value: value, type: type },
					},
				},
				this.changePageHandler
			);
		}
	};

	enableSortTable() {
		const sort = document.querySelectorAll('.a-table-heading .sort');
		const self = this;

		// Add change event
		if (sort) {
			sort.forEach((_e) => {
				_e.addEventListener(
					'click',
					function (e) {
						sort.forEach((_e2) => {
							if (_e !== _e2) {
								removeDomClass('desc', _e2);
								removeDomClass('asc', _e2);
								removeDomClass('active', _e2);
							}
						});

						addDomClass('active', _e);

						if (hasClass('desc', _e)) {
							removeDomClass('desc', _e);
							addDomClass('asc', _e);
						} else if (hasClass('asc', _e)) {
							removeDomClass('asc', _e);
							addDomClass('desc', _e);
						} else {
							addDomClass('desc', _e);
						}

						const sortProperty = `${_e.attributes['data-field'].value} ${
							hasClass('desc', _e) ? 'asc' : 'desc'
						}`;

						// Save sortProperty to localStorage
						self.fs.setSort(sortProperty);

						self.setState(
							{
								sortProperty,
							},
							self.changePageHandler
						);
					},
					false
				);
			});
		}
	}

	handleSelectPayType = (e) => {
		this.isGrid = true;
		this.setState(
			{
				selectedPayType: e,
			},
			this.getVendorPaymentList
		);
		this.fs.setFilter('selectedPayType', e);
	};

	handleMenuClick = (e) => {
		e.preventDefault();

		this.setState(
			(prevState) => (prevState.activeMenu = e.target.dataset.menu)
		);
	};

	handleResetData = () => {
		this.setState({
			checks: {},
			checkedItems: [],
			checkedData: [],
			totalAmount: 0,
			totalChecked: 0,
		});
	};

	handleChecks = async (e) => {
		// Get the target menu.
		const id = parseInt(e.target.getAttribute('data-id'));
		const amount = e.target.getAttribute('data-amount');
		const txnum = e.target.getAttribute('data-txnum');

		const checks = this.state.checkedItems;
		const index = checks.findIndex((_id) => _id === id);
		const checked = index > -1;
		const updatedChecks = checked
			? checks.slice(0, index).concat(checks.slice(index + 1))
			: checks.concat(parseInt(id));

		const totalAmount = checked
			? this.state.totalAmount - parseFloat(amount)
			: this.state.totalAmount + parseFloat(amount);

		const vendorParams = {
			txNum: parseInt(txnum),
			amtdue: parseFloat(amount),
			tag: !checked,
			hold: !checked,
		};

		displayAlertLoader(MSG.loading.hold);

		try {
			await this.api.editVendorPayments(vendorParams);
			this.getVendorPayments(this.state.page);
			hideAlertLoader();
		} catch (error) {
			displayAlertError(getErrorMessage(error));
		}

		// Checked items
		this.setState({
			checkedItems: updatedChecks,
			totalChecked: updatedChecks.length,
			totalAmount,
		});

		if (!updatedChecks.length) {
			this.setState({
				modifyAmountData: {},
			});
		}
	};

	handleSelectAllChecks = async (e) => {
		const { checked } = e.target;
		const { data, page } = this.state;

		const checkedItems = checked
			? data.map((item) => parseInt(item.txnum))
			: [];

		const vendorPayments = data.map((x) => ({
			txNum: parseInt(x.txnum),
			amtdue: parseFloat(x.amtdue),
			tag: checked,
			hold: checked,
		}));

		try {
			displayAlertLoader(MSG.loading.hold);
			for (const payload of vendorPayments) {
				await this.api.editVendorPayments(payload);
			}
			this.getVendorPayments(page);
		} catch (error) {
			displayAlertError(getErrorMessage(error));
		} finally {
			hideAlertLoader();
		}

		this.setState(
			{
				checkedItems,
				totalChecked: checkedItems.length,
			},
			this.setTotalAmount
		);
	};

	handleDateChange = (key, e) => {
		let tmp = this.state.searchProperties;
		if (e !== null) {
			tmp[key] = {
				min: formatFilterDate(e[0]),
				max: formatFilterDate(e[1]),
				type: 'date',
			};
		} else {
			delete tmp[key];
		}
		this.setState(
			{
				searchProperties: tmp,
				dataIsLoaded: false,
			},
			() => {
				this.fs.setSearches(this.state.searchProperties);
				this.changePageHandler();
			}
		);
	};

	sortClass(name) {
		return `sort ${this.fs.isActiveSort(name)}`;
	}

	renderPagination() {
		return (
			<div className={'d-flex flex-wrap justify-content-center'}>
				<div className={'d-flex flex-row align-items-center pagination'}>
					<Button
						variant="ivory"
						size="sm"
						className={'btn-icon pagination-btn'}
						disabled={this.state.page === 1}
						onClick={() => this.handleChangePage(this.state.page - 1)}
					>
						<i className="ri-arrow-left-s-line"></i> Previous
					</Button>
					<span className={'pagination-span'}>{this.state.page}</span>
					<Button
						variant="ivory"
						size="sm"
						className={'btn-icon pagination-btn'}
						disabled={this.state.data.length <= this.state.pageSize.value}
						onClick={() => this.handleChangePage(this.state.page + 1)}
					>
						Next <i className="ri-arrow-right-s-line ms-1"></i>
					</Button>
				</div>
				<Select
					onChange={this.handleFilter('pageSize')}
					key="pageSize"
					options={PAGE_SIZE_OPTIONS}
					defaultValue={this.state.pageSize}
					className="react-select pagination-select mx-3"
					placeholder="Please select"
				/>
			</div>
		);
	}

	renderHeader() {
		return (
			<Header buttons={1}>
				<Header.TitleContent>
					<Header.LeftContent>
						<Header.Breadcumbs>
							<Breadcrumb className="breadcrumb-light">
								<Breadcrumb.Item
									linkProps={{
										to: URI.accountsPayable.payment.base,
									}}
									linkAs={Link}
								>
									Accounts Payable
								</Breadcrumb.Item>
								<Breadcrumb.Item>Payments</Breadcrumb.Item>
							</Breadcrumb>
						</Header.Breadcumbs>
						<Header.Title>Payments</Header.Title>
					</Header.LeftContent>
				</Header.TitleContent>
			</Header>
		);
	}

	renderFilters() {
		return (
			<>
				<ListFilter>
					<ListFilter.Fields md={10} lg={10} xl={10} className="width-33">
						<ListFilter.Field>
							<Form.Label className="text-end">
								<strong>Pay With</strong>
							</Form.Label>
							<Col lg={6} className="mb-2 mb-lg-0">
								<Select
									menuPortalTarget={document.body}
									styles={{
										menuPortal: (base) => ({
											...base,
											zIndex: 9999,
										}),
									}}
									options={this.state.payTypes}
									value={this.state.selectedPayType}
									placeholder="Please select"
									name="payWith"
									onChange={this.handleSelectPayType}
								/>
							</Col>
						</ListFilter.Field>
						<ListFilter.Field className="justify-content-center justify-content-md-start mt-1 mt-md-0">
							<Form.Label className="text-end">
								<span className="me-2">Selected Total:</span>
								<strong>{currencyFormat(this.state.totalAmount, '$')}</strong>
							</Form.Label>
						</ListFilter.Field>
					</ListFilter.Fields>
					<ListFilter.Actions
						md={2}
						lg={2}
						xl={2}
						replaceclassmatch="justify-content-xl-end"
						replaceclassmatchwith="justify-content-md-end"
					>
						<ListFilter.Action>
							<Button
								as={Link}
								to="#"
								variant="ivory"
								size="sm"
								className={`btn-icon btn-action ${
									this.state.showTableSearch ? 'bg-primary-ash text-white' : ''
								}`}
								onClick={this.handleShowTableSearch}
							>
								<i className="ri-search-line"></i> Search
							</Button>
						</ListFilter.Action>
						<ListFilter.Action className="ms-auto ms-md-3">
							<Dropdown
								className="d-flex justify-content-end ms-auto"
								align="end"
							>
								<Dropdown.Toggle
									id="dropdown-autoclose-true"
									variant="ivory"
									size="sm"
								>
									Actions
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<SecureDropdownItem
										attributeNo={58}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec4}
										href=""
										onClick={() => this.handleShowModal(ACTION_TYPES.offset)}
									>
										<i className="ri-swap-box-line"></i> Offset
									</SecureDropdownItem>
									<SecureDropdownItem
										attributeNo={58}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec1}
										href=""
										onClick={() =>
											this.handleShowModal(ACTION_TYPES.modifyAmount)
										}
									>
										<i className="ri-refund-2-line"></i> Modify Amount
									</SecureDropdownItem>
									<SecureDropdownItem
										attributeNo={58}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec5}
										href=""
										onClick={() =>
											this.handleShowModal(ACTION_TYPES.creditCard)
										}
									>
										<i className="ri-bank-card-2-line"></i> Credit Card Payment
									</SecureDropdownItem>
									<SecureDropdownItem
										attributeNo={58}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec6}
										href=""
										onClick={() => this.handleShowModal(ACTION_TYPES.online)}
									>
										<i className="ri-shopping-bag-line"></i> Online Payment
									</SecureDropdownItem>
									<SecureDropdownItem
										attributeNo={58}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec2}
										href=""
										onClick={() => this.handleShowModal(ACTION_TYPES.checkWire)}
									>
										<i className="ri-secure-payment-line"></i> Hand Check or
										Wire Payment
									</SecureDropdownItem>
									<Dropdown.Item
										href=""
										onClick={() =>
											this.handleShowModal(ACTION_TYPES.printedCheck)
										}
									>
										<i className="ri-secure-payment-line"></i> Printed Checks
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</ListFilter.Action>
					</ListFilter.Actions>
				</ListFilter>
				<ListFilter.Spacer />
			</>
		);
	}

	render() {
		const { showTableSearch, totalChecked, checkedItems } = this.state;

		return (
			<>
				{this.renderHeader()}

				<div className="content-padding has-action-bar sticky-container">
					<Container fluid className="px-0">
						{renderSecureContent(
							<Row>
								<Col sm="12">
									{/* Filter */}
									{this.renderFilters()}

									<div className="table-gradient">
										<Table striped responsive className="a-table">
											<thead>
												<tr className="a-table-heading">
													<th align="middle" className="mw-80px">
														<div className="d-flex justify-content-center">
															<Form.Check
																inline
																label=""
																name={`inline-check-th-0`}
																type="checkbox"
																data-id={`th-0`}
																id={`inline-check-th-0`}
																checked={totalChecked > 0}
																className={getCheckAllClass(
																	totalChecked,
																	this.state.data
																)}
																onChange={this.handleSelectAllChecks}
															/>
														</div>
													</th>
													<th className="mw-110px">
														<span
															className={this.sortClass('possidemark')}
															data-field="possidemark"
														>
															Pay With
														</span>
													</th>
													{/* <th><span className='sort'>Hold</span></th> */}
													<th>
														<span
															className={this.sortClass('supplier')}
															data-field="supplier"
														>
															Vendor Code
														</span>
													</th>
													<th className="mw-120px">
														<span
															className={this.sortClass('payee')}
															data-field="payee"
														>
															Payee Name
														</span>
													</th>
													<th>
														<span
															className={this.sortClass('sinvdt')}
															data-field="sinvdt"
														>
															Invoice Date
														</span>
													</th>
													<th>
														<span
															className={this.sortClass('sinvnum')}
															data-field="sinvnum"
														>
															Invoice Number
														</span>
													</th>
													<th className="mw-120px">
														<span
															className={this.sortClass('paydt')}
															data-field="paydt"
														>
															Payment Due Date
														</span>
													</th>
													<th className="mw-120px">
														<span
															className={this.sortClass('amtdue')}
															data-field="amtdue"
														>
															Amount To Pay
														</span>
													</th>
													<th className="mw-120px">
														<span
															className={this.sortClass('invamt')}
															data-field="invamt"
														>
															Original Amount
														</span>
													</th>
													<th className="mw-120px">
														<span
															className={this.sortClass('paidtodate')}
															data-field="paidtodate"
														>
															Already Paid
														</span>
													</th>
													<th>
														<span
															className={this.sortClass('discount')}
															data-field="discount"
														>
															Discount
														</span>
													</th>
													<th>
														<span
															className={this.sortClass('ponum')}
															data-field="ponum"
														>
															PO Number
														</span>
													</th>
													<th>
														<span
															className={this.sortClass('txnum')}
															data-field="txnum"
														>
															Transaction Number
														</span>
													</th>
													<th>
														<span
															className={this.sortClass('orgsupplier')}
															data-field="orgsupplier"
														>
															Original Supplier
														</span>
													</th>
													<th>
														<span
															className={this.sortClass('orgpayee')}
															data-field="orgpayee"
														>
															Original Payee
														</span>
													</th>
													<th className="mw-140px">
														<span
															className={this.sortClass('posdesc')}
															data-field="posdesc"
														>
															Notes
														</span>
													</th>
												</tr>
												<tr
													className={`a-table-search-fields ${
														showTableSearch ? '' : 'd-none'
													}`}
												>
													<th></th>
													<th>
														<Form.Control
															type="text"
															data-field="possidemark"
															onChange={this.handleSearch}
															defaultValue={this.fs.getValue('possidemark')}
														/>
													</th>
													{/* <th><Form.Control type="text" data-field="clientName" /></th> */}
													<th>
														<Form.Control
															type="text"
															data-field="supplier"
															onChange={this.handleSearch}
															defaultValue={this.fs.getValue('supplier')}
														/>
													</th>
													<th>
														<Form.Control
															type="text"
															data-field="payee"
															onChange={this.handleSearch}
															defaultValue={this.fs.getValue('payee')}
														/>
													</th>
													<th>
														<DateRangePicker
															style={{
																minWidth: '200px',
															}}
															placement="auto"
															placeholder="Select date"
															format="MM/dd/yyyy"
															defaultValue={this.fs.getValue('sinvdt')}
															onChange={this.handleDateChange.bind(
																this,
																'sinvdt'
															)}
															onClean={this.handleDateChange.bind(
																this,
																'sinvdt'
															)}
															ranges={[
																{
																	label: 'today',
																	value: [
																		startOfDay(new Date()),
																		endOfDay(new Date()),
																	],
																},
																{
																	label: 'yesterday',
																	value: [
																		startOfDay(addDays(new Date(), -1)),
																		endOfDay(addDays(new Date(), -1)),
																	],
																},
																{
																	label: 'last7Days',
																	value: [
																		startOfDay(subDays(new Date(), 6)),
																		endOfDay(new Date()),
																	],
																},
																{
																	label: 'Last 30 Days',
																	value: [
																		startOfDay(subDays(new Date(), 30)),
																		endOfDay(new Date()),
																	],
																},
																{
																	label: 'This month',
																	value: [
																		startOfDay(
																			new Date(
																				getYear(new Date()),
																				getMonth(new Date()),
																				1
																			)
																		),
																		endOfDay(lastDayOfMonth(new Date())),
																	],
																},
																{
																	label: 'Last month',
																	value: [
																		startOfDay(
																			new Date(
																				getYear(new Date()),
																				getMonth(new Date()) - 1,
																				1
																			)
																		),
																		endOfDay(
																			lastDayOfMonth(
																				new Date(
																					getYear(new Date()),
																					getMonth(new Date()) - 1,
																					1
																				)
																			)
																		),
																	],
																},
															]}
														/>
													</th>
													<th>
														<Form.Control
															type="text"
															data-field="sinvnum"
															onChange={this.handleSearch}
															defaultValue={this.fs.getValue('sinvnum')}
														/>
													</th>
													<th>
														<DateRangePicker
															style={{
																minWidth: '200px',
															}}
															placement="auto"
															placeholder="Select date"
															format="MM/dd/yyyy"
															defaultValue={this.fs.getValue('paydt')}
															onChange={this.handleDateChange.bind(
																this,
																'paydt'
															)}
															onClean={this.handleDateChange.bind(
																this,
																'paydt'
															)}
															ranges={[
																{
																	label: 'today',
																	value: [
																		startOfDay(new Date()),
																		endOfDay(new Date()),
																	],
																},
																{
																	label: 'yesterday',
																	value: [
																		startOfDay(addDays(new Date(), -1)),
																		endOfDay(addDays(new Date(), -1)),
																	],
																},
																{
																	label: 'last7Days',
																	value: [
																		startOfDay(subDays(new Date(), 6)),
																		endOfDay(new Date()),
																	],
																},
																{
																	label: 'Last 30 Days',
																	value: [
																		startOfDay(subDays(new Date(), 30)),
																		endOfDay(new Date()),
																	],
																},
																{
																	label: 'This month',
																	value: [
																		startOfDay(
																			new Date(
																				getYear(new Date()),
																				getMonth(new Date()),
																				1
																			)
																		),
																		endOfDay(lastDayOfMonth(new Date())),
																	],
																},
																{
																	label: 'Last month',
																	value: [
																		startOfDay(
																			new Date(
																				getYear(new Date()),
																				getMonth(new Date()) - 1,
																				1
																			)
																		),
																		endOfDay(
																			lastDayOfMonth(
																				new Date(
																					getYear(new Date()),
																					getMonth(new Date()) - 1,
																					1
																				)
																			)
																		),
																	],
																},
															]}
														/>
													</th>
													<th>
														<Form.Control
															type="number"
															className="no-spinner"
															data-field="amtdue"
															onChange={this.handleSearch}
															defaultValue={this.fs.getValue('amtdue')}
														/>
													</th>
													<th>
														<Form.Control
															type="number"
															className="no-spinner"
															data-field="invamt"
															onChange={this.handleSearch}
															defaultValue={this.fs.getValue('invamt')}
														/>
													</th>
													<th>
														<Form.Control
															type="number"
															className="no-spinner"
															data-field="paidtodate"
															onChange={this.handleSearch}
															defaultValue={this.fs.getValue('paidtodate')}
														/>
													</th>
													<th>
														<Form.Control
															type="number"
															className="no-spinner"
															data-field="discount"
															onChange={this.handleSearch}
															defaultValue={this.fs.getValue('discount')}
														/>
													</th>
													<th>
														<Form.Control
															type="text"
															data-field="projponum"
															onChange={this.handleSearch}
															defaultValue={this.fs.getValue('ponum')}
														/>
													</th>
													<th>
														<Form.Control
															type="number"
															data-field="txnum"
															onChange={this.handleSearch}
															defaultValue={this.fs.getValue('txnum')}
														/>
													</th>
													<th>
														<Form.Control
															type="text"
															data-field="orgsupplier"
															onChange={this.handleSearch}
															defaultValue={this.fs.getValue('orgsupplier')}
														/>
													</th>
													<th>
														<Form.Control
															type="text"
															data-field="orgpayee"
															onChange={this.handleSearch}
															defaultValue={this.fs.getValue('orgpayee')}
														/>
													</th>
													<th>
														<Form.Control
															type="text"
															data-field="posdesc"
															onChange={this.handleSearch}
															defaultValue={this.fs.getValue('posdesc')}
														/>
													</th>
												</tr>
											</thead>
											<tbody>
												{!this.state.dataIsLoaded
													? showLoading()
													: this.state.data.length === 0 &&
													  isFiltered(this.state.searchProperties)
													? showEmpty()
													: this.state.data
															.slice(0, this.state.pageSize.value)
															.map((item, i) => (
																<tr
																	key={i}
																	data-key={i}
																	className={
																		includes(checkedItems, parseInt(item.txnum))
																			? `active`
																			: ''
																	}
																>
																	<td>
																		<div className="d-flex justify-content-center">
																			<Form.Check
																				label=""
																				name={`chk-payment-item`}
																				type="checkbox"
																				data-id={item.txnum}
																				data-amount={item.amtdue}
																				data-txnum={item.txnum}
																				data-index={i}
																				id={`chk-projectview-items-` + i}
																				className="chk-projectview-items-item"
																				checked={includes(
																					checkedItems,
																					parseInt(item.txnum)
																				)}
																				onChange={this.handleChecks}
																			/>
																		</div>
																	</td>
																	<td>{item.possidemark}</td>
																	{/* <td>{item.hold}</td> */}
																	<td>{item.supplier}</td>
																	<td>{item.payee}</td>
																	<td>{formatDate(item.sinvdt)}</td>
																	<td>
																		{item.sinvnum && (
																			<Link
																				to={routeParam(
																					URI.accountsPayable.vendorInvoice
																						.editInvoice,
																					{
																						id: item.txnum,
																					}
																				)}
																			>
																				{item.sinvnum}
																			</Link>
																		)}
																	</td>
																	<td>{formatDate(item.paydt)}</td>
																	<td>{currencyFormat(item.amtdue, '$')}</td>
																	<td>{currencyFormat(item.invamt, '$')}</td>
																	<td>
																		{currencyFormat(item.paidtodate, '$')}
																	</td>
																	<td>{currencyFormat(item.discount, '$')}</td>
																	<td>
																		{item?.proj ? item?.proj + ' - ' : ''}
																		{item.ponum}
																	</td>
																	<td>{item.txnum}</td>
																	<td>{item.orgsupplier}</td>
																	<td>{item.orgpayee}</td>
																	<td>{item.posdesc}</td>
																</tr>
															))}
											</tbody>
										</Table>
										{this.state.data.length > 0 ? this.renderPagination() : ''}
									</div>
								</Col>
							</Row>
						)(30, SECURITY_ATTRIBUTE_TYPES.DenyAccess)}
					</Container>
					<PaymentActionOffsetModal
						show={this.state.activeModal == ACTION_TYPES.offset}
						hideModal={this.handleHideModal.bind(this)}
						checkedData={this.state.checkedData}
					/>
					<PaymentActionModifyAmountModal
						show={this.state.activeModal == ACTION_TYPES.modifyAmount}
						hideModal={this.handleHideModal.bind(this)}
						payTypes={this.state.payTypes.slice(1)}
						data={this.state.modifyAmountData}
					/>
					{this.state.activeModal == ACTION_TYPES.creditCard && (
						<PaymentActionCreditCardPaymentModal
							hideModal={this.handleHideModal.bind(this)}
							glAccounts={this.state.glAccounts2}
							checkedData={this.state.checkedData}
							resetData={this.handleResetData}
						/>
					)}
					{this.state.activeModal == ACTION_TYPES.online && (
						<PaymentActionOnlinePaymentModal
							hideModal={this.handleHideModal.bind(this)}
							glAccounts={this.state.glAccounts1}
							checkedData={this.state.checkedData}
							getVendorPaymentList={this.getVendorPaymentList}
							resetData={this.handleResetData}
						/>
					)}
					{this.state.activeModal == ACTION_TYPES.checkWire && (
						<PaymentActionCheckWirePaymentModal
							hideModal={this.handleHideModal.bind(this)}
							glAccounts={this.state.glAccounts1}
							data={this.state.modifyAmountData}
							checkedData={this.state.checkedData}
							resetData={this.handleResetData}
						/>
					)}
					{this.state.activeModal == ACTION_TYPES.printedCheck && (
						<PaymentActionPrintedChecksModal
							hideModal={this.handleHideModal.bind(this)}
							glAccounts={this.state.glAccounts1}
							totalAmount={this.state.totalAmount}
							checkedData={this.state.checkedItems}
							resetData={this.handleResetData}
						/>
					)}
				</div>
			</>
		);
	}
}

export default WithRouter(PaymentList);
