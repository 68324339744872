import { useMutation } from '@tanstack/react-query';
import { postFinanceCharges } from 'api/resources/financeCharges';
import { EAlertTypes } from 'legacy/app/enums/alertTypes/alertTypes';
import { displayAlert } from 'legacy/utilities/Response';

export const usePostFinanceCharges = () => {
	return useMutation(postFinanceCharges, {
		onSuccess: async () => {
			displayAlert(
				EAlertTypes.Success,
				'Finance charges submitted successfully'
			);
		},
		onError: () => {
			displayAlert(EAlertTypes.Danger, 'Error: could not submit charges');
		},
	});
};
