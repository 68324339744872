import { useQueryClient } from '@tanstack/react-query';
import { useGetLinkToken } from 'api/hooks/useGetLinkToken';
import { useGetPayTypes } from 'api/hooks/useGetPayTypes';
import { usePostConnectToPlaidAccount } from 'api/hooks/usePostConnectPlaidAccount';
import { IMappedPayType } from 'api/resources/payTypes';
import { AxiosError } from 'axios';
import { UserError } from 'legacy/lib/api/types/UserError';
import { ECacheKeys } from 'cache/CacheKeys';
import BaseDropdown from 'legacy/app/components/dropdowns/BaseDropdown';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';
import {
	displayAlertSuccess,
	displayAlertError,
} from 'legacy/utilities/Response';
import { useMemo, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';

export const PlaidButton = ({
	accountId,
	disabled,
	payTypeAccountId,
	defaultAccountsNumber,
	className,
	buttonLabel = 'Add',
}: {
	payTypeAccountId?: number | null;
	defaultAccountsNumber?: string | null;
	disabled?: boolean;
	className?: string;
	accountId?: number;
	buttonLabel?: string;
}) => {
	const queryClient = useQueryClient();
	const {
		data: payTypes,
		isLoading: isFetchingPayTypes,
		error: payTypesError,
	} = useGetPayTypes();
	const {
		data: linkToken,
		isFetching: isLoadingLinkToken,
		error: errorLinkToken,
		refetch: fetchLinkToken,
	} = useGetLinkToken(accountId, {
		enabled: false,
	});
	const { mutateAsync: connectToPlaid, isLoading: isLinking } =
		usePostConnectToPlaidAccount();
	const [showPayWithModal, setShowPayWithModal] = useState(false);
	const [selectedPayWith, setSelectedPayWith] = useState<IMappedPayType | null>(
		null
	);

	const tokenKey = useMemo(
		() => (accountId ? `${accountId}_link_token` : 'link_token'),
		[accountId]
	);
	const savedToken = useMemo(() => localStorage.getItem(tokenKey), [tokenKey]);
	const isBackFromOauth = useMemo(
		() => window.location.href.includes('?oauth_state_id='),
		[]
	);

	const config: PlaidLinkOptions = useMemo(
		() => ({
			onSuccess: async (publicToken, metadata) => {
				try {
					const institutionId = metadata.institution?.institution_id;
					const maskedNumber = metadata.accounts[0].mask;

					const basePayload = {
						institutionId,
						maskedNumber,
						defaultAccountsNumber: selectedPayWith?.id
							? null
							: selectedPayWith?.accountNumber,
						payTypeAccountId: selectedPayWith?.id || null,
					};

					localStorage.removeItem(tokenKey);
					setSelectedPayWith(null);

					await connectToPlaid(
						accountId
							? {
									...basePayload,
									reauthenticatedAccountId: accountId,
							  }
							: {
									...basePayload,
									publicToken,
							  }
					);

					await queryClient.resetQueries({ queryKey: [ECacheKeys.PlaidToken] });
					await queryClient.invalidateQueries({
						queryKey: [ECacheKeys.PlaidAccounts],
					});
					displayAlertSuccess('Account connected successfully!');
				} catch (error) {
					displayAlertError(
						(error as AxiosError<UserError>)?.response?.data?.userError ||
							'There was a problem connecting the account, please try again'
					);
				}
			},
			onExit: async (err) => {
				if (err != null && err.error_code === 'INVALID_LINK_TOKEN') {
					await fetchLinkToken();
					return;
				}
				setSelectedPayWith(null);
				localStorage.removeItem(tokenKey);
				await queryClient.resetQueries({
					queryKey: [ECacheKeys.PlaidToken],
				});
			},
			receivedRedirectUri: isBackFromOauth ? window.location.href : undefined,
			token: isBackFromOauth ? savedToken : (linkToken?.linkToken as string),
		}),
		[
			isBackFromOauth,
			linkToken,
			accountId,
			savedToken,
			tokenKey,
			selectedPayWith,
			fetchLinkToken,
			queryClient,
			connectToPlaid,
		]
	);

	const { open, ready } = usePlaidLink(config);

	useEffect(() => {
		if (errorLinkToken) {
			displayAlertError(
				'There was an error connecting the account, please try again'
			);
		}
	}, [errorLinkToken]);

	useEffect(() => {
		if (payTypesError) {
			displayAlertError(
				'There was an error getting the Pay With options, please try again'
			);
		}
	}, [payTypesError]);

	useEffect(() => {
		if ((defaultAccountsNumber || payTypeAccountId) && payTypes) {
			const selectedPayType = payTypes.find((payType) =>
				payTypeAccountId
					? payType.id === payTypeAccountId
					: payType.accountNumber === defaultAccountsNumber
			);
			setSelectedPayWith(selectedPayType || null);
		}
	}, [payTypes, defaultAccountsNumber, payTypeAccountId]);

	useEffect(() => {
		if (ready) {
			open();
		}
	}, [ready, open]);

	useEffect(() => {
		if (linkToken) {
			localStorage.setItem(tokenKey, linkToken.linkToken);
		}
	}, [linkToken, tokenKey]);

	return (
		<>
			<ConfirmationModal
				title="Pay With"
				show={showPayWithModal}
				disabledOK={selectedPayWith === null}
				onCancel={() => setSelectedPayWith(null)}
				toggleModal={() => setShowPayWithModal(false)}
				confirmAction={() => {
					fetchLinkToken();
					setShowPayWithModal(false);
				}}
				message="Please select a Pay With."
				labelOK="OK"
				labelCancel="Cancel"
			>
				<BaseDropdown
					value={selectedPayWith}
					options={payTypes}
					onChange={(newPayType) =>
						setSelectedPayWith(newPayType as IMappedPayType)
					}
				/>
			</ConfirmationModal>
			<Button
				className={className}
				disabled={
					isLoadingLinkToken || disabled || isFetchingPayTypes || isLinking
				}
				variant="primary"
				onClick={() =>
					selectedPayWith === null
						? setShowPayWithModal(true)
						: fetchLinkToken()
				}
			>
				{buttonLabel}
			</Button>
		</>
	);
};
PlaidButton.displayName = 'PlaidButton';
