import React from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { WithRouter } from '../../../helpers/Router';
import ThreadComponent from '../ThreadComponent';
import { ApiService } from '../../../lib/api/HttpService';
import {
	displayAlert,
	displayAlertLoader,
	getErrorMessage,
} from '../../../utilities/Response';
import MSG from '../../../defaults/Message';

class PaymentActionOffsetModal extends ThreadComponent {
	constructor(props) {
		super(props);

		this.api = new ApiService();
		this.errIds = [];
	}

	componentDidUpdate(previousProps, previousState) {
		if (previousProps.checkedData !== this.props.checkedData) {
			this.errIds = [];
		}
	}

	async updateTransactionTag(txnum) {
		const data = {
			txnum: parseInt(txnum),
			tag: true,
		};

		await this.api.editVendorPayments(data).catch((error) => {
			this.errIds.push(txnum);
		});
	}

	handleAction = async (e) => {
		displayAlertLoader(MSG.loading.update.APPaymentOffset);

		for (let txnum of this.props.checkedData) {
			await this.updateTransactionTag(txnum);
		}

		if (this.errIds.length) {
			displayAlert(
				'danger',
				'The data with the ids [' +
					this.errIds.join() +
					"] couldn't be updated",
				{
					multiple: true,
				}
			);
		}

		if (this.errIds.length !== this.props.checkedData.length) {
			await this.api
				.postVendorPaymentsOffset()
				.then((resp) => {
					displayAlert('success', MSG.success.update.APPayment);
				})
				.catch((err) => {
					displayAlert(
						'danger',
						getErrorMessage(err, MSG.error.update.APPayment)
					);
				});
		}

		this.props.hideModal(true, false, true);
	};

	render() {
		return (
			<Modal
				show={this.props.show}
				aria-labelledby="action-modal"
				className="a-modal"
				backdrop="static"
				centered
			>
				<Modal.Body>
					<Row className="mb-3">
						<Col className="px-5">
							<h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3">
								Offset Invoice
							</h5>
							<div className="text-center mx-auto fw-bold pt-3">
								<h6>Are you sure you wish to offset the selected invoices?</h6>
							</div>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer className="bg-ivory py-3 px-5">
					<Row className="w-100">
						<Col className="d-flex" lg={{ span: 10, offset: 1 }}>
							<Button
								variant="primary"
								onClick={this.handleAction}
								className="w-100 m-2"
							>
								OK
							</Button>
							<Button
								variant="trans-light border-secondary-ash"
								onClick={() => this.props.hideModal(false)}
								className="w-100 m-2"
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default WithRouter(PaymentActionOffsetModal);
