import React from 'react';
import { CheckbookReconcileHeader } from 'legacy/templates/modules/accounts-payable/checkbook/CheckbookReconcile/CheckbookReconcileHeader';
import { CheckbookReconcileTotals } from './CheckbookReconcileTotals';
import { CheckbookReconcileChecksTable } from './CheckbookReconcileChecksTable';
import { CheckbookReconcileDepositsTable } from './CheckbookReconcileDepositsTable';
import { useCheckbookReconcile } from './hooks/useCheckbookReconcile';
import { CheckbookReconcileTabs } from './CheckbookReconcileTabs';
import { CheckbookReconcileForm } from './CheckbookReconcileForm';
import cn from 'classnames';
import { CheckbookReconcileStatus } from './CheckbookReconcileStatus';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';
import Spinner from 'legacy/app/components/help/Spinner';
import { CheckbookReconcileReport } from './CheckbookReconcileReport';
import { FinanceCharge } from 'legacy/pages/accounts-payable/credit-card/reconcile/FinanceCharge/FinanceCharge';
import { CheckbookDetailsModal } from '../CheckbookDetailsModal/CheckbookDetailsModal';

const CheckbookReconcile = () => {
	const {
		started,
		formSubmitted,
		isLoading,
		checkbook,
		showStartErrorModal,
		closeStartErrorModal,
		acceptReconciliation,
		showTotalsErrorModal,
		closeTotalsErrorModal,
		showAcceptReconciliationModal,
		openAcceptReconciliationModal,
		closeAcceptReconciliationModal,
		showFinanceChargesModal,
		closeFinanceChargesModal,
		invalidateGetChecksQuery,
		setUpdateTotals,
		showDetailsModal,
		closeDetailsModal,
		checkbookDetail,
	} = useCheckbookReconcile();

	return (
		<>
			<CheckbookReconcileHeader tab="reconcile" />
			<div className="tw-grid tw-grid-flow-row tw-gap-4 tw-pb-2">
				<div
					className={cn(
						'tw-grid tw-grid-flow-row tw-bg-[#faf9f7] tw-gap-6 tw-pt-8 tw-px-8 tw-border-b tw-border-gray-200',
						{
							'tw-pb-8': !started,
							'tw-pb-4': started,
						}
					)}
				>
					<CheckbookReconcileForm />
					{started && (
						<>
							<CheckbookReconcileTotals />
							<CheckbookReconcileTabs />
						</>
					)}
				</div>
				{formSubmitted && isLoading && <Spinner isChild />}
				{started && (
					<div className="tw-grid tw-grid-flow-row tw-gap-4 tw-px-8">
						<CheckbookReconcileStatus />
						<div className="tw-grid tw-grid-cols-[1fr_1fr] tw-gap-4">
							<CheckbookReconcileChecksTable />
							<CheckbookReconcileDepositsTable />
						</div>
					</div>
				)}
			</div>

			<ConfirmationModal
				show={showStartErrorModal}
				title="Checkbook Reconciliation"
				hideCancel={true}
				okBtnStyle="primary"
				confirmAction={closeStartErrorModal}
				toggleModal={closeStartErrorModal}
			>
				<div className="tw-grid tw-grid-flow-col tw-items-center tw-justify-center tw-gap-2">
					<i className="ri-information-line tw-text-xl tw-text-blue-700"></i>
					<span className="tw-text-sm">
						There is already a reconciliation for this account that is newer or
						the same as this one. Please use the history button to view
						completed reconciliations.
					</span>
				</div>
			</ConfirmationModal>

			<ConfirmationModal
				show={showTotalsErrorModal}
				title="Checkbook Reconciliation"
				confirmAction={openAcceptReconciliationModal}
				okBtnStyle="primary"
				labelCancel="Cancel"
				onCancel={closeTotalsErrorModal}
				toggleModal={closeTotalsErrorModal}
			>
				<div className="tw-grid tw-grid-flow-col tw-items-center tw-justify-center tw-gap-2">
					<i className="ri-error-warning-line tw-text-xl tw-text-yellow-700"></i>
					<span className="tw-text-sm">
						This reconciliation does NOT balance.
					</span>
				</div>
			</ConfirmationModal>

			<ConfirmationModal
				show={showAcceptReconciliationModal}
				title="Checkbook Reconciliation"
				confirmAction={acceptReconciliation}
				okBtnStyle="primary"
				labelCancel="No"
				labelOK="Yes"
				onCancel={closeAcceptReconciliationModal}
				toggleModal={closeAcceptReconciliationModal}
			>
				<div className="tw-grid tw-grid-flow-col tw-items-center tw-justify-center tw-gap-2 tw-px-1">
					<i className="ri-question-line tw-text-xl tw-text-blue-700"></i>
					<span className="tw-text-sm">
						Are you sure that you want to accept this reconciliation? <br />
						By pressing Yes, all of the cleared checks and deposits will drop
						from the reconciliation and it will be ready for next month&apos;s
						bank statement.
					</span>
				</div>
			</ConfirmationModal>
			{started && <CheckbookReconcileReport />}
			{checkbook && showFinanceChargesModal && (
				<FinanceCharge
					show={showFinanceChargesModal}
					onToggleModal={closeFinanceChargesModal}
					account={{
						value: checkbook.cashAccount,
						label: checkbook.cashAccountName,
					}}
					statementDate={checkbook.statementDate}
					isCheckbook={true}
					invalidateReconciliationQueries={() => {
						invalidateGetChecksQuery();
						setUpdateTotals(true);
					}}
				/>
			)}

			{showDetailsModal && checkbookDetail && (
				<CheckbookDetailsModal
					context="reconciliation"
					onClose={closeDetailsModal}
					checkbook={{
						entrytype: checkbookDetail?.entrytype,
						depositslip: checkbookDetail?.depositslip,
						account: checkbook?.cashAccount,
						supplier: checkbookDetail?.supplier,
						checknum: checkbookDetail?.checknum,
						txnum: checkbookDetail?.txnum,
						userdate: checkbookDetail?.userdate,
					}}
				/>
			)}
		</>
	);
};

CheckbookReconcile.displayName = 'CheckbookReconcile';

export { CheckbookReconcile };
