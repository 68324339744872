import React, { useState, useEffect, useCallback } from 'react';
import logoDark from '../../../assets/images/logo-dark.svg';
import { SignUpForm } from 'legacy/app/components/forms/SignUpForm/SignUpForm';
import { SignUpPresentation } from 'legacy/app/components/SignUpPresentation/SignUpPresentation';
import { ESignUpSteps } from 'legacy/app/constants/sign-up/constants';
import { SignUpBackground } from './SignUpBackground';
import { useGetSignUpStatus } from 'api/hooks/useGetSignUpStatus';
import { useNavigate } from 'react-router-dom';
import { ESignUpStatus } from 'legacy/app/enums/signUp/SignUpStatus';
import { TSignUpHeapData } from 'legacy/lib/api/types/SignUp';
import { setCookie, COOKIE_EXPIRATION } from 'legacy/utilities/Auth';
import { parseJwt } from 'legacy/utilities/Auth';
import { setLocalStorage } from 'legacy/utilities/LocalStorage';
import { useGetUserAccount } from 'api/hooks/useGetUserAccount';
import { IUserAccount } from 'api/resources/user';
import Chameleon from 'legacy/app/components/Chameleon';

interface INewUserData {
	userName: string;
	token: string;
}

const PRESENTATION_URL =
	'https://electrickite.s3.amazonaws.com/designmanager/Design+Manager+Interactive+Demo/story.html';

const SignUp = () => {
	const [currentStep, setCurrentStep] = useState(ESignUpSteps.Form);
	const [userClientId, setUserClientId] = useState<number | null>(null);
	const [isSkipEnabled, setIsSkipEnabled] = useState<boolean>(false);
	const [enableStatusCheck, setEnableStatusCheck] = useState<boolean>(false);
	const [newUserData, setNewUserData] = useState<INewUserData>();
	const [signUpData, setSignUpData] = useState<TSignUpHeapData>();
	const [userAccountUserName, setUserAccountUserName] = useState<string>('');
	const navigate = useNavigate();
	const { data: signUpStatus, isFetching: isFetchingSignUpStatus } =
		useGetSignUpStatus(userClientId, {
			enabled: enableStatusCheck,
		});
	const { data: userAccountData } = useGetUserAccount(userAccountUserName, {
		enabled: userAccountUserName !== '',
		onSuccess: (data: IUserAccount[]) => {
			const userData = data[0];
			setCookie('dmUsercode', `${userData.usercode}`, COOKIE_EXPIRATION);
			setCookie('dmUsername', userData.username, COOKIE_EXPIRATION);
			userData.useremail &&
				setCookie('dmUserEmail', userData.useremail, COOKIE_EXPIRATION);
		},
	});

	const handleLogin = useCallback(async () => {
		if (!newUserData) return;
		const { userName, token } = newUserData;
		const parsedToken = parseJwt(token);
		const instances = JSON.parse(parsedToken.DmInstances);
		setLocalStorage('userInstances', instances, true);
		const isImpersonating = parsedToken?.ImpersonationMode ?? false;
		const accountUserName = isImpersonating ? parsedToken.name : userName;
		setUserAccountUserName(encodeURIComponent(accountUserName));
	}, [newUserData]);

	useEffect(() => {
		if (
			signUpStatus?.instanceStatus === ESignUpStatus.Completed &&
			!isFetchingSignUpStatus &&
			!isSkipEnabled
		) {
			handleLogin();
			setIsSkipEnabled(true);
		}
	}, [
		signUpStatus,
		navigate,
		isFetchingSignUpStatus,
		isSkipEnabled,
		handleLogin,
	]);

	return (
		<div className="tw-flex lg:tw-min-h-screen tw-flex-col lg:tw-flex-row">
			{signUpData && (
				<Chameleon
					newUserData={{
						email: signUpData.email,
						username: signUpData.username,
						fullName: signUpData.fullName,
						companyRole: signUpData.companyRole,
					}}
				/>
			)}

			{currentStep === ESignUpSteps.Form && (
				<div className="tw-p-8 tw-mb-5 lg:tw-hidden">
					<img
						src={logoDark}
						alt="logo-dark"
						className="tw-max-w-[140px] tw-relative tw-z-2 tw-w-full tw-h-auto"
					/>
				</div>
			)}
			<SignUpBackground
				backgroundClass={`${
					currentStep === ESignUpSteps.Form
						? 'tw-hidden lg:tw-flex lg:tw-flex-col tw-w-1/3 tw-relative tw-z-2'
						: 'tw-relative tw-w-full tw-h-full  tw-flex tw-flex-col tw-justify-between tw-h-screen'
				}`}
			>
				{currentStep === ESignUpSteps.Video && (
					<>
						<SignUpPresentation
							videoSrc={PRESENTATION_URL}
							showButton={isSkipEnabled && userAccountData !== undefined}
						/>
					</>
				)}
			</SignUpBackground>

			{currentStep === ESignUpSteps.Form && (
				<div className="tw-flex tw-flex-col tw-place-self-center tw-m-auto">
					<div className="tw-flex-1 tw-bg-white tw-my-auto tw-p-5 lg:tw-p-0">
						<SignUpForm
							setCurrentStep={setCurrentStep}
							setUserClientId={setUserClientId}
							setSignUpData={setSignUpData}
							setEnableStatusCheck={setEnableStatusCheck}
							setNewUserData={setNewUserData}
						/>
					</div>
					<div className="tw-text-center tw-text-[#7c7c7c] tw-text-xs tw-py-4">
						<span>By registering into Design Manager, you agree to our</span>
						<a
							href="https://www.designmanager.com/terms"
							className="tw-text-[#008b6d] tw-underline tw-mx-1"
							target="_blank"
							rel="noopener noreferrer"
						>
							Terms of Service
						</a>
						<span>and</span>
						<a
							href="https://www.designmanager.com/privacy-policy"
							className="tw-text-[#008b6d] tw-underline tw-ml-1"
							target="_blank"
							rel="noopener noreferrer"
						>
							Privacy Policy
						</a>
						.
					</div>
				</div>
			)}
		</div>
	);
};

SignUp.displayName = 'SignUp';

export default SignUp;
