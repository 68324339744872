import * as yup from 'yup';

export const ClientValidation = yup.object().shape({
	email: yup
		.string()
		.nullable()
		.test({
			test: (value) => {
				if (!value || value.length === 0) {
					return true;
				}
				const invalidEmails = value
					.split(';')
					.map((email) => email.trim())
					.filter((em) => em)
					.filter(
						(em) => !/^[^.\s][\w\-.{2,}]+@([\w-]+\.)+[\w-]{2,}$/.test(em)
					);

				if (invalidEmails.length > 0) {
					return false;
				}
				return true;
			},
			message: 'Please enter a valid email',
		}),
});
