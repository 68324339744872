import React, { useEffect } from 'react';
import { useSession } from '../../context/session';

export default function Heap() {
	const {
		email,
		userId,
		username,
		companyName,
		clientId,
		clientName,
		clientSerialCode,
		fullName,
		isTrial,
		isWebOnly,
	} = useSession();

	useEffect(() => {
		if (!process.env.REACT_APP_HEAP_ID || !username) {
			return;
		}

		window.heap.identify(username);

		const userProperties = {
			appVersion: process.env.REACT_APP_HASH,
			userId,
			username,
			name: fullName,
			email,
			companyName,
			clientId,
			clientName,
			clientCode: clientSerialCode,
			trial: isTrial,
			webOnly: isWebOnly,
		};

		window.heap.addUserProperties(userProperties);
	}, [
		userId,
		username,
		email,
		clientId,
		clientName,
		fullName,
		isTrial,
		isWebOnly,
		clientSerialCode,
		companyName,
	]);

	return null;
}
