import React, { createContext, useState } from 'react';

type TTab = 'outstanding' | 'cleared';

interface ISelected {
	[key: number]: boolean;
}

interface ICheck {
	cleared: boolean;
	outstanding: boolean;
}

interface IReconcileContext {
	tab: TTab;
	setTab: (tab: TTab) => void;
	selected: ISelected;
	setSelected: (item: ISelected) => void;
	check: ICheck;
	setCheck: (item: ICheck) => void;
	started: boolean;
	setStarted: (value: boolean) => void;
	showFinanceChargesModal: boolean;
	openFinanceChargesModal: () => void;
	closeFinanceChargesModal: () => void;
}

export const ReconcileContext = createContext<IReconcileContext>({
	tab: 'outstanding',
	setTab: () => undefined,
	selected: {},
	setSelected: () => undefined,
	check: { cleared: false, outstanding: false },
	setCheck: () => undefined,
	started: false,
	setStarted: () => undefined,
	showFinanceChargesModal: false,
	openFinanceChargesModal: () => undefined,
	closeFinanceChargesModal: () => undefined,
});

interface IReconcileContextProviderProps {
	children: React.ReactNode;
}

const ReconcileProvider = ({ children }: IReconcileContextProviderProps) => {
	const [tab, setTab] = useState<TTab>('outstanding');
	const [selected, setSelected] = useState<ISelected>({});
	const [started, setStarted] = useState(false);
	const [check, setCheck] = useState<ICheck>({
		cleared: false,
		outstanding: false,
	});
	const [showFinanceChargesModal, setShowFinanceChargesModal] = useState(false);

	const openFinanceChargesModal = () => {
		setShowFinanceChargesModal(true);
	};
	const closeFinanceChargesModal = () => {
		setShowFinanceChargesModal(false);
	};

	return (
		<ReconcileContext.Provider
			value={{
				tab,
				setTab,
				selected,
				setSelected,
				check,
				setCheck,
				started,
				setStarted,
				showFinanceChargesModal,
				openFinanceChargesModal,
				closeFinanceChargesModal,
			}}
		>
			{children}
		</ReconcileContext.Provider>
	);
};

ReconcileProvider.displayName = 'ReconcileProvider';

export { ReconcileProvider };
