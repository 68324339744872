import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import { useGetIsUsernameAvailable } from 'api/hooks/useGetIsUsernameAvailable';
import {
	displayAlertError,
	displayAlertSuccess,
} from 'legacy/utilities/Response';
import MSG from 'legacy/defaults/Message';
import { AxiosError } from 'axios';
import { UserError } from 'legacy/lib/api/types/UserError';
import type {
	TOption,
	TFormValues,
} from 'legacy/templates/modules/sign-up/types/TFormValues';
import { employeeOptions, requiredFields } from './constants/formConstants';
import { usePostUser } from 'api/hooks/usePostUser';
import { useGetCompanyRoles } from 'api/hooks/useGetCompanyRoles';
import AsyncDropdownLoadingIndicator from '../../dropdowns/utils/AsyncDropdownLoadingIndicator';
import { ESignUpSteps } from 'legacy/app/constants/sign-up/constants';
import { usePostUserConnect } from 'api/hooks/usePostUserConnect';
import { decodeToken } from 'legacy/utilities/Auth';
import { setCookie, COOKIE_EXPIRATION } from '../../../../utilities/Auth';
import { ESignUpFormStatus } from 'legacy/app/enums/signUp/SignUpStatus';
import {
	TSignUpHeapData,
	TPostUserErrorResponse,
} from 'legacy/lib/api/types/SignUp';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { TTypeOption } from '../../dropdowns/types/TTypeOption';

interface ISignUpFormProps {
	setCurrentStep: (step: ESignUpSteps) => void;
	setUserClientId: (clientId: number) => void;
	setSignUpData: (data: TSignUpHeapData) => void;
	setEnableStatusCheck: (enable: boolean) => void;
	setNewUserData: (data: { userName: string; token: string }) => void;
}

const SignUpForm = ({
	setCurrentStep,
	setUserClientId,
	setSignUpData,
	setEnableStatusCheck,
	setNewUserData,
}: ISignUpFormProps) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
		control,
	} = useForm<TFormValues>({
		mode: 'onTouched',
		reValidateMode: 'onChange',
	});
	const { executeRecaptcha } = useGoogleReCaptcha();
	const [checkUserName, setCheckUserName] = useState<boolean>(false);
	const [formData, setFormData] = useState<TFormValues | null>(null);
	const [showPassword, setShowPassword] = useState(false);
	const username = watch('username');
	const [disableSubmitButton, setDisableSubmitButton] = useState(false);
	const { mutateAsync: postUser, isLoading: isCreatingUser } = usePostUser();
	const { mutateAsync: postUserConnect } = usePostUserConnect();
	const allFields = watch(requiredFields);
	const isSubmitDisabled = allFields.some(
		(field) =>
			field === undefined ||
			field === null ||
			(field as unknown as string) === '' ||
			(Array.isArray(field) && field.length === 0)
	);

	const { data: companyRoles, isLoading: isLoadingCompanyRoles } =
		useGetCompanyRoles({
			onError: () => {
				displayAlertError('There was an error while loading company roles');
			},
		});

	const companyRoleOptions =
		companyRoles?.map((role) => ({
			value: role.key,
			label: role.value,
		})) || [];

	const { data: userNameData } = useGetIsUsernameAvailable(username, true, {
		enabled: checkUserName,
		onSuccess: (data) => {
			if (data.usernameTaken) {
				setDisableSubmitButton(false);
			}
			setCheckUserName(false);
			if (!data.usernameTaken && formData) {
				submitData(formData);
			}
		},
		onError: () => {
			setDisableSubmitButton(false);
			setCheckUserName(false);
			displayAlertError(MSG.error.userNameCheck);
		},
	});

	useEffect(() => {
		if (window?.heap) {
			window.heap.clearEventProperties();
			window.heap.addEventProperties({
				'Sign Up': 'true',
				Step: 'Form Visited',
			});
		}
	}, []);

	const onSubmit: SubmitHandler<TFormValues> = async (data) => {
		setDisableSubmitButton(true);
		setFormData(data);
		setCheckUserName(true);

		window.heap.clearEventProperties();
		window.heap.addEventProperties({
			'Sign Up': 'true',
			Step: 'Form Submitted',
		});
	};

	const submitData = async (data: TFormValues) => {
		try {
			if (!executeRecaptcha) {
				throw new Error('reCAPTCHA is not loaded');
			}

			const token = await executeRecaptcha('SIGNUP').catch((error) => {
				displayAlertError(
					'There was an error with reCaptcha, please try again later'
				);
				throw new Error('reCaptcha token is not available', error);
			});

			const { employees, companyRole, ...otherData } = data;
			const payload = {
				...otherData,
				employees: (employees as TOption).value,
				companyRole: (companyRole as TOption).value,
				recaptchaToken: token,
			};
			const { status, clientId } = await postUser(payload);
			const { email, username, companyName } = payload;

			if (clientId) {
				setUserClientId(clientId);
				const heapData = {
					email,
					username,
					companyName,
					companyRole: (companyRole as TTypeOption).label as string,
					clientId,
					fullName: `${data.firstName} ${data.lastName}`,
					isTrial: true,
				};
				setSignUpData(heapData);
			}

			switch (status) {
				case ESignUpFormStatus.InProgress:
					displayAlertSuccess(MSG.success.create.User);
					await handleUserConnect({
						username: data.username,
						password: data.password,
						recaptchaToken: token,
					});
					setCurrentStep(ESignUpSteps.Video);
					break;
				case ESignUpFormStatus.Incomplete:
				case ESignUpFormStatus.Failed:
					displayAlertError(MSG.error.create.User);
					break;
				default:
					break;
			}
		} catch (error) {
			setDisableSubmitButton(false);
			displayAlertError(
				(error as AxiosError<TPostUserErrorResponse>)?.response?.data
					?.exceptionMessage ||
					(error as AxiosError<UserError>)?.response?.data?.userError ||
					'There was an error saving the information, please try again'
			);
		}
	};

	const handleUserConnect = async (data: {
		username: string;
		password: string;
		recaptchaToken: string;
	}) => {
		try {
			const { access_token: accessToken } = await postUserConnect(data);
			const decodedToken = decodeToken(accessToken);
			const userInstance = decodedToken?.DmInstances?.[0]?.Id;

			if (userInstance) {
				setCookie('dmInstanceId', userInstance, COOKIE_EXPIRATION);
				setCookie('dmAuthToken', accessToken, COOKIE_EXPIRATION);
				setEnableStatusCheck(true);
				setNewUserData({ userName: username, token: accessToken });
			}
		} catch (error) {
			displayAlertError(
				(error as AxiosError<TPostUserErrorResponse>)?.response?.data
					?.exceptionMessage ||
					(error as AxiosError<UserError>)?.response?.data?.userError ||
					'There was an error saving the information, please try again'
			);
		}
	};

	return (
		<div className="tw-px-6 tw-max-w-xl tw-mx-auto tw-bg-white">
			<div className="tw-mb-8">
				<h1 className="tw-text-[40px] tw-mb-4">Try it for Free</h1>
				<p className="tw-text-gray-600 tw-mb-6">
					Experience Design Manager for one month free and see for yourself how
					we can help you take your interior design business to the next level.
				</p>
			</div>
			<form
				onSubmit={handleSubmit(onSubmit)}
				noValidate
				className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-4 tw-gap-y-6"
			>
				{/* First Name */}
				<FloatingLabel controlId="firstName" label="First Name *">
					<Form.Control
						type="text"
						placeholder="First Name"
						size="lg"
						isInvalid={!!errors.firstName}
						className="d-block"
						style={{ backgroundImage: 'none' }}
						maxLength={45}
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...register('firstName', { required: 'First name is required' })}
					/>
					<Form.Control.Feedback type="invalid">
						{errors.firstName?.message}
					</Form.Control.Feedback>
				</FloatingLabel>

				{/* Last Name */}
				<FloatingLabel controlId="lastName" label="Last Name *">
					<Form.Control
						type="text"
						placeholder="Last Name"
						style={{ backgroundImage: 'none' }}
						size="lg"
						maxLength={45}
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...register('lastName', { required: 'Last name is required' })}
						isInvalid={!!errors.lastName}
					/>
					<Form.Control.Feedback type="invalid">
						{errors.lastName?.message}
					</Form.Control.Feedback>
				</FloatingLabel>

				{/* Company Name */}
				<FloatingLabel controlId="companyName" label="Company Name *">
					<Form.Control
						type="text"
						placeholder="Company Name"
						size="lg"
						maxLength={40}
						style={{ backgroundImage: 'none' }}
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...register('companyName', {
							required: 'Company name is required',
							maxLength: {
								value: 40,
								message: 'Company name cannot exceed 40 characters',
							},
						})}
						isInvalid={!!errors.companyName}
					/>
					<Form.Control.Feedback type="invalid">
						{errors.companyName?.message}
					</Form.Control.Feedback>
				</FloatingLabel>

				{/* Email */}
				<FloatingLabel controlId="email" label="Email *">
					<Form.Control
						type="email"
						placeholder="Email"
						size="lg"
						style={{ backgroundImage: 'none' }}
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...register('email', {
							required: 'Email is required',
							pattern: {
								value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
								message: 'Invalid email format',
							},
						})}
						isInvalid={!!errors.email}
					/>
					<Form.Control.Feedback type="invalid">
						{errors.email?.message}
					</Form.Control.Feedback>
				</FloatingLabel>

				{/* Username */}
				<div>
					<FloatingLabel controlId="username" label="Username *">
						<Form.Control
							type="text"
							placeholder="Username"
							size="lg"
							maxLength={15}
							style={{ backgroundImage: 'none' }}
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...register('username', {
								required: 'Username is required',
								minLength: {
									value: 5,
									message: 'Username must be at least 5 characters',
								},
								maxLength: {
									value: 15,
									message: 'Username cannot exceed 15 characters',
								},
								pattern: {
									value: /^[a-zA-Z0-9!.#$&\-_]+$/,
									message:
										'Username can only contain letters, numbers, and ! . # $ & - _',
								},
							})}
							isInvalid={!!errors.username}
						/>
						<Form.Control.Feedback type="invalid">
							{errors.username?.message}
						</Form.Control.Feedback>
					</FloatingLabel>
					{userNameData?.usernameTaken && (
						<div className="tw-text-red-500">
							<span>Username is taken.</span>
							<br />
							<span>
								{userNameData?.suggestions?.length > 0
									? `Suggestions: ${userNameData.suggestions.join(', ')}`
									: ''}
							</span>
						</div>
					)}
				</div>

				{/* Password */}
				<FloatingLabel controlId="password" label="Password *">
					<Form.Control
						type={showPassword ? 'text' : 'password'}
						placeholder="Password"
						size="lg"
						style={{ backgroundImage: 'none' }}
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...register('password', {
							required: 'Password is required',
							minLength: {
								value: 8,
								message: 'Password must be at least 8 characters',
							},
							pattern: {
								value:
									/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]+$/,
								message:
									'Password must include one of each: lowercase, uppercase, number and special character (acceptable characters are !@$%&*)',
							},
						})}
						isInvalid={!!errors.password}
					/>
					<Form.Control.Feedback type="invalid">
						{errors.password?.message}
					</Form.Control.Feedback>
					<span
						className={`tw-absolute tw-right-3 ${
							errors.password?.message
								? `${
										errors.password?.type === 'pattern'
											? 'tw-top-[31%]'
											: 'tw-top-[36%]'
								  }`
								: 'tw-top-1/2'
						} tw-transform -tw-translate-y-1/2 tw-cursor-pointer`}
						onClick={() => setShowPassword(!showPassword)}
					>
						<i
							className={`${
								showPassword ? 'ri-eye-line' : 'ri-eye-off-line'
							} tw-text-gray-500`}
						/>
					</span>
				</FloatingLabel>

				{/* Phone */}
				<FloatingLabel controlId="phone" label="Phone *">
					<Form.Control
						type="text"
						placeholder="Phone"
						size="lg"
						maxLength={10}
						style={{ backgroundImage: 'none' }}
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...register('phone', {
							required: 'Phone is required',
							pattern: {
								value: /^\d{10}$/,
								message: 'Phone number must be 10 digits',
							},
						})}
						isInvalid={!!errors.phone}
					/>
					<Form.Control.Feedback type="invalid">
						{errors.phone?.message}
					</Form.Control.Feedback>
				</FloatingLabel>

				{/* Employees */}
				<div>
					<Controller
						name="employees"
						control={control}
						defaultValue={undefined}
						rules={{ required: 'Please select the number of employees' }}
						render={({ field }) => (
							<Select
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...field}
								options={employeeOptions}
								placeholder="Select number of employees *"
								styles={{
									menu: (base) => ({
										...base,
										zIndex: 9999,
										backgroundImage: 'none',
									}),
									control: (base) => ({
										...base,
										height: 48,
										borderColor: errors.employees?.message
											? '#dc3545'
											: base.borderColor,
										hover: errors.employees?.message ? '#dc3545' : base.hover,
									}),
								}}
							/>
						)}
					/>
					<Form.Control.Feedback type="invalid">
						{errors.employees?.message}
					</Form.Control.Feedback>
				</div>

				{/* Company Role */}
				<div className="w-100 md:tw-col-span-2">
					<Controller
						name="companyRole"
						control={control}
						defaultValue={undefined}
						rules={{ required: 'Please select your role in the company' }}
						render={({ field }) => (
							<Select
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...field}
								options={companyRoleOptions || []}
								placeholder="What is your role in the firm? *"
								components={{ LoadingIndicator: AsyncDropdownLoadingIndicator }}
								isLoading={isLoadingCompanyRoles}
								styles={{
									menu: (base) => ({
										...base,
										zIndex: 9999,
										backgroundImage: 'none',
									}),
									control: (base) => ({
										...base,
										height: 48,
										borderColor: errors.employees?.message
											? '#dc3545'
											: base.borderColor,
										hover: errors.employees?.message ? '#dc3545' : base.hover,
									}),
								}}
							/>
						)}
					/>
					<Form.Control.Feedback type="invalid">
						{errors.companyRole?.message}
					</Form.Control.Feedback>
				</div>

				{/* Submit Button */}
				<Button
					variant="primary"
					size="lg"
					type="submit"
					className="w-100 md:tw-col-span-2"
					disabled={isSubmitDisabled || isCreatingUser || disableSubmitButton}
				>
					Submit
				</Button>
			</form>
		</div>
	);
};

SignUpForm.displayName = 'SignUpForm';

export { SignUpForm };
