import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import {
	getCheckbookDetailCheck,
	type IGetCheckbookDetailCheckParams,
	type ICheckbookDetailCheck,
} from 'api/resources/generalLedgerTransactions';
import { ECacheKeys } from 'cache/CacheKeys';

export const useGetCheckbookDetailCheck = (
	params: IGetCheckbookDetailCheckParams,
	options?: UseQueryOptions<ICheckbookDetailCheck[]>
) => {
	const query = useQuery({
		queryKey: [
			ECacheKeys.GeneralLedgerTransactions,
			'CheckbookDetailCheck',
			params.account,
			params.checknum,
			params.supplier,
			params.userdate,
		],
		queryFn: () => getCheckbookDetailCheck(params),
		refetchInterval: false,
		refetchOnWindowFocus: false,
		...(options || {}),
	});

	return query;
};
