import { useSession } from 'legacy/app/context/session';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useGetTemporaryReconciliationsChecks } from 'api/hooks/useGetTemporaryReconciliationsChecks';
import type {
	ITemporaryReconciliationCheck as IReconciliationCheck,
	ITemporaryReconciliationDeposit as IReconciliationDeposit,
	ITemporaryReconciliationTotals as IReconciliationTotals,
	ITemporaryReconciliationsAcceptParams as IReconciliationsAcceptParams,
	ITemporaryReconciliationsParams as IReconciliationsParams,
	ITemporaryReconciliationsTotalsParams as IReconciliationsTotalsParams,
	ITemporaryReconciliationsStartParams as IReconciliationsStartParams,
} from 'api/resources/temporaryReconciliations';
import { useGetTemporaryReconciliationsDeposits } from 'api/hooks/useGetTemporaryReconciliationsDeposits';
import { useQueryClient } from '@tanstack/react-query';
import { ECacheKeys } from 'cache/CacheKeys';
import { usePostTemporaryReconciliationsTotals } from 'api/hooks/usePostTemporaryReconciliationsTotals';
import { usePatchTemporaryReconciliations } from 'api/hooks/usePatchTemporaryReconciliations';
import {
	displayAlert,
	displayAlertLoader,
	hideAlertLoader,
} from 'legacy/utilities/Response';
import { EAlertTypes } from 'legacy/app/enums/alertTypes/alertTypes';
import { usePostTemporaryReconciliationsAccept } from 'api/hooks/usePostTemporaryReconciliationsAccept';
import {
	ICheckbook,
	ICheckbookDetail,
	ICheckbookReconcileContext,
	IChecked,
	ISelected,
	TTab,
} from '../types/CheckbookReconcileTypes';
import { useGetReconciliations } from 'api/hooks/useGetReconciliations';
import { usePostTemporaryReconciliationsStart } from 'api/hooks/usePostTemporaryReconciliationsStart';

export const CheckbookReconcileContext =
	createContext<ICheckbookReconcileContext>({
		tab: 'outstanding',
		setTab: () => undefined,
		started: false,
		setStarted: () => undefined,
		formSubmitted: false,
		setFormSubmitted: () => undefined,
		isLoading: false,
		checkbook: null,
		setCheckbook: () => undefined,
		checkbookDetail: null,
		setCheckbookDetail: () => undefined,
		resetState: () => undefined,
		outstandingChecks: [],
		clearedChecks: [],
		outstandingDeposits: [],
		clearedDeposits: [],
		invalidateGetChecksQuery: async () => undefined,
		invalidateGetDepositsQuery: async () => undefined,
		isGetChecksLoading: false,
		isGetDepositsLoading: false,
		selectedChecks: {},
		setSelectedChecks: () => undefined,
		selectedDeposits: {},
		setSelectedDeposits: () => undefined,
		totals: null,
		isTotalsLoading: false,
		showStartErrorModal: false,
		openStartErrorModal: () => undefined,
		closeStartErrorModal: () => undefined,
		showTotalsErrorModal: false,
		closeTotalsErrorModal: () => undefined,
		showAcceptReconciliationModal: false,
		openAcceptReconciliationModal: () => undefined,
		closeAcceptReconciliationModal: () => undefined,
		openPrintModal: () => undefined,
		showPrintModal: false,
		closePrintModal: () => undefined,
		openFinanceChargesModal: () => undefined,
		showFinanceChargesModal: false,
		closeFinanceChargesModal: () => undefined,
		openDetailsModal: () => undefined,
		showDetailsModal: false,
		closeDetailsModal: () => undefined,
		updateTotals: false,
		setUpdateTotals: () => undefined,
		handleOnCheckSelectedChange: async () => undefined,
		handleOnDepositSelectedChange: async () => undefined,
		postTotalsReconciliation: async () => undefined,
		acceptReconciliation: async () => undefined,
		handleOnMarkCleared: () => undefined,
		handleOnMarkUncleared: () => undefined,
		hasOutstandingTaggedItems: () => false,
		isSearchEnabled: false,
		setIsSearchEnabled: () => undefined,
		checksSelectAll: { outstanding: false, cleared: false },
		depositsSelectAll: { outstanding: false, cleared: false },
		handleOnChecksSelectAll: () => undefined,
		handleOnDepositsSelectAll: () => undefined,
	});

interface ICheckbookReconcileContextProviderProps {
	children: React.ReactNode;
}

const CheckbookReconcileProvider = ({
	children,
}: ICheckbookReconcileContextProviderProps) => {
	const [tab, setTab] = useState<TTab>('outstanding');
	const [formSubmitted, setFormSubmitted] = useState(false);
	const { usercode } = useSession();
	const queryClient = useQueryClient();
	const [started, setStarted] = useState(false);
	const [checkbook, setCheckbook] = useState<ICheckbook | null>(null);
	const [checkbookDetail, setCheckbookDetail] =
		useState<ICheckbookDetail | null>(null);
	const [selectedChecks, setSelectedChecks] = useState<ISelected>({});
	const [selectedDeposits, setSelectedDeposits] = useState<ISelected>({});
	const [updateTotals, setUpdateTotals] = useState(false);
	const [checks, setChecks] = useState<IReconciliationCheck[]>([]);
	const [deposits, setDeposits] = useState<IReconciliationDeposit[]>([]);
	const [totals, setTotals] = useState<IReconciliationTotals | null>(null);
	const [showStartErrorModal, setShowStartErrorModal] = useState(false);
	const [showTotalsErrorModal, setShowTotalsErrorModal] = useState(false);
	const [showAcceptModal, setShowAcceptModal] = useState(false);
	const [showPrintModal, setShowPrintModal] = useState(false);
	const [showFinanceChargesModal, setShowFinanceChargesModal] = useState(false);
	const [showDetailsModal, setShowDetailsModal] = useState(false);
	const [balance, setBalance] = useState<number | null>(null);
	const [computerBalance, setComputerBalance] = useState<number | null>(null);
	const [isSearchEnabled, setIsSearchEnabled] = useState(false);
	const [checksSelectAll, setChecksSelectAll] = useState<IChecked>({
		outstanding: false,
		cleared: false,
	});
	const [depositsSelectAll, setDepositsSelectAll] = useState<IChecked>({
		outstanding: false,
		cleared: false,
	});

	const outstandingChecks = checks.filter((x) => !x.cleared);
	const clearedChecks = checks.filter((x) => x.cleared);
	const outstandingDeposits = deposits.filter((x) => !x.cleared);
	const clearedDeposits = deposits.filter((x) => x.cleared);

	const resetState = () => {
		setFormSubmitted(false);
		setCheckbook(null);
		setStarted(false);
		setTotals(null);
		setDeposits([]);
		setChecks([]);
		setUpdateTotals(false);
		setSelectedChecks({});
		setSelectedDeposits({});
		setBalance(null);
		setComputerBalance(null);
	};

	const { mutateAsync: patchReconciliation } =
		usePatchTemporaryReconciliations();

	const hasOutstandingTaggedItems = () => {
		for (const recnum of Object.keys(selectedChecks)) {
			if (
				selectedChecks[recnum] &&
				outstandingChecks.some((x) => x.recnum === Number(recnum))
			) {
				return true;
			}
		}

		for (const recnum of Object.keys(selectedDeposits)) {
			if (
				selectedDeposits[recnum] &&
				outstandingDeposits.some((x) => x.recnum === Number(recnum))
			) {
				return true;
			}
		}

		return false;
	};

	// Modals
	const closeTotalsErrorModal = useCallback(() => {
		setShowTotalsErrorModal(false);
	}, []);

	const openAcceptReconciliationModal = useCallback(() => {
		closeTotalsErrorModal();
		setShowAcceptModal(true);
	}, [closeTotalsErrorModal]);

	const closeAcceptReconciliationModal = () => {
		setShowAcceptModal(false);
	};

	const openStartErrorModal = () => {
		setShowStartErrorModal(true);
	};

	const closeStartErrorModal = () => {
		setFormSubmitted(false);
		setStarted(false);
		setShowStartErrorModal(false);
	};

	const openPrintModal = () => {
		setShowPrintModal(true);
	};

	const closePrintModal = useCallback(() => {
		setShowPrintModal(false);
	}, []);

	const openFinanceChargesModal = () => {
		setShowFinanceChargesModal(true);
	};

	const closeFinanceChargesModal = () => {
		setShowFinanceChargesModal(false);
	};

	const openDetailsModal = () => {
		setShowDetailsModal(true);
	};

	const closeDetailsModal = () => {
		setCheckbookDetail(null);
		setShowDetailsModal(false);
	};

	// Start
	const {
		mutateAsync: postReconciliationStart,
		isLoading: isPostReconciliationStartLoading,
	} = usePostTemporaryReconciliationsStart();

	const { isLoading: isGetReconciliationsLoading } = useGetReconciliations({
		enabled: formSubmitted && checkbook !== null,
		onSuccess: async (reconciliations) => {
			if (
				checkbook &&
				reconciliations.some(
					({ cashaccount, statementdate }) =>
						cashaccount === checkbook.cashAccount &&
						new Date(checkbook.statementDate) <= new Date(statementdate)
				)
			) {
				openStartErrorModal();
			} else {
				if (!checkbook) {
					return;
				}
				const payload: IReconciliationsStartParams = {
					cashAccount: checkbook.cashAccount,
					statementDate: checkbook.statementDate,
				};
				try {
					await postReconciliationStart(payload);
					setStarted(true);
					setUpdateTotals(true);
				} catch (error) {
					displayAlert(
						EAlertTypes.Danger,
						'Error: could not start the reconciliation'
					);
				}
			}
		},
	});

	// Checks
	const { isLoading: isGetChecksLoading } =
		useGetTemporaryReconciliationsChecks(`?$filter=usercode eq ${usercode}`, {
			enabled: started,
			onSuccess: (checks) => {
				setChecks(checks);
			},
		});

	const invalidateGetChecksQuery = async () => {
		await queryClient.invalidateQueries([
			ECacheKeys.TemporaryReconciliations,
			'Checks',
			`?$filter=usercode eq ${usercode}`,
		]);
	};

	const handleOnCheckSelectedChange = useCallback(
		async (recnum: number, checked: boolean) => {
			if (!checked) {
				if (tab === 'outstanding') {
					setChecksSelectAll((prevState) => ({
						...prevState,
						outstanding: false,
					}));
				} else {
					setChecksSelectAll((prevState) => ({
						...prevState,
						cleared: false,
					}));
				}
			}

			setSelectedChecks((prevState) => ({
				...prevState,
				[recnum]: checked,
			}));

			const payload: IReconciliationsParams = {
				usercode: Number(usercode),
				cashAccount: checkbook?.cashAccount || '',
				recnum,
				sumtag: checked,
			};
			try {
				await patchReconciliation(payload);
				setUpdateTotals(true);
			} catch {
				setSelectedChecks((prevState) => ({
					...prevState,
					[recnum]: !checked,
				}));

				displayAlert(EAlertTypes.Danger, 'Error: could not submit changes');
			}
		},
		[usercode, checkbook?.cashAccount, tab, patchReconciliation]
	);

	const handleOnChecksSelectAll = useCallback(
		(checked: boolean) => {
			const isOutstandingTab = tab === 'outstanding';
			setChecksSelectAll((prevState) => ({
				outstanding: isOutstandingTab ? checked : prevState.outstanding,
				cleared: !isOutstandingTab ? checked : prevState.cleared,
			}));

			if (checked) {
				if (isOutstandingTab) {
					const checks = outstandingChecks.reduce<ISelected>((acc, item) => {
						acc[item.recnum] = true;
						return acc;
					}, {});

					setSelectedChecks((prevState) => ({
						...prevState,
						...checks,
					}));
				} else {
					const checks = clearedChecks.reduce<ISelected>((acc, item) => {
						acc[item.recnum] = true;
						return acc;
					}, {});

					setSelectedChecks((prevState) => ({
						...prevState,
						...checks,
					}));
				}
			} else {
				if (isOutstandingTab) {
					const checks = outstandingChecks.reduce<ISelected>((acc, item) => {
						acc[item.recnum] = false;
						return acc;
					}, {});

					setSelectedChecks((prevState) => ({
						...prevState,
						...checks,
					}));
				} else {
					const checks = clearedChecks.reduce<ISelected>((acc, item) => {
						acc[item.recnum] = false;
						return acc;
					}, {});

					setSelectedChecks((prevState) => ({
						...prevState,
						...checks,
					}));
				}
			}

			setUpdateTotals(true);
		},
		[tab, outstandingChecks, clearedChecks, setSelectedChecks]
	);

	// Deposits
	const { isLoading: isGetDepositsLoading } =
		useGetTemporaryReconciliationsDeposits(`?$filter=usercode eq ${usercode}`, {
			enabled: started,
			onSuccess: (deposits) => {
				setDeposits(deposits);
			},
		});

	const invalidateGetDepositsQuery = async () => {
		await queryClient.invalidateQueries([
			ECacheKeys.TemporaryReconciliations,
			'Deposits',
			`?$filter=usercode eq ${usercode}`,
		]);
	};

	const handleOnDepositSelectedChange = useCallback(
		async (recnum: number, checked: boolean) => {
			if (!checked) {
				if (tab === 'outstanding') {
					setDepositsSelectAll((prevState) => ({
						...prevState,
						outstanding: false,
					}));
				} else {
					setDepositsSelectAll((prevState) => ({
						...prevState,
						cleared: false,
					}));
				}
			}
			setSelectedDeposits((prevState) => ({
				...prevState,
				[recnum]: checked,
			}));

			const payload: IReconciliationsParams = {
				usercode: Number(usercode),
				recnum,
				cashAccount: checkbook?.cashAccount || '',
				sumtag: checked,
			};
			try {
				await patchReconciliation(payload);
				setUpdateTotals(true);
			} catch {
				setSelectedDeposits((prevState) => ({
					...prevState,
					[recnum]: !checked,
				}));

				displayAlert(EAlertTypes.Danger, 'Error: could not submit changes');
			}
		},
		[usercode, checkbook?.cashAccount, tab, patchReconciliation]
	);

	const handleOnDepositsSelectAll = useCallback(
		(checked: boolean) => {
			if (checked) {
				if (tab === 'outstanding') {
					setDepositsSelectAll((prevState) => ({
						...prevState,
						outstanding: true,
					}));
					const deposits = outstandingDeposits.reduce<ISelected>(
						(acc, item) => {
							acc[item.recnum] = true;
							return acc;
						},
						{}
					);

					setSelectedDeposits((prevState) => ({
						...prevState,
						...deposits,
					}));
				} else {
					setDepositsSelectAll((prevState) => ({
						...prevState,
						cleared: true,
					}));
					const deposits = clearedDeposits.reduce<ISelected>((acc, item) => {
						acc[item.recnum] = true;
						return acc;
					}, {});

					setSelectedDeposits((prevState) => ({
						...prevState,
						...deposits,
					}));
				}
			} else {
				if (tab === 'outstanding') {
					setDepositsSelectAll((prevState) => ({
						...prevState,
						outstanding: false,
					}));
					const deposits = outstandingDeposits.reduce<ISelected>(
						(acc, item) => {
							acc[item.recnum] = false;
							return acc;
						},
						{}
					);

					setSelectedDeposits((prevState) => ({
						...prevState,
						...deposits,
					}));
				} else {
					setDepositsSelectAll((prevState) => ({
						...prevState,
						cleared: false,
					}));
					const deposits = clearedDeposits.reduce<ISelected>((acc, item) => {
						acc[item.recnum] = false;
						return acc;
					}, {});

					setSelectedDeposits((prevState) => ({
						...prevState,
						...deposits,
					}));
				}
			}
			setUpdateTotals(true);
		},
		[tab, outstandingDeposits, clearedDeposits, setSelectedDeposits]
	);

	// Totals
	const { mutateAsync: postReconciliationTotals, isLoading: isTotalsLoading } =
		usePostTemporaryReconciliationsTotals();

	const { mutateAsync: postAcceptReconciliationTotals } =
		usePostTemporaryReconciliationsTotals();

	const updateReconciliationTotals = useCallback(
		async (checkbook: ICheckbook) => {
			setUpdateTotals(false);
			try {
				const payload: IReconciliationsTotalsParams = {
					account: checkbook.cashAccount,
					statementBalance: checkbook.endingBalance,
					statementEndingDate: checkbook.statementDate,
					doClearFlagsChecks: true,
					doClearFlagsDeps: true,
					doSumFlagsChecks: true,
					doSumFlagsDeps: true,
				};
				const totals = await postReconciliationTotals(payload);
				setTotals(totals);
			} catch (error) {
				displayAlert(EAlertTypes.Danger, 'Error: could not update totals');
			}
		},
		[postReconciliationTotals, setTotals, setUpdateTotals]
	);

	const postTotalsReconciliation = useCallback(async () => {
		try {
			if (!checkbook) return;
			const payload: IReconciliationsTotalsParams = {
				account: checkbook.cashAccount,
				statementBalance: checkbook.endingBalance,
				statementEndingDate: checkbook.statementDate,
				doClearFlagsChecks: true,
				doClearFlagsDeps: true,
				doSumFlagsChecks: true,
				doSumFlagsDeps: true,
			};

			const { difference, balance, computerBalance } =
				await postAcceptReconciliationTotals(payload);

			setBalance(balance);
			setComputerBalance(computerBalance);

			if (difference === 0) {
				openAcceptReconciliationModal();
			} else {
				setShowTotalsErrorModal(true);
			}
		} catch {
			displayAlert(EAlertTypes.Danger, 'Error: could not update totals');
		}
	}, [
		postAcceptReconciliationTotals,
		checkbook,
		openAcceptReconciliationModal,
	]);

	useEffect(() => {
		if (started && checkbook && updateTotals) {
			updateReconciliationTotals(checkbook);
		}
	}, [started, checkbook, updateTotals, updateReconciliationTotals]);

	// Status
	const handleOnMarkCleared = async () => {
		if (
			Object.keys(selectedChecks).length === 0 &&
			Object.keys(selectedDeposits).length === 0
		) {
			return;
		}

		let updatedChecks = false;

		displayAlertLoader('Saving changes');
		for (const recnum of Object.keys(selectedChecks)) {
			if (
				selectedChecks[recnum] &&
				outstandingChecks.some((check) => check.recnum === Number(recnum))
			) {
				const payload = {
					usercode: Number(usercode),
					cashAccount: checkbook?.cashAccount || '',
					recnum: Number(recnum),
					cleared: true,
				};
				try {
					await patchReconciliation(payload);

					setSelectedChecks((prevState) => ({
						...prevState,
						[recnum]: false,
					}));

					updatedChecks = true;
				} catch {
					displayAlert(EAlertTypes.Danger, 'Error: could not submit changes');
				}
			}
		}

		let updatedDeposits = false;
		for (const recnum of Object.keys(selectedDeposits)) {
			if (
				selectedDeposits[recnum] &&
				outstandingDeposits.some((deposit) => deposit.recnum === Number(recnum))
			) {
				const payload = {
					usercode: Number(usercode),
					recnum: Number(recnum),
					cleared: true,
					cashAccount: checkbook?.cashAccount || '',
				};
				try {
					await patchReconciliation(payload);

					setSelectedDeposits((prevState) => ({
						...prevState,
						[recnum]: false,
					}));

					updatedDeposits = true;
				} catch {
					displayAlert(EAlertTypes.Danger, 'Error: could not submit changes');
				}
			}
		}

		hideAlertLoader();

		if (updatedChecks) {
			setChecksSelectAll((prevState) => ({ ...prevState, outstanding: false }));
			await invalidateGetChecksQuery();
		}

		if (updatedDeposits) {
			setDepositsSelectAll((prevState) => ({
				...prevState,
				outstanding: false,
			}));
			await invalidateGetDepositsQuery();
		}

		if (updatedChecks || updatedDeposits) {
			setUpdateTotals(true);
		}
	};

	const handleOnMarkUncleared = async () => {
		if (
			Object.keys(selectedChecks).length === 0 &&
			Object.keys(selectedDeposits).length === 0
		) {
			return;
		}

		let updatedChecks = false;

		displayAlertLoader('Saving changes');
		for (const recnum of Object.keys(selectedChecks)) {
			if (
				selectedChecks[recnum] &&
				clearedChecks.some((check) => check.recnum === Number(recnum))
			) {
				const payload = {
					usercode: Number(usercode),
					recnum: Number(recnum),
					cleared: false,
					cashAccount: checkbook?.cashAccount || '',
				};
				try {
					await patchReconciliation(payload);

					setSelectedChecks((prevState) => ({
						...prevState,
						[recnum]: false,
					}));

					updatedChecks = true;
				} catch {
					displayAlert(EAlertTypes.Danger, 'Error: could not submit changes');
				}
			}
		}

		let updatedDeposits = false;
		for (const recnum of Object.keys(selectedDeposits)) {
			if (
				selectedDeposits[recnum] &&
				clearedDeposits.some((deposit) => deposit.recnum === Number(recnum))
			) {
				const payload = {
					usercode: Number(usercode),
					recnum: Number(recnum),
					cleared: false,
					cashAccount: checkbook?.cashAccount || '',
				};
				try {
					await patchReconciliation(payload);

					setSelectedDeposits((prevState) => ({
						...prevState,
						[recnum]: false,
					}));

					updatedDeposits = true;
				} catch {
					displayAlert(EAlertTypes.Danger, 'Error: could not submit changes');
				}
			}
		}
		hideAlertLoader();

		if (updatedChecks) {
			setChecksSelectAll((prevState) => ({ ...prevState, cleared: false }));
			await invalidateGetChecksQuery();
		}

		if (updatedDeposits) {
			setDepositsSelectAll((prevState) => ({ ...prevState, cleared: false }));
			await invalidateGetDepositsQuery();
		}

		if (updatedChecks || updatedDeposits) {
			setUpdateTotals(true);
		}
	};

	// Accept
	const { mutateAsync: postReconciliationAccept } =
		usePostTemporaryReconciliationsAccept();

	const acceptReconciliation = async () => {
		if (
			!checkbook ||
			typeof balance !== 'number' ||
			typeof computerBalance !== 'number'
		)
			return;

		const payload: IReconciliationsAcceptParams = {
			cashAccount: checkbook.cashAccount,
			statementBalance: checkbook.endingBalance,
			statementEndingDate: checkbook.statementDate,
			balance,
			computerBalance,
		};

		try {
			closeAcceptReconciliationModal();
			displayAlertLoader('Saving changes');

			await postReconciliationAccept(payload);

			displayAlert(EAlertTypes.Success, 'Successfully saved changes');
			resetState();
		} catch {
			displayAlert(EAlertTypes.Danger, 'Error: could not save changes');
		}
	};

	return (
		<CheckbookReconcileContext.Provider
			value={{
				tab,
				setTab,
				started,
				setStarted,
				formSubmitted,
				setFormSubmitted,
				isLoading:
					isGetReconciliationsLoading || isPostReconciliationStartLoading,
				checkbook,
				setCheckbook,
				checkbookDetail,
				setCheckbookDetail,
				resetState,
				outstandingChecks,
				outstandingDeposits,
				clearedChecks,
				clearedDeposits,
				invalidateGetChecksQuery,
				invalidateGetDepositsQuery,
				isGetChecksLoading,
				isGetDepositsLoading,
				postTotalsReconciliation,
				acceptReconciliation,
				selectedChecks,
				setSelectedChecks,
				selectedDeposits,
				setSelectedDeposits,
				totals,
				isTotalsLoading,
				updateTotals,
				setUpdateTotals,
				handleOnCheckSelectedChange,
				handleOnDepositSelectedChange,
				showTotalsErrorModal,
				closeTotalsErrorModal,
				showAcceptReconciliationModal: showAcceptModal,
				openAcceptReconciliationModal,
				closeAcceptReconciliationModal,
				showStartErrorModal,
				openStartErrorModal,
				closeStartErrorModal,
				showPrintModal,
				openPrintModal,
				closePrintModal,
				showDetailsModal,
				openDetailsModal,
				closeDetailsModal,
				showFinanceChargesModal,
				openFinanceChargesModal,
				closeFinanceChargesModal,
				handleOnMarkCleared,
				handleOnMarkUncleared,
				hasOutstandingTaggedItems,
				isSearchEnabled,
				setIsSearchEnabled,
				checksSelectAll,
				depositsSelectAll,
				handleOnChecksSelectAll,
				handleOnDepositsSelectAll,
			}}
		>
			{children}
		</CheckbookReconcileContext.Provider>
	);
};

CheckbookReconcileProvider.displayName = 'CheckbookReconcileProvider';

export { CheckbookReconcileProvider };
