export enum ECheckbookType {
	Check = 4,
	Deposit = 2,
	JournalEntry = 5,
}

export enum ECheckbookEntryType {
	Check = 1,
	Deposit = 2,
	JournalEntry = 3,
}

export enum ECheckType {
	Payroll = -1,
	ExpenseMisc = 0,
	ProjectPO = 1,
	DepositProjectPO = 2,
	DepositInventoryPO = 3,
	InventoryPO = 4,
}
