import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import Select from 'react-select';
import { WithRouter } from '../../../../helpers/Router';
import WysiwygEditor from '../../../components/WysiwygEditor';
import ThreadComponent from '../../ThreadComponent';

class CatalogItemComponentAddSpecification extends ThreadComponent {
	constructor(props) {
		super(props);
		this.state = {
			selectData: [
				{ value: 'option-1', label: 'Option 1' },
				{ value: 'option-2', label: 'Option 2' },
				{ value: 'option-3', label: 'Option 3' },
			],
		};
	}

	render() {
		return (
			<>
				<Row className="mb-4">
					<Col className="mb-3 mb-lg-0">
						<div className="bg-secondary-grey ps-4 roundedx-4 mb-4">
							<Row xs={1} lg={2} className="py-4">
								<Col>
									<h6 className="fw-bold mb-0">Special Instructions</h6>
								</Col>
							</Row>
						</div>
						<Row xs={1} lg={2}>
							<Col>
								<Form.Group>
									<Row className="mb-4">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0 pt-2">
												Instructions
											</Form.Label>
										</Col>
										<Col>
											<WysiwygEditor></WysiwygEditor>
										</Col>
									</Row>

									<Row className="align-items-center mb-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0">PO Sidemark</Form.Label>
										</Col>
										<Col>
											<Form.Control type="text" placeholder="Name" />
										</Col>
									</Row>

									<Row className="align-items-center mb-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0"></Col>
										<Col lg={5}>
											<Form.Check
												inline
												label="CFA Required"
												name="group2"
												type="checkbox"
												id={`inline-radio-2`}
											/>
										</Col>
									</Row>

									<Row className="align-items-center mb-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0">
												Ship On or Before
											</Form.Label>
										</Col>
										<Col>
											<Row>
												<Col>
													<Form.Control
														type="text"
														placeholder="Please enter"
													/>
												</Col>
												<Col></Col>
											</Row>
										</Col>
									</Row>
								</Form.Group>
							</Col>
						</Row>

						<div className="bg-secondary-grey ps-4 roundedx-4 mb-4">
							<Row xs={1} lg={2} className="py-4">
								<Col>
									<h6 className="fw-bold mb-0">
										Workroom Special Instructions
									</h6>
								</Col>
							</Row>
						</div>
						<Row xs={1} lg={2}>
							<Col>
								<Form.Group>
									<Row className="mb-4">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0 pt-2">
												Instructions
											</Form.Label>
										</Col>
										<Col>
											<WysiwygEditor></WysiwygEditor>
										</Col>
									</Row>

									<Row className="align-items-center mb-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0"></Col>
										<Col lg={5}>
											<Form.Check
												inline
												label="Part of Work Order Heading"
												name="group3"
												type="checkbox"
												id={`inline-radio-3`}
											/>
										</Col>
									</Row>

									<Row className="align-items-center mb-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0">Bar Code Labels</Form.Label>
										</Col>
										<Col>
											<Row>
												<Col>
													<Select
														options={this.state.selectData}
														className="react-select"
														placeholder="Please select"
													/>
												</Col>
												<Col></Col>
											</Row>
										</Col>
									</Row>
								</Form.Group>
							</Col>
						</Row>
					</Col>
				</Row>
			</>
		);
	}
}

export default WithRouter(CatalogItemComponentAddSpecification);
