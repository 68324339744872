import React, { useState } from 'react';
import BaseButton from 'legacy/app/components/Buttons/BaseButton';
import { useFeature } from 'use-feature';
import { FeatureFlags } from 'legacy/app/enums/featureFlags/featureFlags';
import { usePatchUserDefaultInstance } from 'api/hooks/usePatchUserDefaultInstance';
import { useSession } from 'legacy/app/context/session';
import { useNavigate } from 'react-router-dom';
import { COOKIE_EXPIRATION, setCookie } from 'legacy/utilities/Auth';
import { useGetUserInstances } from 'api/hooks/useGetUserInstances';
import { displayAlert } from 'legacy/utilities/Response';
import { EAlertTypes } from 'legacy/app/enums/alertTypes/alertTypes';
import { useQueryClient } from '@tanstack/react-query';
import { ECacheKeys } from 'cache/CacheKeys';

const TrailDemoBanner = () => {
	const { username } = useSession();
	const navigate = useNavigate();
	const [showBanner, setShowBanner] = useState(false);
	const isFeatureEnabled = useFeature(FeatureFlags.TrialDemoBanner);
	const queryClient = useQueryClient();

	const { mutateAsync: patchUserDefaultInstance } =
		usePatchUserDefaultInstance();

	const { data: userInstances = [] } = useGetUserInstances(username, {
		enabled: !!username && isFeatureEnabled,
		onSuccess: (userInstances) => {
			const defaultInstance = userInstances.find((i) => i.defaultInstance);
			setShowBanner(defaultInstance?.isDemoData ?? false);
		},
	});

	const handleOnClick = async () => {
		try {
			const instance = userInstances?.find((i) => !i.isDemoData);
			if (!instance) return;

			await patchUserDefaultInstance({
				userCode: username,
				instanceId: instance.instanceId,
			});

			setShowBanner(false);

			queryClient.invalidateQueries([
				ECacheKeys.UserData,
				'instances',
				username,
			]);

			setCookie('dmInstanceId', instance.instanceId, COOKIE_EXPIRATION);
			navigate('/');
		} catch (error: unknown) {
			const responseError = error as {
				response?: { data?: { userError?: string } };
			};
			displayAlert(
				EAlertTypes.Danger,
				responseError.response?.data?.userError || 'Unknown error'
			);
		}
	};

	if (isFeatureEnabled && showBanner) {
		return (
			<div className="tw-grid tw-grid-flow-col tw-items-center tw-gap-1 tw-justify-around tw-py-4 tw-px-8 tw-bg-[#FAE588]">
				<p className="tw-text-base tw-m-0">
					You are currently viewing our demo data. Please use this data{' '}
					<span className="tw-font-bold">only for exploring our app</span>. Real
					projects and accounting should be entered in your company app. To
					switch to your data, click Go to My App.
				</p>
				<BaseButton
					buttonClass="tw-justify-end tw-px-5 tw-py-2.5 tw-border-[1px] tw-border-[#008B6D] tw-bg-[#008B6D] tw-text-white tw-rounded tw-w-fit"
					text="Go to My App"
					onClick={handleOnClick}
				></BaseButton>
			</div>
		);
	}

	return null;
};

TrailDemoBanner.displayName = 'TrailDemoBanner';

export { TrailDemoBanner };
