import { useSearchParams } from 'react-router-dom';

/**
 * Custom hook to retrieve the "pageNumber" query parameter from the URL.
 *
 * It returns the value as a string or `null` if the parameter is not present.
 *
 * @returns {string | null} The page number as a string, or `null` if not found.
 */
export const usePageNumber = (): string | null => {
	const [searchParams] = useSearchParams();
	return searchParams.get('pageNumber');
};
