import React from 'react';
import { Container, Button, Form, Row, Col, Table } from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import { WithRouter } from '../../../../helpers/Router';
import { Modal } from 'react-bootstrap';
import { ApiService } from '../../../../lib/api/HttpService';
import WysiwygEditor from '../../../components/WysiwygEditor';
import DatePicker from 'react-datepicker';
import {
	handleResponseAlert,
	displayAlert,
	displayAlertLoader,
	displayAlertSuccess,
	displayAlertError,
	getErrorMessage,
	hideAlertLoader,
} from '../../../../utilities/Response';
import {
	FormEvent,
	setLocalStorageKeyValueObject,
} from '../../../../utilities/FormEvent';
import {
	getLocalStorage,
	setLocalStorage,
} from '../../../../utilities/LocalStorage';
import { generatePath } from 'react-router';
import URI from '../../../../defaults/RoutesDefault';
import { getCookie } from '../../../../utilities/Auth';
import { delay, max } from 'lodash';
import MSG from '../../../../defaults/Message';
import { mapRtfToDraft } from '../../../../utilities/Rtf';
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import { formatDate } from '../../../../helpers/Date';
import { showLoading } from '../../../../helpers/Loading';

class ProjectVendorOrdersAddPurchaseOrderGeneratedPOModal extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			checks: {},
			startDate: '',
			minDate: new Date(),
			isDisabled: true,
			show: false,
			showEditPOModal: false,
			orderToEdit: {},
			projectId: 0,
			toggleSpecialIns: false,
			specInstHtml: '',
			dataIsLoaded: false,
		};

		this.api = new ApiService();
		this.formEvent = new FormEvent('temp_purchase_order_edit');
		this.userCode = getCookie('dmUsercode');

		this.htmlToRtfConverter = new HtmlToRtfBrowser();
	}

	componentInit() {
		this.setTitle('Edit Generated PO').setFormAction(true);
	}

	async componentDidMount() {
		const { show } = this.props;

		const tempOrders = await this.api.getNewPurchaseOrders(
			`?$filter=usercode eq ${this.userCode}`
		);
		const activeProj = await this.api.getProjects(
			`?$filter=id eq ${this.props.params.id}`
		);

		this.setState({
			data: tempOrders,
			projectId: activeProj[0].id,
			project: activeProj[0],
			show,
			dataIsLoaded: true,
		});
	}

	async componentDidUpdate(previousProps, previousState) {
		if (
			previousProps.data !== this.props.data ||
			previousProps.show !== this.props.show
		) {
			const { data, show } = this.props;

			const tempOrders = await this.api.getNewPurchaseOrders(
				`?$filter=usercode eq ${this.userCode}`
			);
			const activeProj = await this.api.getProjects(
				`?$filter=id eq ${this.props.params.id}`
			);

			this.setState({
				data: tempOrders,
				projectId: activeProj[0].id,
				project: activeProj[0],
				show,
			});
		}
	}

	renderData(data) {
		this.setState({
			dataIsLoaded: true,
			data: data,
		});
	}

	handleShowTableSearch = (e) => {
		this.setState((prev) => {
			prev.showTableSearch = !prev.showTableSearch;
			return prev;
		});
	};

	handleSave = async (e) => {
		displayAlertLoader(MSG.loading.update.msg);
		window.onbeforeunload = null;
		let data = {
			curProj: this.state.project.proj,
		};

		if (this.props.params.orderId) {
			data.isUpdating = true;
		}

		const self = this;

		this.api
			.postJson('temporarypurchaseorders/accept', data)
			.then((res) => {
				const maxRequest = 10;
				const totalData = self.state.data?.length ?? 0;
				let requestCount = 0,
					activeDataIndex = 0;
				displayAlertLoader(
					'Checking for generated PDF files<span class="dot-load"></span>'
				);
				// Lets check if fileId is created
				const checkFileId = async () => {
					// Check the last added item for PDF/fileId.
					try {
						const orderId = self.state.data[activeDataIndex]?.ponum;
						const [purchaseOrder] = await self.api.getProjectPurchaseOrders(
							`?$filter=proj eq '${data.curProj}' and ponum eq '${orderId}'`
						);

						requestCount++;
						if (purchaseOrder?.fileId) {
							requestCount = 0;
							activeDataIndex++;
						}

						// If not found in maximum request, lets go to the next item
						if (requestCount > maxRequest) {
							requestCount = 0;
							activeDataIndex++;
						}

						if (
							(!purchaseOrder?.fileId &&
								requestCount < maxRequest &&
								activeDataIndex < totalData) ||
							activeDataIndex < totalData
						) {
							delay(async () => {
								await checkFileId();
							}, 2000);
						} else {
							redirect();
						}
					} catch (error) {
						// Ignore in frontend but display in console just incase error occured.
						// But this shoudn't break the process and continue to redirect even if multiple error in api.
						console.error(error);
						await checkFileId();
					}
				};

				const redirect = () => {
					self.props.hideModal();
					displayAlertLoader(
						'Redirecting, please wait<span class="dot-load"></span>'
					);
					delay(() => {
						hideAlertLoader();
						self.props.navigate(
							generatePath(URI.project.viewPage, {
								id: self.state.projectId,
								page: 'vendor-orders',
							})
						);
					}, 1800);
				};

				totalData && checkFileId();
			})
			.catch((err) => {
				displayAlertError(getErrorMessage(err));
			});
	};

	handleReject = (e) => {
		window.onbeforeunload = null;
		this.api.postJson('temporarypurchaseorders/reject', {}).then((res) => {
			this.props.hideModal();
			this.hideEditPOModal();
		});
	};

	handleSavePO = (e) => {
		let { data } = this.state;

		this.api
			.updateTemporaryPurchaseOrder({
				ponum: this.state.orderToEdit.ponum,
				shipby: formatDate(this.state.orderToEdit.shipby),
				shipvia: this.state.orderToEdit.shipvia,
				si: this.state.orderToEdit.si,
				sirtf: this.state.orderToEdit.sirtf,
				verbalref: this.state.orderToEdit.verbalref,
				designer: this.state.orderToEdit.designer,
				userCode: getCookie('dmUsercode'),
				proj: this.state.orderToEdit.proj,
				overridesi: this.state.toggleSpecialIns,
			})
			.then((res) => {
				displayAlert(
					'success',
					'Successfully edited temporary purchase order.'
				);

				const updatedData = [...data];
				updatedData[this.state.orderToEdit.index] = res;

				this.setState({
					data: updatedData,
					showEditPOModal: false,
					orderToEdit: {},
					toggleSpecialIns: false,
					specInstHtml: '',
				});
			})
			.catch((err) => {
				displayAlertError(getErrorMessage(err));
			});
	};

	handleTextFieldChange = (e) => {
		this.formEvent.handleTextChange(e);

		const key = e.target.name;
		this.setState((prevState) => ({
			orderToEdit: {
				...prevState.orderToEdit,
				[key]: e.target.value,
			},
		}));
	};

	handleDateChange = (date) => {
		let { orderToEdit } = this.state;
		orderToEdit.shipby = date.toString();
		this.setState({ orderToEdit });
		this.formEvent.handleDateChange('YYYY-MM-DD', 'shipby', date);
	};

	hideEditPOModal = (e) => {
		this.setState({
			showEditPOModal: false,
			orderToEdit: {},
			toggleSpecialIns: false,
			specInstHtml: '',
		});
	};

	handleSelectPO = async (e) => {
		let orderToEdit = this.state.data[e];
		orderToEdit.index = e;

		setLocalStorage(`temp_purchase_order_edit`, orderToEdit, true);

		this.setState({
			specInstHtml: await mapRtfToDraft(orderToEdit.sirtf),
			orderToEdit,
			showEditPOModal: true,
		});
	};

	handleSpecialInstChange = async (e) => {
		this.setState((prevState) => ({
			orderToEdit: {
				...prevState.orderToEdit,
				si: e.target.value,
				sirtf: this.htmlToRtfConverter.convertHtmlToRtf(e.target.html),
			},
		}));
	};

	handleOverrideSi = (e) => {
		this.setState((prevState) => ({
			orderToEdit: {
				...prevState.orderToEdit,
				overridesi: e.target.checked,
			},
		}));
	};

	getShipByDateValue() {
		return this.state.orderToEdit !== undefined &&
			this.state.orderToEdit.shipby !== null &&
			this.state.orderToEdit.shipby !== ''
			? new Date(this.state.orderToEdit.shipby)
			: '';
	}

	renderEditModal() {
		return (
			<Modal
				show={this.state.showEditPOModal}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				onHide={this.hideEditPOModal}
			>
				<Modal.Header closeButton>Edit Generated PO</Modal.Header>
				<Modal.Body>
					<div className="content-padding min-height">
						<Container fluid className="ms-0">
							<Form.Group>
								<Row xs={1} lg={2} className="mb-5">
									<Col>
										<Row className="align-items-center mb-3">
											<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
												<Form.Label className="mb-0">Ship Via</Form.Label>
											</Col>
											<Col>
												<Form.Control
													type="text"
													placeholder="Please enter"
													name="shipvia"
													defaultValue={this.state.orderToEdit?.shipvia}
													onChange={this.handleTextFieldChange}
												/>
											</Col>
										</Row>

										<Row className="align-items-center mb-4">
											<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
												<Form.Label className="mb-0">
													Designer/ Phone Ext.
												</Form.Label>
											</Col>
											<Col>
												<Form.Control
													type="text"
													placeholder="Please enter"
													name="designer"
													defaultValue={this.state.orderToEdit?.designer}
													onChange={this.handleTextFieldChange}
												/>
											</Col>
										</Row>

										<Row className="align-items-start mb-3">
											<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
												<Form.Label className="mb-0">
													Override Special Instructions
												</Form.Label>
											</Col>
											<Col>
												<Form.Check
													inline
													label="Override Special Instructions"
													name="group-check-override"
													type="checkbox"
													id={`group-check-override`}
													className={`${
														this.state.toggleSpecialIns ? '' : 'text-disabled'
													}mb-3`}
													onChange={() => {
														this.setState({
															toggleSpecialIns: !this.state.toggleSpecialIns,
														});
													}}
												/>
												<div className="wys-disabled-custom">
													<WysiwygEditor
														customWrapperClass={`${
															this.state.toggleSpecialIns
																? ''
																: 'text-disabled bg-secondary-grey'
														}`}
														isReadOnly={!this.state.toggleSpecialIns}
														onChange={this.handleSpecialInstChange}
													>
														{this.state.specInstHtml}
													</WysiwygEditor>
												</div>
											</Col>
										</Row>
									</Col>
									<Col>
										<Row className="align-items-center mb-3">
											<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
												<Form.Label className="mb-0">
													Ship On or Before Date
												</Form.Label>
											</Col>
											<Col>
												<Row lg={1}>
													<Col>
														<DatePicker
															selected={this.getShipByDateValue()}
															minDate={this.state.minDate}
															onChange={this.handleDateChange}
															showMonthDropdown="true"
															showYearDropdown="true"
															className="form-control form-control-sm"
														/>
													</Col>
												</Row>
											</Col>
										</Row>
										<Row className="align-items-center mb-3">
											<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
												<Form.Label className="mb-0">
													Verbal Order/ Ref No.
												</Form.Label>
											</Col>
											<Col>
												<Row lg={1}>
													<Col>
														<Form.Control
															type="text"
															placeholder="Please enter"
															name="verbalref"
															defaultValue={this.state.orderToEdit?.verbalref}
															onChange={this.handleTextFieldChange}
														/>
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>
							</Form.Group>
						</Container>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={this.handleSavePO}>
						OK
					</Button>
					<Button variant="primary" onClick={this.hideEditPOModal}>
						Cancel
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	render() {
		return (
			<>
				<Modal
					size="xl"
					show={this.props.show}
					onHide={this.props.hideModal}
					animation={false}
					centered
					dialogClassName="modal-90w"
				>
					<Modal.Header closeButton>
						<Modal.Title>Generated Purchase Order(s)</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Table striped responsive className="a-table table-check">
							<thead>
								<tr className="a-table-heading" key="0">
									<th className="ps-4 mw-150px">
										<span className="sort active">PO#</span>
									</th>
									<th>
										<span className="sort">Verbal/Ref. No.</span>
									</th>
									<th>
										<span className="sort">Order Date</span>
									</th>
									<th>
										<span className="sort">Vendor</span>
									</th>
									<th>
										<span className="sort">Ship To</span>
									</th>
									<th>
										<span className="sort">Ship Via</span>
									</th>
									<th>
										<span className="sort">Ship By</span>
									</th>
									<th>
										<span className="sort">Designer/Ext.</span>
									</th>
									<th>
										<span className="sort">Special Instructions</span>
									</th>
								</tr>

								<tr
									className={`a-table-search-fields ${
										this.state.showTableSearch ? '' : 'd-none'
									}`}
								>
									<th className="ps-4">
										<Form.Control type="text" data-field="projectCodeNo" />
									</th>
									<th>
										<Form.Control type="text" data-field="clientName" />
									</th>
									<th>
										<Form.Control type="text" data-field="clientName" />
									</th>
									<th>
										<Form.Control type="text" data-field="clientName" />
									</th>
									<th>
										<Form.Control type="text" data-field="clientName" />
									</th>
									<th>
										<Form.Control type="text" data-field="clientName" />
									</th>
									<th>
										<Form.Control type="text" data-field="clientName" />
									</th>
									<th>
										<Form.Control type="text" data-field="clientName" />
									</th>
									<th>
										<Form.Control type="text" data-field="clientName" />
									</th>
								</tr>
							</thead>
							<tbody>
								{!this.state.dataIsLoaded
									? showLoading()
									: this.state.data.map((item, i) => (
											<tr
												key={i}
												data-key={i}
												className={this.state.checks[i] ? `active` : ''}
												onClick={() => this.handleSelectPO(i)}
											>
												<td className="ps-4">
													<u>{`${item.proj}-${item.ponum}`}</u>
												</td>
												<td>{item.verbalref}</td>
												<td>
													{new Date(item.orderdt).toLocaleDateString('en-US')}
												</td>
												<td>{item.supplier}</td>
												<td>{item.shipto}</td>
												<td>{item.shipvia}</td>
												<td>{item.shipby ? formatDate(item.shipby) : ''}</td>
												<td>{item.designer}</td>
												<td
													style={{
														minWidth: '200px',
														maxHeight: '100px',
													}}
												>
													{item.si}
												</td>
											</tr>
									  ))}
							</tbody>
						</Table>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleSave}>
							OK
						</Button>
						<Button variant="primary" onClick={this.handleReject}>
							Cancel
						</Button>
					</Modal.Footer>
				</Modal>
				{this.state.showEditPOModal && this.renderEditModal()}
			</>
		);
	}
}

export default WithRouter(ProjectVendorOrdersAddPurchaseOrderGeneratedPOModal);
