import { apiClient } from 'api/apiClient';
import type { TFormValues } from 'legacy/templates/modules/sign-up/types/TFormValues';

const RESOURCE = '/connect';

export type TUserConnectData = Pick<
	TFormValues,
	'username' | 'password' | 'recaptchaToken'
>;

export interface IPostUserConnectResponse {
	access_token: string;
	expires_in: number;
	token_type: string;
	scope: string;
}

export const postUserConnect = async (
	params: TUserConnectData
): Promise<IPostUserConnectResponse> => {
	const { data } = await apiClient.postAuth(`${RESOURCE}/token`, params);
	return data;
};
