import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useGetCompany } from 'api/hooks/useGetCompany';
import { useGetCompanyTwo } from 'api/hooks/useGetCompanyTwo';
import { usePostCheckbookVoid } from 'api/hooks/usePostCheckbookVoid';
import { formatFiscalMonth } from './utils';
import { displayAlert } from 'legacy/utilities/Response';
import { EAlertTypes } from 'legacy/app/enums/alertTypes/alertTypes';
import BaseButton from 'legacy/app/components/Buttons/BaseButton';

interface ICheckbook {
	checknum?: string | null;
	userdate?: string | null;
	account?: string | null;
}

interface ICheckbookVoidModalProps {
	onClose: () => void;
	onConfirm?: () => void;
	checkbook: ICheckbook;
	isUndoVoid: boolean;
}

const CheckbookVoidModal = ({
	onClose,
	onConfirm,
	checkbook,
	isUndoVoid,
}: ICheckbookVoidModalProps) => {
	const { checknum, userdate, account } = checkbook;
	const [fiscalMonth, setFiscalMonth] = useState<Date | null>(null);
	const { data: company } = useGetCompany();
	const { data: companyTwo } = useGetCompanyTwo();
	const { mutateAsync: postCheckbookVoid } = usePostCheckbookVoid();

	useEffect(() => {
		if (company && companyTwo && !fiscalMonth) {
			let fiscalMonth = new Date();
			if (companyTwo.autodeffmo) {
				fiscalMonth = new Date(
					fiscalMonth.setMonth(fiscalMonth.getMonth() + company.fiscaloffset)
				);
			} else {
				fiscalMonth = new Date(companyTwo.closedapfmo);
				fiscalMonth.setMonth(fiscalMonth.getMonth() + 1);
			}
			setFiscalMonth(fiscalMonth);
		}
	}, [company, companyTwo, setFiscalMonth, fiscalMonth]);

	const onFiscalMonthChange = (date: Date) => {
		setFiscalMonth(date);
	};

	const handleOnConfirm = async () => {
		if (!fiscalMonth) return;

		const payload = {
			checkNum: checknum,
			checkDt: userdate,
			cashAcctNum: account,
			voidFMo: formatFiscalMonth(fiscalMonth),
			isUndoVoid,
		};

		try {
			await postCheckbookVoid(payload);
			onConfirm?.();
			displayAlert(
				EAlertTypes.Success,
				`Successfully ${isUndoVoid ? 'Unvoided' : 'Voided'} entry`
			);
		} catch (error: unknown) {
			const responseError = error as {
				response?: { data?: { userError?: string } };
			};
			displayAlert(
				EAlertTypes.Danger,
				responseError.response?.data?.userError || 'Unknown error'
			);
		} finally {
			onClose();
		}
	};

	return (
		<Modal show={true} onHide={onClose} backdrop="static" centered>
			<Modal.Header>
				<Modal.Title as="h5">Void Check</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="tw-grid tw-grid-flow-col tw-justify-center tw-items-center tw-gap-2">
					<label>Fiscal Month</label>
					<div>
						<DatePicker
							selected={fiscalMonth}
							onChange={onFiscalMonthChange}
							className="form-control"
							dateFormat="MM/yyyy"
							showMonthYearPicker
						/>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<BaseButton
					onClick={onClose}
					text="Close"
					buttonClass="tw-px-5 tw-py-2.5 tw-border-[1px] tw-border-[#e7faf3] tw-bg-[#e7faf3] tw-text-[#006a53] tw-rounded"
				/>
				<BaseButton
					onClick={handleOnConfirm}
					text="Ok"
					buttonClass="tw-px-5 tw-py-2.5 tw-border-[1px] tw-border-[#008B6D] tw-bg-[#008B6D] tw-text-white tw-rounded"
				/>
			</Modal.Footer>
		</Modal>
	);
};

CheckbookVoidModal.displayName = 'CheckbookVoidModal';

export { CheckbookVoidModal };
