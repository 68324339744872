import { useMutation } from '@tanstack/react-query';
import { postVoidFinanceCharges } from 'api/resources/financeCharges';
import { EAlertTypes } from 'legacy/app/enums/alertTypes/alertTypes';
import { displayAlert } from 'legacy/utilities/Response';

export const usePostVoidFinanceCharges = () => {
	return useMutation(postVoidFinanceCharges, {
		onSuccess: () => {
			displayAlert(EAlertTypes.Success, 'Finance charge voided successfully');
		},
		onError: () => {
			displayAlert(EAlertTypes.Danger, 'Error: could not void charge');
		},
	});
};
