import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { ECacheKeys } from 'cache/CacheKeys';
import type { ICompanyRole } from 'api/resources/companyRole';
import { getCompanyRole } from 'api/resources/companyRole';

export const useGetCompanyRole = (
	userCode?: number,
	options?: UseQueryOptions<ICompanyRole>
) => {
	const query = useQuery({
		queryKey: [ECacheKeys.CompanyRole, userCode],
		queryFn: () => getCompanyRole(userCode),
		refetchInterval: false,
		meta: {
			error: 'There was an error fetching the company roles, please try again',
		},
		refetchOnWindowFocus: false,
		...(options || {}),
	});

	return query;
};
