import useStartPaymentProcessorSession from 'legacy/lib/api/hooks/useStartPaymentProcessorSession';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import React, { useState } from 'react';
import OnboardMerchantComponent from './OnboardingComponent';
import Spinner from 'legacy/app/components/help/Spinner';
import useGetPaymentProcessorInfo from 'legacy/lib/api/hooks/useGetPaymentProcessorStatus';
import { OnboardingStatus } from 'legacy/lib/api/types/PaymentProcessorResponse';
import useGetManagedAccounts from 'legacy/lib/api/hooks/useGetManagedAccounts';
import { PaymentSessionType } from 'legacy/lib/api/types/PaymentSessionType';
import PaymentProcessorInfo from './PaymentProcessorInfo';
import { useGetCurrentUser } from 'api/hooks/useGetCurrentUser';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';
import { useFeature } from 'use-feature';
import { FeatureFlags } from 'legacy/app/enums/featureFlags/featureFlags';
import { useSession } from 'legacy/app/context/session';
import { useGetUserInstances } from 'api/hooks/useGetUserInstances';

const OnboardingStartFlow = () => {
	const {
		data: sessionInfo,
		refetch,
		isFetching,
		error,
	} = useStartPaymentProcessorSession(PaymentSessionType.ONBOARDING, {
		enabled: false,
	});

	const [showOnboardingComponent, setShowOnboardingComponent] = useState(false);

	const { username } = useSession();
	const isTrailDemoFeatureEnabled = useFeature(FeatureFlags.TrialDemoBanner);
	const { data: userInstances = [] } = useGetUserInstances(username, {
		enabled: !!username && isTrailDemoFeatureEnabled,
	});

	const [showTrialDemoModal, setShowTrialDemoModal] = useState(false);
	const openTrialDemoModal = () => {
		setShowTrialDemoModal(true);
	};
	const closeTrialDemoModal = () => {
		setShowTrialDemoModal(false);
	};

	const { data: stripeData, isLoading } = useGetPaymentProcessorInfo('Stripe');
	const { data: currentUser } = useGetCurrentUser();

	const {
		data: managedAccounts,
		error: accountsError,
		isLoading: isGettingAccounts,
	} = useGetManagedAccounts();

	const isAccountManager = managedAccounts?.activeUsers.find(
		(user) => user.userId === managedAccounts.currentUserId
	)?.isAdmin;

	if (isFetching || isLoading || isGettingAccounts || !currentUser) {
		return <Spinner isChild />;
	}

	if (error || accountsError) {
		return null;
	}

	if (sessionInfo && showOnboardingComponent) {
		return (
			<div className="tw-p-10">
				<OnboardMerchantComponent
					sessionKey={sessionInfo.sessionKey}
					merchantApplicationId={sessionInfo.merchantApplicationId}
					merchantId={sessionInfo.merchantId}
				/>
			</div>
		);
	}

	return (
		<>
			<PaymentProcessorInfo stripeData={stripeData} />
			<div className="tw-flex tw-justify-center">
				<OverlayTrigger
					show={!isAccountManager || currentUser.clientIsTrial}
					overlay={
						<Tooltip style={{ zIndex: 20000 }}>
							{!isAccountManager && (
								<>
									Only users with Account Manager permissions can register for
									DesignPay. User permissions can be changed in the User
									Management area of Settings.
								</>
							)}
							{currentUser.clientIsTrial && (
								<>DesignPay is not available for trial accounts.</>
							)}
						</Tooltip>
					}
				>
					<span>
						<Button
							disabled={!isAccountManager || currentUser.clientIsTrial}
							size="lg"
							onClick={() => {
								if (
									isTrailDemoFeatureEnabled &&
									userInstances.find((i) => i.defaultInstance)?.isDemoData
								) {
									openTrialDemoModal();
								} else {
									refetch();
									setShowOnboardingComponent(true);
								}
							}}
						>
							<span className="tw-text-base">
								{stripeData?.onboardingStatus === OnboardingStatus.Verified
									? 'Switch to DesignPay'
									: 'Start Accepting Payments'}
							</span>
						</Button>
					</span>
				</OverlayTrigger>
			</div>

			<ConfirmationModal
				show={showTrialDemoModal}
				okBtnStyle="primary"
				title="Feature not Available"
				message="The Demo Data does not allow this action. Please switch to your company’s data in order to utilize this feature."
				confirmAction={closeTrialDemoModal}
				toggleModal={closeTrialDemoModal}
				hideCancel
			/>
		</>
	);
};
OnboardingStartFlow.displayName = 'OnboardingStartFlow';

export default OnboardingStartFlow;
