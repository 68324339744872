import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSession } from '../../context/session';
import CryptoJS from 'crypto-js';

declare global {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface Window {
		chmln: {
			identify: (username: string, options: Record<string, any>) => void;
		};
	}
}

interface IChameleonUserData {
	email: string;
	username: string;
	fullName: string;
	companyRole: string;
}

interface IChameleonProps {
	newUserData?: IChameleonUserData;
}

async function encrypt(uid: string): Promise<string> {
	const secret = process.env.REACT_APP_CHAMELEON_HASH || '';
	const now = Math.floor(Date.now() / 1000);

	const hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret);
	hmac.update(`${uid}-${now}`);
	return hmac.finalize().toString();
}

const debug = (...args: any[]): void => {
	if (localStorage.debug === 'true') {
		console.log(...args);
	}
};

const Chameleon: React.FC<IChameleonProps> = ({ newUserData }) => {
	debug('<Chameleon /> component loaded');
	const isNewUser = !!newUserData;
	const sessionData = useSession(isNewUser);

	const { email, username, fullName, companyRole } = isNewUser
		? newUserData
		: sessionData;

	useEffect(() => {
		debug('REACT_APP_CHAMELEON_HASH', process.env.REACT_APP_CHAMELEON_HASH);
		debug('REACT_APP_CHAMELEON_ID', process.env.REACT_APP_CHAMELEON_ID);
	}, []);

	useEffect(() => {
		if (!companyRole || !username || !window.chmln) {
			return;
		}

		if (!process.env.REACT_APP_CHAMELEON_ID) {
			debug('env var REACT_APP_CHAMELEON_ID not found. Aborting...');
			return;
		}

		async function identifyUser() {
			const now = Math.floor(Date.now() / 1000);
			const hash = [await encrypt(username), now].join('-');

			debug('calling window.chmln.identify()...');

			const chameleonPayload: {
				uid_hash: string;
				email: string;
				name: string;
				role: string;
				companyRole: string;
			} = {
				uid_hash: hash,
				email,
				name: fullName,
				role: companyRole,
				companyRole,
			};

			window.chmln.identify(username, chameleonPayload);
		}

		identifyUser();
	}, [username, email, fullName, companyRole]);

	if (
		!process.env.REACT_APP_CHAMELEON_ID ||
		!process.env.REACT_APP_CHAMELEON_HASH
	) {
		debug(
			'Either REACT_APP_CHAMELEON_HASH or REACT_APP_CHAMELEON_ID not found. Aborting...'
		);
		return null;
	}

	debug('Chameleon criteria met. Inserting script...');

	return (
		<Helmet>
			<script>{`!function(d,w){var t="${process.env.REACT_APP_CHAMELEON_ID}",c="chmln",i=d.createElement("script");if(w[c]||(w[c]={}),!w[c].root){w[c].accountToken=t,w[c].location=w.location.href.toString(),w[c].now=new Date,w[c].fastUrl='https://fast.chameleon.io/';var m="identify alias track clear set show on off custom help _data".split(" ");for(var s=0;s<m.length;s++){!function(){var t=w[c][m[s]+"_a"]=[];w[c][m[s]]=function(){t.push(arguments);};}();}i.src=w[c].fastUrl+"messo/"+t+"/messo.min.js",i.async=!0,d.head.appendChild(i);}}(document,window);`}</script>
		</Helmet>
	);
};

Chameleon.displayName = 'Chameleon';

export default Chameleon;
