import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { IAuthorizationTemplate } from 'api/resources/authorizationTemplate';
import { getAuthorizationTemplates } from 'api/resources/authorizationTemplate';
import { ECacheKeys } from 'cache/CacheKeys';

export const useGetAuthorizationTemplates = (
	options?: UseQueryOptions<IAuthorizationTemplate[]>
) => {
	return useQuery<IAuthorizationTemplate[], unknown>({
		queryKey: [ECacheKeys.AuthorizationTemplates],
		queryFn: getAuthorizationTemplates,
		refetchInterval: false,
		refetchOnWindowFocus: false,
		meta: {
			error: 'There was an error fetching the system roles, please try again',
		},
		...(options || {}),
	});
};
