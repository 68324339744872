import React from 'react';
import { Col, Container, Row, Button, Form, Table } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import URI from '../../../defaults/RoutesDefault';
import { WithRouter } from '../../../helpers/Router';
import ListFilter from '../../components/ListFilter';
import Header from '../../components/Header';
import ReportingTree from './ReportingTree';

import ThreadComponent from '../ThreadComponent';
import { compareStr } from '../../../helpers/String';
import { ApiService } from '../../../lib/api/HttpService';
import ReportModal from '../modal/ReportModal';
import { containStr } from '../../../helpers/String';
import { getCookie } from '../../../utilities/Auth';
import { showLoading } from '../../../helpers/Loading';
import { toggleDMPageLoader } from '../../../utilities/DOM';
import { delay } from 'lodash';

class Reporting extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			checks: {},
			showRetainerModal: false,
			activeMenu: 'all',
			userId: 0,
			usercode: 0,
			alltree: {
				children: [],
			},
			favtree: {},
			activeTree: {},
			flatTree: [],
			flatFavTree: [],
			selectedReport: { params: [], reportFormats: [] },
			reportData: {},
			isLoaded: false,
		};

		this.api = new ApiService();
		this.updateSelectedReport = this.updateSelectedReport.bind(this);
	}

	componentInit() {
		this.setTitle('Reporting').setActionBar(true);
	}

	async componentDidMount() {
		const usercode = getCookie('dmUsercode');
		const data = await this.api.get(
			'useraccounts',
			`?$filter=usercode eq ${usercode}`
		);

		this.setState({ userId: data[0].userId, usercode: usercode });

		const favs = await this.api.get(
			'ReportFavorites',
			'?$filter=usercode eq ' + usercode
		);

		const favtree = this.buildFavTree(favs);

		const alltree = await this.api.getReportsTree();
		this.flattenTree(alltree.rootNode);
		const flatTree = this.updateFlatTree(this.state.flatTree);

		this.setState({
			favtree: favtree,
			activeTree: flatTree,
			flatTree,
			isLoaded: true,
		});
	}

	async getReportDetails(reportId) {
		let tempReport = await this.api.getReport(reportId);
		let reportParams = await this.api.getReportParameters(reportId);
		let reportTypes = await this.api.getReportTypes();
		reportParams = reportParams.reportParametersDtos;
		reportTypes = reportTypes.reportParameterTypeDtos;

		for (let param of reportParams) {
			const reportType = reportTypes.find((type) => type.id === param.typeId);

			const paramData = await this.getDataByParameterType(reportType.label);
			param.typeLabel = reportType.label;
			param.reportData = paramData;

			if (reportType.label === 'month' || reportType.label === 'date') {
				param.defaultValue = new Date();
			}
		}

		tempReport.params = reportParams;

		return tempReport;
	}

	updateTree(node) {
		node.name = node.label;
		if (node.children) {
			for (let c of node.children) {
				c.name = c.label;
				this.updateTree(c);
			}
		}
		if (node.reports) {
			for (let r of node.reports) {
				r.name = r.label;
			}
		}
	}

	updateFlatTree(tree) {
		for (let node of tree) {
			node.name = node.label;

			if (node.reports) {
				node.reports = node.reports.filter((r) => r.allowInWeb);
				for (let r of node.reports) {
					r.name = r.label;
				}
			}
		}

		return tree;
	}

	buildFavTree(data) {
		let newTree = [{ name: 'Favorites', reports: [], uniqueKey: 1 }];

		for (let el of data) {
			if (el.reportNum !== 0) {
				el.id = el.reportNum;
				newTree[0].reports.push(el);
			}
		}

		return newTree;
	}

	flattenTree(tree) {
		let { flatTree } = this.state;
		flatTree.push(tree);
		this.setState({
			flatTree,
		});
		if (tree.children) {
			for (let c of tree.children) {
				this.flattenTree(c);
			}
		}
	}

	async updateSelectedReport(reportId) {
		const report = await this.getReportDetails(reportId);

		this.setState({
			selectedReport: report,
		});
		this.handleRunReport();
	}

	async getDataByParameterType(type) {
		switch (type) {
			case 'account':
				return await this.api.getGLAccounts();
			case 'purchase_order':
				return await this.api.getProjectPurchaseOrders();
			case 'stock':
				return await this.api.getInventory();
			case 'vendor':
				return await this.api.getVendorsSummaries();
			case 'item_status':
				return await this.api.getStatusCodes();
			case 'cash_account':
				const filterCash = '?$filter=type eq 1 and specacct eq 1';
				return await this.api.getGLAccounts(filterCash);
			case 'credit_card_account':
				const filterCredit = '?$filter=type eq 2 and specacct eq 2';
				return await this.api.getGLAccounts(filterCredit);
			case 'client_type':
				const filterClient = '?$filter=addressType eq 4';
				const addressClient = await this.api.getAddresses(filterClient);
				return this.getSortedAddress(addressClient);
			case 'vendor_type':
				const filterVendor = '?$filter=addressType eq 2';
				const addressVendor = await this.api.getAddresses(filterVendor);
				return this.getSortedAddress(addressVendor);
			case 'shipto_type':
				const filterShipTo = '?$filter=addressType eq 1';
				const addressShipTo = await this.api.getAddresses(filterShipTo);
				return this.getSortedAddress(addressShipTo);
			case 'user_list':
				const users = await this.api.getUserAccounts();
				return users.sort(this.sortByUsernameUsercode);
			default:
				return [];
		}
	}

	sortByUsernameUsercode(a, b) {
		const userA = a.username.toLowerCase() + a.usercode;
		const userB = b.username.toLowerCase() + b.usercode;

		if (userA < userB) {
			return -1;
		}
		if (userA > userB) {
			return 1;
		}
		return 0;
	}

	getSortedAddress(address) {
		let unique = address
			.map((item) => item.typedesc)
			.filter(
				(value, index, self) =>
					self.indexOf(value) === index && value !== '' && value !== null
			);
		const sortedAddress = unique.sort();

		return sortedAddress;
	}

	searchFlatTreeForReports(element, matchingTitle) {
		let reportList = [];

		for (const item of element) {
			if (item.reports !== null) {
				for (const report of item.reports) {
					const label = report.label.toLowerCase();
					if (containStr(label, matchingTitle)) {
						reportList.push(report);
					}
				}
			}
		}

		return reportList;
	}

	renderData(data) {
		this.setState({
			sectionTree: data,
		});
	}

	handleMenuClick = (e) => {
		e.preventDefault();

		this.setState(
			(prevState) => (prevState.activeMenu = e.target.dataset.menu)
		);
	};

	handleSearch = (e) => {
		const searchString = e.target.value.toLowerCase();

		if (searchString !== '') {
			let resultTree = [];
			let searchItem = { name: 'Search Results' };

			const result = this.searchFlatTreeForReports(
				this.state.flatTree,
				searchString
			);
			searchItem.reports = result;
			resultTree.push(searchItem);
			this.setState({
				activeTree: resultTree,
			});
		} else {
			this.setState({
				activeTree: this.state.flatTree,
			});
		}
	};

	handleRunReport = (e) => {
		this.setState(
			{
				showReportModal: true,
			},
			() => {
				delay(() => {
					toggleDMPageLoader({
						show: false,
						destroy: true,
					});
				}, 300);
			}
		);
	};

	handleCloseReport = () => {
		this.setState({
			showReportModal: false,
		});
	};

	renderHeader() {
		const { activeMenu } = this.state;

		return (
			<Header buttons={true}>
				<Header.TitleContent className="mb-4">
					<Header.Title>Reporting</Header.Title>
				</Header.TitleContent>
				{/* show v1
                <Header.Buttons>
                    { (this.state.isLoaded) ?
                     <>
                    <li><NavLink to={URI.report.base + '/all'} data-menu="all" className={`btn text-white ${compareStr(activeMenu, 'all', 'active', '')}`} onClick={this.handleMenuClick}>All</NavLink></li>
                    <li><NavLink to={URI.report.base + '/favorites'} data-menu="favourites" className={`btn text-white ${compareStr(activeMenu, 'favourites', 'active', '')}`} onClick={this.handleMenuClick}>Favorites</NavLink></li>
                    </>
                    :
                     <li></li>
                    }
                </Header.Buttons>
                */}
			</Header>
		);
	}

	renderFilter() {
		if (this.state.activeMenu === 'all') {
			return (
				<ListFilter>
					<ListFilter.Fields lg={8} xl={8} className="w-33">
						<ListFilter.Field>
							<Form.Label className="text-end">
								<strong>Search All</strong>
							</Form.Label>
							<Form.Control
								size="sm"
								placeholder=""
								onChange={this.handleSearch}
							/>
						</ListFilter.Field>
					</ListFilter.Fields>
					<ListFilter.Actions
						lg={4}
						xl={4}
						replaceclassmatch="justify-content-xl-end"
						replaceclassmatchwith="justify-content-lg-end"
					>
						{/* show v1
                        <ListFilter.Action>
                            <Button
                                variant="primary"
                                size="sm"
                                className='btn-icon'
                                onClick={this.handleRunReport}
                            >
                                <i className="ri-file-chart-fill me-1"></i> Run Report
                            </Button>
                        </ListFilter.Action>

                        <ListFilter.Action className='ms-auto ms-lg-0'>
                            <Button as={Link} to="" variant='ivory' size='sm' className='btn-icon btn-action ms-3'>
                                <i className="ri-star-fill"></i> Add to favorites
                            </Button>
                        </ListFilter.Action>
                        */}
					</ListFilter.Actions>
				</ListFilter>
			);
		} else {
			return (
				<ListFilter expandable={0}>
					<ListFilter.Actions
						lg={12}
						xl={12}
						replaceclassmatch="justify-content-xl-end"
						replaceclassmatchwith="justify-content-lg-end"
					>
						<ListFilter.Action className="me-3">
							<Button variant="primary" size="sm" className="btn-icon">
								<i className="ri-file-chart-fill"></i> Run Report
							</Button>
						</ListFilter.Action>
						<ListFilter.Action className="ms-auto ms-lg-0">
							<Button
								as={Link}
								to=""
								variant="ivory"
								size="sm"
								className="btn-icon btn-action"
							>
								<i className="ri-folder"></i> New folder
							</Button>
							<Button
								as={Link}
								to=""
								variant="ivory"
								size="sm"
								className="btn-icon btn-action ms-3"
							>
								<i className="ri-close-line"></i> Remove
							</Button>
						</ListFilter.Action>
					</ListFilter.Actions>
				</ListFilter>
			);
		}
	}

	renderContent() {
		if (this.state.isLoaded) {
			const data = [];

			data.push(this.state.activeTree);

			const data2 = this.state.favtree;

			if (this.state.activeMenu === 'all') {
				return (
					<ReportingTree
						isroot={1}
						data={this.state.activeTree}
						style={{ maxHeight: '500px', overflow: 'scroll' }}
						updatereport={this.updateSelectedReport}
					></ReportingTree>
				);
			} else {
				return (
					<ReportingTree
						data={data2}
						style={{ maxHeight: '500px', overflow: 'scroll' }}
						updatereport={this.updateSelectedReport}
					></ReportingTree>
				);
			}
		} else {
			return (
				<Table striped responsive className="a-table mb-0">
					<tbody>{showLoading({ rows: 4, rowClass: 'compact' })}</tbody>
				</Table>
			);
		}
	}

	render() {
		return (
			<>
				{this.renderHeader()}

				<div className="content-padding">
					<Container fluid className="px-0">
						<div className="mb-3">{this.renderFilter()}</div>

						<Row>
							<Col sm={12} lg={6} className="mb-4 mb-lg-0">
								<div className="w-100 border p-4">{this.renderContent()}</div>
							</Col>
						</Row>
					</Container>
				</div>

				<ReportModal
					show={this.state.showReportModal}
					hideModal={() =>
						this.setState((prev) => (prev.showReportModal = false))
					}
					report={this.state.selectedReport}
					projects={this.state.projectList}
				/>
			</>
		);
	}
}

export default WithRouter(Reporting);
