import { CellContext } from '@tanstack/react-table';
import SecureBootstrapButton from 'legacy/app/components/security/SecureBootstrapButton';
import { SECURITY_ATTRIBUTE_TYPES } from 'legacy/app/context/security';
import URI from 'legacy/defaults/RoutesDefault';
import { TComponentWithTxNum } from 'legacy/lib/api/hooks/useGetComponents';
import { Button } from 'react-bootstrap';
import {
	generatePath,
	Link,
	useParams,
	LinkProps,
	useNavigate,
} from 'react-router-dom';
import { usePageNumber } from 'legacy/hooks/usePageNumber';

type TCustomBootstrapLinkType = React.ComponentClass<LinkProps> &
	keyof JSX.IntrinsicElements;

const TableButtonCell = ({
	info,
	submitMethods,
	doNotSubmit,
	isItemFromInventory,
	disabled,
}: {
	isItemFromInventory: boolean;
	doNotSubmit: boolean;
	disabled: boolean;
	info: CellContext<TComponentWithTxNum, unknown>;
	submitMethods: (customRedirectPath?: string) => Promise<void>;
}) => {
	const { id: projectId, itemId } = useParams();
	const pageNumber = usePageNumber();

	const navigate = useNavigate();

	let linkRoute = info.row.original.txnum
		? generatePath(URI.timeEntry.edit, {
				id: info.row.original.txnum,
		  })
		: generatePath(URI.project.newComponentEdit, {
				itemId,
				id: projectId,
				componentId: info.row.original.id,
		  });

	if (pageNumber) {
		linkRoute += `?pageNumber=${pageNumber}`;
	}

	return (
		<div className="tw-flex tw-items-center">
			<SecureBootstrapButton
				variant="link"
				disabled={isItemFromInventory ? false : disabled}
				attributeNo={14}
				attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
				onClick={
					doNotSubmit || isItemFromInventory
						? () => navigate(linkRoute)
						: () => submitMethods(linkRoute)
				}
				className="!tw-p-0 !tw-m-0 !tw-min-w-min !tw-min-h-min"
			>
				<Link
					to={linkRoute}
					className="!tw-text-[#211f21] tw-underline tw-text-xs tw-pointer-events-none"
				>
					{info.getValue() as string}
				</Link>
			</SecureBootstrapButton>
			{info.row.original.numAttachments > 0 && (
				<Button
					as={Link as unknown as TCustomBootstrapLinkType}
					to={generatePath(URI.project.newComponentEdit, {
						id: info.row.original.projectId,
						itemId: info.row.original.itemId,
						componentId: info.row.original.id,
					})}
					variant="ivory"
					size="sm"
					className="tw-border-0 fsx-16 ri-attachment-2 square tw-ml-1 tw-no-underline"
				/>
			)}
		</div>
	);
};
TableButtonCell.displayName = 'TableButtonCell';
export default TableButtonCell;
