import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { ECacheKeys } from 'cache/CacheKeys';
import { getVendors } from 'api/resources/vendor';
import type { Summary } from 'legacy/lib/api/types/Summary';

export const useGetVendors = (
	filter?: string,
	options?: UseQueryOptions<Summary[]>
) => {
	const query = useQuery<Summary[]>({
		queryKey: [ECacheKeys.Vendors, filter],
		queryFn: () => getVendors(filter),
		refetchInterval: false,
		refetchOnWindowFocus: false,
		...(options || {}),
	});

	return query;
};
