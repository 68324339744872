import React from 'react';
import { Link, NavLink, Outlet } from 'react-router-dom';
import { Collapse, Image, Nav, Navbar, Tooltip } from 'react-bootstrap';
import URI from '../../defaults/RoutesDefault';
import { Resource } from '../../resources/Resources';
import { getLocalStorage } from '../../utilities/LocalStorage';
import { generatePath } from 'react-router-dom';
import { clearUserData, getCookie, parseJwt } from '../../utilities/Auth';
import Intercom from '../components/Intercom';
import SessionProvider from '../context/session';
import SecurityProvider from '../context/security';
import Datadog from '../components/datadog';
import logo from '../../assets/images/logo.svg';
import { AppNavigationComponent, isActiveMenu } from '../../utilities/Router';
import { initClickNavLink } from '../../utilities/DOM';
import ConfirmModal from '../components/modal/ConfirmModal';
import { delay } from 'lodash';
import Heap from '../components/Heap';
import AccountSwitcherDropdown from '../components/navigation/AccountSwitcherDropdown';
import SecureNavLink from '../components/security/SecureNavLink';
import { SECURITY_ATTRIBUTE_TYPES } from '../context/security';
import Chameleon from '../components/Chameleon';
import ImpersonationBanner from '../components/banners/ImpersonationBanner';
import { SuperHeader } from 'legacy/app/layouts/SuperHeader/SuperHeader';
import { OverlayTrigger } from 'react-bootstrap';
import { TrailDemoBanner } from 'legacy/app/components/banners/TrialDemoBanner/TrailDemoBanner';

interface State {
	isToggleInventory: boolean;
	isToggleProjects: boolean;
	isToggleTimeTracking: boolean;
	isToggleAccounting: boolean;
	isToggleTopMenu: boolean;
	showLogoutModal: boolean;
	inImpersonationMode: boolean;
	superAdminName: string;
	userName: string;
}
interface Props {}

class AppLayout extends React.Component<Props, State> {
	private isFixed: boolean;
	private dmUserName?: string;

	constructor(props: Props) {
		super(props);

		const token = getCookie('dmAuthToken');
		const tokenData = token ? parseJwt(token) : null;

		this.state = {
			isToggleProjects: false,
			isToggleTimeTracking: false,
			isToggleInventory: false,
			isToggleAccounting: false,
			isToggleTopMenu: false,
			showLogoutModal: false,
			inImpersonationMode: tokenData?.ImpersonationMode ?? false,
			superAdminName: tokenData?.SuperadminUsername as string,
			userName: tokenData?.name ?? '',
		};

		this.isFixed = true;
		this.dmUserName = getCookie('dmUsername');

		// This binding is necessary to make `this` work in the callback
		this.handleClick = this.handleClick.bind(this);
		this.handleMenuClick = this.handleMenuClick.bind(this);
	}

	componentDidMount(): void {
		this.bindNavLinkEvents();
	}

	bindNavLinkEvents() {
		// Bind click event on sidebar nav-links.
		// If draft is enabled, this will trigger browser confirm()
		// before leaving the current screen.
		initClickNavLink();
	}

	handleClick = (e: any) => {
		e.preventDefault();

		// Get the target menu.
		const id = e.target.getAttribute('data-state');

		// Set the new state.
		this.setState((prevState) => {
			const data = {};
			//@ts-ignore
			data[id] = !prevState[id];

			return data;
		});

		const isDraft = getLocalStorage('isDraft');

		if (isDraft === 'true') {
			if (!e.target.classList.contains('menu-toggle')) {
				if (confirm('Leaving this screen will discard your changes.')) {
					window.localStorage.setItem('isDraft', 'false');
					if (e.currentTarget.href !== undefined) {
						location.href = e.currentTarget.href;
					} else if (e.target.href !== undefined) {
						location.href = e.target.href;
					} else {
						location.href = e.currentTarget.firstChild.href;
					}
				} else {
					return false;
				}
			}
		} else {
			if (!e.target.classList.contains('menu-toggle')) {
				if (e.currentTarget.href !== undefined) {
					location.href = e.currentTarget.href;
				} else if (e.target.href !== undefined) {
					location.href = e.target.href;
				} else {
					location.href = e.currentTarget.firstChild.href;
				}
			}
		}
	};

	handleMenuClick = (e: any) => {
		e.preventDefault();
		// Get the target menu.
		const id = e.target.getAttribute('data-state');

		// Set the new state.
		this.setState((prevState: any) => {
			const data: any = {};
			data[id] = !prevState[id];
			return data;
		});
	};

	handleLogoutClick = (e: any) => {
		this.toggleLogoutModal(false);
		e.preventDefault();

		delay(() => {
			clearUserData();
			window.location.href = generatePath(URI.login.base);
		}, 700);
	};

	toggleLogoutModal = (state: boolean) => {
		this.setState({
			showLogoutModal: state,
		});
	};

	changeStatus = () => {
		this.setState((prevState: State) => {
			prevState.isToggleTopMenu = false;
			return prevState;
		});
	};

	render(): JSX.Element {
		const { pathname } = window.location;

		return (
			<SecurityProvider>
				<SessionProvider>
					<Intercom />
					<Heap />
					<Datadog />
					<Chameleon />

					<AppNavigationComponent changeStatus={this.changeStatus}>
						<div className="nav-container">
							{this.state.inImpersonationMode && (
								<ImpersonationBanner userName={this.state.userName} />
							)}
							<Navbar bg="dark" className="topmenu-wrapper">
								<Navbar.Brand as={Link} to={'/'}>
									<img
										src={logo}
										width="100%"
										height="auto"
										className="d-inline-block align-top"
										alt="React Bootstrap logo"
									/>
								</Navbar.Brand>
								<Navbar.Toggle
									as={NavLink}
									to={URI.home}
									className={`menu-toggle ${
										this.state.isToggleTopMenu ? 'nav-open' : ''
									}`}
									data-state="isToggleTopMenu"
									onClick={this.handleClick}
								>
									<span className="menu-toggle-bar menu-toggle-bar--top"></span>
									<span className="menu-toggle-bar menu-toggle-bar--middle"></span>
									<span className="menu-toggle-bar menu-toggle-bar--bottom"></span>
								</Navbar.Toggle>
							</Navbar>
						</div>
						<div
							className={`sidemenu-wrapper ${
								this.isFixed ? 'fixed-left' : ''
							} ${this.state.isToggleTopMenu ? 'nav-open' : ''}`}
						>
							<Navbar
								bg="dark"
								expand="lg"
								variant="dark"
								className={`sidemenu ${
									this.state.inImpersonationMode ? 'sidemenu-impersonation' : ''
								} `}
							>
								<Navbar.Brand
									onClick={this.handleClick}
									as={Link}
									to={URI.home}
									className="d-flex w-100"
								>
									<Image
										src={Resource.Image.Logo.NavigationLogo}
										className="me-auto"
									></Image>
								</Navbar.Brand>
								<Nav className="side-nav">
									<NavLink
										to={URI.project.base}
										data-toggle="collapse"
										data-state="isToggleProjects"
										data-target="#isToggleProjects"
										aria-controls="isToggleProjects"
										onClick={this.handleMenuClick}
										data-testid="sidebar-projects-option"
										className={() =>
											[
												'nav-link fw-bold tw-pb-4',
												this.state.isToggleProjects ? 'nav-link-show' : null,
											]
												.filter(Boolean)
												.join(' ')
										}
									>
										<i className="ri-stack-fill fsx-24"></i>{' '}
										<span className="tw-pl-8">Projects</span>
									</NavLink>
									<Collapse timeout={0} in={this.state.isToggleProjects}>
										<ul className="nav-second-level">
											<li className="tw-text-xs tw-pt-2 tw-pb-2  tw-text-white tw-opacity-50">
												Project
											</li>
											<NavLink
												data-testid="sidebar-projects-list-option"
												to={URI.project.list}
												className={() => {
													const baseClassNames = 'nav-link fw-bold !tw-mb-0';

													if (pathname === URI.project.list) {
														return `${baseClassNames} active`;
													}
													return baseClassNames;
												}}
											>
												Project List
											</NavLink>
											<SecureNavLink
												attributeNo=""
												attributeType=""
												to={URI.item.clippedItem.base}
												className={({ isActive }: { isActive: boolean }) =>
													[
														isActive ? 'active' : null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
											>
												Clipped Items
											</SecureNavLink>
											<NavLink
												to={URI.item.search}
												className={({ isActive }) =>
													[
														isActive ? 'active' : null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
											>
												Item Search
											</NavLink>
											<NavLink
												to={URI.glossary.locations}
												className={({ isActive }) =>
													[
														isActive ? 'active' : null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
											>
												Locations
											</NavLink>

											<li className="tw-text-xs tw-pt-2 tw-pb-2  tw-text-white tw-opacity-50">
												Contacts
											</li>
											<SecureNavLink
												attributeNo={39}
												data-testid="sidebar-contacts-clients-option"
												attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
												to={URI.contact.client}
												className={({ isActive }: { isActive: boolean }) =>
													[
														isActive || isActiveMenu(URI.contact.client)
															? 'active'
															: null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
											>
												Clients
											</SecureNavLink>
											<SecureNavLink
												attributeNo={49}
												attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
												to={URI.contact.vendor}
												className={({ isActive }: { isActive: boolean }) =>
													[
														isActive || isActiveMenu(URI.contact.vendor)
															? 'active'
															: null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
											>
												Vendors
											</SecureNavLink>
											<SecureNavLink
												attributeNo={40}
												attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
												to={URI.contact.employee}
												className={({ isActive }: { isActive: boolean }) =>
													[
														isActive || isActiveMenu(URI.contact.employee)
															? 'active'
															: null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
											>
												Employees
											</SecureNavLink>
											<NavLink
												to={URI.glossary.shipToCodes}
												className={({ isActive }) =>
													[
														isActive ? 'active' : null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
											>
												Ship To Locations
											</NavLink>
										</ul>
									</Collapse>
									<NavLink
										to={URI.timeManagement.base}
										className={({ isActive }: { isActive: boolean }) =>
											[
												isActive ? 'active' : null,
												'nav-link fw-bold has-collapse tw-pb-4',
												this.state.isToggleTimeTracking
													? 'nav-link-show'
													: null,
											]
												.filter(Boolean)
												.join(' ')
										}
										data-toggle="collapse"
										data-state="isToggleTimeTracking"
										data-target="#isToggleTimeTracking"
										aria-controls="isToggleTimeTracking"
										aria-expanded={this.state.isToggleTimeTracking}
										onClick={this.handleMenuClick}
									>
										<i className="ri-timer-fill fsx-24"></i>{' '}
										<span className="tw-pl-8">Time Tracking</span>
									</NavLink>
									<Collapse timeout={0} in={this.state.isToggleTimeTracking}>
										<ul className="nav-second-level">
											<SecureNavLink
												attributeNo={15}
												attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
												to={URI.timeEntry.base}
												className={({ isActive }: { isActive: boolean }) =>
													[
														isActive || isActiveMenu(URI.timeEntry.base)
															? 'active'
															: null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
											>
												Time Entry
											</SecureNavLink>
											<NavLink
												to={URI.glossary.timeActivities}
												className={({ isActive }: { isActive: boolean }) =>
													[
														isActive ||
														isActiveMenu(URI.glossary.timeActivities)
															? 'active'
															: null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
											>
												Time Activities
											</NavLink>
											<SecureNavLink
												attributeNo={40}
												attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
												to={URI.contact.employee}
												className={({ isActive }: { isActive: boolean }) =>
													[
														isActive || isActiveMenu(URI.contact.employee)
															? 'active'
															: null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
											>
												Employees
											</SecureNavLink>
										</ul>
									</Collapse>
									<NavLink
										to={URI.accountsReceivable.designPay.base}
										className={({ isActive }) =>
											[isActive ? 'active' : null, 'nav-link fw-bold tw-pb-4']
												.filter(Boolean)
												.join(' ')
										}
									>
										<i className="ri-money-dollar-circle-fill fsx-24"></i>{' '}
										<span className="tw-pl-8">Design Pay</span>
									</NavLink>
									<NavLink
										to={URI.accountsReceivable.base}
										className={() =>
											[
												'nav-link fw-bold has-collapse tw-pb-4',
												this.state.isToggleAccounting ? 'nav-link-show' : null,
											]
												.filter(Boolean)
												.join(' ')
										}
										data-toggle="collapse"
										data-state="isToggleAccounting"
										data-target="#isToggleAccounting"
										aria-controls="isToggleAccounting"
										aria-expanded={this.state.isToggleAccounting}
										onClick={this.handleMenuClick}
									>
										<i className="ri-file-list-3-fill fsx-24"></i>
										<span className="tw-pl-8">Accounting</span>
									</NavLink>
									<Collapse timeout={0} in={this.state.isToggleAccounting}>
										<ul className="nav-second-level">
											<li className="tw-text-xs tw-pt-2 tw-pb-2  tw-whitespace-nowrap tw-text-white tw-opacity-50">
												Accounts Receivable
											</li>
											<SecureNavLink
												attributeNo={26}
												attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
												to={URI.accountsReceivable.clientInvoices.listNew}
												className={({ isActive }: { isActive: boolean }) =>
													[
														isActive ||
														isActiveMenu(
															URI.accountsReceivable.clientInvoices.listNew
														)
															? 'active'
															: null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
											>
												Client Invoices
											</SecureNavLink>
											<SecureNavLink
												attributeNo={28}
												attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
												to={URI.accountsReceivable.cashReceipt.listNew}
												className={({ isActive }: { isActive: boolean }) =>
													[
														isActive ||
														isActiveMenu(
															URI.accountsReceivable.cashReceipt.listNew
														)
															? 'active'
															: null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
											>
												Cash Receipts
											</SecureNavLink>
											<SecureNavLink
												attributeNo={194}
												attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
												to={URI.accountsReceivable.returnsAndCredit.base}
												className={({ isActive }: { isActive: boolean }) =>
													[
														isActive ||
														isActiveMenu(
															URI.accountsReceivable.returnsAndCredit.base
														)
															? 'active'
															: null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
											>
												Returns & Credits
											</SecureNavLink>
											<li className="tw-text-xs tw-pt-2 tw-pb-2  tw-whitespace-nowrap tw-text-white tw-opacity-50">
												Accounts Payable
											</li>
											<NavLink
												to={URI.accountsPayable.vendorInvoice.listNew}
												className={({ isActive }: { isActive: boolean }) =>
													[
														isActive ||
														isActiveMenu(
															URI.accountsPayable.vendorInvoice.listNew
														)
															? 'active'
															: null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
											>
												Vendor Deposits & Invoices
											</NavLink>
											<NavLink
												to={URI.accountsPayable.transactions}
												className={({ isActive }: { isActive: boolean }) =>
													[
														isActive ||
														isActiveMenu(URI.accountsPayable.transactions)
															? 'active'
															: null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
											>
												Bank Data Import
											</NavLink>
											<NavLink
												to={URI.accountsPayable.payment.base}
												className={({ isActive }: { isActive: boolean }) =>
													[
														isActive ||
														isActiveMenu(URI.accountsPayable.payment.base)
															? 'active'
															: null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
											>
												Payments
											</NavLink>
											<NavLink
												to={URI.accountsPayable.checkbook.base}
												className={({ isActive }: { isActive: boolean }) =>
													[
														isActive ||
														isActiveMenu(URI.accountsPayable.checkbook.base)
															? 'active'
															: null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
											>
												Checkbook
											</NavLink>
											<NavLink
												to={URI.accountsPayable.creditCard.base}
												className={({ isActive }: { isActive: boolean }) =>
													[
														isActive ||
														isActiveMenu(URI.accountsPayable.creditCard.base)
															? 'active'
															: null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
												end={true}
											>
												Credit Card
											</NavLink>
											<li className="tw-text-xs tw-pt-2 tw-pb-2  tw-whitespace-nowrap tw-text-white tw-opacity-50">
												General Ledger
											</li>
											<SecureNavLink
												attributeNo={31}
												attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
												to={URI.generalLedger.account.list}
												className={({ isActive }: { isActive: boolean }) =>
													[
														isActive ||
														isActiveMenu(URI.generalLedger.account.list)
															? 'active'
															: null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
											>
												Accounts
											</SecureNavLink>
											<NavLink
												to={URI.generalLedger.transactionSearch.list}
												className={({ isActive }) =>
													[
														isActive ? 'active' : null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
											>
												Transaction Search
											</NavLink>
											<SecureNavLink
												attributeNo={32}
												attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
												to={URI.generalLedger.journalEntry.listNew}
												className={({ isActive }: { isActive: boolean }) =>
													[
														isActive ||
														isActiveMenu(URI.generalLedger.journalEntry.listNew)
															? 'active'
															: null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
											>
												Journal Entries
											</SecureNavLink>
											<NavLink
												to={URI.glossary.salesCategories}
												className={({ isActive }) =>
													[
														isActive ? 'active' : null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
											>
												Sales Categories
											</NavLink>
											<NavLink
												to={URI.glossary.salesTaxCodesList}
												className={({ isActive }) =>
													[
														isActive ? 'active' : null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
											>
												Sales Tax Codes
											</NavLink>
										</ul>
									</Collapse>
									<NavLink
										to={URI.stockItem.base}
										data-toggle="collapse"
										data-state="isToggleInventory"
										data-target="#isToggleInventory"
										aria-controls="isToggleInventory"
										onClick={this.handleMenuClick}
										data-testid="sidebar-inventory-option"
										className={() =>
											[
												'nav-link fw-bold',
												this.state.isToggleInventory ? 'nav-link-show' : null,
											]
												.filter(Boolean)
												.join(' ')
										}
									>
										<i className="ri-briefcase-4-fill fsx-24"></i>{' '}
										<span className="tw-pl-8">Inventory</span>
									</NavLink>
									<Collapse timeout={0} in={this.state.isToggleInventory}>
										<ul className="nav-second-level">
											<NavLink
												to={URI.stockItem.base}
												className={({ isActive }) =>
													[
														isActive ? 'active' : null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
											>
												Stock Items
											</NavLink>
											<NavLink
												to={URI.glossary.shipToCodes}
												className={({ isActive }) =>
													[
														isActive ? 'active' : null,
														'nav-link fw-bold !tw-mb-0',
													]
														.filter(Boolean)
														.join(' ')
												}
											>
												Ship To Locations
											</NavLink>
										</ul>
									</Collapse>
								</Nav>

								<Nav className="side-nav at-bottom">
									<ul className="tw-flex tw-justify-center tw-p-0">
										<OverlayTrigger
											overlay={
												<Tooltip style={{ zIndex: 20000 }}>Reporting</Tooltip>
											}
										>
											<li>
												<SecureNavLink
													attributeNo={67}
													attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
													to={URI.report.base}
													className={({ isActive }: { isActive: boolean }) =>
														[
															isActive ? 'active' : null,
															'nav-link fw-bold',
															'tw-p-4',
														]
															.filter(Boolean)
															.join(' ')
													}
												>
													<i className="ri-file-chart-fill fsx-24 !tw-m-0 !tw-static"></i>
												</SecureNavLink>
											</li>
										</OverlayTrigger>
										<OverlayTrigger
											placement="top"
											overlay={
												<Tooltip style={{ zIndex: 20000 }}>Settings</Tooltip>
											}
										>
											<li className="tw-flex tw-justify-center">
												<NavLink
													data-testid="sidebar-settings-option"
													to={URI.settings.list}
													className={({ isActive }) =>
														[
															isActive ? 'active' : null,
															'nav-link fw-bold tw-p-4',
														]
															.filter(Boolean)
															.join(' ')
													}
												>
													<i className="ri-settings-4-fill fsx-24 !tw-m-0 !tw-static"></i>
												</NavLink>
											</li>
										</OverlayTrigger>
										<OverlayTrigger
											overlay={
												<Tooltip style={{ zIndex: 20000 }}>Help</Tooltip>
											}
										>
											<li>
												<a
													href="/help"
													onClick={(e) => e.preventDefault()}
													className="intercom-help-button nav-link fw-bold tw-p-4"
												>
													<i className="ri-customer-service-fill fsx-24 !tw-m-0 !tw-static"></i>
												</a>
											</li>
										</OverlayTrigger>
									</ul>
									<AccountSwitcherDropdown
										onLogout={() => {
											this.toggleLogoutModal(true);
										}}
									/>
								</Nav>
							</Navbar>
						</div>
						<div
							className={`main-content ${
								this.state.inImpersonationMode ? 'impersonating' : ''
							}`}
						>
							<TrailDemoBanner />
							<SuperHeader />
							<Outlet />
						</div>
						<div id="response-alert-div"></div>

						<ConfirmModal
							title="Logout Confirm"
							message={`Are you sure you want to logout as ${this.dmUserName}?`}
							labelOK="Logout"
							labelCancel="Cancel"
							show={this.state.showLogoutModal}
							toggleModal={this.toggleLogoutModal.bind(this, false)}
							confirmAction={this.handleLogoutClick.bind(this)}
						/>
					</AppNavigationComponent>
				</SessionProvider>
			</SecurityProvider>
		);
	}
}

export default AppLayout;
