import React from 'react';
import { Link } from 'react-router-dom';
import {
	Button,
	Col,
	Form,
	Row,
	Table,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import {
	getActiveMenuUri,
	routeParam,
	WithRouter,
} from '../../../../helpers/Router';
import ListFilter from '../../../components/ListFilter';
import { isFiltered, getStatusColor } from '../../../../helpers/Util';
import DatePicker from 'react-datepicker';
import InputBar from '../../../components/InputBar';
import {
	getLocalStorage,
	setLocalStorage,
} from '../../../../utilities/LocalStorage';
import {
	FormEvent,
	setDraft,
	setLocalStorageKeyValueObject,
} from '../../../../utilities/FormEvent';
import ProjectProposalAddProposalAddItemModal from './ProjectProposalAddProposalAddItemModal';
import Select from 'react-select';
import noItems from '../../../../assets/images/icons/item.svg';
import URI from '../../../../defaults/RoutesDefault';
import { ApiService } from '../../../../lib/api/HttpService';
import { currencyFormat } from '../../../../helpers/Number';
import SendProposalModal from '../../modal/SendProposalModal';
import { displayAlert } from '../../../../utilities/Response';
import { showEmpty, showLoading } from '../../../../helpers/Loading';
import { addDomClass, hasClass, removeDomClass } from '../../../../helpers/DOM';
import debounce from 'lodash/debounce';
import FilterSort from '../../../../utilities/modules/FilterSort';
import dayjs from 'dayjs';
import { SearchExpressionButton } from '../../../components/SearchExpressionButton';
import { sortLocalData } from '../../../../utilities/modules/TableSorting';
import { tableSearch } from '../../../../utilities/modules/TableSearch';
import { getCheckAllClass } from '../../../../utilities/ModuleHelper';
import { stringToDate } from '../../../../helpers/Date';
import loadItemImages, { renderItemImage } from '../../../../utilities/Images';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security';
import SecureLink from '../../../../app/components/security/SecureLink';
import round from 'lodash/round';

function truncateCharCount(text, max) {
	if (text && text.length > max) {
		let result = '';
		const words = text.split(' ');
		for (let i = 0; i < words.length; i++) {
			result += ' ' + words[i];
			if (result.length >= max) {
				break;
			}
		}
		if (result.length < text.length) {
			result += '...';
		}
		return result;
	}
	return text;
}

class ProjectProposalAddProposal extends ThreadComponent {
	constructor(props) {
		super(props);

		const propEdit = getLocalStorage('project_proposal_edit', true);
		this.fs = new FilterSort('project_proposal_add_item');
		this.state = {
			checks: {},
			totalChecked: 0,
			activeMenu: getActiveMenuUri(
				this.props.paths.pathname,
				['proposal', 'remarks', 'additional-deposit', 'layout'],
				'proposal'
			),
			totalPrice: 0,
			freight: 0,
			salesTax: 0,
			requestedDeposit: 0,
			totalEstimated: 0,
			minDate:
				stringToDate(propEdit.adddepdt || this.props.proposal.propdate) ??
				new Date(),
			proposal: {
				propname: this.props.proposal.propname ?? '',
			},
			showModal: false,
			addItemData: [],
			proposalTypeDefault: null,
			notification: null,
			showProposalModal: false,
			sendProposalData: {
				token: '',
				link: '',
				contact: '',
				address: {},
			},
			project: {},
			company: {},
			searchProperties: {},
			isSortEnabled: false,
			page: 1,
			pageSize: { value: 10, label: '10' },
			dataActiveItems: [],
			processedData: [],
			images: [],
			imagesLoaded: false,
			statusCodes: this.props.statusCodes || [],
		};

		this.availablePageSize = [
			{ value: '10', label: '10' },
			{ value: '20', label: '20' },
			{ value: '50', label: '50' },
			{ value: '75', label: '75' },
			{ value: '100', label: '100' },
		];
		this.changePageHandler = debounce(this.handleChangePage.bind(this, 1), 200);

		this.formEvent = new FormEvent(`project_proposal_edit`);

		this.api = new ApiService();
	}

	async componentDidMount() {
		window.imageData = {};
		this.setTitle('Add Proposal').setFormAction(true);
		const currentDate = dayjs(new Date()).format('YYYY-MM-DD');

		this.formEvent.handleDateChange('YYYY-MM-DD', 'propdate', currentDate);

		const company = await this.api.get('company', '');
		this.setState({
			company: company,
		});

		const project = await this.api.get(
			'projects',
			'?$filter=id eq ' + this.props.params.id
		);

		const getAddresses = await this.api.getAddresses(
			`?$filter=code eq '${this.props.proposalObj?.proj}' and addressType eq 0`
		);

		await this.paginateLocalData(sortLocalData(this, this.getTheData()));
		this.setState((prevState) => ({
			sendProposalData: {
				...prevState.sendProposalData,
				address: getAddresses,
			},
			project: project[0],
		}));

		if (Object.keys(this.props.proposal).length > 0) {
			let proposal = this.props.proposal || {};
			let notification = null;
			if (proposal.lastNotificationId) {
				notification = await this.api.getNotification(
					proposal.lastNotificationId
				);

				const data = await this.api.get(
					'useraccounts',
					`?$filter=usercode eq ${notification.userCode}`
				);
				notification.username = data[0]['username'];

				if (notification.sentInfo === null) {
					notification.sentInfo = JSON.parse(
						notification.notificationPayload
					).To.join(',');
				}
			}

			const propEdit = getLocalStorage('project_proposal_edit', true);
			let minDate = proposal.propdate
				? stringToDate(propEdit.adddepdt || proposal.propdate)
				: new Date();

			this.setState({
				proposal,
				minDate,
				proposalTypeDefault: this.props.proposalTypeDefault,
				notification: notification,
			});
		}

		this.getProposalTypeDefault();

		this.setPrices();

		const fromQuickCreate = new URLSearchParams(window.location.search).get(
			'isQuickCreate'
		);
		const preData = getLocalStorage('preData', true);
		if (fromQuickCreate && preData !== null && preData.length) {
			this.handleAddItem(preData);
		}

		this.enableSortTable();
	}

	componentDidUpdate(previousProps, previousState) {
		this.formEvent.handleDateChange(
			'YYYY-MM-DD',
			'propdate',
			this.state.minDate
		);

		if (
			previousProps.proposal !== this.props.proposal ||
			previousProps.proposalTypeDefault !== this.props.proposalTypeDefault
		) {
			let proposal = this.props.proposal || {};
			this.setState({
				proposal,
				minDate: this.state.minDate,
				proposalTypeDefault: this.props.proposalTypeDefault,
			});

			this.getProposalTypeDefault();

			//setTimeout(() => { this.checkSelected() }, 50)
			this.setPrices();
			this.enableSortTable();
			this.paginateLocalData(sortLocalData(this, this.getTheData()));
		}
	}

	async loadThumbnails(data) {
		if (!this.state.imagesLoaded)
			loadItemImages({
				data,
				property: 'primaryImageId',
				callback: (imageData) => {
					this.setState({
						imagesLoaded: true,
					});
					window.imageData = {
						...imageData,
						...window.imageData,
					};
				},
			});
	}

	loadModalImages(imageData) {
		window.imageData = {
			...imageData,
			...window.imageData,
		};
	}

	getTheData() {
		return isFiltered(this.state.searchProperties)
			? this.props.filteredActiveItems
			: this.props.activeItems || [];
	}

	paginateSearchSortData = () =>
		tableSearch({
			data: this.props.activeItems,
			searchProps: this.state.searchProperties,
			callback: async (data) => {
				await this.paginateLocalData(sortLocalData(this, data));
			},
		});

	paginateLocalData = async (data) => {
		const newData = (data ?? []).slice(
			(this.state.page - 1) * this.state.pageSize.value,
			this.state.page * this.state.pageSize.value
		);

		this.loadThumbnails(data);

		this.setState({
			dataActiveItems: newData,
			processedData: data ?? [],
		});
	};

	checkSelected() {
		const ids = getLocalStorage('project_proposal_edit_checked_item', true);
		let totalChecked = 0,
			checks = {};
		if (ids && ids.length) {
			for (let id of ids) {
				const chk = document.querySelector(
					`.chk-projectview-proposal-item input[data-id="${id}"]`
				);
				if (chk) {
					chk.checked = true;
					totalChecked++;
					checks[id] = true;
				}
			}

			this.setState({
				totalChecked,
				checks,
			});
		}
	}

	handleShowTableSearch = (e) => {
		const filters = document.querySelectorAll('.a-table-search-fields input');
		if (filters) {
			filters.forEach((e) => {
				e.value = '';
			});
		}

		let newTableSearch = !this.state.showTableSearch;
		this.setState({
			showTableSearch: newTableSearch,
		});

		if (JSON.stringify(this.state.searchProperties) !== '{}') {
			this.setState(
				{
					searchProperties: {},
				},
				this.changePageHandler
			);
		}
	};

	setPrices() {
		const items = this.props.activeItems;

		const totalPrice = items.reduce(
			(total, item) => total + item.proposalPrice,
			0
		);
		const salesTax = items.reduce((total, item) => total + item.propsTax, 0);
		const freight = items.reduce(
			(total, item) =>
				total +
				item.estdprice +
				item.estfprice +
				item.estiprice +
				item.estlprice +
				item.estoprice,
			0
		);
		const requestedDeposit = items.reduce(
			(total, item) => total + item.estdep,
			0
		);
		const totalEstimated = round(
			round(totalPrice, 2) + round(salesTax, 2) + round(freight, 2),
			2
		);
		const roundedDeposit = round(requestedDeposit, 2);
		const calculatedDeposit =
			totalEstimated - requestedDeposit <= 1 ? totalEstimated : roundedDeposit;

		this.setState({
			totalPrice: round(totalPrice, 2),
			salesTax: round(salesTax, 2),
			freight: round(freight, 2),
			totalEstimated: this.props.proposalToEdit
				? round(this.props.proposalToEdit?.[0]?.totalProposed, 2)
				: totalEstimated,
			requestedDeposit: this.props.proposalToEdit
				? round(this.props.proposalToEdit?.[0]?.requestedDeposit, 2)
				: calculatedDeposit,
		});
	}

	updatePricesToStorage() {
		const { totalPrice, freight, salesTax, requestedDeposit } = this.state;
		const totalProposed = totalPrice + freight + salesTax;
		this.syncToStorage(
			'proposal',
			(this.props.proposal || {}).id,
			'totalProposed',
			totalProposed
		);
		this.syncToStorage(
			'proposal',
			(this.props.proposal || {}).id,
			'requestedDeposit',
			requestedDeposit
		);
	}

	updateItemList() {
		const checks = document.querySelectorAll(
			'.chk-projectview-proposal-item input[checked], .chk-projectview-proposal-item input:checked'
		);
		let itemCodes = [];
		let itemIds = [];
		if (checks.length) {
			for (let i = 0; i < checks.length; i++) {
				const chk = checks[i];
				chk.checked = true;
				itemCodes.push(chk.dataset.item);
				itemIds.push(chk.dataset.id);
			}
		}

		setLocalStorage('project_proposal_edit_checked_item', itemIds, true);

		setLocalStorageKeyValueObject(
			'project_proposal_edit',
			'itemNoArray',
			itemCodes
		);
	}

	handleSelectAllChecks = (e) => {
		// Get the target menu.
		const isChecked = e.target.checked;

		this.setState((prevState) => {
			if (isChecked) {
				if (this.state.totalChecked < this.state.dataActiveItems.length) {
					let checkedIds = [];
					let checkedItemNo = [];
					this.state.dataActiveItems.map((item, i) => {
						prevState.checks[item.id] = true;
						checkedIds.push(item.id);
						checkedItemNo.push(item.item);

						let elem = document.getElementById('chk-projectview-proposal-' + i);
						if (elem) {
							document.getElementById(
								'chk-projectview-proposal-' + i
							).checked = true;
						}
					});

					setLocalStorage(
						'project_proposal_edit_checked_item',
						checkedIds,
						true
					);
					setLocalStorageKeyValueObject(
						'project_proposal_edit',
						'itemNoArray',
						checkedItemNo
					);
				}
				prevState.totalChecked = this.state.dataActiveItems.length;
			} else {
				prevState.checks = {};
				prevState.totalChecked = 0;

				const checks = document.querySelectorAll(
					'.chk-projectview-proposal-item input'
				);
				if (checks) {
					checks.forEach((e) => {
						e.checked = false;
					});
				}

				setLocalStorage('project_proposal_edit_checked_item', [], true);
				setLocalStorageKeyValueObject(
					'project_proposal_edit',
					'itemNoArray',
					[]
				);
			}

			return prevState;
		});
	};

	handleChecks = (e) => {
		// Get the target menu.
		const indx = e.target.getAttribute('data-id');
		const isChecked = e.target.checked;
		// Set the new state.
		this.setState((prevState) => {
			let totalChecked = 0;
			prevState.checks[indx] = isChecked;

			if (prevState.checks) {
				/* eslint-disable no-unused-vars */
				for (const [key, value] of Object.entries(prevState.checks)) {
					if (value) {
						totalChecked++;
					}
				}
				/* eslint-enable no-unused-vars */
			}

			prevState.totalChecked = totalChecked;

			if (totalChecked > 0) {
				document.getElementById('inline-radio-th-0').checked = true;
			} else {
				document.getElementById('inline-radio-th-0').checked = false;
			}

			return prevState;
		});

		setDraft('true');

		this.updateItemList();
		this.updatePricesToStorage();
	};

	handleDateChange = (name, date) => {
		this.setState({
			[name]: date,
		});

		if (date !== null) {
			this.formEvent.handleDateChange('YYYY-MM-DD', 'propdate', date);
			this.formEvent.handleDateChange('YYYY-MM-DD', 'adddepdt', date);
		}

		setDraft('true');
	};

	handleProposalTypeChange = (select, e) => {
		setLocalStorageKeyValueObject(
			'project_proposal_edit',
			'propStyle',
			select.value,
			true
		);

		setLocalStorageKeyValueObject(
			'project_proposal_edit',
			'reportFormatId',
			select.value,
			true
		);

		setLocalStorage('project_proposal_edit_proposal_type', select, true);

		setDraft('true');
	};

	checkChange = (type, id, e, select) => {
		let key = '';
		let value = '';

		if (select) {
			key = e.name;
			value = select.value;
		} else if (e.target.getAttribute('type') === 'checkbox') {
			key = e.target.name;
			value = e.target.checked ? true : false;
		} else {
			key = e.target.name;
			value = e.target.value;
		}

		this.syncToStorage(type, id, key, value);
	};

	syncToStorage = (type, id, key, value) => {
		let data = getLocalStorage(type, true);
		data['id'] = id;
		data[key] = value;
		setLocalStorage(type, data, true);
	};

	displayModalItems = (e) => {
		this.setState({
			showModal: true,
		});

		e.preventDefault();
	};

	handleAddItem = (ids) => {
		if (this.props.addItemCall) {
			this.props.addItemCall(ids);

			this.setState({
				showModal: false,
			});

			this.handleCheckState();
		}
	};

	handleRemoveItem = () => {
		if (this.props.removeItemCall) {
			let ids = [];
			/* eslint-disable no-unused-vars */
			for (const [key, value] of Object.entries(this.state.checks)) {
				if (value) {
					ids.push(parseInt(key));
				}
			}
			/* eslint-enable no-unused-vars */

			this.props.removeItemCall(ids);
			this.handleCheckState();
			this.setState({
				totalChecked: 0,
				checks: {},
			});
		}
	};

	handleCheckState() {
		const chkAll = document.querySelectorAll(
			'.chk-projectview-proposal-item input'
		);
		if (chkAll) {
			setTimeout(() => {
				for (let chk of chkAll) {
					chk.checked = false;
				}
			}, 100);
		}
	}

	getProposalTypeDefault() {
		const propsalTypeDefault = getLocalStorage(
			'project_proposal_edit_proposal_type',
			true
		);

		if (propsalTypeDefault) {
			setLocalStorageKeyValueObject(
				'project_proposal_edit',
				'propStyle',
				propsalTypeDefault.value,
				true
			);

			setLocalStorageKeyValueObject(
				'project_proposal_edit',
				'reportFormatId',
				propsalTypeDefault.value,
				true
			);

			this.setState({
				proposalTypeDefault: propsalTypeDefault,
			});
		}
	}

	async handleDocument(e) {
		e.preventDefault();

		let document = await this.api.postJson('publicmappingbatches', {
			publicMappingObjects: [
				{
					objectId: this.props.params.pid,
					objectType: 'Proposal',
				},
			],
		});

		window.open(document.link, '_blank').focus();
	}

	async handleOpenFile(e) {
		e.preventDefault();

		const { fileId } = this.props.proposalObj;
		await this.api.getFile(fileId).then((blob) => {
			var file = window.URL.createObjectURL(blob);
			window.open(file, '_blank').focus();
		});
	}

	openProposalModal = async () => {
		try {
			const postData = await this.api.postJson('publicmappingbatches', {
				publicMappingObjects: [
					{
						objectId: this.props.params.pid,
						objectType: 'Proposal',
					},
				],
			});

			this.setState((prevState) => ({
				showProposalModal: true,
				sendProposalData: {
					...prevState.sendProposalData,
					link: postData.link,
					token: postData.token,
				},
			}));
		} catch (error) {
			displayAlert('danger', 'No document for this invoice.');
		}
	};

	closeProposalModal = () => {
		this.setState({ showProposalModal: false });
	};

	renderMessage = () => {
		const calculatedDeposit =
			this.state.proposal.requestedDeposit -
			this.state.proposal.receivedDeposit;
		return [
			`Proposal ${this.state.project.proj}-${this.props.proposalObj.propnum} ${
				this.props.proposalObj.propname
			}, Deposit $${
				calculatedDeposit < 0 ? '0.00' : calculatedDeposit.toFixed(2)
			}`,
		];
	};

	handleSearch = (e) => {
		const key = e.target.attributes['data-field'].value;
		const value = e.target.value;
		const type = e.target.attributes['data-type']?.value ?? 'string';
		const exp = e.target.attributes['placeholder']?.value ?? '>=';

		const searchPropertiesCopy = {
			...this.state.searchProperties,
			[key]: { value, type, exp },
		};

		let searchProperties = {};

		if (Object.keys(searchPropertiesCopy).length) {
			for (const k of Object.keys(searchPropertiesCopy)) {
				if (searchPropertiesCopy[k]?.value?.toString()?.length) {
					searchProperties[k] = searchPropertiesCopy[k];
				}
			}
		}

		this.setState({
			searchProperties,
		});

		this.props.filterItems(searchProperties);
	};

	enableSortTable() {
		const sort = document.querySelectorAll('.a-table-heading .sort');

		if (sort && !this.state.isSortEnabled) {
			sort.forEach((_e) => {
				_e.addEventListener('click', (e) => {
					sort.forEach((_e2) => {
						if (_e !== _e2) {
							removeDomClass('desc', _e2);
							removeDomClass('asc', _e2);
							removeDomClass('active', _e2);
						}
					});

					addDomClass('active', _e);

					if (hasClass('desc', _e)) {
						removeDomClass('desc', _e);
						addDomClass('asc', _e);
					} else if (hasClass('asc', _e)) {
						removeDomClass('asc', _e);
						addDomClass('desc', _e);
					} else {
						addDomClass('desc', _e);
					}

					const sortProperty = `${_e.attributes['data-field'].value} ${
						hasClass('desc', _e) ? 'asc' : 'desc'
					}`;

					// Save sortProperty to localStorage
					this.fs.setSort(sortProperty);

					if (this.props.sortItems) {
						this.props.sortItems(sortProperty);
					}
				});
			});

			this.setState({
				isSortEnabled: true,
			});
		}
	}

	handleChangePage = async (page) => {
		this.setState(
			{
				page,
			},
			this.paginateSearchSortData
		);
	};

	handleFilter = (name) => (e) => {
		this.setState(
			{
				[name]: e,
				page: 1,
			},
			this.changePageHandler
		);
	};

	sortClass(name) {
		return `sort ${this.fs.isActiveSort(name)}`;
	}

	isNextDisabled() {
		const { processedData, page, pageSize } = this.state;
		const total = page * pageSize.value;

		return Number(processedData?.length) <= total;
	}

	renderInfo() {
		if (this.props.params.pid) {
			return (
				<Row className="w-100 mt-3 pr-0">
					<Col lg={5} className="p-0">
						<strong className="fw-bold">Payment Information</strong>
						<br />
						Requested:
						{currencyFormat(this.props.proposal.requestedDeposit, '$')}
						<br />
						Received:
						{this.props.proposal.receivedDeposit > 0
							? currencyFormat(this.props.proposal.receivedDeposit, '$') +
							  ' on ' +
							  dayjs(
									this.props.proposal.lastPaymentDate?.substr(0, 10)
							  ).format('MM/DD/YYYY')
							: currencyFormat(this.props.proposal.receivedDeposit, '$')}
					</Col>
					<Col lg={4} className="py-2 px-0">
						<strong className="fw-bold">Sending Information</strong>
						<br />
						{this.state.notification
							? 'Sent to ' + this.state.notification.sentInfo
							: 'Not sent'}
						<br />
						{this.state.notification
							? 'On ' +
							  dayjs(
									this.state.notification.sentDateTimeUtc?.substr(0, 10)
							  ).format('MM/DD/YYYY') +
							  ' by ' +
							  this.state.notification.username
							: ''}
					</Col>
					<Col lg={3} className="px-0 mx-0">
						<div className="d-flex justify-content-end">
							<a
								href=""
								className="text-black text-decoration-none mx-3"
								onClick={(e) => {
									this.handleDocument(e);
								}}
							>
								<i className="ri-external-link-line fsx-20"></i>
							</a>
							<a
								href=""
								className="text-black text-decoration-none"
								onClick={(e) => {
									this.handleOpenFile(e);
								}}
							>
								<i className="ri-file-transfer-line fsx-20"></i>
							</a>
						</div>
					</Col>
				</Row>
			);
		}
	}

	renderListFilter() {
		return (
			<>
				<ListFilter
					className="project-proposal-add-proposal-filter"
					breakpoint={1600}
				>
					<ListFilter.Fields md={12} lg={12} className="width-25">
						<ListFilter.Field>
							<Form.Label className="text-end">
								<strong>
									Name/
									<br />
									Phase
								</strong>
							</Form.Label>
							<Form.Control
								size="sm"
								placeholder=""
								defaultValue={this.state.proposal.propname}
								name="propname"
								onChange={this.formEvent.handleTextChange.bind(this)}
							/>
						</ListFilter.Field>
						<ListFilter.Field>
							<Form.Label className="text-end">
								<strong>Date</strong>
							</Form.Label>
							<div className="react-select-header">
								<DatePicker
									selected={this.state.minDate}
									onChange={this.handleDateChange.bind(this, 'minDate')}
									showMonthDropdown="true"
									showYearDropdown="true"
									name="propdate"
									className="form-control form-control-sm"
									placeholderText="Select"
								/>
							</div>
						</ListFilter.Field>
						<ListFilter.Field>
							<Form.Label className="text-end">
								<strong>Style</strong>
							</Form.Label>
							<Select
								key={`${Math.floor(Math.random() * 10000)}-min`}
								options={this.props.proposalObj.proposalTypes}
								className="react-select react-select-sm react-select-full-width"
								placeholder="Please select"
								name="propStyle"
								defaultValue={this.state.proposalTypeDefault}
								onChange={this.handleProposalTypeChange.bind(this)}
							/>
						</ListFilter.Field>
						{this.props.params.pid ? (
							<ListFilter.Field className="justify-content-end pe-lg-0">
								<Button
									variant="primary"
									size="sm"
									className="btn-icon"
									onClick={this.openProposalModal}
									disabled={!this.state.dataActiveItems.length}
								>
									Send Proposal
								</Button>
							</ListFilter.Field>
						) : (
							<div></div>
						)}
						{this.renderInfo()}
					</ListFilter.Fields>
					<ListFilter.Actions md={12} lg={12}>
						<ListFilter.Action>
							{(() => {
								if (this.props.params.pid) {
									return (
										<Button
											as={Link}
											to="#"
											variant="ivory"
											size="sm"
											className={`btn-icon btn-action ${
												this.state.showTableSearch
													? 'bg-primary-ash text-white'
													: ''
											}`}
											onClick={this.handleShowTableSearch}
										>
											<i className="ri-search-line"></i> Search
										</Button>
									);
								}
							})()}

							{this.state.totalChecked > 0 ? (
								<Button
									disabled={false}
									variant="primary"
									size="sm"
									className="btn-icon ms-3"
									onClick={this.handleRemoveItem.bind(this)}
								>
									Remove Item
								</Button>
							) : (
								<OverlayTrigger
									overlay={
										<Tooltip id="button-tooltip">
											Please tag an item to remove
										</Tooltip>
									}
								>
									<span className="d-inline-block">
										<Button
											disabled={true}
											variant="ivory"
											size="sm"
											className="btn-icon ms-3"
											onClick={this.handleRemoveItem.bind(this)}
											style={{ pointerEvents: 'none' }}
										>
											Remove Item
										</Button>
									</span>
								</OverlayTrigger>
							)}
							<Button
								variant="primary"
								size="sm"
								className="btn-icon ms-3"
								onClick={this.displayModalItems.bind(this)}
							>
								Add Item
							</Button>
						</ListFilter.Action>
					</ListFilter.Actions>
				</ListFilter>
				<ListFilter.Spacer />
			</>
		);
	}

	renderInputBar() {
		return (
			<InputBar className="large">
				<InputBar.Links className="full-width">
					<InputBar.Link>
						<Form.Label htmlFor="inputPassword5" className="ilabel">
							Total Price
						</Form.Label>
						<div className="form-group-extra reversed">
							<Form.Control
								type="text"
								placeholder="0.00"
								value={this.state.totalPrice}
								size="sm"
								readOnly
								disabled={true}
							/>
							<span>$</span>
						</div>
					</InputBar.Link>
					<InputBar.Link style={{ width: '20px', padding: '0px' }}>
						<i className="ri-add-line fsx-20 text-secondary-ash"></i>
					</InputBar.Link>
					<InputBar.Link>
						<Form.Label htmlFor="inputPassword5" className="ilabel">
							Freight/Fees/Etc.
						</Form.Label>
						<div className="form-group-extra reversed">
							<Form.Control
								type="text"
								placeholder="0.00"
								value={this.state.freight}
								size="sm"
								readOnly
								disabled={true}
							/>
							<span>$</span>
						</div>
					</InputBar.Link>
					<InputBar.Link style={{ width: '20px', padding: '0px' }}>
						<i className="ri-add-line fsx-20 text-secondary-ash"></i>
					</InputBar.Link>
					<InputBar.Link>
						<Form.Label htmlFor="inputPassword5" className="ilabel">
							Sales Tax
						</Form.Label>
						<div className="form-group-extra reversed">
							<Form.Control
								type="text"
								placeholder="0.00"
								value={this.state.salesTax}
								size="sm"
								readOnly
								disabled={true}
							/>
							<span>$</span>
						</div>
					</InputBar.Link>
					<InputBar.Link style={{ width: '20px', padding: '0px' }}>
						<span className="fsx-20 text-secondary-ash">=</span>
					</InputBar.Link>
					<InputBar.Link>
						<Form.Label htmlFor="inputPassword5" className="ilabel">
							Total Estimated
						</Form.Label>
						<div className="form-group-extra reversed">
							<Form.Control
								type="text"
								placeholder="0.00"
								value={this.state.totalEstimated}
								size="sm"
								readOnly
								disabled={true}
							/>
							<span>$</span>
						</div>
					</InputBar.Link>
					<InputBar.Link>
						<Form.Label htmlFor="inputPassword5" className="ilabel">
							Requested Deposit
						</Form.Label>
						<div className="form-group-extra reversed">
							<Form.Control
								type="text"
								placeholder="0.00"
								value={this.state.requestedDeposit}
								size="sm"
								readOnly
								disabled={true}
							/>
							<span>$</span>
						</div>
					</InputBar.Link>
				</InputBar.Links>
			</InputBar>
		);
	}

	renderPagination() {
		if (this.state.dataActiveItems.length) {
			return (
				<div className={'d-flex flex-wrap justify-content-center'}>
					<div className={'d-flex flex-row align-items-center pagination'}>
						<Button
							variant="ivory"
							size="sm"
							className={'btn-icon pagination-btn'}
							disabled={this.state.page === 1}
							onClick={() => this.handleChangePage(this.state.page - 1)}
						>
							<i className="ri-arrow-left-s-line"></i> Previous
						</Button>
						<span className={'pagination-span'}>{this.state.page}</span>
						<Button
							variant="ivory"
							size="sm"
							className={'btn-icon pagination-btn'}
							disabled={this.isNextDisabled()}
							onClick={() => this.handleChangePage(this.state.page + 1)}
						>
							Next <i className="ri-arrow-right-s-line ms-1"></i>
						</Button>
					</div>
					<Select
						onChange={this.handleFilter('pageSize')}
						key={`${Math.floor(Math.random() * 1000)}-min`}
						options={this.availablePageSize}
						defaultValue={this.state.pageSize}
						className="react-select pagination-select mx-3"
						placeholder="Please select"
					/>
				</div>
			);
		}
	}

	renderContent() {
		const { imagesLoaded, statusCodes } = this.state;
		return (
			<>
				<div
					className={`table-gradient mb-5 ${
						this.props.activeItems.length ? '' : 'd-none'
					} `}
				>
					<Table striped responsive className="a-table">
						<thead>
							<tr key="0" className="a-table-heading">
								<th className="ps-4">
									<Form.Check
										inline
										label=""
										name={`group-all`}
										type="checkbox"
										data-id={`th-0`}
										id={`inline-radio-th-0`}
										checked={this.state.totalChecked > 0}
										className={getCheckAllClass(
											this.state.totalChecked,
											this.state.dataActiveItems
										)}
										onChange={this.handleSelectAllChecks}
									/>
								</th>
								<th>Image</th>
								<th className="mw-180px">
									<span
										className={this.sortClass('locationName')}
										data-field="locationName"
									>
										Location Name
									</span>
								</th>
								<th className="mw-100px">
									<span className={this.sortClass('item')} data-field="item">
										Ref. No.
									</span>
								</th>
								<th className="mw-280px">
									<span className={this.sortClass('desc')} data-field="desc">
										Description
									</span>
								</th>
								<th className="mw-100px">
									<span
										className={this.sortClass('proposalPrice')}
										data-field="proposalPrice"
									>
										Proposal Price
									</span>
								</th>
								<th>
									<span
										className={this.sortClass('statusName')}
										data-field="statusName"
									>
										Status
									</span>
								</th>
								<th className="mw-100px">
									<span
										className={this.sortClass('estmprice')}
										data-field="estmprice"
									>
										{this.state.company.compviewm}
									</span>
								</th>
								<th className="mw-100px">
									<span
										className={this.sortClass('estdprice')}
										data-field="estdprice"
									>
										{this.state.company.compviewd}
									</span>
								</th>
								<th className="mw-100px">
									<span
										className={this.sortClass('estfprice')}
										data-field="estfprice"
									>
										{this.state.company.compviewf}
									</span>
								</th>
								<th className="mw-100px">
									<span
										className={this.sortClass('estiprice')}
										data-field="estiprice"
									>
										{this.state.company.compviewi}
									</span>
								</th>
								<th className="mw-100px">
									<span
										className={this.sortClass('estlprice')}
										data-field="estlprice"
									>
										{this.state.company.compviewl}
									</span>
								</th>
								<th className="mw-100px">
									<span
										className={this.sortClass('estoprice')}
										data-field="estoprice"
									>
										{this.state.company.compviewo}
									</span>
								</th>
								<th className="mw-100px">
									<span
										className={this.sortClass('estdep')}
										data-field="estdep"
									>
										Requested Deposit
									</span>
								</th>
								<th className="mw-100px">
									<span
										className={this.sortClass('quantity')}
										data-field="quantity"
									>
										Quantity
									</span>
								</th>
								<th className="mw-100px">
									<span
										className={this.sortClass('propsTax')}
										data-field="propsTax"
									>
										Sales Tax
									</span>
								</th>
							</tr>
							<tr
								className={`a-table-search-fields ${
									this.state.showTableSearch ? '' : 'd-none'
								}`}
							>
								<th></th>
								<th></th>
								<th>
									<Form.Control
										data-type="text"
										data-field="locationName"
										onChange={this.handleSearch.bind(this)}
									/>
								</th>
								<th>
									<Form.Control
										data-type="text"
										data-field="item"
										onChange={this.handleSearch}
									/>
								</th>
								<th>
									<Form.Control
										data-type="text"
										data-field="desc"
										onChange={this.handleSearch}
									/>
								</th>
								<th className="position-relative">
									<Form.Control
										type="number"
										data-field="proposalPrice"
										data-type="number"
										onChange={this.handleSearch}
										placeholder={this.state.expProposalPrice || '>='}
									/>
									<SearchExpressionButton
										operator={this.state.expProposalPrice}
										callback={(expProposalPrice) =>
											this.setState({
												expProposalPrice,
											})
										}
									/>
								</th>
								<th className="mw-100px">
									<Form.Control
										type="text"
										data-field="statusName"
										onChange={this.handleSearch}
									/>
								</th>
								<th className="position-relative">
									<Form.Control
										type="number"
										data-field="estmprice"
										data-type="number"
										onChange={this.handleSearch}
										placeholder={this.state.expestmprice || '>='}
									/>
									<SearchExpressionButton
										operator={this.state.expestmprice}
										callback={(expestmprice) =>
											this.setState({
												expestmprice,
											})
										}
									/>
								</th>
								<th className="position-relative">
									<Form.Control
										type="number"
										data-field="estdprice"
										data-type="number"
										onChange={this.handleSearch}
										placeholder={this.state.expestdprice || '>='}
									/>
									<SearchExpressionButton
										operator={this.state.expestdprice}
										callback={(expestdprice) =>
											this.setState({
												expestdprice,
											})
										}
									/>
								</th>
								<th className="position-relative">
									<Form.Control
										type="number"
										data-field="estfprice"
										data-type="number"
										onChange={this.handleSearch}
										placeholder={this.state.expestfprice || '>='}
									/>
									<SearchExpressionButton
										operator={this.state.expestfprice}
										callback={(expestfprice) =>
											this.setState({
												expestfprice,
											})
										}
									/>
								</th>
								<th className="position-relative">
									<Form.Control
										type="number"
										data-field="estlprice"
										data-type="number"
										onChange={this.handleSearch}
										placeholder={this.state.expestlprice || '>='}
									/>
									<SearchExpressionButton
										operator={this.state.expestlprice}
										callback={(expestlprice) =>
											this.setState({
												expestlprice,
											})
										}
									/>
								</th>
								<th className="position-relative">
									<Form.Control
										type="number"
										data-field="estiprice"
										data-type="number"
										onChange={this.handleSearch}
										placeholder={this.state.expestiprice || '>='}
									/>
									<SearchExpressionButton
										operator={this.state.expestiprice}
										callback={(expestiprice) =>
											this.setState({
												expestiprice,
											})
										}
									/>
								</th>
								<th className="position-relative">
									<Form.Control
										type="number"
										data-field="estoprice"
										data-type="number"
										onChange={this.handleSearch}
										placeholder={this.state.expestoprice || '>='}
									/>
									<SearchExpressionButton
										operator={this.state.expestoprice}
										callback={(expestoprice) =>
											this.setState({
												expestoprice,
											})
										}
									/>
								</th>
								<th className="position-relative">
									<Form.Control
										type="number"
										data-field="estdep"
										data-type="number"
										onChange={this.handleSearch}
										placeholder={this.state.expestdep || '>='}
									/>
									<SearchExpressionButton
										operator={this.state.expestdep}
										callback={(expestdep) =>
											this.setState({
												expestdep,
											})
										}
									/>
								</th>
								<th className="position-relative">
									<Form.Control
										type="number"
										data-field="quantity"
										data-type="number"
										onChange={this.handleSearch}
										placeholder={this.state.expquantity || '>='}
									/>
									<SearchExpressionButton
										operator={this.state.expquantity}
										callback={(expquantity) =>
											this.setState({
												expquantity,
											})
										}
									/>
								</th>
								<th className="position-relative">
									<Form.Control
										type="number"
										data-field="propsTax"
										data-type="number"
										onChange={this.handleSearch}
										placeholder={this.state.exppropsTax || '>='}
									/>
									<SearchExpressionButton
										operator={this.state.exppropsTax}
										callback={(exppropsTax) =>
											this.setState({
												exppropsTax,
											})
										}
									/>
								</th>
							</tr>
						</thead>
						<tbody>
							{(() => {
								if (this.props.dataIsLoaded) {
									if (
										isFiltered(this.state.searchProperties) &&
										this.props.filteredActiveItems?.length === 0
									) {
										return showEmpty();
									}
									return this.state.dataActiveItems.map((item, i) => (
										<tr
											key={i}
											className={`${
												this.state.checks[i] ? `active` : ''
											} tr-loading-1`}
										>
											<td className="ps-4">
												<Form.Check
													inline
													label=""
													name={`group-` + i}
													type="checkbox"
													data-id={item.id}
													data-item={item.item}
													id={`chk-projectview-proposal-` + i}
													onChange={this.handleChecks}
													className="chk-projectview-proposal-item"
												/>
											</td>
											<td className="td-1">
												{renderItemImage({
													item,
													imagesLoaded,
													className: 'img-thumbnail p-0',
													imageData: window.imageData,
													property: 'primaryImageId',
													altProperty: 'itemName',
												})}
											</td>
											<td>{item.locationName}</td>
											<td>
												<SecureLink
													attributeNo={14}
													attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
													className="text-black"
													to={routeParam(URI.project.newItemEdit, {
														id: this.props.params.id,
														itemId: item.id,
													})}
												>
													{item.item}
												</SecureLink>
											</td>
											<td>{truncateCharCount(item.desc || '', 100)}</td>
											<td className="align-right">
												{currencyFormat(item.proposalPrice, '$')}
											</td>
											<td>
												<strong
													className={`dot`}
													style={{
														'--color': getStatusColor(
															item.statusName,
															statusCodes
														),
													}}
												>
													{item.statusName}
												</strong>
											</td>
											<td className="align-right">
												{currencyFormat(item.estmprice, '$')}
											</td>
											<td className="align-right">
												{currencyFormat(item.estdprice, '$')}
											</td>
											<td className="align-right">
												{currencyFormat(item.estfprice, '$')}
											</td>
											<td className="align-right">
												{currencyFormat(item.estiprice, '$')}
											</td>
											<td className="align-right">
												{currencyFormat(item.estlprice, '$')}
											</td>
											<td className="align-right">
												{currencyFormat(item.estoprice, '$')}
											</td>
											<td className="align-right">
												{currencyFormat(item.estdep, '$')}
											</td>
											<td>{item.quantity}</td>
											<td className="align-right">
												{currencyFormat(item.propsTax, '$')}
											</td>
										</tr>
									));
								} else {
									return showLoading();
								}
							})()}
						</tbody>
					</Table>
					{this.renderPagination()}
				</div>
				{!this.props.activeItems.length && this.props.dataIsLoaded && (
					<div className="row justify-content-center text-center py-5">
						<div className="col-md-3">
							<img src={noItems} className="mw-100 mb-4" alt="" />

							<h6>Add Items</h6>
							<p>
								Use the "Add Item" button to select items to include on your
								proposal.
							</p>

							<Button
								onClick={this.displayModalItems.bind(this)}
								variant="primary"
								size="md"
								className="w-100 btn-icon mt-4"
							>
								<i className="ri-add-line ri-lg"></i> Add Item
							</Button>
						</div>
					</div>
				)}
			</>
		);
	}

	render() {
		return (
			<>
				{/* Filter */}
				{this.renderListFilter()}

				{/* Content */}
				{this.renderContent()}

				{this.renderInputBar()}

				<ProjectProposalAddProposalAddItemModal
					statusCodes={this.state.statusCodes}
					data={this.props.projectItemsUnselected || []}
					company={this.state.company}
					showAllAvailableItems={this.props.showAllAvailableItems}
					addItemCall={this.handleAddItem.bind(this)}
					showAllItems={this.props.showAllUnselectedItems.bind(this)}
					show={this.state.showModal}
					hideModal={() => this.setState((prev) => (prev.showModal = false))}
					isEdit={this.props.params.pid !== undefined}
					dataIsLoaded={this.props.dataIsLoaded}
					loadImage={(data) => this.loadModalImages(data)}
				/>

				<SendProposalModal
					show={this.state.showProposalModal}
					hideModal={this.closeProposalModal}
					sendProposalData={this.state.sendProposalData}
					message={this.renderMessage()}
					project={this.state.project}
				/>
			</>
		);
	}
}

export default WithRouter(ProjectProposalAddProposal);
