import CompPercentTypeDropdown from 'legacy/app/components/dropdowns/CompPercentTypeDropdown';
import CompTypeDropdown from 'legacy/app/components/dropdowns/CompTypeDropdown';
import React, { useEffect, useState } from 'react';
import AddressesDropdown from 'legacy/app/components/dropdowns/ShipToDropdown';
import Label from 'legacy/app/components/label/Label';
import { CompNumericPercentType } from 'legacy/lib/api/types/CompPercentType';
import { FormControl, Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import BTermsInputs from './BTermsInputs';
import { TWhatChangedComponent } from 'legacy/lib/api/types/WhatChangedComponent';
import { Component } from 'legacy/lib/api/types/Component';
import ImageDragAndDrop from 'legacy/app/components/ImageDragAndDrop/ImageDragAndDrop';
import useGetFile from 'legacy/lib/api/hooks/useGetFile';
import TextEditor from 'legacy/app/components/textEditor/TextEditor';
import { WarehouseSelect } from 'app/widgets/Selects/WarehouseSelect';
import AsyncVendorsDropdown from 'legacy/app/components/dropdowns/AsyncVendorsDropdown';
import { TTypeOption } from 'legacy/app/components/dropdowns/types/TTypeOption';

const MainSection = ({
	disableInputs,
	handleRecalculate,
	component,
}: {
	component: Component;
	disableInputs: boolean;
	handleRecalculate: (whatPropertyChanged: `${TWhatChangedComponent}`) => void;
}) => {
	const methods = useFormContext();

	const [displayImage, setDisplayImage] = useState<Blob | null>(null);

	const useDisc = methods.watch('usedisc');

	const { setValue, watch } = methods;

	useEffect(() => {
		if (useDisc === CompNumericPercentType.Fee) {
			setValue('useterms', false);
		}
	}, [component, setValue, useDisc]);

	useEffect(() => {
		return () => {
			const description = watch('description')?.html;
			setValue('initialDescription', description || '');
		};
	}, [setValue, watch]);

	const { data: editItemImage } = useGetFile(
		component?.primaryImageId as string,
		{
			enabled: !!component?.primaryImageId,
		}
	);

	useEffect(() => {
		if (useDisc === CompNumericPercentType.Fee) {
			setValue('useterms', false);
		}
		if (editItemImage) {
			setDisplayImage(editItemImage);
		}
	}, [component, setValue, useDisc, setDisplayImage, editItemImage]);

	return (
		<div className="tw-pt-7 tw-p-10 tw-flex tw-justify-center tw-gap-20 tw-flex-wrap">
			<div className="tw-grid tw-gap-y-4  tw-gap-x-8 tw-h-full tw-pl-24 tw-grid-cols-[20%_80%] tw-w-3/6 tw-items-center tw-flex-1">
				<Label label="Name" />
				<Controller
					name="itemName"
					render={({ field: { value, onChange } }) => (
						<FormControl
							id="Name"
							maxLength={45}
							onChange={onChange}
							value={value}
						/>
					)}
				/>
				<Label label="Description" />
				<Controller
					control={methods.control}
					name="description"
					render={({ field }) => (
						<TextEditor
							initialData={methods.watch('initialDescription')}
							onChange={(e) => field.onChange(e.target)}
						/>
					)}
				/>
				<Label label="Type" />
				<Controller
					control={methods.control}
					name="comptype"
					render={({ field }) => (
						<CompTypeDropdown
							isDisabled={disableInputs}
							inputId="Type"
							blurInputOnSelect
							value={{
								label: '',
								value: field.value,
							}}
							onChange={(value) => field.onChange(value?.value)}
							onBlur={() =>
								handleRecalculate(TWhatChangedComponent.cwcComponentType)
							}
						/>
					)}
				/>
				<Label label="Vendor" />
				<Controller
					control={methods.control}
					name="supplier"
					render={({ field }) => (
						<AsyncVendorsDropdown
							inputId="Vendor"
							blurInputOnSelect
							doNotSetValueOnEffect
							isDisabled={disableInputs}
							value={
								field.value
									? {
											// If there´s a supplier, the label will be found by the dropdown
											label: 'Loading...',
											value: field.value,
									  }
									: null
							}
							onSuccessCreate={() =>
								handleRecalculate(TWhatChangedComponent.cwcVendor)
							}
							initialValue={component.supplier}
							creatable
							isClearable
							onChange={(value: TTypeOption) => {
								setValue('vendorName', value?.label);
								field.onChange(
									value?.value === '' || value?.value === undefined
										? null
										: value?.value
								);
							}}
							onBlur={() => handleRecalculate(TWhatChangedComponent.cwcVendor)}
						/>
					)}
				/>
				<input
					type="hidden"
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...methods.register('vendorName')}
				/>
				<Label label="Vendor Deposit %" />
				<Controller
					name="supdep"
					render={({ field: { value, onChange } }) => (
						<FormControl
							id="Vendor Deposit %"
							onChange={onChange}
							value={value}
							disabled={disableInputs}
							maxLength={15}
							onBlur={(e) => {
								const isNotAValidNumber = isNaN(Number(e.target.value));
								if (
									!e.target.value ||
									isNotAValidNumber ||
									e.target.value === '-0.'
								) {
									methods.setValue('supdep', '0');
								} else {
									methods.setValue('supdep', String(Number(e.target.value)));
								}
								handleRecalculate(
									TWhatChangedComponent.cwcVendorDepositPercent
								);
							}}
						/>
					)}
				/>
				<div className="tw-col-start-2">
					<Form.Check
						disabled={
							methods.watch('usedisc') === CompNumericPercentType.Fee ||
							disableInputs
						}
						type="checkbox"
						name={methods.register('useterms').name}
						ref={methods.register('useterms').ref}
						onChange={methods.register('useterms').onChange}
						label="Use Buying Terms"
						onBlur={() =>
							handleRecalculate(TWhatChangedComponent.cwcUseBuyingTerms)
						}
					/>
				</div>
				{methods.watch('useterms') &&
					methods.watch('usedisc') !== CompNumericPercentType.Fee && (
						<BTermsInputs
							disableInputs={disableInputs}
							handleRecalculate={handleRecalculate}
						/>
					)}
				<Label label="% Type" />
				<div className="tw-flex tw-items-center tw-gap-4 tw-flex-wrap">
					<Controller
						control={methods.control}
						name="usedisc"
						render={({ field }) => (
							<CompPercentTypeDropdown
								inputId='"% Type'
								blurInputOnSelect
								isDisabled={disableInputs}
								value={{
									label: '',
									value: field.value,
								}}
								onChange={(value) => field.onChange(value?.value)}
								onBlur={() => {
									if (methods.watch('usedisc') === CompNumericPercentType.Fee) {
										methods.setValue('useterms', false);
									}
									handleRecalculate(TWhatChangedComponent.cwcPercentType);
								}}
							/>
						)}
					/>
					<div className="tw-flex tw-items-center tw-gap-4 tw-flex-wrap">
						<Label label="%Value" />
						<Controller
							name="markup"
							render={({ field: { value, onChange } }) => (
								<FormControl
									className="tw-max-w-40"
									onChange={onChange}
									value={value}
									disabled={disableInputs}
									maxLength={15}
									onBlur={(e) => {
										const isNotAValidNumber = isNaN(Number(e.target.value));
										if (
											!e.target.value ||
											isNotAValidNumber ||
											e.target.value === '-0.'
										) {
											methods.setValue('markup', '0');
										} else {
											methods.setValue(
												'markup',
												String(Number(e.target.value))
											);
										}
										handleRecalculate(TWhatChangedComponent.cwcPercent);
									}}
								/>
							)}
						/>
					</div>
				</div>
				<Label label="Ship To" />
				<Controller
					control={methods.control}
					name="shipto"
					render={({ field }) => (
						<AddressesDropdown
							inputId="Ship To"
							blurInputOnSelect
							defaultValue={
								component.shipto
									? {
											label: component.shipto,
											value: component.shipto,
									  }
									: undefined
							}
							value={{
								label: '',
								value: field.value,
							}}
							onChange={(value) => field.onChange(value?.value)}
						/>
					)}
				/>
				{component.stockno !== '' ? (
					<>
						<Label label="Stock No." />
						<div className="tw-flex tw-items-center tw-gap-4">
							<div className="tw-max-w-[50%]">
								<Controller
									name="stockno"
									render={({ field }) => (
										<FormControl id="stockno" value={field?.value} disabled />
									)}
								/>
							</div>
							<Label label="Warehouse" />
							<Controller
								name="commitwhcode"
								render={({ field }) => (
									<WarehouseSelect
										handleSelect={(option) => {
											option && field.onChange(option.code);
										}}
										defaultSelected={component?.commitwhcode}
									/>
								)}
							/>
						</div>
					</>
				) : (
					<>
						<Label label="Mfg. Cat." />
						<Controller
							name="catalog"
							render={({ field: { value, onChange } }) => (
								<FormControl id="Mfg. Cat." onChange={onChange} value={value} />
							)}
						/>
					</>
				)}
			</div>
			<div className="tw-flex-1 tw-h-1/2">
				<ImageDragAndDrop
					image={displayImage}
					imageIndex={0}
					handleDelete={() => {
						setValue('image', null);
						setValue('isImageDirty', true);
						setDisplayImage(null);
					}}
					handleFile={(image: Blob) => {
						setValue('image', image);
						setValue('isImageDirty', true);
						setDisplayImage(image);
					}}
				/>
			</div>
		</div>
	);
};

MainSection.displayName = 'MaiNSection';
export default MainSection;
