import ThreadComponent from '../ThreadComponent';
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React from 'react';
import { AlertLoader } from '../../../app/components/bootstrap/BAlerts';
import MSG from '../../../defaults/Message';
import { getCheckAllClass } from '../../../utilities/ModuleHelper';
import { SearchExpressionButton } from '../../components/SearchExpressionButton';
import { getComponentName } from '../../../utilities/ComponentTypes';
import { showLoading } from '../../../helpers/Loading';
import { debounce } from 'lodash';
import { WithRouter } from '../../../helpers/Router';
import FilterSort from '../../../utilities/modules/FilterSort';
import { ApiService } from '../../../lib/api/HttpService';
import { truncateCharCount } from '../../../helpers/String';
import Select from 'react-select';
import { addDomClass, hasClass, removeDomClass } from '../../../helpers/DOM';
import { displayAlert } from '../../../utilities/Response';
import loadItemImages, { renderItemImage } from '../../../utilities/Images';

class AddComponentModal extends ThreadComponent {
	constructor(props) {
		super(props);

		this.fs = new FilterSort(this.props.filterKey);

		this.state = {
			isShowAll: false,
			imagesLoaded: false,
			dataIsLoaded: false,
			data: [],
			selected: [],
			sortProperty: this.fs.getSort() ?? 'id desc',
			page: 1,
			pageSize: { value: 10, label: '10' },
			imageData: {},
			showTableSearch: this.fs.isSearchActive(),
			searchProperties: this.fs.getSearches(),
		};
		this.availablePageSize = [
			{ value: '10', label: '10' },
			{ value: '20', label: '20' },
			{ value: '50', label: '50' },
			{ value: '75', label: '75' },
			{ value: '100', label: '100' },
		];

		this.api = new ApiService();
		this.searchHandler = debounce(this.handleDoSearch.bind(this, 1), 200);
		this.changePageHandler = debounce(this.handleChangePage.bind(this, 1), 200);
	}

	async componentDidMount() {
		window.isMounted = true;
		this.enableSearchAndSortTable();

		this.setState({
			sortProperty: `${this.props.defaultSortField} asc`,
		});
	}

	componentDidUpdate(prevProps, prevState) {
		const { show, selected } = this.props;
		if (prevProps.show !== show) {
			let remSelected = [];
			selected.map((item) => {
				remSelected.push(item);
			});

			this.setState({
				show,
				selected: remSelected,
			});

			if (show && this.state.data.length < 1) {
				this.fetchData();
			}
		}
	}

	componentWillUnmount() {
		window.isMounted = false;
	}

	async fetchData() {
		const { orderId, project, selected } = this.props;
		let filter, queryString;
		if (typeof orderId !== 'undefined') {
			filter = `?$filter=proj eq '${project.proj}' and comptype ne 5 and createdbysinv eq false and createdbymisc eq false and (stockno eq null OR stockno eq '' OR stockno lt '!')`;

			if (selected.length > 0) {
				const { supplier, shipto } = selected[0];
				filter += ` and supplier eq '${supplier}' and shipto eq '${shipto}'`;
			}
		} else {
			filter = `?$filter=proj eq '${project.proj}' and comptype ne 5 and createdbysinv eq false and createdbymisc eq false and (stockno eq null OR stockno eq '' OR stockno lt '!') and inactive eq false and completed eq false and workroom ne supplier and supplier gt '!' and latestpodtonly eq null`;
		}

		let searchFilters = [];
		const searches = Object.keys(this.state.searchProperties);
		searches.forEach((key) => {
			const property = this.state.searchProperties[key];
			if (property.value) {
				if (property.type === 'number') {
					searchFilters.push(`${key} eq ${property.value}`);
				} else if (property.type === 'date') {
					searchFilters.push(`${key} ge ${property.value}`);
					searchFilters.push(`${key} le ${property.value}`);
				} else {
					searchFilters.push(`contains(${key}, '${property.value}')`);
				}
			}
		});

		queryString =
			searchFilters.length > 0
				? `${filter} and ${searchFilters.join(' and ')}`
				: filter;
		if (!this.state.isShowAll) {
			queryString += `&$top=${parseInt(this.state.pageSize.value) + 1}&$skip=${
				(this.state.page - 1) * this.state.pageSize.value
			}`;
		}

		if (this.state.sortProperty !== '') {
			queryString += `&$orderby=${this.state.sortProperty}`;
		}

		const components = await this.api.getComponents(queryString);

		this.setState(
			{
				imagesLoaded: false,
				data: components,
				dataIsLoaded: true,
			},
			() => {
				this.loadImages(components);
				this.enableSortTable();
			}
		);
	}

	async loadImages(data) {
		loadItemImages({
			data,
			property: 'primaryImageId',
			callback: (imageData) => {
				this.setState({
					imagesLoaded: true,
					imageData,
				});
				this.props.setImageData && this.props.setImageData(imageData);
			},
		});
	}

	enableSortTable() {
		const sort = document.querySelectorAll('.a-table-heading .sort');
		const self = this;

		// Add change event
		if (sort) {
			sort.forEach((_e) => {
				_e.addEventListener(
					'click',
					function (e) {
						sort.forEach((_e2) => {
							if (_e !== _e2) {
								removeDomClass('desc', _e2);
								removeDomClass('asc', _e2);
								removeDomClass('active', _e2);
							}
						});

						addDomClass('active', _e);

						if (hasClass('desc', _e)) {
							removeDomClass('desc', _e);
							addDomClass('asc', _e);
						} else if (hasClass('asc', _e)) {
							removeDomClass('asc', _e);
							addDomClass('desc', _e);
						} else {
							addDomClass('desc', _e);
						}

						const sortProperty = `${_e.attributes['data-field'].value} ${
							hasClass('desc', _e) ? 'asc' : 'desc'
						}`;

						// Save sortProperty to localStorage
						self.fs.setSort(sortProperty);
						self.setState(
							{
								sortProperty,
							},
							self.changePageHandler
						);
					},
					false
				);
			});
		}
	}

	handleShowTableSearch = (e) => {
		const filters = document.querySelectorAll('.a-table-search-fields input');
		if (filters) {
			filters.forEach((e) => {
				e.value = '';
			});
		}

		let newTableSearch = !this.state.showTableSearch;
		this.setState({
			showTableSearch: newTableSearch,
		});
		this.fs.setIsActiveSearch(newTableSearch);

		if (JSON.stringify(this.state.searchProperties) !== '{}') {
			this.fs.setSearches({});
			this.setState(
				{
					searchProperties: {},
				},
				this.changePageHandler
			);
		}
	};

	handleShowAll = (e) => {
		this.setState(
			{
				isShowAll: e.target.checked,
				dataIsLoaded: false,
			},
			() => this.fetchData()
		);
	};

	handleComponentAddItem = (e) => {
		const selected = Array.from(
			new Map(this.state.selected.map((item) => [item.id, item])).values()
		);
		if (!selected.length) {
			displayAlert('danger', 'Please select at least one item', 2000);
		} else {
			this.props.handleAddItem(selected);
		}
	};

	handleSelectAllChecks = (e) => {
		const isChecked = e.target.checked;
		let selected = [];
		if (isChecked) {
			this.state.data.map((item) => {
				let canCheck = item.completeShipTo && item.vendorName;
				if (canCheck) {
					selected.push(item);
				}
			});
		}

		this.setState({
			selected: selected,
		});
	};

	handleCheck = (item) => {
		const selected = this.state.selected;

		let canCheck = item.completeShipTo && item.vendorName;
		if (!canCheck) {
			displayAlert(
				'warning',
				'A Component cannot be selected unless it has had both a Vendor and a Ship To Code entered for it.'
			);

			return;
		}

		const associatedComponents = this.state.data.filter(
			(_item) => _item.item === item.item && _item.assocwithcomp === item.comp
		);

		if (associatedComponents.length) {
			const parentIndex = selected.findIndex((_item) => _item.id === item.id);
			associatedComponents.forEach((component) => {
				const index = selected.findIndex((_item) => _item.id === component.id);
				parentIndex > -1 ? selected.splice(index, 1) : selected.push(component);
			});
		} else {
			const index = selected.findIndex((_item) => _item.id === item.id);
			index > -1 ? selected.splice(index, 1) : selected.push(item);
		}
		this.setState({
			selected: selected,
		});
	};

	handleSearch = (e) => {
		const key = e.target.attributes['data-field'].value;
		const value = e.target.value;
		const type = e.target.attributes['data-type']?.value ?? 'string';
		const exp = e.target.attributes['placeholder']?.value ?? '>=';
		const custom = Boolean(e.target.attributes['data-custom']?.value);

		this.setState(
			{
				searchProperties: {
					...this.state.searchProperties,
					[key]: { value, type, exp, custom },
				},
			},
			() => {
				this.fs.setSearches(this.state.searchProperties);
				this.searchHandler();
			}
		);
	};

	handleDoSearch = (e) => {
		this.fetchData();
	};

	handleChangePage = async (page) => {
		this.setState(
			{
				page: page,
			},
			() => this.fetchData()
		);
	};

	handleFilter = (name) => (e) => {
		this.setState(
			{
				[name]: e,
				page: 1,
			},
			this.changePageHandler
		);
	};

	sortClass(name) {
		return `sort ${this.fs.isActiveSort(name)}`;
	}

	formatDesc(item) {
		const itemName = item.itemName || '';
		const itemDesc = item.desc || '';

		let result = '';

		if (itemName && itemDesc) {
			const truncatedDesc = truncateCharCount(itemDesc, 100);
			result = truncatedDesc.includes(itemName)
				? truncatedDesc
				: `${itemName} ${truncatedDesc}`;
		} else {
			result = itemName || itemDesc || '';
		}

		return result;
	}

	renderPagination() {
		if (!this.state.isShowAll) {
			return (
				<div className={'d-flex flex-wrap justify-content-center'}>
					<div className={'d-flex flex-row align-items-center pagination'}>
						<Button
							variant="ivory"
							size="sm"
							className={'btn-icon pagination-btn'}
							disabled={this.state.page === 1}
							onClick={() => this.handleChangePage(this.state.page - 1)}
						>
							<i className="ri-arrow-left-s-line"></i> Previous
						</Button>
						<span className={'pagination-span'}>{this.state.page}</span>
						<Button
							variant="ivory"
							size="sm"
							className={'btn-icon pagination-btn'}
							disabled={this.state.data.length <= this.state.pageSize.value}
							onClick={() => this.handleChangePage(this.state.page + 1)}
						>
							Next <i className="ri-arrow-right-s-line ms-1"></i>
						</Button>
					</div>
					<Select
						onChange={this.handleFilter('pageSize')}
						key={`${Math.floor(Math.random() * 1000)}-min`}
						options={this.availablePageSize}
						defaultValue={this.state.pageSize}
						className="react-select pagination-select mx-3"
						placeholder="Please select"
					/>
				</div>
			);
		}
	}

	render() {
		return (
			<Modal
				size="xxl"
				show={this.props.show}
				onHide={this.props.hideModal}
				aria-labelledby="example-modal-sizes-title-lg"
				className="right a-modal modal-quarter fixed-footer"
				backdrop="static"
			>
				<Modal.Header className="bg-ivory py-4 px-5">
					<Row className="w-100">
						<Col className="text-start">
							<Button
								as={Link}
								to="#"
								variant="ivory"
								size="sm"
								className={`d-inline-block ms-3 mt-2 btn-icon btn-action fw-bold ${
									this.state.showTableSearch ? 'bg-primary-ash text-white' : ''
								}`}
								onClick={this.handleShowTableSearch}
							>
								<i className="ri-search-line"></i> Search
							</Button>
							<Form.Check
								inline
								label="Show All"
								name="group-check-show-all"
								type="checkbox"
								id={`group-check-show-all`}
								className="ms-3 align-middle pt-2"
								onClick={this.handleShowAll}
								defaultChecked={this.state.isShowAll}
							/>
						</Col>
						<Col className="text-end">
							<Button
								variant="trans-light border-secondary-ash"
								onClick={this.props.hideModal}
								className="d-inline-block w-150px"
							>
								Cancel
							</Button>
							{this.state.data.length > 0 && (
								<Button
									variant="primary"
									onClick={this.handleComponentAddItem}
									className="d-inline-block w-150px ms-3"
								>
									Save
								</Button>
							)}
						</Col>
					</Row>
				</Modal.Header>
				<Modal.Body className="bg-ivory">
					<Row className="w-100 px-5">
						<Col sm={12}>
							<div className="table-gradient mb-5 sticky-container">
								<Table striped responsive className="a-table">
									<thead>
										<tr key="0" className="a-table-heading unselected">
											<th className="ps-4">
												<Form.Check
													inline
													label=""
													type="checkbox"
													defaultChecked={this.state.selected.length > 0}
													className={getCheckAllClass(
														this.state.selected.length,
														this.state.selected
													)}
													onClick={this.handleSelectAllChecks}
												/>
											</th>
											<th>Image</th>
											<th className="mw-100px">
												<span
													className={this.sortClass('refNum')}
													data-field="refNum"
												>
													Ref No.
												</span>
											</th>
											<th className="mw-100px">
												<span
													className={this.sortClass('vendorName')}
													data-field="vendorName"
												>
													{this.props.orderType && this.props.orderType == 'wo'
														? 'Workroom'
														: 'Vendor'}
												</span>
											</th>
											{this.props.orderType && this.props.orderType == 'wo' ? (
												<th>
													<span
														className={this.sortClass('completeVendor')}
														data-field="completeVendor"
													>
														Ordered From
													</span>
												</th>
											) : (
												''
											)}
											<th className="mw-180px">
												<span
													className={this.sortClass('desc')}
													data-field="desc"
												>
													Description
												</span>
											</th>
											<th className="mw-110px">
												<span
													className={this.sortClass('quantity')}
													data-field="quantity"
												>
													Quantity
												</span>
											</th>
											<th className="mw-140px">
												<span
													className={this.sortClass('estcost')}
													data-field="estcost"
												>
													Estimated Cost
												</span>
											</th>
											<th className="mw-100px">
												<span
													className={this.sortClass('comptype')}
													data-field="comptype"
												>
													Type
												</span>
											</th>
											<th>
												<span
													className={this.sortClass('completeLocation')}
													data-field="completeLocation"
												>
													Location
												</span>
											</th>
											<th className="mw-100px">
												<span
													className={this.sortClass('completeShipTo')}
													data-field="completeShipTo"
												>
													Ship To
												</span>
											</th>
											<th>
												<span
													className={this.sortClass('catalog')}
													data-field="catalog"
												>
													Catalog No.
												</span>
											</th>
											<th>
												<span
													className={this.sortClass('latestProposalNo')}
													data-field="latestProposalNo"
												>
													Last Proposal No
												</span>
											</th>
										</tr>
										<tr
											className={`a-table-search-fields unselected ${
												this.state.showTableSearch ? '' : 'd-none'
											}`}
										>
											<th></th>
											<th></th>
											<th>
												<Form.Control
													type="text"
													data-field="refNum"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('refNum')}
												/>
											</th>
											<th>
												<Form.Control
													type="text"
													data-field="vendorName"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('vendorName')}
												/>
											</th>
											{this.props.orderType && this.props.orderType == 'wo' ? (
												<th>
													<Form.Control
														type="text"
														data-field="completeVendor"
														onChange={this.handleSearch}
														defaultValue={this.fs.getValue('completeVendor')}
													/>
												</th>
											) : (
												''
											)}
											<th>
												<Form.Control
													type="text"
													data-field="desc"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('desc')}
												/>
											</th>
											<th className="position-relative">
												<Form.Control
													type="number"
													data-field="quantity"
													placeholder={this.fs.getSearchExpression('quantity')}
													data-type="number"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('quantity')}
												/>
												<SearchExpressionButton
													operator={this.fs.getSearchExpression('quantity')}
													callback={(exp) => {
														this.fs.setSearchExperation('quantity', exp, this);
													}}
												/>
											</th>
											<th className="position-relative">
												<Form.Control
													type="number"
													data-field="estcost"
													placeholder={this.fs.getSearchExpression('estcost')}
													data-type="number"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('estcost')}
												/>
												<SearchExpressionButton
													operator={this.fs.getSearchExpression('estcost')}
													callback={(exp) => {
														this.fs.setSearchExperation('estcost', exp, this);
													}}
												/>
											</th>
											<th>
												<Form.Control
													type="text"
													data-field="comptype"
													data-custom="comptype"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('comptype')}
												/>
											</th>
											<th className="mw-100px">
												<Form.Control
													type="text"
													data-field="completeLocation"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('completeLocation')}
												/>
											</th>
											<th className="mw-100px">
												<Form.Control
													type="text"
													data-field="completeShipTo"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('completeShipTo')}
												/>
											</th>
											<th className="mw-100px">
												<Form.Control
													type="text"
													data-field="catalog"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('catalog')}
												/>
											</th>
											<th className="mw-100px">
												<Form.Control
													type="text"
													data-field="latestProposalNo"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('latestProposalNo')}
												/>
											</th>
										</tr>
									</thead>
									<tbody>
										{(() => {
											if (this.state.dataIsLoaded) {
												return this.state.data.length > 0 ? (
													(this.state.data || [])
														.slice(0, this.state.pageSize.value)
														.map((item, i) => (
															<tr
																key={i}
																className={`${
																	this.state.selected
																		.map((_item) => _item.id)
																		.includes(item.id)
																		? `active`
																		: ''
																} tr-loading-1`}
															>
																<td className="ps-4">
																	<Form.Check
																		inline
																		label=""
																		type="checkbox"
																		className="chk-component-items"
																		checked={this.state.selected
																			.map((_item) => _item.id)
																			.includes(item.id)}
																		onChange={() => {
																			this.handleCheck(item);
																		}}
																	/>
																</td>
																<td className="td-1">
																	{renderItemImage({
																		item,
																		imagesLoaded: this.state.imagesLoaded,
																		imageData: this.state.imageData,
																		property: 'primaryImageId',
																		altProperty: 'itemName',
																	})}
																</td>
																<td>{item.refNum}</td>
																<td>{item.vendorName}</td>
																{this.props.orderType &&
																this.props.orderType == 'wo' ? (
																	<td>{item.completeVendor}</td>
																) : (
																	''
																)}
																<td>{this.formatDesc(item)}</td>
																<td>{`${item.quantity}  ${
																	item?.unitmeasure || ''
																}`}</td>
																<td>{item.estcost?.toFixed(2)}</td>
																<td>{getComponentName(item.comptype)}</td>
																<td>{item.completeLocation}</td>
																<td>{item.completeShipTo}</td>
																<td>{item.catalog}</td>
																<td>{item.latestProposalNo}</td>
															</tr>
														))
												) : (
													<tr>
														<td colSpan={12} className="fw-bold">
															No available components
														</td>
													</tr>
												);
											} else {
												return showLoading();
											}
										})()}
									</tbody>
								</Table>
								{this.renderPagination()}
							</div>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		);
	}
}

export default WithRouter(AddComponentModal);
