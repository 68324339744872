import { apiClient } from 'api/apiClient';
import { getCookie } from 'legacy/utilities/Auth';

const RESOURCE = (userCode: string) => `/UserAccounts/${userCode}/company-role`;

export interface ICompanyRole {
	companyRole: number | null;
}

export interface IPatchCompanyRole {
	newCompanyRole: number;
}

export enum ECompanyRole {
	PpalDesigner = 1,
	Bookkeeper = 2,
	IntDesigner = 3,
	OpsManager = 4,
}

export const getCompanyRole = async (
	userCode?: number
): Promise<ICompanyRole> => {
	const savedUserCode = getCookie('dmUsercode');
	const { data } = await apiClient.get(
		`${RESOURCE(userCode ? String(userCode) : savedUserCode)}`
	);

	return {
		companyRole:
			ECompanyRole[data.companyRole as keyof typeof ECompanyRole] || null,
	};
};

export const patchCompanyRole = async (
	params: IPatchCompanyRole
): Promise<ICompanyRole> => {
	const userCode = getCookie('dmUsercode');

	const { data } = await apiClient.patch(`${RESOURCE(userCode)}`, params);

	return data;
};
