import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { ECacheKeys } from 'cache/CacheKeys';
import { getInstances, type IUserInstance } from 'api/resources/user';

export const useGetUserInstances = (
	userName: string,
	options?: UseQueryOptions<IUserInstance[]>
) => {
	return useQuery<IUserInstance[]>({
		queryKey: [ECacheKeys.UserData, 'instances', userName],
		refetchInterval: false,
		refetchOnWindowFocus: false,
		queryFn: () => getInstances(userName),
		...(options || {}),
	});
};
