import { useMutation } from '@tanstack/react-query';
import {
	postCheckbookVoid,
	type IPostCheckbookVoidParams,
} from 'api/resources/generalLedgerTransactions';

export const usePostCheckbookVoid = () => {
	return useMutation((params: IPostCheckbookVoidParams) =>
		postCheckbookVoid(params)
	);
};
