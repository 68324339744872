import { ECheckType } from './enums';

export const CHECK_TYPES_LABELS = {
	[ECheckType.Payroll]: 'Payroll',
	[ECheckType.ExpenseMisc]: 'Expense/ Misc.',
	[ECheckType.ProjectPO]: 'Proj. PO',
	[ECheckType.DepositProjectPO]: 'Deposit (Proj. PO)',
	[ECheckType.DepositInventoryPO]: 'Deposit (Inventory PO)',
	[ECheckType.InventoryPO]: 'Inventory PO',
};
