import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { Modal, Button } from 'react-bootstrap';
import { useGetCompany } from 'api/hooks/useGetCompany';
import { postNotification, saveFile } from 'api/resources/sendNotification';
import {
	displayAlertError,
	displayAlertLoader,
	displayAlertSuccess,
} from 'legacy/utilities/Response';
import { getValidEmails } from 'utils/validEmails/validEmails';
import type { ISendItemsModalProps } from 'api/types/sendItems.types';
import type { IEmailFormInputs } from 'api/types/sendEmail.types';
import { usePostClientPortalToken } from 'api/hooks/usePostClientPortalToken';
import MSG from 'legacy/defaults/Message';

export const SendItemsModal = ({
	show,
	hideModal,
	email,
	refreshData,
	sendItemsData,
	project,
}: ISendItemsModalProps) => {
	const { data: company } = useGetCompany();
	const { mutateAsync } = usePostClientPortalToken();

	const renderMessage = (): string => {
		return `Dear ${project?.clientName || 'Client'},\n\n\t${
			company?.name || 'Our company'
		} would like to inform you that they have added some information to your client portal.`;
	};

	const { register, handleSubmit, watch, setValue } = useForm<IEmailFormInputs>(
		{
			values: {
				to: email || '',
				cc: '',
				bcc: '',
				subject:
					`An update was made to your project by ${company?.name.trim()}` || '',
				message: renderMessage() || '',
				includeUserSignature: true,
				files: [],
			},
			defaultValues: {
				to: email || '',
				cc: '',
				bcc: '',
				subject: '',
				message: '',
				includeUserSignature: true,
				files: [],
			},
		}
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: (acceptedFiles) => {
			setValue('files', acceptedFiles as File[]);
		},
		multiple: false,
	});

	const onSubmit = async (data: IEmailFormInputs) => {
		try {
			if (!getValidEmails(data.to)) {
				displayAlertError('Please enter a valid email address');
				return;
			}

			let fileId: number | undefined = undefined;
			if (data.files && data.files.length === 1) {
				const file = data.files[0];
				const saveFileResponse = await saveFile(file);
				fileId = saveFileResponse.id;
			}

			displayAlertLoader('Sending email<span class="dot-load"></span>');

			const emailData = {
				to: getValidEmails(data.to).split(';'),
				cc: data.cc ? getValidEmails(data.cc).split(';') : [],
				bcc: data.bcc ? getValidEmails(data.bcc).split(';') : [],
				subject: data.subject,
				message: data.message,
			};

			await postNotification({
				notificationTypeId: 1,
				emailData,
				...(fileId && { files: [fileId] }),
				publicMappingBatchToken: sendItemsData.token,
				includeUserSignature: data.includeUserSignature,
			});

			displayAlertSuccess('Email submitted successfully');
			hideModal();
			refreshData();
		} catch (error) {
			displayAlertError('Failed to send email');
		}
	};

	const launchClientPortal = async () => {
		if (project.clientPortalEnabled) {
			try {
				const response = await mutateAsync(project.id);

				const portalUrl = `${process.env.REACT_APP_CLIENT_PORTAL_PRO_BASE_URL}/items?token=${response.token}`;
				window.open(portalUrl, '_blank');
			} catch (error) {
				displayAlertError(MSG.error.clientPortalToken.generate);
				return { success: false, error };
			}
		} else {
			window.open(sendItemsData?.link, '_blank');
		}
	};

	const labelClassName =
		'tw-text-sm tw-font-medium tw-font-semibold tw-text-gray-700 tw-mb-2';
	const inputClassName =
		'tw-mb-2 tw-w-full tw-px-3 tw-py-2 tw-border tw-rounded-md tw-shadow-sm focus:tw-ring-primary focus:tw-border-primary';
	const inputLabelContainer = 'tw-d-flex tw-mb-3';

	return (
		<Modal
			size="lg"
			show={show}
			onHide={hideModal}
			aria-labelledby="send-items-modal"
			className="right a-modal fixed-footer"
			backdrop="static"
		>
			<Modal.Body>
				<div className="tw-p-5 tw-mt-10">
					<h4 className="ff-type-medium">Send Client Portal Notification</h4>
					<form className="tw-px-4 tw-max-w-[800px]">
						{/* Email Fields */}
						{(['to', 'cc', 'bcc'] as (keyof IEmailFormInputs)[]).map(
							(field) => (
								<div key={field} className={inputLabelContainer}>
									<label htmlFor={field} className={labelClassName}>
										{field.charAt(0).toUpperCase() + field.slice(1)}:
									</label>
									<input
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...register(field)}
										id={field}
										className={inputClassName}
										onBlur={(e) =>
											setValue(field, getValidEmails(e.target.value))
										}
									/>
								</div>
							)
						)}

						<div className={inputLabelContainer}>
							<label htmlFor="subject" className={labelClassName}>
								Subject:
							</label>

							<div>
								<input
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...register('subject')}
									id="subject"
									className={inputClassName}
								/>
								{/* Items Link */}
								<a href="#" onClick={launchClientPortal}>
									<>
										Items for {project.proj}
										{project.clientPortalEnabled && ' - Client Portal'}
									</>
								</a>
							</div>
						</div>

						{/* Attachment */}
						<div className={inputLabelContainer}>
							<label htmlFor="attachment" className={labelClassName}>
								Attachment:
							</label>

							<div
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...getRootProps()}
								className={`tw-border-2 tw-p-2 tw-border-dashed tw-rounded-md tw-text-center tw-cursor-pointer tw-transition-colors
								${
									isDragActive
										? 'tw-border-blue tw-bg-primary/5'
										: 'tw-border-gray-300 hover:tw-border-primary'
								}
								
								${watch('files').length > 0 ? 'tw-border-green-500 tw-bg-green-500/5' : ''}`}
							>
								{/* eslint-disable-next-line react/jsx-props-no-spreading */}
								<input {...getInputProps()} />
								<span
									className={`${labelClassName} tw-flex tw-items-center tw-justify-between tw-ml-auto tw-w-3/4`}
								>
									<span>
										{isDragActive
											? 'Drop the file here...'
											: watch('files')?.[0]?.name
											? watch('files')?.[0]?.name
											: 'Drag & drop a file here, or click to select'}
									</span>
									<div>
										{watch('files').length > 0 ? (
											<i
												onClick={(event) => {
													event.stopPropagation();
													setValue('files', []);
												}}
												className="ri-close-circle-line tw-leading-none"
											/>
										) : (
											<i className="ri-attachment-2 tw-leading-none" />
										)}
									</div>
								</span>
							</div>
						</div>

						{/* Message */}
						<div className={inputLabelContainer}>
							<label htmlFor="message" className={labelClassName}>
								Message:
							</label>
							<div>
								<textarea
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...register('message')}
									id="message"
									rows={10}
									className={inputClassName}
								/>
								{/* Include Signature */}
								<div className="tw-flex tw-items-center tw-space-x-2">
									<input
										type="checkbox"
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...register('includeUserSignature')}
										id="signature"
										className="tw-rounded tw-border-gray-300 tw-text-primary focus:tw-ring-primary"
									/>
									<label htmlFor="signature" className="tw-text-gray-700">
										Include Signature from User Settings
									</label>
								</div>
							</div>
						</div>
					</form>
				</div>
			</Modal.Body>
			<Modal.Footer className="bg-ivory py-2 px-5">
				<div className="w-100">
					<Button
						variant="primary"
						onClick={handleSubmit(onSubmit)}
						className="w-100 mb-2"
					>
						Send
					</Button>
					<Button
						variant="trans-light border-secondary-ash"
						onClick={hideModal}
						className="w-100"
					>
						Cancel
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
};

SendItemsModal.displayName = 'SendItemsModal';
