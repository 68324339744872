import dayjs from 'dayjs';

export function formatDate(date: string) {
	return dayjs(date).format('MM/DD/YYYY');
}

export function formatUserDate(userDate: string | Date | null | undefined) {
	return dayjs(userDate).format('YYYY-MM-DD');
}

export function formatFiscalMonth(fiscalmonth: string | Date) {
	return dayjs(fiscalmonth).format('MM/YYYY');
}

export function formatNumber(value: number) {
	return value.toFixed(2);
}
