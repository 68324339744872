import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { IGetCompanyRolesResponse } from 'api/resources/signUp';
import { ECacheKeys } from 'cache/CacheKeys';
import { getCompanyRoles } from 'api/resources/signUp';

export const useGetCompanyRoles = (
	options?: UseQueryOptions<IGetCompanyRolesResponse[], unknown>
) => {
	return useQuery<IGetCompanyRolesResponse[], unknown>({
		queryKey: [ECacheKeys.SignUp, 'companyRoles'],
		queryFn: getCompanyRoles,
		refetchInterval: false,
		refetchOnWindowFocus: false,
		...(options || {}),
	});
};
