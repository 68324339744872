import { Col, Row, Form, Container, Table } from 'react-bootstrap';
import ThreadComponent from '../ThreadComponent';
import { WithRouter } from '../../../helpers/Router';
import Select from 'react-select';
import WysiwygEditor from '../../components/WysiwygEditor';
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import { mapRtfToDraft } from '../../../utilities/Rtf';

class ProjectAddMarkupProposal extends ThreadComponent {
	constructor(props) {
		super(props);
		this.updatedData = this.props.updatedData ?? {};
		this.styleOptions = [
			{
				value: 0,
				label: 'Combine',
			},
			{
				value: 1,
				label: 'List',
			},
			{
				value: 2,
				label: 'Total',
			},
			{
				value: 3,
				label: 'Ignore',
			},
		];

		this.state = {
			propremarksHtml: '',
		};

		this.htmlToRtfConverter = new HtmlToRtfBrowser();
	}

	async componentDidMount() {
		this.setFormAction(true);
		this.setState({
			propremarksHtml: await mapRtfToDraft(
				this.updatedData?.propremarksrtf ?? this.props.company?.propremarksrtf
			),
		});
	}

	getDefaultStyleValue(key) {
		return this.styleOptions.find((style) => {
			return (
				style.value === this.updatedData[key] ||
				style.value === this.props.project?.[key]
			);
		});
	}

	handleChange = (e, meta = {}) => {
		let key, value;
		if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.name ?? e.target.id;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value =
					key === 'propsupname' || key === 'propsupnum'
						? !e.target.checked
						: e.target.checked;
			}
		}
		this.props.onDataChange({ key: key, value: value });

		if (key === 'propremarks') {
			const valueHtml = this.htmlToRtfConverter.convertHtmlToRtf(e.target.html);
			this.props.onDataChange({ key: `${key}rtf`, value: valueHtml });
		}
	};

	render() {
		return (
			<>
				<Container className="ms-0">
					<Row xs={1} lg={2} className="mb-4">
						<Col className="mb-3 mb-lg-0">
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Proposal Title</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										maxLength={30}
										placeholder="Proposal Title"
										onChange={this.handleChange}
										defaultValue={
											this.updatedData.proptitle ??
											this.props.company?.proptitle
										}
										name="proptitle"
									/>
								</Col>
							</Row>

							<Row>
								<Col>
									<Table borderless className="">
										<thead>
											<tr key="0">
												<th className="fs-lg w-120px"></th>
												<th className="fs-lg">
													<Row>
														<Col>Title</Col>
														<Col>Style</Col>
													</Row>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr key={0}>
												<td className="align-middle text-end">
													{this.props.company?.compviewd}
												</td>
												<td className="pe-0">
													<Row>
														<Col>
															<Form.Control
																type="text"
																maxLength={30}
																placeholder="Proposal Title"
																onChange={this.handleChange}
																defaultValue={
																	this.updatedData.propdesigndesc ??
																	this.props.company?.propdesigndesc
																}
																name="propdesigndesc"
															/>
														</Col>
														<Col>
															<Select
																key={`${Math.floor(Math.random() * 1000)}-min`}
																options={this.styleOptions}
																className="react-select"
																name="propdesignstyle"
																defaultValue={this.getDefaultStyleValue(
																	'propdesignstyle'
																)}
																onChange={this.handleChange}
															/>
														</Col>
													</Row>
												</td>
											</tr>
											<tr key={1}>
												<td className="align-middle text-end">
													{this.props.company?.compviewf}
												</td>
												<td className="pe-0">
													<Row>
														<Col>
															<Form.Control
																type="text"
																maxLength={30}
																placeholder="Proposal Title"
																onChange={this.handleChange}
																defaultValue={
																	this.updatedData.propfreightdesc ??
																	this.props.company?.propfreightdesc
																}
																name="propfreightdesc"
															/>
														</Col>
														<Col>
															<Select
																key={`${Math.floor(Math.random() * 1000)}-min`}
																options={this.styleOptions}
																className="react-select"
																name="propfreightstyle"
																defaultValue={this.getDefaultStyleValue(
																	'propfreightstyle'
																)}
																onChange={this.handleChange}
															/>
														</Col>
													</Row>
												</td>
											</tr>
											<tr key={2}>
												<td className="align-middle text-end">
													{this.props.company?.compviewi}
												</td>
												<td className="pe-0">
													<Row>
														<Col>
															<Form.Control
																type="text"
																maxLength={30}
																placeholder="Proposal Title"
																onChange={this.handleChange}
																defaultValue={
																	this.updatedData.propinstalldesc ??
																	this.props.company?.propinstalldesc
																}
																name="propinstalldesc"
															/>
														</Col>
														<Col>
															<Select
																key={`${Math.floor(Math.random() * 1000)}-min`}
																options={this.styleOptions}
																className="react-select"
																name="propinstallstyle"
																defaultValue={this.getDefaultStyleValue(
																	'propinstallstyle'
																)}
																onChange={this.handleChange}
															/>
														</Col>
													</Row>
												</td>
											</tr>
											<tr key={3}>
												<td className="align-middle text-end">
													{this.props.company?.compviewl}
												</td>
												<td className="pe-0">
													<Row>
														<Col>
															<Form.Control
																type="text"
																maxLength={30}
																placeholder="Proposal Title"
																onChange={this.handleChange}
																defaultValue={
																	this.updatedData.proplabordesc ??
																	this.props.company?.proplabordesc
																}
																name="proplabordesc"
															/>
														</Col>
														<Col>
															<Select
																key={`${Math.floor(Math.random() * 1000)}-min`}
																options={this.styleOptions}
																className="react-select"
																name="proplaborstyle"
																defaultValue={this.getDefaultStyleValue(
																	'proplaborstyle'
																)}
																onChange={this.handleChange}
															/>
														</Col>
													</Row>
												</td>
											</tr>
											<tr key={4}>
												<td className="align-middle text-end">
													{this.props.company?.compviewo}
												</td>
												<td className="pe-0">
													<Row>
														<Col>
															<Form.Control
																type="text"
																maxLength={30}
																placeholder="Proposal Title"
																onChange={this.handleChange}
																defaultValue={
																	this.updatedData.propotherdesc ??
																	this.props.company?.propothersdesc
																}
																name="propotherdesc"
															/>
														</Col>
														<Col>
															<Select
																key={`${Math.floor(Math.random() * 1000)}-min`}
																options={this.styleOptions}
																className="react-select"
																name="propotherstyle"
																defaultValue={this.styleOptions.find(
																	(style) => {
																		return (
																			style.value ===
																			(this.updatedData.propotherstyle ??
																				this.props.company?.propothersstyle)
																		);
																	}
																)}
																onChange={this.handleChange}
															/>
														</Col>
													</Row>
												</td>
											</tr>
										</tbody>
									</Table>
								</Col>
							</Row>
						</Col>

						<Col className="mb-3 mb-lg-0">
							<Row className="align-items-center mb-5 d-none d-lg-flex">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0"></Form.Label>
								</Col>
							</Row>
							<Row className="align-items-start mb-3">
								<Col className="ps-2 ps-lg-5">
									<div className="mb-3">
										<Form.Check
											inline
											label="Show Proposal Name"
											name="propsupname"
											type="checkbox"
											id={`chk-option-propsupname`}
											className="vtop"
											defaultChecked={
												!(
													this.updatedData.propsupname ??
													this.props.company?.propsupname
												)
											}
											onClick={this.handleChange}
										/>
									</div>
									<div className="mb-3">
										<Form.Check
											inline
											label="Show Proposal Number"
											name="propsupnum"
											type="checkbox"
											id={`chk-option-propsupnum`}
											className="vtop"
											defaultChecked={
												!(
													this.updatedData.propsupnum ??
													this.props.company?.propsupnum
												)
											}
											onClick={this.handleChange}
										/>
									</div>
									<div className="mb-3">
										<Form.Check
											inline
											label="Use Name as Number"
											name="propnameasnum"
											type="checkbox"
											id={`chk-option-propnameasnum`}
											className="vtop"
											defaultChecked={
												this.updatedData.propnameasnum ??
												this.props.company?.propnameasnum
											}
											onClick={this.handleChange}
										/>
									</div>
									<div className="mb-3">
										<Form.Check
											inline
											label="Suppress Loc. Heading"
											name="propsuploc"
											type="checkbox"
											id={`chk-option-propsuploc`}
											className="vtop"
											defaultChecked={
												this.updatedData.propsuploc ??
												this.props.company?.propsuploc
											}
											onClick={this.handleChange}
										/>
									</div>
									<div className="mb-3">
										<Form.Check
											inline
											label="Suppress Unit Prices"
											name="propsupunit"
											type="checkbox"
											id={`chk-option-propsupunit`}
											className="vtop"
											defaultChecked={
												this.updatedData.propsupunit ??
												this.props.company?.propsupunit
											}
											onClick={this.handleChange}
										/>
									</div>
									<div className="mb-3">
										<Form.Check
											inline
											label="Unit to 4 Decimal Places"
											name="propunit2dec"
											type="checkbox"
											id={`chk-option-propunit2dec`}
											className="vtop"
											defaultChecked={
												this.updatedData.propunit2dec ??
												this.props.company?.propunit2dec
											}
											onClick={this.handleChange}
										/>
									</div>
								</Col>
								<Col>
									<div className="mb-3">
										<Form.Check
											inline
											label="Suppress Designer Signature"
											name="propsuppressdes"
											type="checkbox"
											id={`chk-option-propsuppressdes`}
											className="vtop"
											defaultChecked={
												this.updatedData.propsuppressdes ??
												this.props.company?.propsuppressdes
											}
											onClick={this.handleChange}
										/>
									</div>
									<div className="mb-3">
										<Form.Check
											inline
											label="Remarks on New Page"
											name="propremsep"
											type="checkbox"
											id={`chk-option-propremsep`}
											className="vtop"
											defaultChecked={
												this.updatedData.propremsep ??
												this.props.company?.propremsep
											}
											onClick={this.handleChange}
										/>
									</div>
									<div className="mb-3">
										<Form.Check
											inline
											label="Show Site Address"
											name="propshowsite"
											type="checkbox"
											id={`chk-option-propshowsite`}
											className="vtop"
											defaultChecked={
												this.updatedData.propshowsite ??
												this.props.company?.propshowsite
											}
											onClick={this.handleChange}
										/>
									</div>
									<div className="mb-3">
										<Form.Check
											inline
											label="Show Project Code"
											name="propprojcode"
											type="checkbox"
											id={`chk-option-propprojcode`}
											className="vtop"
											defaultChecked={
												this.updatedData.propprojcode ??
												this.props.company?.propprojcode
											}
											onClick={this.handleChange}
										/>
									</div>
									<div className="mb-3">
										<Form.Check
											inline
											label="Suppress Sales Tax"
											name="propshowtax"
											type="checkbox"
											id={`chk-option-propshowtax`}
											className="vtop"
											defaultChecked={
												this.updatedData.propshowtax ??
												this.props.company?.propshowtax
											}
											onClick={this.handleChange}
										/>
									</div>
									<div className="mb-3">
										<Form.Check
											inline
											label="Allow One Proposal Per Item"
											name="oneproposal"
											type="checkbox"
											id={`chk-option-oneproposal`}
											className="vtop"
											defaultChecked={
												this.updatedData.oneproposal ??
												this.props.company?.oneproposal
											}
											onClick={this.handleChange}
										/>
									</div>
									<div>
										<Form.Check
											inline
											label="Show Email & Website"
											name="propemail"
											type="checkbox"
											id={`chk-option-propemail`}
											className="vtop"
											defaultChecked={
												this.updatedData.propemail ??
												this.props.company?.propemail
											}
											onClick={this.handleChange}
										/>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>

					<div className="bg-secondary-grey mb-4 roundedx-4">
						<Row xs={1} className="py-3">
							<Col>
								<Row className="align-items-center px-3">
									<Col className="px-3">
										<h6 className="fw-bold mb-0">Remarks</h6>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>

					<Row xs={1} lg={2} className="mb-5">
						<Col className="mb-3 mb-lg-0">
							<WysiwygEditor id="propremarks" onChange={this.handleChange}>
								{this.state.propremarksHtml}
							</WysiwygEditor>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default WithRouter(ProjectAddMarkupProposal);
