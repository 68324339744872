import React, { useCallback, useMemo, useState } from 'react';
import type { ITemporaryReconciliationCheck } from 'api/resources/temporaryReconciliations';
import { useCheckbookReconcile } from './hooks/useCheckbookReconcile';
import {
	ColumnDef,
	ColumnFiltersState,
	SortingState,
	Row as TTableRow,
	FilterFn,
} from '@tanstack/react-table';
import Table from 'legacy/app/components/newTable/Table';
import { formatReconciliationCurrency } from 'legacy/utilities/formatReconciliationCurrency';
import { CheckbookReconcileCheckboxCell } from './CheckbookReconcileCheckboxCell';
import { CheckbookReconcileCheckboxHeader } from './CheckbookReconcileCheckboxHeader';
import { startOfDay, endOfDay } from 'date-fns';
import { CheckbookReconcileLinkCell } from './CheckbookReconcileLinkCell';

const CheckbookReconcileChecksTable = () => {
	const {
		tab,
		outstandingChecks,
		clearedChecks,
		isGetChecksLoading,
		selectedChecks,
		isSearchEnabled,
		handleOnCheckSelectedChange,
		checksSelectAll,
		handleOnChecksSelectAll,
		openDetailsModal,
		setCheckbookDetail,
	} = useCheckbookReconcile();

	const isOutstandingTab = tab === 'outstanding';

	const handleOnChange = useCallback(
		async (
			e: React.ChangeEvent<HTMLInputElement>,
			row?: TTableRow<ITemporaryReconciliationCheck>
		) => {
			if (!row || !row.id) return;
			await handleOnCheckSelectedChange(Number(row.id), e.target.checked);
		},
		[handleOnCheckSelectedChange]
	);

	const handleOnClick = useCallback(
		(row?: TTableRow<ITemporaryReconciliationCheck>) => {
			if (!row || !row.id) return;
			setCheckbookDetail({
				depositslip: row.original.depositslip,
				checknum: row.original.checknum,
				entrytype: row.original.entrytype,
				supplier: row.original.supplier,
				txnum: row.original.txnum,
				userdate: row.original.userdate,
			});
			openDetailsModal();
		},
		[openDetailsModal, setCheckbookDetail]
	);

	const handleOnSelectAll = useCallback(
		async (e: React.ChangeEvent<HTMLInputElement>) => {
			handleOnChecksSelectAll(e.target.checked);
		},
		[handleOnChecksSelectAll]
	);

	const [columnFiltersOutstandingChecks, setColumnFiltersOutstandingChecks] =
		useState<ColumnFiltersState>([]);
	const [columnFiltersClearedChecks, setColumnFiltersClearedChecks] =
		useState<ColumnFiltersState>([]);

	const [sortingOutstandingChecks, setSortingOutstandingChecks] =
		useState<SortingState>([]);
	const [sortingClearedChecks, setSortingClearedChecks] =
		useState<SortingState>([]);

	const columns = useMemo<ColumnDef<ITemporaryReconciliationCheck>[]>(() => {
		const dateBetweenFilterFn: FilterFn<ITemporaryReconciliationCheck> = (
			row,
			columnId,
			filterValue: [string | undefined, string | undefined]
		): boolean => {
			const dateString = row.getValue<string | undefined>(columnId);
			if (!dateString) return false;
			const [startDate, endDate] = filterValue ?? [];
			const date = new Date(dateString);
			const isAfterStartDate = startDate
				? date >= startOfDay(new Date(startDate))
				: true;
			const isBeforeEndDate = endDate
				? date <= endOfDay(new Date(endDate))
				: true;

			return isAfterStartDate && isBeforeEndDate;
		};

		return [
			{
				id: 'cleared',
				header: () =>
					CheckbookReconcileCheckboxHeader({
						handleOnChange: handleOnSelectAll,
						checked: isOutstandingTab
							? checksSelectAll.outstanding
							: checksSelectAll.cleared,
						disabled: isOutstandingTab
							? outstandingChecks.length === 0
							: clearedChecks.length === 0,
					}),
				size: 25,
				cell: ({ row }) =>
					CheckbookReconcileCheckboxCell({
						row,
						handleOnChange,
						checked: selectedChecks[row.id],
					}),
				enableColumnFilter: false,
			},
			{
				accessorKey: 'checknum',
				header: 'Check #',
				size: 50,
				cell: (info) => info.getValue(),
			},
			{
				id: 'userdate',
				accessorFn: (col) => col.userdate,
				header: 'Date',
				size: 50,
				cell: (info) =>
					CheckbookReconcileLinkCell({
						info,
						handleOnClick,
					}),
				filterFn: dateBetweenFilterFn,
				meta: {
					CustomFilterInputType: 'date',
				},
			},
			{
				accessorKey: 'amount',
				header: 'Amount',
				size: 50,
				filterFn: 'includesString',
				cell: (info) => {
					const value = info.getValue<number>() ?? 0;
					return formatReconciliationCurrency(value);
				},
				sortingFn: (rowA, rowB, columnId) => {
					const aValue = rowA.getValue<number>(columnId) ?? 0;
					const bValue = rowB.getValue<number>(columnId) ?? 0;
					return aValue - bValue;
				},
			},
			{
				accessorKey: 'supplier',
				header: 'Payee',
				size: 50,
				cell: (info) => info.getValue(),
			},
		];
	}, [
		selectedChecks,
		handleOnChange,
		checksSelectAll,
		handleOnSelectAll,
		isOutstandingTab,
		clearedChecks,
		outstandingChecks,
		handleOnClick,
	]);

	return (
		<div className="tw-pb-12">
			<div className="tw-grid tw-grid-flow-col tw-items-center tw-justify-between tw-bg-[#eeede9] tw-p-4 tw-border tw-rounded-md">
				<span className="tw-font-bold tw-text-lg">Checks</span>
			</div>
			<div className="tw-h-96 tw-overflow-auto tw-pb-4">
				{isOutstandingTab ? (
					<Table
						key="outstandingChecks"
						columns={columns}
						isLoading={isGetChecksLoading}
						state={{
							columnFilters: columnFiltersOutstandingChecks,
							sorting: sortingOutstandingChecks,
						}}
						onSortingChange={setSortingOutstandingChecks}
						onColumnFiltersChange={setColumnFiltersOutstandingChecks}
						enableFilters={isSearchEnabled}
						data={outstandingChecks}
						getRowId={(check) => check.recnum.toString()}
						useArrowDownIconAsDefault={true}
					/>
				) : (
					<Table
						key="clearedChecks"
						columns={columns}
						isLoading={isGetChecksLoading}
						state={{
							columnFilters: columnFiltersClearedChecks,
							sorting: sortingClearedChecks,
						}}
						onSortingChange={setSortingClearedChecks}
						onColumnFiltersChange={setColumnFiltersClearedChecks}
						enableFilters={isSearchEnabled}
						data={clearedChecks}
						getRowId={(check) => check.recnum.toString()}
						useArrowDownIconAsDefault={true}
					/>
				)}
			</div>
		</div>
	);
};

CheckbookReconcileChecksTable.displayName = 'CheckbookReconcileChecksTable';

export { CheckbookReconcileChecksTable };
