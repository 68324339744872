export enum FeatureFlags {
	ProjectProposalStatus = 'PROJECT_PROPOSAL_STATUS',
	SpecificationTemplates = 'SPECIFICATION_TEMPLATES',
	ProjectSchedule = 'PROJECT_SCHEDULE',
	Reporting = 'REPORTING',
	BudgetInputs = 'BUDGET_INPUTS',
	FinanceCharges = 'FINANCE_CHARGES',
	CheckbookFinanceCharges = 'CHECKBOOK_FINANCE_CHARGES',
	EnableReorder = 'ENABLE_REORDER',
	CheckbookDetailsModal = 'CHECKBOOK_DETAILS_MODAL',
	TrialDemoBanner = 'TRIAL_DEMO_BANNER',
}
